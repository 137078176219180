import moment from 'moment';
import { React, useEffect } from 'react';
import ReactTooltip from 'react-tooltip';
import { Field } from 'redux-form/immutable';
import ReactDOMServer from 'react-dom/server';

import Select from 'components/common/select/Select';
import SubmitButton from 'components/Shared/SubmitButton';

import { DATE_FORMAT_YYYY_MM_DD } from 'helpers/dateFormats';

const PayerPortalStandalone = (props) => {
  const {
    isDownloadingPDFPackage,
    standalonePayerPortalOptions,
    openPayerPortalSubmissionConfirmationPopup,
    clientPartitionId,
    onDownloadPDFPackage,
    change,
    selectedPayerContactForPayerPortal,
    autoSelectPayerDropdown,
  } = props;

  useEffect(() => {
    return () => {
      change('payerPortalOptions', null);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const downloadFileName = `${clientPartitionId}__${moment().format(
    DATE_FORMAT_YYYY_MM_DD
  )}`;

  const renderPayerPortalUrl = (payerPortalUrl) => {
    return (
      <div>
        <strong>Payer Portal Url</strong> : {payerPortalUrl}
      </div>
    );
  };

  /**
   * Renders react select option for payer portal.
   * @param {Object} option
   * @returns {JSX}
   */
  const renderPayerPortalOptionLabel = (option) => {
    const tooltipId = String(option.value);
    const dataTipContent = ReactDOMServer.renderToStaticMarkup(
      renderPayerPortalUrl(option.payerPortalUrl)
    );

    return (
      <div>
        <span data-for={tooltipId} data-tip={dataTipContent} data-html={true}>
          {option.label}
        </span>
        <ReactTooltip
          id={tooltipId}
          delayShow={300}
          effect="solid"
          place="top"
        />
      </div>
    );
  };

  const handlePayerContactChange = (selectedPayerContact) => {
    autoSelectPayerDropdown(selectedPayerContact?.payerIds);
  };

  const fileDownloadingMessage = `${downloadFileName} ${
    isDownloadingPDFPackage
      ? 'is preparing for download'
      : 'is ready for download'
  }`;

  return (
    <div>
      <div>
        {standalonePayerPortalOptions &&
          standalonePayerPortalOptions.length > 0 && (
            <div className="row">
              <label className="dropdown__label">Payer Portal: *</label>
              <Field
                component={Select}
                options={standalonePayerPortalOptions}
                name="payerPortalOptions"
                placeholder="Select Payer Contact"
                onChange={handlePayerContactChange}
                isDisabled={
                  standalonePayerPortalOptions &&
                  standalonePayerPortalOptions.length < 1
                }
                reactSelectProps={{
                  formatOptionLabel: (option) => {
                    return renderPayerPortalOptionLabel(option);
                  },
                }}
              />
            </div>
          )}
      </div>
      {selectedPayerContactForPayerPortal && (
        <div className="mt-40">
          <div className="row">
            <div className="appealio-card row mb-30">
              <div className="col-lg-8">
                <div className="appealio-card-title mb-8">
                  1. Download Package
                </div>

                <div className="appealio-card-info">
                  Click the following link to download package.
                </div>
              </div>

              <div className="col-lg-4 d-flex flex-direction--column align-item-flex-end">
                <SubmitButton
                  className="submit__button submit__button--fullwidth button--bg-primary mb-8"
                  title="Download"
                  type="button"
                  disabled={isDownloadingPDFPackage}
                  onClick={() => onDownloadPDFPackage(downloadFileName)}
                  datacy="submit-confirm-download-SubmitButton"
                />
                <span className="info-text pr-1">{fileDownloadingMessage}</span>
              </div>
            </div>

            <div className="appealio-card row mb-30">
              <div className="col-lg-8">
                <div className="appealio-card-title mt-12">
                  2. Visit Payer Portal & Submit Standalone
                </div>
              </div>

              <div className="col-lg-4 d-flex flex-direction--column align-item-flex-end mt-8">
                <a
                  href={selectedPayerContactForPayerPortal?.payerPortalUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <SubmitButton
                    className="submit__button submit__button--fullwidth button--bg-primary"
                    title="Open Payer Portal"
                    type="button"
                  />
                </a>
              </div>
            </div>

            <div className="appealio-card row mb-30">
              <div className="col-lg-8">
                <div className="appealio-card-title mb-8">
                  3. Confirm Submission
                </div>

                <div className="appealio-card-info">
                  Once the Package submitted through the Payer Portal,
                  <br /> please confirm by clicking "Confirm Submission".{' '}
                </div>
              </div>

              <div className="col-lg-4 d-flex flex-direction--column align-item-flex-end mt-8">
                <SubmitButton
                  onClick={(e) => {
                    e.preventDefault();
                    openPayerPortalSubmissionConfirmationPopup();
                  }}
                  className="submit__button submit__button--fullwidth"
                  title="Confirm Submission"
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PayerPortalStandalone;
