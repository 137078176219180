export const PCE_SEARCH_PATIENTS = 'estimator/patient/search/';
export const PCE_PATIENT_INSURANCES =
  'estimator/patient/:patientId/insurances/';
export const PCE_VERIFY_PATIENT_INSURANCE_LATEST_ELIGIBILITY =
  'estimator/patient/:patientId/insurances/:insuranceId/eligibility/';
export const PCE_PATIENT_INSURANCE_LATEST_ELIGIBILITY =
  'estimator/patient/:patientId/insurances/:insuranceId/eligibility/latest/';
export const PCE_CALCULATE_ESTIMATE =
  'estimator/patient/:patientId/insurances/:insuranceId/eligibility/:eligibilityId/calculate/?estimate=1';
export const PCE_CPT_SEARCH = 'codes/cpts/:code';
export const PCE_CALCULATE = 'estimator/calculate/';

export const CLIENT_SIGNUP = '/client/signup/';

export const RPA_LOGIN = '/api/uhc/login';
export const RPA_TWO_FA = '/api/uhc/provide2FA';
export const RPA_CLAIM_STATUS = '/api/uhc/getClaimStatus';
export const RPA_GET_BROWSER_STATUS_BY_FOR_USER = '/api/uhc/getStatusAndPageID';
export const RPA_BROWSER_WS_PAGE = '/page/:pageId?authorization=:authToken';
export const RPA_AUTO_POPULATION = 'rpa-auto-population';
export const RPA_AVAILITY_LOGIN = '/api/availity/login';
export const RPA_AVAILITY_LOGOUT = '/api/availity/logout';
export const RPA_AVAILITY_TWO_FA = '/api/availity/provide2FA';
export const RPA_AVAILITY_SCROLL = '/api/availity/scroll';
export const RPA_AVAILITY_ORGANIZATION_AND_PAYERS =
  '/api/availity/organization-payers-list';
export const RPA_AVAILITY_CLAIM_STATUS_FETCH_OPTIONS =
  '/api/availity/claim-status-fetch-options';
export const RPA_AVAILITY_CLAIM_STATUS = '/api/availity/getClaimStatus';
export const RPA_AVAILITY_GET_BROWSER_STATUS_BY_FOR_USER =
  '/api/availity/getStatusAndPageID';
export const RPA_UHC_CLOSE_PAGE = '/api/uhc/closePage';
export const RPA_AVAILITY_CLOSE_PAGE = '/api/availity/closePage';
export const CLAIM_NUMBER_SEARCH = 'claim-number-search';

export const USERS = 'users/';
export const APPEALS = 'appeals/';
export const PAYERS = 'payers-new/';
export const CONTACTS = 'contacts/';
export const CLINICS = 'clinics-new/';
export const PRACTICES = 'practices-new/';
export const CLONE_PAYERS = 'clone-payers/';
export const PAYER_CONTACTS = 'payer-contacts/';
export const PRACTICE_GROUPS = 'practice-group/';
export const DENIAL_ACTIONS = 'denial-action-config/';
export const PAYER_IDENTIFIERS = 'payer-identifiers/';
export const BILLING_PROVIDERS = 'billing-providers/';
export const REQUEST_REACTIVATE = 'request-reactivate/';
export const DEACTIVATE_PRACTICE = 'deactivate-practice/';
export const RENDERING_PROVIDERS = 'rendering-providers/';
export const SET_CLIENT_HOME_PAGE = 'set-client-home-page/';
export const UPDATE_DENIAL_ACTION_CONFIG = 'denial-action-config/';
export const SEARCH_CLINICS_FOR_PRACTICE = 'practices/search/clinics';
export const SEARCH_CONTACTS_FOR_PAYER = 'payers/search/payer-contacts';
export const CONTACTS_DV_VERIFIED = 'contacts/dv-verified/';
export const PARTNERS = 'partners/';

export const SEARCH_APPEAL_TEMPLATES_FOR_PAYER =
  'payers/search/appeal-templates';
export const SEARCH_BILLING_PROVIDERS_FOR_PRACTICE =
  'practices/search/billing-providers';

export const SEARCH_RENDERING_PROVIDERS_FOR_PRACTICE =
  'practices/search/rendering-providers';

export const MANAGE_USERS = '/manage-users/';
export const MANAGE_USERS_ACCESS_CONTROL = '/manage-users/:userId/';

export const TOP_PAYERS = '/analytics/top-payers/';
export const CLAIM_PERFORMANCE = '/analytics/claims-performance/';
export const AVERAGE_AMOUNTS_PROCEDURE_CODES =
  '/analytics/top-procedure-codes/';
export const DENAILS_REASONS_BY_CATEGORY =
  '/analytics/denial-reasons-by-category/';
export const ANALYTICS_PAYER = '/analytics/payers/';
export const ANALYTICS_PRACTICE = '/analytics/practices/';
export const ANALYTICS_DATA_COUNTS = '/analytics/data-counts/';
export const ANALYTICS_PERFORMANCE_DETAILS = '/analytics/performance-details/';
export const ANALYTICS_PERFORMANCE_DETAILS_PRACTICES = `${ANALYTICS_PERFORMANCE_DETAILS}practices`;
export const ANALYTICS_PERFORMANCE_DETAILS_RULES = `${ANALYTICS_PERFORMANCE_DETAILS}rules`;
export const ANALYTICS_PERFORMANCE_DETAILS_USERS = `${ANALYTICS_PERFORMANCE_DETAILS}users`;
export const ANALYTICS_PERFORMANCE_DETAILS_ACTION_BREAKDOWN = `${ANALYTICS_PERFORMANCE_DETAILS}actions-breakdown`;
export const ANALYTICS_SYSTEM_OVERVIEW = '/analytics/system-overview/';

export const SUBMIT_PACKAGE = 'submit-package';
export const DELIVERY_TRACKING = 'delivery-tracking/';
export const EMAIL_SUBMISSION_ALLOWED = 'email-submission-allowed';
export const PREVIEW_EOB_DOCUMENT = 'preview-eob-document';
export const PREVIEW_CMS1500_DOCUMENT = 'preview-cms1500-document';
export const EXPORT_DELIVERY_TRACKING = 'delivery-tracking/export/';
export const CREATE_SUBMIT_APPEAL = 'appeal/submit-identical/';

export const SOP_AND_RULE_INFO = 'rules/sop-information/';
export const CLAIMS_FORM_MERGE_UTILITY = 'utilities/claims-form-merge';
export const MERGE_UTILITY_CONFIGS = 'merge-utility-configs';
export const ACTION_LOGS = 'action-logs/';
export const ACTION_LOGS_COMPLETE = 'action-logs/complete';
export const APPEAL_SUBMIT_DIRECT = 'appeal_submit_direct';

export const FEATURE_FLAGS = 'feature-flags/';

export const DENIALS = 'denials';
export const DENIALS_QUEUE = 'denials-queue/';
export const DENIALS_QUEUE_COUNTS = 'denials-count/';
export const UCH_CLAIM_STATUS = '/claim-status/uhc';
export const UHC_CLAIM_STATUS_HISTORY = '/claim-status-history';
export const UHC_CLAIM_STATUS_SCHEDULE = '/claim-status/uhc/schedule';
export const AVAILITY_CLAIM_STATUS_SCHEDULE = '/claim-status/availity/schedule';
export const DENIALS_QUEUE_AGENT_ASSIGNMENT = 'denials-queue/agent-assignment/';

export const AVAILITY_CLAIM_STATUS_PAYER_CONFIG = `/claim-status/availity`;
export const AVAILITY_CLAIM_STATUS = `/claim-status/availity`;
export const AVAILITY_CLAIM_STATUS_PREFILL_DATA = `/claim-status/availity/fill`;

export const RULES = '/rules';
export const APPLIED_RULE_COUNT = 'applied-rule-count/';

export const CLIENT_REGISTRATION = '/client/registration/';
export const CLIENT_ACCOUNT_ACTIVATE = '/client/account-activate/';
export const PAYMENT_URL = '/me/payments/';

export const USERS_ME = '/users/me';

export const CREATE_OPTION = 'create-option';

export const CLIENTS = '/clients';
export const SWITCH_CLIENT = '/switch-client';
export const APPEALIO_EXPRESS_TRANSACTION_DETAILS =
  '/appealio-express/transactions-details/';
export const DOWNLOAD_PROOF_OF_SUBMISSION = 'filing/:logId/';

export const EBRIDGE_FILES = 'eBridge/files';
export const EBRIDGE_FILE_BY_ID = 'eBridge/files/:fileId';
export const EBRIDGE_INDEXES = 'eBridge/indexes';
export const EBRIDGE_LOG_ATTACHMENT = 'eBridge/log-attachment';

export const EXPORT_ALL_CLAIMS = 'claims/export-all/';

export const TEAM_DASHBOARD_COUNTS = 'team-dashboard-counts';

export const APPEAL_SUBMIT_PAYER_PORTAL = '/appeal-submit-payer-portal/';

export const CREATE_MANUAL_CLAIM = 'manual-claims/';
export const APPEAL_MANUAL_CLAIM = '/manual-claims/import/';

export const VALIDATE_MANUAL_CLAIM_CSV = '/manual-claims-csv-validate/';
export const UPLOAD_MANUAL_CLAIM_CSV = '/manual-claims-csv-upload/';

export const APPEALS_FILTER_OPTIONS_COUNTS = 'appeals/filter-options-counts/';
export const SUBMISSIONS_NOTES = '/notes/';

export const NOTES_COUNT = '/notes/count/';

export const DENIALS_REMINDERS = '/denials-reminders/';

export const CREATE_SAME_CLAIM_DIFFERENT_ATTACHMENT =
  'appeal/create-identical/same-claim/';

export const CREATE_IDENTICAL_IN_PROGRESS =
  'appeal/create-identical-in-progress/';

export const APPEAL_LETTER_PDFS = 'appeal_letter_pdfs/';
export const SOP = '/rules/sop/';
export const SOP_LIST = 'rules/:id/sops/';
export const DELETE_SOP_STEP = '/rules/sop/steps/';

export const COPY_PM_NOTE = 'denials/copy-pm-note/';

export const CREATE_OR_FETCH_REMINDER = `${DENIALS_QUEUE}:dId/reminder/`;
export const UPDATE_OR_DELETE_REMINDER = `${CREATE_OR_FETCH_REMINDER}:rId/`;
export const RESOLVE_REMINDER = `${UPDATE_OR_DELETE_REMINDER}resolve/`;
export const NOTIFICATION = 'notification/';

export const TEAMS = 'teams/';
export const INDIVIDUAL_TEAM = `${TEAMS}:id/`;
export const APPEAL_DOCUMENTS = 'appeal_documents/';
export const DOWNLOAD_APPEAL_DOCUMENT = 'download_appeal_document/:documentId/';
export const REQUEST_PM_NOTE_TEMPLATE = 'pm-note-templates/request/';
export const PROPOSED_ACTIONS = 'rules/proposed-actions/';

export const DENIALS_AND_CLAIMS = 'denials-and-claims';
export const BATCH_IMPORT = 'claims/batch-import/';
export const BATCH_IMPORT_STATUS = 'batch-jobs/:jobId/status/';
export const FETCH_PORTAL_FIELDS = `appeals/:id/portal-fields`;
