import React from 'react';

import SearchBox from 'components/common/search';
import Dropdown from 'components/Shared/Dropdown';

const FilterSection = ({
  filters,
  searchTerm,
  onFilterChange,
  onSearchChange,
  dropdownOptions,
}) => {
  const handleSelectedPayerChange = (value) => onFilterChange('payer', value);
  const handleSelectedPracticeChange = (value) =>
    onFilterChange('practice', value);
  const handleSelectedProviderChange = (value) =>
    onFilterChange('providers', value);
  const handleSelectedCptCodeChange = (value) =>
    onFilterChange('procedures', value);
  const handleSelectedProcedureModifierChange = (value) =>
    onFilterChange('modifiers', value);
  const handleSelectedReasonCodeChange = (value) =>
    onFilterChange('reasons', value);
  const handleSelectedRemarkCodeChange = (value) =>
    onFilterChange('remarks', value);

  const debounceOnGeneralSearchFilterChange = (value) => {
    onSearchChange(value);
  };

  return (
    <div className="denied-claims-filter__row">
      <div className="">
        <div className="">&nbsp;</div>
        <div className="batch-submission-filter__search_string_filter">
          <SearchBox
            placeholder="Search by Name & IDs"
            className="mt-4 mr-8"
            onSearch={debounceOnGeneralSearchFilterChange}
          />
        </div>
      </div>
      <div className="mr-8 width-150">
        <Dropdown
          label="Payer"
          name="payer"
          placeholder="All"
          onChange={handleSelectedPayerChange}
          value={filters.payer}
          isClearable={false}
          options={dropdownOptions?.payers?.data}
          isLoading={false}
          isDisabled
        />
      </div>
      <div className="mr-8 width-150">
        <Dropdown
          label="Practice"
          name="practice"
          placeholder="All"
          isClearable={false}
          onChange={handleSelectedPracticeChange}
          value={filters.practice}
          options={dropdownOptions?.practices?.data}
          isLoading={false}
        />
      </div>
      <div className="mr-8 width-150">
        <Dropdown
          label="Provider"
          name="Provider"
          placeholder="All"
          onChange={handleSelectedProviderChange}
          value={filters.providers}
          isClearable={false}
          options={dropdownOptions?.providers?.data}
          isLoading={false}
        />
      </div>
      <div className="mr-8 width-150">
        <Dropdown
          label="Procedure"
          name="cpt-code"
          placeholder="All"
          onChange={handleSelectedCptCodeChange}
          value={filters.procedures}
          isClearable={false}
          options={dropdownOptions?.cptCodes?.data}
          isLoading={false}
          datacy="procedure-Dropdown"
        />
      </div>
      <div className="mr-8 width-150">
        <Dropdown
          label="Modifier"
          name="procedure-modifiers"
          placeholder="All"
          onChange={handleSelectedProcedureModifierChange}
          value={filters.modifiers}
          isClearable={false}
          options={dropdownOptions?.procedureModifiers?.data}
          isLoading={false}
          datacy="modifier-Dropdown"
        />
      </div>
      <div className="mr-8 width-150">
        <Dropdown
          label="Reason"
          name="reason-code"
          placeholder="All"
          onChange={handleSelectedReasonCodeChange}
          value={filters.reasons}
          isClearable={false}
          options={dropdownOptions?.reasonCodes?.data}
          isLoading={false}
          datacy="reason-Dropdown"
        />
      </div>
      <div className="mr-8 width-150">
        <Dropdown
          label="Remark"
          name="remark-codes"
          placeholder="All"
          onChange={handleSelectedRemarkCodeChange}
          value={filters.remarks}
          isClearable={false}
          options={dropdownOptions?.remarkCodes?.data}
          isLoading={false}
          datacy="remark-Dropdown"
        />
      </div>
    </div>
  );
};

export default FilterSection;
