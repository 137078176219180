export const LOGIN = '/login';

export const CREATE_APPEAL = '/createappeal';
export const CREATE_APPEAL_PATIENT_INFO = '/createappeal/patientinfo';
export const CREATE_APPEAL_DENIAL_INFO = '/createappeal/denialinfo';
export const CREATE_APPEAL_APPEAL_LETTER = '/createappeal/appealletter';
export const CREATE_APPEAL_DOCUMENTS = '/createappeal/documents';
export const CREATE_APPEAL_REVIEW = '/createappeal/review';
export const CREATE_APPEAL_SUBMIT_CONFIRM = '/createappeal/submitconfirm';

export const SUBMISSIONS = '/submissions';
export const SUBMISSIONS_IN_PROGRESS = `${SUBMISSIONS}/in-progress`;
export const DASHBOARD_MEDICAL_RECORDS = '/dashboard/medical-records';

export const APPEAL_HISTORY = '/appeal/history/:appealID';

export const RE_APPEAL = '/reappeal/:appealID';

export const DENIED_QUEUE_ARCHIVED = '/denied-queue/archived';
export const DENIED_QUEUE = '/denied-queue';

export const DENIED_QUEUE_LOGS = '/denied-queue/logs';
export const DENIED_QUEUE_LOGS_ARCHIVED = '/denied-queue/logs/archived';
export const DENIED_QUEUE_COMPLETED_LOGS = '/denied-queue/logs/completed';
export const DENIED_QUEUE_COMPLETED_LOGS_ARCHIVED =
  '/denied-queue/logs/completed/archived';

export const CLAIM_STATUS_TRACKER = '/claim-status-tracker';

export const UTILITIES = '/utilities';
export const CMS_1500_MERGE_TOOL = '/cms-1500-merge-tool';
export const UB_04_MERGE_TOOL = '/ub-04-merge-tool';

export const HELP = '/help';
export const MANUAL_ERA_UPLOAD = '/manual-era-upload';

export const V3_ANALYTICS = '/v3/analytics';

export const FORGOT_PASSWORD = '/forgotpassword';
export const REQUEST_NEW_PASSWORD = '/request-new-password';
export const PASSWORD_SENT = '/password/sent';
export const RESET_PASSWORD = '/password/reset/confirm/:uid/:token';
export const CREATE_PASSWORD = '/password/create/:uid/:token';

export const MANAGER_SETTINGS = '/manager/settings';
export const MANAGER_DASHBOARD = '/manager/dashboard';
export const ANALYTICS = '/manager/dashboard/analytics';
export const MANAGER_DASHBOARD_TEAM = '/manager/dashboard/team';
export const MANAGER_DASHBOARD_OVERVIEW = '/manager/dashboard/overview';
export const DENIALS_QUEUE_ANALYTICS = '/manager/dashboard/dq-analytics';

export const PATIENT_COST_ESTIMATOR = '/estimator';
export const PATIENT_COST_ESTIMATOR_CREATE_ESTIMATE = '/estimator/create';

export const CREATE_APPEALIO_ACCOUNT_INTRO = '/create-account/intro';
export const CREATE_APPEALIO_ACCOUNT_DETAIL = '/create-account/detail';

export const MANAGER_SETTINGS_ACCOUNT = '/manager/settings/account';
export const MANAGER_SETTINGS_ORGANIZATION = '/manager/settings/organization';
export const MANAGER_SETTINGS_PAYER_PORTALS = '/manager/settings/payer-portals';

export const MANAGER_SETTINGS_ORGANIZATION_USERS =
  '/manager/settings/organization/users';
export const MANAGER_SETTINGS_ORGANIZATION_PAYERS =
  '/manager/settings/organization/payers';
export const MANAGER_SETTINGS_ORGANIZATION_DENIAL_ACTIONS =
  '/manager/settings/organization/denial-actions';
export const MANAGER_SETTINGS_ORGANIZATION_CLINICS =
  '/manager/settings/organization/clinics';
export const MANAGER_SETTINGS_ORGANIZATION_TEMPLATES =
  '/manager/settings/organization/templates';
export const MANAGER_SETTINGS_ORGANIZATION_PRACTICES =
  '/manager/settings/organization/practices';
export const MANAGER_SETTINGS_ORGANIZATION_PRACTICE_GROUP =
  '/manager/settings/organization/practice-groups';
export const MANAGER_SETTINGS_ORGANIZATION_PAYER_CONTACTS =
  '/manager/settings/organization/payers-contacts';
export const MANAGER_SETTINGS_ORGANIZATION_BILLING_PROVIDERS =
  '/manager/settings/organization/billing-providers';
export const MANAGER_SETTINGS_ORGANIZATION_RENDERING_PROVIDERS =
  '/manager/settings/organization/rendering-providers';

export const MANAGER_SETTINGS_ORGANIZATION_TEAMS =
  '/manager/settings/organization/teams';

export const SIGNUP = '/signup';
export const USER_CONFIRMATION = '/user/confirmation';
export const ACTIVATE_USER = '/client/account-activate';

export const PAYMENT_DETAILS = '/payment/details';

export const EXPRESS_SETTINGS = '/express/settings';
export const EXPRESS_SETTINGS_USERS = '/express/settings/users';
export const EXPRESS_SETTINGS_RETURN_CONTACTS =
  '/express/settings/return-contacts';
export const EXPRESS_SETTINGS_DESTINATION_CONTACTS =
  '/express/settings/destination-contacts';

export const NOT_FOUND = '/404-not-found';
