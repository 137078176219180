import classnames from 'classnames';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { FaSearch } from 'react-icons/fa';
import { bindActionCreators } from 'redux';
import { BsEyeFill } from 'react-icons/bs';
import { BiDownload } from 'react-icons/bi';
import { VscVerified } from 'react-icons/vsc';
import { AiOutlineRight } from 'react-icons/ai';
import { isInteger, isEmpty, get } from 'lodash';
import { FiBox, FiAlignJustify } from 'react-icons/fi';
import { push, replace } from 'connected-react-router';
import React, { useCallback, useMemo, useState } from 'react';

import { mimeTypes } from 'helpers/mimeTypes';
import { moneyFormatter } from 'helpers/money';
import { capitalizeFirstLetter } from 'helpers/string';

import { getUserInfo } from 'redux/reducers/loginStore';
import { createSubmitPackage } from 'redux/actions/createSubmitPackageActions';
import { openUhcClaimStatusWidget } from 'redux/actions/uhcClaimStatusWidgetActions';
import { openAvailityClaimStatusWidget } from 'redux/actions/availityClaimStatusWidgetActions';
import {
  clearCurrentAppealData,
  setAppealData,
  createReAppeal,
  getAppealById,
} from 'redux/actions/createAppealActions';

import './style.css';

import CancelPopup from './CancelPopup';
import Table from 'components/common/table';
import Counter from 'components/common/counter';
import * as toast from 'components/Shared/toast';
import NotesPopup from 'components/DenialQueue/Notes';
import ReminderPopup from 'components/Shared/Reminder';
import SopInfoIcon from 'components/Shared/SopInfoIcon';
import StatusCircle from 'components/common/statusCircle';
import ReminderIcon from 'components/Shared/ReminderIcon';
import AppealioPopup from 'components/Shared/AppealioPopup';
import RightSlideOut from 'components/Shared/RightSlideOut';
import RecordResponse from 'components/Dashboard/RecordResponse';
import LoadingBlockHelper from 'components/Shared/LoadingBlockHelper';
import ActionPopup, { TYPE_ACCEPT } from 'components/Shared/ActionPopup';
import DocumentViewer from 'components/CreateAppeal/Review/DocumentViewer';
import ResendIdenticalAppeal from '../DuplicateClaim/ResendIdenticalAppeal';
import AppealDetails from 'components/Dashboard/AppealDetails/AppealDetails';
import FaxSubmissionDetails from '../SubmissionDetails/FaxSubmissionDetails';
import MailSubmissionDetails from '../SubmissionDetails/MailSubmissionDetails';
import EmailSubmissionDetails from '../SubmissionDetails/EmailSubmissionDetails';
import DirectSubmissionDetails from '../SubmissionDetails/DirectSubmissionDetails';
import PortalSubmissionDetails from '../SubmissionDetails/PortalSubmissionDetails';
import SameClaimEditAttachmentsPopup from '../DuplicateClaim/SameClaimEditAttachmentsPopup';
import OutsideClickWrapper from 'components/common/outsideClickWrapper/OutsideClickWrapper';

import emptyIcon from 'img/empty.svg';
import checkIcon from 'img/appeal_check.svg';
import EmailCheckIcon from 'img/email-check.svg';
import { MdOutlineEventNote } from 'react-icons/md';
import dropArrowCircle from 'img/drop-arrow-circle.svg';

import * as routes from 'constants/routes';
import { SUBMISSION_MENU_OPTION } from './constants';
import { STATUS_OPTIONS_TYPE } from '../DeliveryTrackerActionBar/constant';

import AppealsAPI from 'API/AppealsAPI';
import CreateAppealAPI from 'API/CreateAppealAPI';

import { handleError } from 'helpers/errorHandler';

import {
  downloadPreviewPackage,
  createSubmittedPackageLink,
  downloadProofOfSubmission,
} from 'API/DeliveryLogsAPI';
import { isAppealioExpressUser, isClientAdmin } from 'Auth/AuthUtils';
import { EOBProcedureTransform } from 'API/Serializers/EOB';
import { useRemindersData } from 'hooks/components/useRemindersData';
import { openBatchUploadWidget } from 'redux/actions/batchUploadWidgetActions';

import {
  isAvailityClaimStatusCheckEnabledForUser,
  isUHCClaimStatusCheckEnabledForUser,
  isUserAuthorizedForBatchSubmission,
} from 'Auth/FeatureFlags';

import { PAYER_API_TYPE, SUBMISSION_TYPE } from 'constants/appConstants';
import { DATE_FORMAT_MM_DD_YYYY, getFormattedDate } from 'helpers/dateFormats';
import { getUHCClaimStatusSearchDataForDeliveryTracker } from 'components/GlobalSearch/hook';
import {
  getSopIds,
  hasSopIds,
  useFetchNotesCounts,
  useFetchSopAndRuleInfo,
} from '../hooks';
import { isDateWithinPastDays } from 'helpers/utils';

const HandleEmptyValueCell = ({ value }) => {
  return value && value.trim() ? value : 'N/A';
};

const BuildAmountCell = ({ value, row: { original } }) => {
  const amount = value;
  const readOnly = original?.readOnly;
  if (Number(amount) === 0) {
    return (
      <div
        className={classnames('appealio-table__custom-cell', {
          'appealio-table__body-row--disabled': readOnly,
        })}
      >
        $0.00
      </div>
    );
  }

  const formattedAmount = isInteger(amount)
    ? amount
    : parseFloat(amount).toFixed(2);

  const formattedText = moneyFormatter().format(formattedAmount);

  return (
    <div
      className={classnames('appealio-table__custom-cell', {
        'appealio-table__body-row--disabled': readOnly,
      })}
    >
      {formattedText}
    </div>
  );
};

const DateOfServiceCell = ({ value, row: { original } }) => {
  const date = value;
  const readOnly = original?.readOnly;
  if (!date || !date.trim())
    return (
      <div
        className={classnames('appealio-table__custom-cell', {
          'appealio-table__body-row--disabled': readOnly,
        })}
      >
        N/A
      </div>
    );

  let cellValue;
  let toolTip;
  const [fromDate, toDate] = date.split(' to ');
  if (fromDate === toDate) {
    cellValue = getFormattedDate(fromDate);
    toolTip = getFormattedDate(fromDate);
  } else {
    cellValue = `${getFormattedDate(fromDate)} to ${getFormattedDate(toDate)}`;
    toolTip = cellValue;
  }

  return (
    <div
      className={classnames('appealio-table__custom-cell', {
        'appealio-table__body-row--disabled': readOnly,
      })}
    >
      <span
        data-tip={toolTip}
        data-for="dateOfService"
        data-class="interactive-tooltip"
      >
        {cellValue}
      </span>
      <ReactTooltip
        id="dateOfService"
        effect="solid"
        className="interactive-tooltip"
        delayHide={600}
        place="top"
      />
    </div>
  );
};

const CustomCell = (props) => {
  const {
    value,
    row: { original },
    accessor,
  } = props;
  const isEmptyString = !value?.trim();
  const cellValue = isEmptyString ? 'N/A' : value;
  const toolTip = isEmptyString ? '' : value;
  const readOnly = accessor === 'payer' ? original?.readOnly : false;

  return (
    <div
      className={classnames('appealio-table__custom-cell', {
        'appealio-table__body-row--disabled': readOnly,
      })}
    >
      <span {...(toolTip === '' ? {} : { 'data-tip': toolTip })}>
        {cellValue}
      </span>
      <ReactTooltip effect="solid" />
    </div>
  );
};

const TypeStatusCell = ({ row }) => {
  const { original: appeal } = row;
  const value = appeal.submissionType;
  const isEmptyString = !value;
  const cellValue = isEmptyString ? 'N/A' : value;
  const toolTip = isEmptyString ? '' : value;
  const appealStatus = appeal.parentAppealStatus || appeal.appealStatus;
  return (
    <div className="appealio-table__custom-cell table-status__cell position-relative">
      <div className="counter-wrapper-cell ml-4 mr-2">
        {appeal.appealRound > 1 && <Counter value={appeal.appealRound} />}
      </div>
      <span
        {...(toolTip === '' ? {} : { 'data-tip': toolTip })}
        className="delivery-tracker__status-cell-value"
      >
        {cellValue}
      </span>
      <StatusCircle statusCode={appealStatus} />
      <ReactTooltip effect="solid" />
    </div>
  );
};

const CustomProviderCell = ({ row }) => {
  const providerName = row.original.provider || 'N/A';
  const practiceName = row.original.practice || 'N/A';
  const readOnly = row.original?.readOnly;
  return (
    <div
      className={classnames('appealio-table__custom-cell', {
        'appealio-table__body-row--disabled': readOnly,
      })}
    >
      <span
        {...(providerName === 'N/A' ? {} : { 'data-tip': providerName })}
        data-for="practice-provider-tooltip"
      >
        {providerName}
      </span>
      <br />
      {practiceName && (
        <span
          {...(practiceName === 'N/A' ? {} : { 'data-tip': practiceName })}
          data-for="practice-provider-tooltip"
        >
          ({practiceName})
        </span>
      )}
      <ReactTooltip
        id="practice-provider-tooltip"
        className="interactive-tooltip"
        delayHide={600}
        effect="solid"
        place="left"
      />
    </div>
  );
};
const TagsCell = ({ value: array, row: { original } }) => {
  const readOnly = original?.readOnly;
  if (isEmpty(array)) {
    const handleClick = (e) => {
      e.preventDefault();
      e.stopPropagation();
    };

    return (
      <div
        onClick={handleClick}
        className={classnames('appealio-table__custom-cell', {
          'appealio-table__body-row--disabled': readOnly,
        })}
      >
        <span>N/A</span>
      </div>
    );
  }

  const firstArrayItem = array[0];
  const arrayLength = array.length;

  let dataToRender =
    arrayLength === 1
      ? firstArrayItem
      : `${firstArrayItem} +${arrayLength - 1}`;

  if (isAppealioExpressUser) {
    dataToRender = array.join(', ');
  }

  const dataTip = array.length === 1 ? array : array.join(', ');

  return (
    <div className={classnames('appealio-table__custom-cell')}>
      <span data-for="array-tooltip" data-tip={dataTip}>
        {dataToRender}
      </span>
      <ReactTooltip
        id="array-tooltip"
        className="interactive-tooltip"
        delayHide={600}
        effect="solid"
        place="left"
      />
    </div>
  );
};

const ICON_MAP = {
  'In Transit': <FiBox className="delivery-status-icon" />,
  Delivered: <img src={EmailCheckIcon} />,
  'Under Review': <FiAlignJustify className="delivery-status-icon" />,
  Queued: <FiAlignJustify className="delivery-status-icon" />,
};

const STATUS_CLASSNAME_MAP = {
  'In Transit': 'delivery-logs-status-col--transit',
  Delivered: 'delivery-logs-status-col--delivered',
  'Under Review': 'delivery-logs-status-col--under-review',
  Queued: 'delivery-logs-status-col--queue',
};

const notesDefaultInfo = {
  isOpen: false,
  appealId: null,
  claimId: null,
  claimNumber: null,
};

const SubmissionDetailContent = ({ submissionDetails }) => {
  if (submissionDetails.type === 'Mail') {
    return (
      <MailSubmissionDetails
        data={submissionDetails.data}
        status={submissionDetails.status}
        tags={submissionDetails.tags}
      />
    );
  } else if (submissionDetails.type === 'Email') {
    return (
      <EmailSubmissionDetails
        data={submissionDetails.data}
        tags={submissionDetails.tags}
        submissionId={submissionDetails.submissionId}
      />
    );
  } else if (submissionDetails.type === 'Fax') {
    return (
      <FaxSubmissionDetails
        data={submissionDetails.data}
        tags={submissionDetails.tags}
      />
    );
  } else if (submissionDetails.type === 'Direct') {
    const subStatus = submissionDetails.data.deliverSubStatus;
    const lastStatusChange = submissionDetails.data.lastStatusChange;
    const lastStatusCheck = submissionDetails.data.lastStatusCheck;
    const externalAppealId = submissionDetails.data?._id;
    const data = {
      label: 'API',
      value: 'UHC',
      subStatus,
      lastStatusChange,
      lastStatusCheck,
      externalAppealId,
    };
    return (
      <DirectSubmissionDetails data={data} tags={submissionDetails.tags} />
    );
  } else {
    return (
      <PortalSubmissionDetails data={submissionDetails.data?.payerPortalUrl} />
    );
  }
};

const SubmissionMenu = ({
  submission,
  selectedRowId,
  setSelectedRowId,
  setAppealActionFlyover,
  setShowClaimDetailFlyover,
  setCancelPopup,
  isAppealioExpressUser,
  setSameClaimEditAttachmentsPopup,
  setResendIdenticalAppealPopup,
  openBatchUploadWidget,
  userInfo,
}) => {
  const status = submission.original?.status;
  const readOnly = submission.original?.readOnly;
  const appealId = submission?.original?.appealId;
  const reAppealId = submission?.original?.reAppealId;
  const submissionId = submission?.original?.submissionId;
  const submissionType = submission?.original?.submissionType;
  const recordResponseAppealId = submission?.original?.recordResponseAppealId;

  const isEmailSubmission =
    submission.original?.method.toLowerCase() === 'email';
  const showEditCancelOption =
    !isEmailSubmission &&
    (submissionType === SUBMISSION_TYPE.APPEAL
      ? status === STATUS_OPTIONS_TYPE.UNDER_REVIEW ||
        status === STATUS_OPTIONS_TYPE.QUEUED
      : submissionType === SUBMISSION_TYPE.RECORD
      ? status === STATUS_OPTIONS_TYPE.UNDER_REVIEW ||
        status === STATUS_OPTIONS_TYPE.QUEUED
      : (submissionType === SUBMISSION_TYPE.STANDALONE &&
          !isAppealioExpressUser &&
          status === STATUS_OPTIONS_TYPE.UNDER_REVIEW) ||
        status === STATUS_OPTIONS_TYPE.QUEUED);

  const showResolveResponseOption =
    reAppealId &&
    submissionType === SUBMISSION_TYPE.APPEAL &&
    status === STATUS_OPTIONS_TYPE.DELIVERED;

  const showRecordResponseOption =
    recordResponseAppealId &&
    submissionType === SUBMISSION_TYPE.APPEAL &&
    [STATUS_OPTIONS_TYPE.DELIVERED, STATUS_OPTIONS_TYPE.IN_TRANSIT].includes(
      status
    );

  const showViewDetailsOption =
    submissionType === SUBMISSION_TYPE.APPEAL &&
    status === STATUS_OPTIONS_TYPE.DELIVERED;

  const showDuplicateOption =
    [STATUS_OPTIONS_TYPE.IN_TRANSIT, STATUS_OPTIONS_TYPE.DELIVERED].includes(
      status
    ) &&
    [SUBMISSION_TYPE.RECORD, SUBMISSION_TYPE.APPEAL].includes(submissionType);

  const handleEdit = () => {
    const submissionDetails = submission.original?.detail || {};
    submissionDetails.tags = submission.original?.searchTags || [];
    submissionDetails.practiceName = submission.original?.practice || '';
    submissionDetails.payerName = submission.original?.payer || '';
    setCancelPopup({
      isOpen: true,
      type: SUBMISSION_MENU_OPTION.EDIT,
      submissionId: selectedRowId,
      appealId: submission.original?.appealId,
      submissionType: submission.original?.submissionType,
      submissionDetails,
    });
  };

  const handleCancel = () =>
    setCancelPopup({
      isOpen: true,
      type: SUBMISSION_MENU_OPTION.CANCEL,
      submissionId: selectedRowId,
      submissionType: submission.original?.submissionType,
      fileUrl: submission?.original?.fileUrl,
    });

  const handleBatchDuplicateDifferentClaims = () => {
    openBatchUploadWidget({
      appealIdToDuplicate: appealId,
    });
  };

  const handleSameClaimEditAttachments = () =>
    setSameClaimEditAttachmentsPopup({
      isOpen: true,
      appealId: submission.original?.appealId,
      patientName: submission.original?.patient,
      claimId:
        submissionType === SUBMISSION_TYPE.RECORD
          ? submission.original?.submitterClaimId
          : submission.original?.claimNumber,

      submissionId,
      submissionType,
    });

  const handleResendIdenticalAppeal = () => {
    setResendIdenticalAppealPopup({
      isOpen: true,
      deliveryDetail: submission.original.detail,
      appealId: submission.original.appealId,
      submissionId: submission.original.submissionId,
      patientName: submission.original.patient,
      claimNumber: submission.original.claimNumber,
      logId: submission.original.id,
      payerId: submission.original.payerId,
      method: submission.original.method,
      submissionType,
    });
  };

  const RenderEditAndCancelOption = () => {
    return (
      <>
        <button className="ap-dropmenu__item" onClick={handleEdit}>
          <span>Edit</span>
        </button>
        <button className="ap-dropmenu__item" onClick={handleCancel}>
          <span>Cancel</span>
        </button>
      </>
    );
  };

  const RenderRecordResponseOption = () => {
    return (
      <button
        className="ap-dropmenu__item"
        onClick={() =>
          setAppealActionFlyover({
            isOpen: true,
            appealId: recordResponseAppealId,
          })
        }
      >
        <span>Record Response</span>
      </button>
    );
  };

  const RenderResolveClaimOption = () => {
    return (
      <button
        className="ap-dropmenu__item"
        onClick={() =>
          setAppealActionFlyover({
            isOpen: true,
            appealId: reAppealId,
            isResolveClaim: true,
          })
        }
      >
        <span>Resolve Claim</span>
      </button>
    );
  };

  const RenderViewDetailsOption = () => {
    return (
      <button
        className="ap-dropmenu__item"
        onClick={() =>
          setShowClaimDetailFlyover({
            isOpen: true,
            appealId,
            defaultActiveTabKey: '4',
          })
        }
      >
        <span>View Details</span>
      </button>
    );
  };

  const RenderBatchDuplicateOption = () => {
    if (!isUserAuthorizedForBatchSubmission(userInfo)) return null;

    return (
      <div className="ap-dropmenu__item--submenu-wrapper">
        <div
          className="ap-dropmenu__item"
          onClick={handleBatchDuplicateDifferentClaims}
        >
          <span>Batch Duplicate - Different Claim(s)</span>
        </div>
      </div>
    );
  };

  const RenderDuplicateOption = () => {
    return (
      <div className="ap-dropmenu__item--submenu-wrapper">
        <div className="ap-dropmenu__item" onClick={(e) => e.stopPropagation()}>
          <span>Duplicate - Same Claim</span>
          <span>
            <AiOutlineRight />
          </span>
        </div>
        <div className="ap-dropmenu__wrapper--nested">
          <div className="ap-dropmenu ap-dropmenu--visible">
            <button
              className="ap-dropmenu__item"
              onClick={handleResendIdenticalAppeal}
            >
              Resend Identical Submission
            </button>
            <button
              className="ap-dropmenu__item"
              onClick={handleSameClaimEditAttachments}
            >
              Same Claim, Edit Attachments
            </button>
          </div>
        </div>
      </div>
    );
  };
  const shouldReturnEmpty =
    readOnly ||
    (!showEditCancelOption &&
      !showRecordResponseOption &&
      !showViewDetailsOption &&
      !showDuplicateOption);

  if (shouldReturnEmpty) {
    return '';
  }

  return (
    <div className="delivery-logs-status-menu">
      <OutsideClickWrapper handleOutsideClick={() => setSelectedRowId(null)}>
        <div
          className="ap-dropmenu__wrapper"
          onClick={(e) => {
            setSelectedRowId(
              selectedRowId === submissionId ? null : submissionId
            );
          }}
        >
          <img
            src={dropArrowCircle}
            className={classnames('ml-4', {
              'drop-arrow-circle--open':
                selectedRowId === submission.original?.submissionId,
            })}
          />
          <div
            className={classnames('ap-dropmenu', {
              'ap-dropmenu--visible': selectedRowId === submissionId,
            })}
          >
            {showResolveResponseOption ? (
              <RenderResolveClaimOption />
            ) : (
              showRecordResponseOption && <RenderRecordResponseOption />
            )}
            {showEditCancelOption && <RenderEditAndCancelOption />}
            {showDuplicateOption && <RenderDuplicateOption />}
            {showDuplicateOption && <RenderBatchDuplicateOption />}
            {showViewDetailsOption && <RenderViewDetailsOption />}
          </div>
        </div>
      </OutsideClickWrapper>
    </div>
  );
};

const DeliveryLogsTable = (props) => {
  const {
    isLoading,
    onSort,
    sortBy,
    userInfo,
    fetchDeliveryLogs,
    agentsList,
    actions: { openUhcClaimStatusWidget, openAvailityClaimStatusWidget },
    handleResetClick,
  } = props;

  const data = props.data.map((item) => ({
    ...item,
    readOnly:
      item.readOnly ||
      (item.submissionType !== SUBMISSION_TYPE.STANDALONE &&
        !item.reAppealId &&
        !item.recordResponseAppealId),
  }));

  const defaultPackagePreviewLink = useMemo(
    () => ({
      isOpen: false,
      isLoading: false,
      link: '',
      title: '',
      fileNameForSave: '',
    }),
    []
  );

  const [isFetchingSubmitPackage, setIsFetchingSubmitPackage] = useState(false);
  const [packagePreviewLink, setPackagePreviewLink] = useState(
    defaultPackagePreviewLink
  );

  const [previewLetterForm, setPreviewLetterForm] = useState({
    isOpen: false,
    fileUrl: null,
  });

  const [appealActionFlyover, setAppealActionFlyover] = useState({
    isOpen: false,
    appealId: null,
    isResolveClaim: false,
  });

  const [showClaimDetailFlyover, setShowClaimDetailFlyover] = useState({
    isOpen: false,
    appealId: null,
    defaultActiveTabKey: '4',
  });

  const [closeAppealPopup, setCloseAppealPopup] = useState({
    isOpen: false,
    appeal: null,
    serviceDates: [],
  });

  const [selectedRowId, setSelectedRowId] = useState(null);
  const [cancelPopup, setCancelPopup] = useState({
    isOpen: false,
    type: null,
    submissionId: null,
    submissionDetails: null,
  });
  const [notesPopup, setNotesPopup] = useState(notesDefaultInfo);
  const [reminderPopup, setReminderPopup] = useState({
    isOpen: false,
    claimId: null,
    denialsQueueId: null,
    reminderId: null,
  });
  const [sameClaimEditAttachmentPopup, setSameClaimEditAttachmentsPopup] =
    useState({
      isOpen: false,
    });

  const [resendIdenticalAppealPopup, setResendIdenticalAppealPopup] = useState({
    isOpen: false,
  });

  const DEFAULT_SUBMISSION_DETAILS_STATE = {
    data: {},
    isVisible: false,
    type: null,
    status: '',
    tags: [],
    submissionId: null,
  };

  const [submissionDetails, setSubmissionDetails] = useState(
    DEFAULT_SUBMISSION_DETAILS_STATE
  );
  const resetModal = () => {
    setSubmissionDetails(DEFAULT_SUBMISSION_DETAILS_STATE);
  };

  const closeRecordResponseFlyover = () => {
    setAppealActionFlyover({
      isOpen: false,
      appealId: null,
      isResolveClaim: false,
    });
    fetchDeliveryLogs();
  };

  const appealIds = useMemo(
    () =>
      props.data.reduce((ids, element) => {
        if (element?.appealId != null) {
          ids.push(Number(element.appealId));
        }
        return ids;
      }, []),
    [props.data]
  );

  const onClosePressed = () => {
    setResendIdenticalAppealPopup({
      isOpen: false,
      deliveryDetail: null,
      appealId: null,
      submissionId: null,
      patientName: null,
      claimNumber: null,
      logId: null,
      payerId: null,
      id: null,
    });
  };

  const handleOnSubmitResendIdenticalAppeal = useCallback(() => {
    toast.success({
      title: 'Success',
      message: `Appeal has been created successfully.`,
    });
    onClosePressed();
    fetchDeliveryLogs();
    handleResetClick();
  }, [fetchDeliveryLogs, handleResetClick]);

  const {
    notesCount,
    notesLatestCreatedTime,
    fetch: fetchNotesCount,
  } = useFetchNotesCounts(appealIds, true);
  const { sopAndRuleInfo } = useFetchSopAndRuleInfo(appealIds, true);
  const { remindersData, fetchReminders } = useRemindersData(appealIds, true);
  const reAppealAppeal = async (appealId) => {
    const { value: appeal } = await props.actions.getAppealById(appealId);

    const procedures = appeal.eob.procedures;

    try {
      const requestPayload = {
        id: appealId,
        eob_procedures: procedures.map(EOBProcedureTransform),
        denied_at: appeal.deniedAt,
        deadline: appeal.deadline,
      };
      const newAppeal = await CreateAppealAPI.reAppeal(appealId, {
        deadline: appeal.deadLine,
        ...requestPayload,
      });

      props.actions.setAppealData({
        appeals: [
          {
            ...newAppeal,
            step: 0,
          },
        ],
        name: newAppeal.patientName,
      });

      toast.success({
        title: 'Success',
        message: `The Appeal of Claim ID ${appeal.claimNumber} is re-appealed`,
      });

      props.actions.push(
        `${routes.CREATE_APPEAL_PATIENT_INFO}?appealId=${newAppeal.id}`
      );
    } catch (error) {
      handleError(error);
    }
  };

  const ClaimIdColumn = useCallback(({ value, row: { original } }) => {
    const cellValue = value || 'N/A';
    const toolTip = value === null || value === '' ? '' : value;
    const readOnly = original?.readOnly || false;
    return (
      <div
        className={classnames('d-flex align-items-center', {
          'appealio-table__body-row--disabled': readOnly,
        })}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div
          data-tip={toolTip}
          data-class="interactive-tooltip"
          className={'appealio-table__custom-cell'}
        >
          {cellValue}
        </div>
        <ReactTooltip
          className="interactive-tooltip"
          delayHide={600}
          effect="solid"
        />
      </div>
    );
  }, []);

  const PatientCell = useCallback(
    ({ value, row: { original }, accessor }) => {
      return (
        <div className={classnames('appealio-table__custom-cell')}>
          <div className="table-status__cell--action">
            <CustomCell value={value} row={original} accessor={accessor} />

            {hasSopIds(sopAndRuleInfo, original.appealId) && (
              <SopInfoIcon
                appliedRule={get(
                  sopAndRuleInfo,
                  `${original.appealId}.appliedRule`
                )}
                sopIds={getSopIds(sopAndRuleInfo, original.appealId)}
              />
            )}
          </div>
        </div>
      );
    },
    [sopAndRuleInfo]
  );

  const NoteCell = useCallback((props) => {
    const {
      appealId,
      claimNumber,
      submitterClaimId,
      notesCount,
      notesLatestCreatedTime,
      className,
    } = props;
    const count = get(notesCount, appealId);
    const latestCreatedTime = get(notesLatestCreatedTime, appealId);
    const isNotesRecent = isDateWithinPastDays(latestCreatedTime, 7);

    return (
      <div className={classnames('appealio-table__custom-cell', className)}>
        <div
          className={classnames('custom-badge', {
            'custom-badge--border-0': !count,
            'custom-badge--primary': isNotesRecent,
          })}
          onClick={() => {
            setNotesPopup({
              isOpen: true,
              appealId,
              claimId: submitterClaimId === '--' ? null : submitterClaimId,
              claimNumber: claimNumber === '--' ? null : claimNumber,
            });
          }}
        >
          <MdOutlineEventNote
            size="20"
            className={classnames('cursor-pointer custom-badge__icon')}
          />
          <span> {count > 0 && count} </span>
        </div>
      </div>
    );
  }, []);

  const ClaimStatusAndNoteColumn = useCallback(
    ({ row: { original } }) => {
      const billingProviderTaxId =
        original.billingProviderTaxId &&
        original.billingProviderTaxId.replace('-', '');
      const isUhcClaim =
        [original.ediPayerId, original.claimNumber, billingProviderTaxId].every(
          (val) => val !== '--'
        ) && original.payerApi === PAYER_API_TYPE.UHC;
      const isAvailityClaim = original.payerApi === PAYER_API_TYPE.AVAILITY;
      const isGetClaimStatusAPIEnabled =
        ((original.payerApi === PAYER_API_TYPE.UHC &&
          isUHCClaimStatusCheckEnabledForUser(userInfo)) ||
          (original.payerApi === PAYER_API_TYPE.AVAILITY &&
            isAvailityClaimStatusCheckEnabledForUser(userInfo))) &&
        original.payerApi &&
        (isUhcClaim || isAvailityClaim);
      const selectedAppealId = original?.appealId || null;

      const handleClaimStatusCheckClick = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (isGetClaimStatusAPIEnabled) {
          if (isAvailityClaim) {
            return openAvailityClaimStatusWidget({
              claimControlNumber: original.claimNumber,
              ediPayerId: original.ediPayerId,
              appealId: original.appealId,
            });
          }
          return openUhcClaimStatusWidget(
            getUHCClaimStatusSearchDataForDeliveryTracker(original, userInfo)
          );
        }
      };

      return (
        <div className="delivery-logs-notes-and-claim-search-container">
          {selectedAppealId && (
            <NoteCell
              userInfo={userInfo}
              appealId={selectedAppealId}
              claimNumber={original?.claimNumber}
              submitterClaimId={original?.submitterClaimId}
              notesCount={notesCount}
              notesLatestCreatedTime={notesLatestCreatedTime}
            />
          )}
          {isGetClaimStatusAPIEnabled ? (
            <button
              data-tip={`Claim Status Check Via ${
                isAvailityClaim ? 'Availity' : 'UHC'
              }`}
              className="ap-button ap-button--action ml-4"
              onClick={handleClaimStatusCheckClick}
            >
              <FaSearch />
            </button>
          ) : (
            ''
          )}
        </div>
      );
    },
    [
      openAvailityClaimStatusWidget,
      openUhcClaimStatusWidget,
      userInfo,
      notesCount,
      notesLatestCreatedTime,
    ]
  );

  const ReminderCell = useCallback(
    (props) => {
      const remindersData = props.remindersData;
      const submissionType = props?.row.original?.submissionType;
      const claimId =
        submissionType === SUBMISSION_TYPE.RECORD
          ? props.row.original?.submitterClaimId
          : props.row.original?.claimNumber;
      const id = props?.row?.original?.appealId;
      const reminderId = remindersData?.[id]?.reminderId;
      const denialsQueueId = remindersData?.[id]?.denialsQueueId;

      const handleClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setReminderPopup({
          isOpen: true,
          denialsQueueId,
          claimId,
          reminderId,
        });
      };
      return (
        <ReminderIcon
          remindersData={remindersData}
          id={id}
          claimId={claimId}
          handleClick={handleClick}
          isClientAdmin={isClientAdmin(userInfo)}
          userInfo={userInfo}
        />
      );
    },
    [userInfo]
  );

  const MethodColumn = useCallback(({ value, row: { original } }) => {
    const { detail, totalPages = 0 } = original;
    if (!value) return 'N/A';
    const method = value === 'Payer Portal' ? 'portal' : value;
    const methodValue = `(${capitalizeFirstLetter(method)} - ${totalPages})`;

    if (detail?.certified) {
      return (
        <span
          className="d-flex align-items-center"
          data-for="agent-method-tooltip"
          data-tip="# of pages"
        >
          {methodValue}
          <VscVerified
            size="16"
            className="ml-4 mw-16"
            data-tip="Certified Mail"
          />
        </span>
      );
    }

    return (
      <span
        className="d-flex align-items-center"
        data-for="agent-method-tooltip"
        data-tip="# of pages"
      >
        {methodValue}
      </span>
    );
  }, []);

  const providerNameCustomCell = () => {
    return (
      <div>
        Provider
        <br />
        (Practice)
      </div>
    );
  };

  const StatusCell = ({ value, row }) => {
    const { submissionType, status, method } = row.original;
    const proofOfSubmissionTitle = `Proof of Successful ${method} Submission`;
    const isProofOfTimelyFilingAvailable =
      Object.values(SUBMISSION_TYPE).includes(submissionType) &&
      status === 'Delivered' &&
      ['Mail', 'Fax'].includes(method);

    const fileNameForSave = extractFileName(row);

    return (
      <div
        className={classnames(
          'd-flex delivery-logs-status-col',
          STATUS_CLASSNAME_MAP[value]
        )}
      >
        {ICON_MAP[value]}
        <div
          className={classnames(
            'text-decoration-underline position-relative delivery-logs-status-text',
            {
              'cursor-pointer': !(
                doesAppealioExpressUserExist && value === 'Queued'
              ),
            }
          )}
          {...(doesAppealioExpressUserExist && value === 'Queued'
            ? {}
            : isProofOfTimelyFilingAvailable
            ? {
                onClick: () =>
                  handlePackageClick(
                    downloadProofOfSubmission,
                    row.original.id,
                    proofOfSubmissionTitle,
                    fileNameForSave
                  ),
              }
            : {
                onClick: async () => {
                  setSubmissionDetails({
                    isVisible: true,
                    data: row.original.detail,
                    type: row.original.method,
                    submissionId: row.original.submissionId,
                    status: row.original.status,
                    tags: row.original.searchTags && row.original.searchTags,
                  });
                },
              })}
        >
          {value}
          {isProofOfTimelyFilingAvailable && (
            <span className="potf-link ml-4">Proof</span>
          )}
        </div>
        <SubmissionMenu
          submission={row}
          selectedRowId={selectedRowId}
          setSelectedRowId={setSelectedRowId}
          setAppealActionFlyover={setAppealActionFlyover}
          setShowClaimDetailFlyover={setShowClaimDetailFlyover}
          reAppealAppeal={reAppealAppeal}
          userInfo={userInfo}
          setCancelPopup={setCancelPopup}
          isAppealioExpressUser={doesAppealioExpressUserExist}
          setSameClaimEditAttachmentsPopup={setSameClaimEditAttachmentsPopup}
          setResendIdenticalAppealPopup={setResendIdenticalAppealPopup}
          openBatchUploadWidget={props.actions.openBatchUploadWidget}
        />
      </div>
    );
  };

  const AgentMethodHeaderCell = () => {
    const agentTitle = doesAppealioExpressUserExist ? 'User' : 'Agent';
    return (
      <div>
        {agentTitle}
        <br />
        (Method)
      </div>
    );
  };

  const AgentMethodCell = ({ row }) => {
    const method = row.original.method;
    const agentName = row.original.agent || 'N/A';
    const agentCellValue = agentName;
    const readOnly = row?.original?.readOnly;

    const agent = capitalizeFirstLetter(agentCellValue);
    const capitalizeAgentName = capitalizeFirstLetter(agentName);

    return (
      <div
        className={classnames('appealio-table__custom-cell', {
          'appealio-table__body-row--disabled': readOnly,
        })}
      >
        <span
          {...(agentCellValue === 'N/A'
            ? {}
            : { 'data-tip': capitalizeAgentName })}
          data-for="agent-method-tooltip"
        >
          {agent}
        </span>
        <br />
        {method && <MethodColumn value={method} row={row} />}
        <ReactTooltip
          id="agent-method-tooltip"
          className="interactive-tooltip"
          delayHide={600}
          effect="solid"
          place="top"
        />
      </div>
    );
  };

  const handlePackageClick = async (
    downloadFunc,
    args,
    title,
    fileNameForSave
  ) => {
    setPackagePreviewLink({
      isOpen: true,
      isLoading: true,
      link: '',
      title,
      fileNameForSave,
    });
    try {
      const packageFile = await downloadFunc(args);
      const fileUrl = window.URL.createObjectURL(packageFile);
      setPackagePreviewLink({
        isOpen: true,
        isLoading: false,
        link: fileUrl,
        title,
        fileNameForSave,
      });
    } catch (error) {
      setPackagePreviewLink(defaultPackagePreviewLink);
      toast.error({
        title: '',
        message: 'Package is not available at the moment',
      });
    }
  };

  const extractFileName = (row) => {
    const { patient: patientName, claimNumber, submittedAt } = row.original;
    const formattedSubmittedAt = getFormattedDate(
      submittedAt,
      DATE_FORMAT_MM_DD_YYYY
    );

    return [patientName, claimNumber, formattedSubmittedAt]
      .filter(Boolean)
      .join('_');
  };

  const SubmittedPackageCell = ({ value, row }) => {
    const isForPackagePreview =
      row.original.status === 'Queued' ||
      row.original.status === 'Under Review';
    const dataTipForPackage = isForPackagePreview
      ? 'Preview Submitted Package'
      : 'Download Submitted Package';
    const fileNameForSave = extractFileName(row);

    const onClickHandlePackage = () => {
      handlePackageClick(
        downloadPreviewPackage,
        createSubmittedPackageLink(value.replace('/api/', '')),
        'Submitted Package',
        fileNameForSave
      );
    };

    return (
      <div className="submitted-package-container">
        <div
          data-tip={dataTipForPackage}
          onClick={onClickHandlePackage}
          className={classnames('submitted-package')}
        >
          {isForPackagePreview ? <BsEyeFill /> : <BiDownload />}
        </div>
        <ReactTooltip effect="solid" id="download-btn" />
      </div>
    );
  };

  const onCloseNotesPopup = () => {
    setNotesPopup(notesDefaultInfo);
    fetchNotesCount();
  };

  const RenderPreview = ({ item }) => {
    if (previewLetterForm.isOpen) {
      const finalPackage = [
        {
          link: previewLetterForm.fileUrl || '',
          name: item.label,
          docType: mimeTypes.APPLICATION_PDF,
        },
      ];

      return (
        <DocumentViewer
          documents={finalPackage}
          defaultIndex={0}
          previewTitle={item.label}
          onClosePress={() =>
            setPreviewLetterForm({
              isOpen: false,
              fileUrl: null,
            })
          }
        />
      );
    }
  };

  const doesAppealioExpressUserExist = isAppealioExpressUser(props.userInfo);

  const columns = [
    {
      Header: 'Status',
      accessor: 'status',
      Cell: StatusCell,
      width: doesAppealioExpressUserExist ? 175 : 125,
      className: 'position-relative',
    },
    {
      Header: 'Patient',
      accessor: 'patient',
      className: 'appealio-table-cell__border--right',
      width: 110,
      Cell: (props) => <PatientCell accessor="patient" {...props} />,
    },
    {
      Header: () => <div className="delivery-tracker__type-header">Type</div>,
      accessor: 'submissionType',
      Cell: TypeStatusCell,
      className: 'pl-0',
      width: doesAppealioExpressUserExist ? 180 : 100,
    },
    {
      Header: '',
      accessor: 'claimStatus',
      width: 100,
      disableSortBy: true,
      Cell: ClaimStatusAndNoteColumn,
    },
    {
      Header: '',
      accessor: 'setReminder',
      width: 35,
      sortKey: '',
      disableSortBy: true,
      Cell: (props) => (
        <ReminderCell {...props} remindersData={remindersData} />
      ),
      className: 'pr-0',
    },
    {
      Header: 'Claim ID',
      accessor: 'submitterClaimId',
      Cell: ClaimIdColumn,
      width: 110,
    },
    {
      Header: 'Payer',
      accessor: 'payer',
      Cell: (props) => <CustomCell accessor="payer" {...props} />,
      width: 90,
    },
    {
      Header: 'Payer Claim ID',
      accessor: 'claimNumber',
      Cell: ClaimIdColumn,
      width: 115,
    },
    {
      Header: 'Billed Amt',
      accessor: 'billedAmount',
      Cell: BuildAmountCell,
      width: 80,
    },
    {
      Header: 'Date(s) of Service',
      accessor: 'serviceDate',
      Cell: DateOfServiceCell,
      width: 90,
      disableSortBy: true,
    },
    {
      Header: providerNameCustomCell,
      accessor: 'provider',
      Cell: CustomProviderCell,
      width: 90,
      disableSortBy: true,
    },
    {
      Header: AgentMethodHeaderCell,
      accessor: 'method',
      width: doesAppealioExpressUserExist ? 305 : 100,
      Cell: AgentMethodCell,
      disableSortBy: true,
    },

    {
      Header: 'Tags',
      accessor: 'searchTags',
      bodyClassName: '',
      width: doesAppealioExpressUserExist ? 420 : 65,
      Cell: TagsCell,
      disableSortBy: true,
    },
    {
      Header: 'Submitted On',
      accessor: 'submittedAt',
      bodyClassName: 'text-decoration-underline',
      width: doesAppealioExpressUserExist ? 250 : 90,
      Cell: ({ value }) => (
        <HandleEmptyValueCell value={value ? getFormattedDate(value) : ''} />
      ),
    },
    {
      Header: 'Submitted Package',
      accessor: 'fileUrl',
      Cell: SubmittedPackageCell,
      width: doesAppealioExpressUserExist ? 150 : 100,
      disableSortBy: true,
    },
  ].filter((column) => {
    if (!isAppealioExpressUser(props.userInfo)) {
      return true;
    }
    const columnsToHideForStandaloneOnly = [
      'patient',
      'submitterClaimId',
      'claimNumber',
      'billedAmount',
      'serviceDate',
      'payer',
      'provider',
      'submissionType',
    ];
    return !columnsToHideForStandaloneOnly.includes(column.accessor);
  });

  const getSubmitPackage = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      await props.actions.clearCurrentAppealData();
      setIsFetchingSubmitPackage(true);
      const {
        value: { id },
      } = await props.actions.createSubmitPackage(
        { single: 1 },
        'submitPackage'
      );
      await CreateAppealAPI.deleteSubmissionDocuments(id);
      props.actions.replace(routes.CREATE_APPEAL_DOCUMENTS);
      setIsFetchingSubmitPackage(false);
    } catch (e) {
      if (e.response.status === 402) {
        props.actions.replace(routes.PAYMENT_DETAILS);
      } else {
        handleError(e);
      }
      setIsFetchingSubmitPackage(false);
    }
  };

  if (!isLoading && doesAppealioExpressUserExist && data.length < 1) {
    const title = `No submissions found. Send your first mail or fax delivery to start using the Delivery Tracker.`;
    const btnTitle = `Send Mail/Fax Anywhere`;
    return (
      <div className="emptytable" datacy="Empty-Table-Message">
        <div className="emptytable__padding-container emptytable__padding-container--md">
          <img alt="Empty Table" className="emptytable__img" src={emptyIcon} />
          <div className="col-md-6 emptytable__text">{title}</div>
          <div className="col-md-6 emptytable__link" onClick={getSubmitPackage}>
            {' '}
            {isFetchingSubmitPackage ? (
              <LoadingBlockHelper isLoading={true} className="mt-40" />
            ) : (
              btnTitle
            )}
          </div>
        </div>
      </div>
    );
  }

  /**
   * Handles close appeal.
   *
   * @param {Object} appeal
   */
  const handleCloseAppeal = async (appeal) => {
    const { claimNumber, status: appealStatus } = appeal;
    const appealAction = appealStatus === 'In Progress' ? 'deleted' : 'closed';

    try {
      if (appealStatus === 'In Progress') {
        await AppealsAPI.moveClaimBack(appeal.appealId);
      } else {
        await AppealsAPI.closeAppeal(appeal.appealId);
      }

      toast.success({
        title: 'Success',
        message: `The Appeal with Payer Claim ID ${claimNumber} has been ${appealAction} successfully.`,
      });

      fetchDeliveryLogs();
    } catch (error) {
      toast.error({
        title: 'Error',
        message: 'Opps something went wrong.',
      });
    }
  };

  const resetCloseAppealPopup = () =>
    setCloseAppealPopup({
      isOpen: false,
      appealId: null,
      serviceDates: [],
    });

  const renderCloseAppealConfirmationDialog = () => {
    const { appeal, serviceDates } = closeAppealPopup;

    const handelActionButtonClick = (index) => {
      if (index === 1) {
        handleCloseAppeal(appeal);
      }
      resetCloseAppealPopup();
    };

    if (closeAppealPopup.isOpen) {
      const {
        status: appealStatus,
        claimNumber,
        patient: patientName,
      } = appeal;
      const appealAction = appealStatus === 'In Progress' ? 'delete' : 'close';

      const appealStatusText = appealStatus.toLowerCase();
      const dateRangeString = serviceDates.length
        ? `${serviceDates[0]} -  ${serviceDates[serviceDates.length - 1]}`
        : 'N/A';
      const claimDetails = () => {
        return (
          <div className="claim-details">
            <p>
              Are you sure you want to {appealAction} the {appealStatusText}{' '}
              appeal?
            </p>
            <div className="row">
              <div className="col-lg-offset-1 col-lg-4 fw-bold">Claim ID</div>
              <div className="col-lg-offset-1 col-lg-6">{claimNumber}</div>
            </div>
            <div className="row">
              <div className="col-lg-offset-1  col-lg-4 fw-bold">
                Patient Name
              </div>
              <div className="col-lg-offset-1 col-lg-6">{patientName}</div>
            </div>
            <div className="row">
              <div className="col-lg-offset-1 col-lg-4 fw-bold">
                Date of Service
              </div>
              <div className="col-lg-offset-1 col-lg-6">{dateRangeString}</div>
            </div>
          </div>
        );
      };

      return (
        <ActionPopup
          title={claimDetails()}
          actionButtons={[
            {
              title: 'No',
            },
            {
              title: 'Yes',
              type: TYPE_ACCEPT,
            },
          ]}
          actionPopupClassName="action_popup--md"
          icon={checkIcon}
          onClosePressed={resetCloseAppealPopup}
          onActionButtonPressed={handelActionButtonClick}
        />
      );
    }

    return '';
  };

  const onClickPreviewPackage = (url) => {
    setPreviewLetterForm({
      isOpen: true,
      fileUrl: createSubmittedPackageLink(url),
      item: { label: 'Submitted Package' },
    });
  };

  return (
    <div>
      <Table
        columns={columns}
        data={data}
        onSort={onSort}
        isLoading={isLoading}
        defaultSortBy={sortBy}
        className="delivery-tracking-table"
        disableRowOnReadOnly={false}
      />
      {packagePreviewLink.isOpen && (
        <DocumentViewer
          documents={[
            {
              link: packagePreviewLink.link || '',
              name: packagePreviewLink.title,
              fileNameForSave: packagePreviewLink.fileNameForSave,
              docType: mimeTypes.APPLICATION_PDF,
            },
          ]}
          isLoadingDocuments={packagePreviewLink.isLoading}
          defaultIndex={0}
          onClosePress={() => setPackagePreviewLink(defaultPackagePreviewLink)}
        />
      )}
      {submissionDetails.isVisible && (
        <AppealioPopup
          title={'Submission Details'}
          className="submission-details"
          onClosePressed={resetModal}
        >
          <div>
            <SubmissionDetailContent submissionDetails={submissionDetails} />
          </div>
        </AppealioPopup>
      )}
      {cancelPopup.isOpen && (
        <CancelPopup
          cancelPopup={cancelPopup}
          setCancelPopup={setCancelPopup}
          fetchDeliveryLogs={fetchDeliveryLogs}
        />
      )}
      {appealActionFlyover.isOpen && (
        <RightSlideOut
          isOpen={appealActionFlyover.isOpen}
          onDimRegionPressed={closeRecordResponseFlyover}
        >
          <RecordResponse
            appealID={appealActionFlyover.appealId}
            isViewOnlyForm={appealActionFlyover.isResolveClaim}
            onDimRegionPressed={closeRecordResponseFlyover}
            closeAppealDetails={closeRecordResponseFlyover}
          />
        </RightSlideOut>
      )}
      {showClaimDetailFlyover.isOpen && (
        <RightSlideOut
          isOpen={showClaimDetailFlyover.isOpen}
          onDimRegionPressed={() =>
            setShowClaimDetailFlyover({
              isOpen: false,
              appealId: null,
              defaultActiveTabKey: '4',
            })
          }
        >
          <AppealDetails
            appealId={showClaimDetailFlyover.appealId}
            defaultActiveTabKey={showClaimDetailFlyover.defaultActiveTabKey}
          />
        </RightSlideOut>
      )}
      {renderCloseAppealConfirmationDialog()}

      {notesPopup.isOpen && (
        <NotesPopup
          appealId={notesPopup.appealId}
          claimId={notesPopup.claimId}
          claimNumber={notesPopup.claimNumber}
          userInfo={props.userInfo}
          agents={agentsList}
          setShowNotesPopup={onCloseNotesPopup}
          isAppeal={true}
        />
      )}
      {reminderPopup.isOpen && (
        <ReminderPopup
          onClosePressed={() => {
            setReminderPopup({
              isOpen: false,
              denialsQueueId: null,
              claimId: null,
            });
            fetchReminders();
          }}
          denialsQueueId={reminderPopup.denialsQueueId}
          claimId={reminderPopup.claimId}
          reminderId={reminderPopup.reminderId}
        />
      )}
      {sameClaimEditAttachmentPopup.isOpen && (
        <SameClaimEditAttachmentsPopup
          onClosePressed={() =>
            setSameClaimEditAttachmentsPopup({
              ...sameClaimEditAttachmentPopup,
              isOpen: false,
            })
          }
          patientName={sameClaimEditAttachmentPopup.patientName}
          claimId={sameClaimEditAttachmentPopup.claimId}
          appealId={sameClaimEditAttachmentPopup.appealId}
          submissionId={sameClaimEditAttachmentPopup.submissionId}
          submissionType={sameClaimEditAttachmentPopup.submissionType}
          setPreviewLetterForm={setPreviewLetterForm}
        />
      )}

      {resendIdenticalAppealPopup.isOpen && (
        <ResendIdenticalAppeal
          deliveryDetail={resendIdenticalAppealPopup.deliveryDetail}
          appealId={resendIdenticalAppealPopup.appealId}
          submissionId={resendIdenticalAppealPopup.submissionId}
          onClosePressed={onClosePressed}
          handleOnSubmitResendIdenticalAppeal={
            handleOnSubmitResendIdenticalAppeal
          }
          patientName={resendIdenticalAppealPopup.patientName}
          claimNumber={resendIdenticalAppealPopup.claimNumber}
          submissionType={resendIdenticalAppealPopup.submissionType}
          onClickPreviewPackage={onClickPreviewPackage}
          payerId={resendIdenticalAppealPopup.payerId}
          logId={resendIdenticalAppealPopup.logId}
          submissionMethod={resendIdenticalAppealPopup.method}
        />
      )}
      {previewLetterForm.isOpen && (
        <RenderPreview item={previewLetterForm.item} />
      )}
    </div>
  );
};

function mapStateToProps(state) {
  const userInfo = getUserInfo(state);
  if (!userInfo) {
    return {};
  }

  return {
    userInfo,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        openUhcClaimStatusWidget,
        openAvailityClaimStatusWidget,
        clearCurrentAppealData,
        createSubmitPackage,
        setAppealData,
        createReAppeal,
        getAppealById,
        openBatchUploadWidget,
        push,
        replace,
      },
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryLogsTable);
