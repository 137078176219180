import {
  camelCase,
  snakeCase,
  some,
  values,
  countBy,
  max,
  pickBy,
  keys,
} from 'lodash';

export const camelizeKeys = (obj) => {
  if (Array.isArray(obj)) {
    return obj.map((v) => camelizeKeys(v));
  } else if (obj && obj.constructor === Object) {
    return Object.keys(obj).reduce(
      (result, key) => ({
        ...result,
        [key.charAt(0) === '_'
          ? `_${camelCase(key.substr(1))}`
          : camelCase(key)]: camelizeKeys(obj[key]),
      }),
      {}
    );
  }
  return obj;
};

export const snakeCaseKeys = (obj) => {
  if (Array.isArray(obj)) {
    return obj.map((v) => snakeCaseKeys(v));
  } else if (obj && typeof obj === 'object') {
    return Object.keys(obj).reduce(
      (result, key) => ({
        ...result,
        [snakeCase(key)]: snakeCaseKeys(obj[key]),
      }),
      {}
    );
  }
  return obj !== undefined ? obj : obj || '';
};

/**
 * Get the copy of object without attributes.
 *
 * @param  {Object} obj
 * @param  {Array} attrsToExclude
 * @returns {Object}
 */
export function withoutAttrs(obj, attrsToExclude) {
  const result = {};

  Object.keys(obj).forEach((key) => {
    if (!attrsToExclude.includes(key)) {
      result[key] = obj[key];
    }
  });

  return result;
}

/**
 * Get the copy of object with only specified attributes.
 *
 * @param  {Object} obj
 * @param  {Array} attrs
 * @returns {Object}
 */
export function withAttrs(obj, attrs) {
  const result = {};

  Object.keys(obj).forEach((key) => {
    if (attrs.includes(key)) {
      result[key] = obj[key];
    }
  });

  return result;
}

/**
 * Check if object is empty.
 *
 * @param {Object} obj
 * @returns {Boolean}
 */
export function isEmpty(obj) {
  return Object.keys(obj).length === 0;
}

/**
 * Check if object values are empty.
 *
 * @param {Object} obj
 * @returns {Boolean}
 */
export function isEmptyObjectValues(obj) {
  return Object.values(obj).every((value) => !value);
}

/** Extracts object key map.
 *
 * @param {Array} array
 * @param {String} objectKey
 * @param {String} objectValue
 * @returns {Object}
 */
export function extractObjectMap(array, objectKey, objectValue = null) {
  return array.reduce((acc, cur) => {
    const key = cur[objectKey];
    const value = objectValue ? cur[objectValue] : cur;
    return {
      ...acc,
      [key]: value,
    };
  }, {});
}

/**
 * Check if object values are empty.
 * @param {Object} obj
 * @returns {Boolean}
 */
export function isObjectValuesEmpty(obj) {
  return Object.values(obj).every((value) => !value);
}

/**
 * Sorts an array of objects based on the values of a given property, in the order specified.
 * @param {Array} arr - The array of objects to sort.
 * @param {Array} orderedKeys - The array of property values to sort by, in the desired order.
 * @param {string} key - The name of the property to sort by.
 * @returns {Array} The sorted array of objects.
 */
export function sortArrayOfObjectsByPropertyOrder(arr, orderedKeys, key) {
  // Create a dictionary to map keys to their corresponding indexes in the given array of property values
  const keyIndexMap = {};
  orderedKeys.forEach((value, index) => (keyIndexMap[value] = index));

  // Sort the array of objects based on the property values given
  return arr.sort((obj1, obj2) => {
    const value1 = obj1[key];
    const value2 = obj2[key];
    const index1 = keyIndexMap[value1];
    const index2 = keyIndexMap[value2];

    if (index1 < index2) {
      return -1;
    } else if (index1 > index2) {
      return 1;
    } else {
      return -1;
    }
  });
}

/**
 * Deeply compares two objects or arrays for value equality.
 *
 * @param {Object|Array} obj1 - The first object or array to compare.
 * @param {Object|Array} obj2 - The second object or array to compare.
 * @returns {boolean} - Returns true if the objects or arrays are value-wise equal, otherwise false.
 */
export function objectsAreEqual(obj1, obj2) {
  if (obj1 === obj2) return true;

  if (obj1 == null || obj2 == null) return false;

  if (Array.isArray(obj1) && Array.isArray(obj2)) {
    if (obj1.length !== obj2.length) return false;
    for (let i = 0; i < obj1.length; i++) {
      if (!objectsAreEqual(obj1[i], obj2[i])) return false;
    }
    return true;
  }

  // Handle objects
  if (typeof obj1 === 'object' && typeof obj2 === 'object') {
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) return false;

    for (const key of keys1) {
      if (!objectsAreEqual(obj1[key], obj2[key])) return false;
    }

    return true;
  }

  return false;
}

/**
 * Checks if any property value in the provided object is `true`.
 *
 * @param {Object} obj - The object to check.
 * @returns {boolean} `true` if any property value in the object is `true`, `false` otherwise.
 */

export const checkIfAnyPropertyValueTrue = (obj) => {
  return some(values(obj), (value) => value === true);
};

/**
 * Filters an array by excluding items from a second array based on a specified property.
 *
 * @param {Array} array1 - The array to filter.
 * @param {Array} array2 - The array to exclude items from.
 * @param {string} [property='value'] - The property to compare between the two arrays.
 * @returns {Array} A new array containing the items from `array1` that are not present in `array2` based on the specified property.
 */

export const filterByPropertyExclusion = (
  array1,
  array2,
  property = 'value'
) => {
  return array1.filter(
    (item) => !array2.some((item2) => item2[property] === item[property])
  );
};

/**
 * Finds the value with the maximum occurrence in an array.
 * If there are multiple values with the same maximum occurrence, returns an empty string.
 *
 * @param {Array} arr - The array to search in.
 * @returns {string} The value with the maximum occurrence, or an empty string if there are multiple values with the same maximum occurrence.
 */
export const findMaxOccurrenceValue = (arr) => {
  const occurrenceMap = countBy(arr);
  const maxCount = max(values(occurrenceMap));
  const maxValues = keys(pickBy(occurrenceMap, (count) => count === maxCount));

  return maxValues.length > 1 ? '' : maxValues[0];
};
