import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import LoadingIndicator from 'components/Shared/LoadingIndicator';

const SopList = ({ isFetchingSopList, sopList, onSopClick, selectedSopId }) => {
  if (isFetchingSopList) {
    return (
      <div className="sop-list-container position-relative">
        <LoadingIndicator showing={true} />
      </div>
    );
  }

  return (
    <div className="sop-list-container">
      <ul className="card-list" datacy="card-list-ul">
        {sopList.map((sop) => (
          <li
            className={classnames('card-list__item', {
              'card-list__item--active': selectedSopId === sop.id,
            })}
            key={sop.id}
            onClick={() => onSopClick(sop.id)}
          >
            {sop.name || 'N/A'}
          </li>
        ))}
      </ul>
    </div>
  );
};

SopList.propTypes = {
  ruleId: PropTypes.number.isRequired,
};

export default SopList;
