import React from 'react';
import Utils from './utils';
import PropTypes from 'prop-types';

/**
 * Allow find a string or substring from a text and
 * highlight it with html wrapper and unicode support.
 * @return {string}
 */
const searchTextHL = {
  highlight(text = '', query = '', options = {}) {
    Utils.validate.highlight(text, query, options);

    options = Utils.getOptions(options);
    if (!query) {
      return text;
    }

    let modifiers = options.matchAll ? 'g' : '';
    modifiers += options.caseSensitive ? '' : 'i';
    return text.replace(new RegExp(query, modifiers), (match) => {
      return `<${options.htmlTag} class="${options.hlClass}">${match}</${options.htmlTag}>`;
    });
  },
};

const TextHighlighter = (props) => {
  const { text, query } = props;
  const highlightedText = searchTextHL.highlight(text, query);
  return <span dangerouslySetInnerHTML={{ __html: highlightedText }} />;
};

TextHighlighter.propTypes = {
  text: PropTypes.string.isRequired,
  query: PropTypes.string.isRequired,
};

export default TextHighlighter;
