import classnames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';
import { bindActionCreators } from 'redux';
import { AiOutlineSearch } from 'react-icons/ai';
import { NavLink, withRouter } from 'react-router-dom';
import { replace, push } from 'connected-react-router';

import Dropdown from './Dropdown';
import AccountMenu from './AccountMenu';

import { getAuthToken } from '../../helpers/tokenStorage';
import { getUserInfo } from '../../redux/reducers/loginStore';
import {
  getCurrentAppealData,
  getCurrentAppealStep,
} from 'redux/reducers/createAppealStore';
import { clearAuthToken, signOut } from '../../redux/actions/loginActions';

import * as routes from 'constants/routes';
import { CLIENT_CONFIG_KEYS, CLIENT_LOGO_CONFIG } from 'constants/appConstants';

import { fetchClients, changeClient } from 'API/Clients';
import { createURLWithParams, CURRENT_DEPLOYMENT_INSTANCE } from 'API/Config';

import appealioLogo from 'img/DV.svg';
import aqkodeLogo from 'img/AQ_Logo.svg';
import dropdownIcon from 'img/ic-chevron-down.svg';
import appealioExpress from 'img/appealio-express-logo.svg';

import { isUHCClaimStatusCheckEnabledForUser } from 'Auth/FeatureFlags';

import {
  isUserAuthorizedForAnalytics,
  isUserAuthorizedForDashboard,
  isUserAuthorizedForDenialsPage,
  isUserAuthorizedForPCE,
  isUserOnboarding,
  isUserAuthorizedCms1500Merge,
  isUserAuthorizedUB04Merge,
  isUserAuthorizedForSubmission,
  isUserAuthorizedForStandaloneSubmission,
  isAppealioExpressUser,
  isAnalyticsOnlyUser,
  isAuthorizedForDenialAnalytics,
  isClientAgent,
} from '../../Auth/AuthUtils';

import CreateSubmission from './CreateSubmission';
import GlobalSearch from 'components/GlobalSearch';
import AppNotification from './AppNotification/AppNotification';

import { handleError } from 'helpers/errorHandler';
import { getHomePageRouteForUser } from 'helpers/route';
import OutsideClickWrapper from 'components/common/outsideClickWrapper/OutsideClickWrapper';

class TopBar extends Component {
  static propTypes = {
    isLoggedIn: PropTypes.bool.isRequired,
    isAdmin: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      showAccountMenu: false,
      showGlobalSearch: false,
      isStandAloneUserOnly: false,
      clientOptions: [],
      isFetchingClients: false,
      isChangingClient: false,
    };
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (
      newProps.isAuthorizedToSwitchClient &&
      this.props.isAuthorizedToSwitchClient !==
        newProps.isAuthorizedToSwitchClient
    ) {
      this.fetchAndSetClientOptions();
    }
  }

  fetchAndSetClientOptions = async () => {
    try {
      this.setState({ isFetchingClients: true });
      const { data } = await fetchClients();
      const clientOptions = data.map((client) => ({
        value: client.id,
        label: client.name,
      }));
      this.setState({ clientOptions });
    } catch (error) {
      handleError(error);
    } finally {
      this.setState({ isFetchingClients: false });
    }
  };

  getDashboardItem = () => {
    const { isLoggedIn, userInfo, canViewSubmissionPage, actions } = this.props;

    const canViewDashboard =
      isLoggedIn && isUserAuthorizedForDashboard(userInfo);
    const canViewAnalytics = isUserAuthorizedForAnalytics(userInfo);
    const isDashboardTabDisabled = [
      canViewSubmissionPage,
      canViewAnalytics,
    ].every((isEnabled) => !isEnabled);

    const stopPropagationAndPreventDefault = (e, action) => {
      e.preventDefault();
      e.stopPropagation();
      if (action) action();
    };

    const renderButton = (route, datacy, text) => (
      <button
        onClick={(e) =>
          stopPropagationAndPreventDefault(e, () => actions.replace(route))
        }
        className="ap-button ap-button--primary-outline text-left"
        datacy={datacy}
      >
        {text}
      </button>
    );

    if (
      !canViewDashboard &&
      isClientAgent(userInfo) &&
      isAuthorizedForDenialAnalytics(userInfo)
    ) {
      return (
        <NavLink
          activeClassName="topbar--active-item"
          to={routes.DENIALS_QUEUE_ANALYTICS}
          datacy="manager-dashboard-NavLink"
        >
          <div className="topbar__dashitem topbar-dropmenu">
            <div className="topbar__dashitem-div">Dashboards</div>
            <div className="button-popover-wrapper">
              {renderButton(
                routes.DENIALS_QUEUE_ANALYTICS,
                'denials-analytics-button',
                'Denial Analytics'
              )}
            </div>
          </div>
        </NavLink>
      );
    }

    if (isDashboardTabDisabled) return null;

    if (canViewDashboard) {
      const defaultRoute = canViewSubmissionPage
        ? routes.MANAGER_DASHBOARD
        : routes.ANALYTICS;

      return (
        <NavLink
          activeClassName="topbar--active-item"
          to={defaultRoute}
          datacy="manager-dashboard-NavLink"
        >
          <div className="topbar__dashitem topbar-dropmenu">
            <div className="topbar__dashitem-div">Dashboards</div>
            <div className="button-popover-wrapper">
              {canViewSubmissionPage &&
                renderButton(
                  { pathname: routes.MANAGER_DASHBOARD_TEAM },
                  'team-performance-dashboard-NavLink',
                  'Team Performance'
                )}
              {canViewAnalytics &&
                renderButton(
                  routes.ANALYTICS,
                  'claims-analytics-button',
                  'Claims Analytics'
                )}
              {isAuthorizedForDenialAnalytics(userInfo) &&
                renderButton(
                  routes.DENIALS_QUEUE_ANALYTICS,
                  'denials-analytics-button',
                  'Denial Analytics'
                )}
            </div>
          </div>
        </NavLink>
      );
    }

    return null;
  };

  getHomeItem = () => {
    if (
      !this.props.canViewSubmissionPage &&
      !isUserAuthorizedForStandaloneSubmission(this.props.userInfo)
    ) {
      return null;
    }

    let item = '';
    if (this.props.isLoggedIn) {
      item = (
        <NavLink
          activeClassName="topbar--active-item"
          to={routes.SUBMISSIONS}
          datacy="dashboard-NavLink"
        >
          <div className="topbar__dashitem topbar-dropmenu">
            <div className="topbar__dashitem-div">Submissions</div>
          </div>
        </NavLink>
      );
    }

    return item;
  };

  getPatientCostEstimator = () => {
    let item = '';
    if (this.props.isLoggedIn && isUserAuthorizedForPCE(this.props.userInfo)) {
      item = (
        <NavLink
          activeClassName="topbar--active-item"
          to="/estimator/create"
          datacy="patient-cost-estimator-NavLink"
        >
          <div className="topbar__dashitem">
            <div className="topbar__dashitem-div">Estimator</div>
          </div>
        </NavLink>
      );
    }
    return item;
  };

  getStatusTrackerItem = () => {
    const isAuthorizedForStatusTracker = isUHCClaimStatusCheckEnabledForUser(
      this.props.userInfo
    );

    if (
      !isAuthorizedForStatusTracker ||
      isAppealioExpressUser(this.props.userInfo) ||
      isAnalyticsOnlyUser(this.props.userInfo)
    ) {
      return null;
    }

    return (
      <NavLink
        activeClassName="topbar--active-item"
        to={routes.CLAIM_STATUS_TRACKER}
        datacy="denied-queue-NavLink"
      >
        <div className="topbar__dashitem topbar-dropmenu">
          <div className="topbar__dashitem-div">Claim Status Tracker</div>
        </div>
      </NavLink>
    );
  };

  getCreateItem = () => {
    let item = '';
    const isUserAuthorizedForStandalone =
      isUserAuthorizedForStandaloneSubmission(this.props.userInfo);
    const isAuthorizedForSubmission = isUserAuthorizedForSubmission(
      this.props.userInfo
    );
    if (
      (this.props.isLoggedIn && isAuthorizedForSubmission) ||
      isUserAuthorizedForStandalone
    ) {
      item = (
        <CreateSubmission
          isStandAloneSubmission={this.props.isCurrentAppealStandalone}
          isUserAuthorizedForStandalone={isUserAuthorizedForStandalone}
          isAuthorizedForSubmission={isAuthorizedForSubmission}
          showGlobalSearch={this.state.showGlobalSearch}
          closeGlobalSearch={this.closeGlobalSearch}
          currentStep={this.props.currentStep}
        />
      );
    }
    return item;
  };

  getDenialQueueItem = () => {
    let item = '';

    if (
      this.props.isLoggedIn &&
      isUserAuthorizedForDenialsPage(this.props.userInfo)
    ) {
      item = (
        <NavLink
          activeClassName="topbar--active-item"
          to={routes.DENIED_QUEUE}
          datacy="denied-queue-NavLink"
        >
          <div className="topbar__dashitem topbar-dropmenu">
            <div className="topbar__dashitem-div">Denials</div>
          </div>
        </NavLink>
      );
    }

    return item;
  };

  showAccountMenu = () => {
    this.setState({ showAccountMenu: true });
  };

  hideAccountMenu = () => {
    this.setState({ showAccountMenu: false });
  };

  renderAccountMenu = () => {
    if (this.state.showAccountMenu) {
      return (
        <AccountMenu
          role={this.props.role}
          fullName={this.props.fullName}
          email={this.props.email}
          userInfo={this.props.userInfo}
          onSignOutClick={this.onSignOutClick}
          onClickedOutside={this.onClickedOutsideAccountMenu}
          onSettingsClick={this.onSettingsClick}
          onSettingsClickForExpressUser={this.onSettingsClickForExpressUser}
          datacy="top-bar-AccountMenu"
        />
      );
    }
  };

  onSignOutClick = async (e) => {
    this.setState(
      {
        showAccountMenu: false,
      },
      async () => {
        await this.props.actions.signOut(getAuthToken());
        this.props.actions.clearAuthToken();
        this.props.actions.replace('/login');
        window.location.reload(true);
      }
    );
  };

  onSettingsClick = () => {
    this.props.actions.push(routes.MANAGER_SETTINGS);
    this.setState({ showAccountMenu: false });
  };

  onSettingsClickForExpressUser = () => {
    this.props.actions.push(routes.EXPRESS_SETTINGS);
  };

  onAccountMenuClicked = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const isShowingAccountMenu = this.state.showAccountMenu;
    this.setState({
      showAccountMenu: !isShowingAccountMenu,
      showGlobalSearch: false,
    });
  };

  onClickedOutsideAccountMenu = (e) => {
    this.hideAccountMenu();
  };

  getAccountItem = () => {
    if (this.props.isLoggedIn) {
      const { clientName } = this.props.userInfo;
      const [accountName] = clientName.split(' ');
      return (
        <div onClick={this.onAccountMenuClicked} className="topbar__dropmenu">
          <div className="topbar__dropmenu-text" data-tip={clientName}>
            {accountName}
          </div>
          <img
            alt="Dropdown"
            className="topbar__dropmenu-ic"
            src={dropdownIcon}
          />
          <ReactTooltip effect="solid" place="top" />
        </div>
      );
    }
  };

  getHelpItem = () => {
    let item = '';
    if (this.props.isLoggedIn) {
      item = (
        <NavLink
          activeClassName="topbar--active-item"
          to="/help"
          datacy="help-NavLink"
        >
          <div className="topbar__help">Help</div>
        </NavLink>
      );
    }
    return item;
  };

  extractCurrentInstanceInfo = () => {
    if (!CURRENT_DEPLOYMENT_INSTANCE) {
      return null;
    }

    const deploymentInfoInstanceMap = {
      dirty: {
        displayName: 'Dirty',
        className: 'topbar__environment--dirty',
      },
      dev: {
        displayName: 'Dev',
        className: 'topbar__environment--dev',
      },
      qa: {
        displayName: 'QA',
        className: 'topbar__environment--qa',
      },
      uat: {
        displayName: 'UAT',
        className: 'topbar__environment--uat',
      },
      training: {
        displayName: 'Training',
        className: 'topbar__environment--training',
      },
      demo: {
        displayName: 'Demo',
        className: 'topbar__environment--demo',
      },
      prodtest: {
        displayName: 'PRODTEST',
        className: 'topbar__environment--prodtest',
      },
    };

    return (
      deploymentInfoInstanceMap[CURRENT_DEPLOYMENT_INSTANCE.toLowerCase()] ||
      null
    );
  };

  getUtilityItem = () => {
    let item = '';
    const isLoggedIn = this.props.isLoggedIn;
    const userInfo = this.props.userInfo;
    const canViewCms1500 = isLoggedIn && isUserAuthorizedCms1500Merge(userInfo);
    const canViewUb04 = isLoggedIn && isUserAuthorizedUB04Merge(userInfo);
    const pathname = this.props.location.pathname;

    const isCms1500MergeToolPage = pathname === routes.CMS_1500_MERGE_TOOL;
    const isUb04MergeToolPage = pathname === routes.UB_04_MERGE_TOOL;

    if (canViewCms1500 || canViewUb04) {
      item = (
        <NavLink
          to={'#'}
          activeClassName={
            isCms1500MergeToolPage || isUb04MergeToolPage
              ? 'topbar--active-item'
              : ''
          }
        >
          <div className="topbar__dashitem topbar-dropmenu">
            <div className="topbar__dashitem-div">Utilities</div>
            <div className="button-popover-wrapper">
              {canViewCms1500 && (
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    this.props.actions.replace({
                      pathname: routes.CMS_1500_MERGE_TOOL,
                    });
                  }}
                  className={classnames(
                    'ap-button ap-button--primary-outline text-left ap-button--topbar-single width-160',
                    {
                      'ap-button--primary-outline-active':
                        isCms1500MergeToolPage,
                    }
                  )}
                >
                  CMS-1500 Merge Tool
                </button>
              )}
              {canViewUb04 && (
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    this.props.actions.replace({
                      pathname: routes.UB_04_MERGE_TOOL,
                    });
                  }}
                  className={classnames(
                    'ap-button ap-button--primary-outline text-left ap-button--topbar-single width-160',
                    {
                      'ap-button--primary-outline-active': isUb04MergeToolPage,
                    }
                  )}
                >
                  UB-04 Merge Tool
                </button>
              )}
            </div>
          </div>
        </NavLink>
      );
    }

    return item;
  };

  getStandAloneItem = () => {
    let item = '';
    if (this.props.isLoggedIn) {
      item = (
        <NavLink
          activeClassName="topbar--active-item"
          to={routes.SUBMISSIONS}
          datacy="dashboard-NavLink"
        >
          <div className="topbar__dashitem topbar-dropmenu">
            <div className="topbar__dashitem-div">Submissions</div>
            <div className="button-popover-wrapper">
              <button
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  this.props.actions.replace(routes.SUBMISSIONS);
                }}
                className="ap-button ap-button--primary-outline text-left"
                datacy="delivery-tracker-button"
              >
                Delivery Tracker
              </button>
            </div>
          </div>

          <div onClick={this.onAccountMenuClicked} className="topbar__dropmenu">
            <div className="topbar__dropmenu-text">Account</div>
            <img
              alt="Dropdown"
              className="topbar__dropmenu-ic"
              src={dropdownIcon}
            />
          </div>
        </NavLink>
      );
    }

    return item;
  };

  onChangeClient = async (selectedOption) => {
    const { value } = selectedOption;

    try {
      this.setState({ isChangingClient: true });
      await changeClient(value);
      window.location.reload();
    } catch (error) {
      handleError(error);
    } finally {
      this.setState({ isChangingClient: false });
    }
  };

  renderChangeClientDropdown = () => {
    if (!this.props.isAuthorizedToSwitchClient) {
      return null;
    }
    const currentClientName = this.props.userInfo.clientName || '';
    const currentSelectedClient = this.state.clientOptions?.filter(
      (client) => client.label === currentClientName
    );
    return (
      <div className="topbar__welcome topbar__change-client-select position-relative">
        <Dropdown
          className="width-160 topbar__change-client-select-dropdown "
          name="select client"
          onChange={this.onChangeClient}
          value={currentSelectedClient}
          options={this.state.clientOptions}
          toolTip={currentClientName}
          isLoading={this.state.isFetchingClients}
        />
      </div>
    );
  };

  render() {
    const currentInstanceInfo = this.extractCurrentInstanceInfo();
    const showAppealioExpressLogo =
      isAppealioExpressUser(this.props.userInfo) ||
      this.props.location.pathname.includes(routes.SIGNUP) ||
      this.props.location.pathname.includes(routes.ACTIVATE_USER) ||
      this.props.location.pathname.includes(routes.USER_CONFIRMATION);

    const isAqkodeClient = this.props.clientLogo === CLIENT_LOGO_CONFIG.AQKODE;
    const brandLogo = isAqkodeClient ? aqkodeLogo : appealioLogo;

    return (
      <div
        className={classnames('topbar no-print', {
          'topbar__environment--prodtest-bg':
            CURRENT_DEPLOYMENT_INSTANCE.toLowerCase() === 'prodtest',
        })}
      >
        {showAppealioExpressLogo ? (
          <a
            className="topbar__title"
            href="https://docvocate.com/mail-fax.html"
          >
            {this.props.isLoggedIn && currentInstanceInfo && (
              <div
                className={`topbar__environment ${currentInstanceInfo.className}`}
              >
                {currentInstanceInfo.displayName}
              </div>
            )}
            <img
              className="topbar__title__logo"
              alt="Appealio Logo"
              src={appealioExpress}
            />
          </a>
        ) : (
          <NavLink
            activeClassName="topbar--active-item"
            to={getHomePageRouteForUser(this.props.userInfo)}
            datacy="appealio-logo-NavLink"
          >
            <div className="topbar__title">
              {this.props.isLoggedIn && currentInstanceInfo && (
                <div
                  className={`topbar__environment ${currentInstanceInfo.className}`}
                >
                  {currentInstanceInfo.displayName}
                </div>
              )}
              {this.props.isLoggedIn ? (
                <img
                  className={classnames({
                    topbar__title__logo: !isAqkodeClient,
                  })}
                  alt="Brand Logo of Company"
                  src={brandLogo}
                />
              ) : null}
            </div>
          </NavLink>
        )}

        {!this.props.isLoggedIn && currentInstanceInfo && (
          <div
            className={`topbar__environment--unauthorized ${currentInstanceInfo.className}`}
          >
            {currentInstanceInfo.displayName}
          </div>
        )}
        {!this.props.isUserOnboarding && this.getDenialQueueItem()}
        {!this.props.isUserOnboarding && this.getHomeItem()}
        {!this.props.isUserOnboarding && this.getDashboardItem()}
        {!this.props.isUserOnboarding && this.getPatientCostEstimator()}
        {!this.props.isUserOnboarding && this.getUtilityItem()}
        {!this.props.isUserOnboarding && this.getStatusTrackerItem()}
        {!this.props.isUserOnboarding && this.getCreateItem()}
        {this.getAccountItem()}
        {this.getHelpItem()}
        {this.props.isLoggedIn && (
          <AppNotification
            onNotificationsClicked={this.onNotificationsClicked}
            onClickedOutside={this.onClickedOutsideAccountMenu}
            hideAccountMenu={this.hideAccountMenu}
            showAccountMenu={this.state.showAccountMenu}
          />
        )}
        {this.renderWelcomeMessage()}
        {!isAppealioExpressUser(this.props.userInfo) &&
          !isAnalyticsOnlyUser(this.props.userInfo) &&
          this.renderGlobalSearch()}
        {this.renderAccountMenu()}
        {this.renderChangeClientDropdown()}
      </div>
    );
  }

  renderWelcomeMessage() {
    if (this.props.isLoggedIn) {
      const hasFirstName = !!this.props.firstName;
      const welcomeMessage = hasFirstName
        ? `Welcome, ${this.props.firstName}`
        : 'Welcome';
      return <div className="topbar__welcome">{welcomeMessage}</div>;
    }
  }

  closeGlobalSearch = () => {
    this.setState({
      showGlobalSearch: false,
    });
  };

  toggleGlobalSearch = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      showGlobalSearch: !this.state.showGlobalSearch,
    });
  };

  renderGlobalSearch() {
    const handleRedirect = (url, queryParam) => {
      this.props.actions.push(
        createURLWithParams(url, queryParam, true, '', true)
      );
    };

    if (this.props.isLoggedIn) {
      return (
        <div>
          <div
            className={classnames(
              'topbar__welcome topbar__search position-relative cursor-pointer mr-20',
              {
                'topbar__search--active': this.state.showGlobalSearch,
              }
            )}
            onClick={this.toggleGlobalSearch}
          >
            <AiOutlineSearch className="topbar__search__icon" /> Search
          </div>
          {this.state.showGlobalSearch && (
            <OutsideClickWrapper
              handleOutsideClick={() => {
                if (this.props.isClaimStatusWidgetOpen) return;

                this.setState({
                  showGlobalSearch: false,
                });
              }}
            >
              <GlobalSearch
                closeSearch={() => this.closeGlobalSearch()}
                handleRedirect={handleRedirect}
              />
            </OutsideClickWrapper>
          )}
        </div>
      );
    }
  }
}

function mapStateToProps(state) {
  const userInfo = getUserInfo(state);
  const currentAppeal = getCurrentAppealData(state);
  const currentStep = getCurrentAppealStep(state);
  const isCurrentAppealStandalone = currentAppeal?.get('isStandAlone');

  if (!userInfo) {
    return {};
  }
  const { isOpen: isClaimStatusWidgetOpen } = state
    .get('uhcClaimStatusStoreWidgetStore')
    .toJS();

  const canViewSubmissionPage = isUserAuthorizedForSubmission(userInfo);

  const isAuthorizedToSwitchClient =
    userInfo?.isStaff === true || userInfo?.isSuperuser === true;

  return {
    fullName: `${userInfo.firstName} ${userInfo.lastName}`,
    email: userInfo.email,
    firstName: userInfo.firstName,
    integrationType: userInfo.integrationType,
    userInfo,
    isAuthorizedToSwitchClient,
    isUserOnboarding: isUserOnboarding(userInfo),
    isClaimStatusWidgetOpen,
    role: userInfo.role,
    canViewSubmissionPage,
    clientLogo: userInfo?.clientConfig?.[CLIENT_CONFIG_KEYS.CLIENT_LOGO],
    isCurrentAppealStandalone,
    currentStep,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      { replace, push, clearAuthToken, signOut },
      dispatch
    ),
    dispatch,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TopBar));
