import moment from 'moment';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import React, { useState, useEffect } from 'react';
import { isInclusivelyAfterDay } from 'react-dates';
import {
  Field,
  change,
  reset,
  reduxForm,
  SubmissionError,
  formValueSelector,
} from 'redux-form/immutable';

import Input from 'components/common/input';
import * as toast from 'components/Shared/toast';
import { DatePicker } from 'components/common/datepicker';
import PatientInput from 'components/DenialQueue/PatientInput';
import CustomDropdown from 'components/common/dropdown/ButtonDropdown/CustomDropdown';

import calendar from 'img/calendar-icon.svg';

import { handleError } from 'helpers/errorHandler';
import {
  validatePayerClaimId,
  validateAlphaNumericString,
} from 'helpers/validators';

import { addManualClaim } from 'API/ManualClaimAPI';
import {
  fetchRenderingProviders,
  fetchBillingProviders,
} from 'API/AccountSettingsAPI';

import { getUserInfo } from 'redux/reducers/loginStore';
import { removeEmptyOrInactiveOptions } from 'helpers/utils';

let AddSingleClaimForm = (props) => {
  const {
    error,
    change,
    pristine,
    resetForm,
    submitting,
    fetchDenials,
    handleSubmit,
    dropdownOptions,
    closeAddManualClaimPopup,
    selectedServiceFromDate,
    selectedServiceToDate,
    selectedPractice,
    selectedRenderingProvider,
  } = props;

  const [providerOptions, setProviderOptions] = useState({
    renderingProviderOptions: [],
    isLoadingRenderingProviderOptions: false,
    billingProviderOptions: [],
    isLoadingBillingProviderOptions: false,
  });

  const validateClaimId = (claimId, errorMessage) => {
    if (!claimId) return;
    const invalidMessage = validatePayerClaimId(claimId, errorMessage);
    if (invalidMessage) {
      return invalidMessage;
    }
  };
  const validate = (values) => {
    const errors = {};
    const errorMessage = {
      patientFirstName: 'First Name is required',
      patientLastName: 'Last Name is required',
      payerLookupId: 'Payer is required',
      practiceIdentifier: 'Practice is required',
    };

    const validateField = (fieldName, value, maxLength) => {
      if (value.length === 0) {
        return `${fieldName} is required`;
      }
      if (value.length > maxLength) {
        return `${fieldName} exceeds maximum length of ${maxLength}`;
      }
      return null;
    };

    const requiredFields = [
      'patientFirstName',
      'patientLastName',
      'payerLookupId',
      'practiceIdentifier',
    ];

    requiredFields.forEach((field) => {
      if (!values.get(field)) {
        errors[field] = errorMessage[field];
      }
    });

    const claimNumber = values.get('claimNumber');
    const claimControlNumber = values.get('claimControlNumber');
    if (!claimNumber && !claimControlNumber) {
      errors._error = 'Requires at least one Claim ID or Payer Claim ID';
    }

    const invalidValidClaimId = validateClaimId(
      claimNumber,
      'Claim ID should only contain "-" , letters or numbers.'
    );
    const invalidPayerClaimId = validateClaimId(
      claimControlNumber,
      'Claim ID should only contain "-" , letters or numbers.'
    );

    if (invalidValidClaimId) {
      errors.claimNumber = invalidValidClaimId;
    }

    if (invalidPayerClaimId) {
      errors.claimControlNumber = invalidPayerClaimId;
    }

    const patientFirstName = values.get('patientFirstName');
    if (patientFirstName) {
      const trimmedFirstName = patientFirstName.trim();
      const firstNameError = validateField('First Name', trimmedFirstName, 150);
      if (firstNameError) {
        errors.patientFirstName = firstNameError;
      }
      if (!validateAlphaNumericString(trimmedFirstName)) {
        errors.patientFirstName =
          'First Name must only contain alphanumeric characters';
      }
    }

    const patientLastName = values.get('patientLastName');
    if (patientLastName) {
      const trimmedLastName = patientLastName.trim();
      const lastNameError = validateField('Last Name', trimmedLastName, 150);
      if (lastNameError) {
        errors.patientLastName = lastNameError;
      }
      if (!validateAlphaNumericString(trimmedLastName)) {
        errors.patientLastName =
          'Last Name must only contain alphanumeric characters';
      }
    }

    const payerLookupId = values.get('payerLookupId');
    if (payerLookupId) {
      if (payerLookupId.length === 0) {
        errors.payerLookupId = errorMessage.payerLookupId;
      }
    }

    const practiceIdentifier = values.get('practiceIdentifier');
    if (practiceIdentifier) {
      if (practiceIdentifier.length === 0) {
        errors.practiceIdentifier = errorMessage.practiceIdentifier;
      }
    }

    if (Object.keys(errors).length) {
      throw new SubmissionError(errors);
    }
  };

  const handleAddClaimError = async (error) => {
    const errorResponse = error.response;
    if (errorResponse.status === 400) {
      const response = await error.response.json();
      if (response.claim_number || response.claim_control_number) {
        return Promise.reject(
          new SubmissionError({
            ...(response.claim_number && {
              claimNumber: 'Claim ID already exists in Appealio.',
            }),
            ...(response.claim_control_number && {
              claimControlNumber: 'Payer Claim ID already exists in Appealio.',
            }),
          })
        );
      }
    }
    handleError(error);
  };

  const handleFormSubmit = async (formValues) => {
    const values = formValues.toJS();
    validate(formValues);
    let formData = {};

    formData = {
      ...values,
      payerLookupId: values?.payerLookupId?.[0]?.value,
      practiceIdentifier: values?.practiceIdentifier?.[0]?.value,
      cptCodes: values?.cptCodes?.map((cptCode) => cptCode.value),
      billingProviderLookupId: values?.billingProviderLookupId?.[0]?.lookupId,
      providerLookupId: values?.providerLookupId?.[0]?.lookupId,
      cptModifiersCode: values?.cptModifiersCode?.map((code) => code.value),
      remarkCodes: values?.remarkCodes?.map((remark) => remark.value),
      reasonCodes: values?.reasonCodes?.map((reason) => reason.value),
      assignedTo: values?.assignedTo?.map((assignee) => assignee.value),
      serviceDate: values?.serviceDateFrom,
    };

    try {
      await addManualClaim(formData);
      toast.success({
        title: 'Success',
        message: 'Claim added successfully',
      });
      fetchDenials();
      closeAddManualClaimPopup();
    } catch (error) {
      return handleAddClaimError(error);
    }
  };

  useEffect(() => {
    if (!selectedPractice.length) {
      change('providerLookupId', '');
      change('billingProviderLookupId', '');
    }
    if (!selectedRenderingProvider.length) {
      change('billingProviderLookupId', '');
    }
    const payerOptions = removeEmptyOrInactiveOptions(
      dropdownOptions?.payers?.data,
      false
    );
    if (payerOptions.length === 1) {
      change('payerLookupId', payerOptions);
    }

    const practiceOptions = removeEmptyOrInactiveOptions(
      dropdownOptions?.practices?.data
    );
    if (practiceOptions.length === 1) {
      change('practiceIdentifier', practiceOptions);
    }
  }, [
    selectedPractice,
    selectedRenderingProvider,
    change,
    dropdownOptions?.payers?.data,
    dropdownOptions?.practices?.data,
  ]);

  const handlePatientChange = (patientFirstName, data = null) => {
    props.change('patientFirstName', patientFirstName);
    if (!data) {
      return;
    }
    props.change('patientLastName', data.lastName);
  };

  const handlePracticeChange = async (practice) => {
    props.change('practiceIdentifier', practice);
    try {
      setProviderOptions((prev) => ({
        ...prev,
        isLoadingRenderingProviderOptions: true,
      }));

      const { data } = await fetchRenderingProviders({
        practice_id: practice?.[0]?.id,
        format_for_dropdown: true,
      });

      const renderingProviderOptions = data.map((provider) => ({
        ...provider,
        label: provider.providerName,
      }));

      if (renderingProviderOptions.length === 1) {
        handleRenderingProviderChange(renderingProviderOptions);
      } else {
        props.change('providerLookupId', null);
      }
      setProviderOptions((prev) => ({
        ...prev,
        isLoadingRenderingProviderOptions: false,
        renderingProviderOptions,
      }));
    } catch (error) {
      handleError(error);
    }
  };

  const handleRenderingProviderChange = async (provider) => {
    props.change('providerLookupId', provider);
    try {
      setProviderOptions((prev) => ({
        ...prev,
        isLoadingBillingProviderOptions: true,
      }));
      const { data } = await fetchBillingProviders({
        rendering_provider_id: provider?.[0]?.id,
        format_for_dropdown: true,
      });

      const billingProviderOptions = data.map((provider) => ({
        ...provider,
        label: provider.providerName,
      }));

      if (billingProviderOptions.length === 1) {
        props.change('billingProviderLookupId', billingProviderOptions);
      } else {
        props.change('billingProviderLookupId', null);
      }
      setProviderOptions((prev) => ({
        ...prev,
        isLoadingBillingProviderOptions: false,
        billingProviderOptions,
      }));
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <form
      onSubmit={handleSubmit(handleFormSubmit)}
      datacy="add-manual-claim-form"
    >
      <div className="form-step__wrapper">
        {error ? (
          <div className="row mb-12">
            <div className="col-md-12">
              <span className="ap-input-error">{error}</span>
            </div>
          </div>
        ) : (
          <div className="mb-16">
            <i>
              *You must provide either the Claim ID or the Payer Claim ID to add
              a new claim.
            </i>
          </div>
        )}
        <div className="form-step__content">
          <div className="row mb-6">
            <div className="col-md-6">
              <label className="ap-input-label">First Name *</label>
              <Field
                name="patientFirstName"
                placeholder="Enter Patient’s First Name"
                autoFocus={true}
                required={true}
                maxLength="150"
                component={PatientInput}
                handleChange={(value, data) => handlePatientChange(value, data)}
                datacy="patient-first-name-Field"
              />
            </div>
            <div className="col-md-6">
              <Field
                component={Input}
                name="patientLastName"
                label="Last Name *"
                placeholder="Enter Patient’s Last Name"
                datacy="patient-last-name-Field"
              />
            </div>
          </div>
          <div className="row mb-6">
            <div className="col-md-6">
              <Field
                component={Input}
                name="claimNumber"
                label="Claim ID"
                placeholder="Enter Claim ID"
                required
                datacy="patient-claim-id"
                dataTip={
                  'Claim ID should only contain "-" , letters or numbers.'
                }
              />
            </div>
            <div className="col-md-6">
              <Field
                component={Input}
                name="claimControlNumber"
                label="Payer Claim ID"
                placeholder="Enter Payer Claim ID"
                datacy="patient-payer-claim-id"
                dataTip={
                  'Payer Claim ID cannot be duplicative of an existing one in Appealio.'
                }
              />
            </div>
          </div>
          <div className="row mb-6">
            <div className="col-md-6">
              <Field
                component={CustomDropdown}
                name="payerLookupId"
                options={removeEmptyOrInactiveOptions(
                  dropdownOptions?.payers?.data,
                  false
                )}
                label="Payer *"
                placeholder="Select Payer"
                datacy="payer-Field"
                showAllSelectedLabel={false}
              />
            </div>
            <div className="col-md-6">
              <Field
                component={CustomDropdown}
                name="practiceIdentifier"
                options={removeEmptyOrInactiveOptions(
                  dropdownOptions?.practices?.data
                )}
                label="Practice *"
                onChange={(v) => handlePracticeChange(v)}
                placeholder="Select Practice"
                datacy="practice-Field"
                showAllSelectedLabel={false}
              />
            </div>
          </div>
          <div className="row mb-6">
            <div className="col-md-6">
              <Field
                component={CustomDropdown}
                name="providerLookupId"
                options={providerOptions?.renderingProviderOptions}
                label="Rendering Provider"
                placeholder="Select Rendering Provider"
                datacy="renderingProvider-Field"
                onChange={(v) => handleRenderingProviderChange(v)}
                isDisabled={
                  !selectedPractice.length > 0 ||
                  providerOptions.isLoadingRenderingProviderOptions
                }
                showAllSelectedLabel={false}
              />
            </div>
            <div className="col-md-6">
              <Field
                component={CustomDropdown}
                name="billingProviderLookupId"
                options={providerOptions?.billingProviderOptions}
                label="Billing Provider"
                placeholder="Select Billing Provider"
                datacy="billingProvider-Field"
                isDisabled={
                  !selectedRenderingProvider.length > 0 ||
                  providerOptions.isLoadingBillingProviderOptions
                }
                showAllSelectedLabel={false}
              />
            </div>
          </div>
          <div className="row mb-6">
            <div className="col-md-6">
              <Field
                component={CustomDropdown}
                name="cptCodes"
                options={removeEmptyOrInactiveOptions(
                  dropdownOptions?.cptCodes?.data,
                  false
                )}
                isMulti
                label="Procedure"
                placeholder="Select Procedure"
                datacy="procedure-Field"
                showAllSelectedLabel={false}
              />
            </div>
            <div className="col-md-6">
              <Field
                component={CustomDropdown}
                name="reasonCodes"
                options={removeEmptyOrInactiveOptions(
                  dropdownOptions?.reasonCodes?.data,
                  false
                )}
                label="Reason(s)"
                isMulti
                placeholder="Select Reason(s)"
                datacy="reasons-Field"
                showAllSelectedLabel={false}
              />
            </div>
          </div>
          <div className="row mb-6">
            <div className="col-md-6">
              <Field
                component={CustomDropdown}
                name="cptModifiersCode"
                options={removeEmptyOrInactiveOptions(
                  dropdownOptions?.procedureModifiers?.data,
                  false
                )}
                label="Modifier(s)"
                isMulti
                placeholder="Select Procedure(s)"
                datacy="modifiers-Field"
                showAllSelectedLabel={false}
              />
            </div>
            <div className="col-md-6">
              <Field
                component={CustomDropdown}
                name="remarkCodes"
                options={removeEmptyOrInactiveOptions(
                  dropdownOptions?.remarkCodes?.data,
                  false
                )}
                label="Remark(s)"
                isMulti
                placeholder="Select Remark(s)"
                datacy="remarks-Field"
                showAllSelectedLabel={false}
              />
            </div>
          </div>
          <div className="row mb-6">
            <div className="col-md-6">
              <div className="dropdown-container mt-0">
                <div className="dd-label">Date of Service</div>
                <div className="row">
                  <div className="col-md-12">
                    <Field
                      component={() => (
                        <DatePicker
                          displayFormat="LL"
                          customInputIcon={
                            <img
                              className="form-icon"
                              src={calendar}
                              alt="Calendar"
                            />
                          }
                          placeholder="Date of Service"
                          verticalSpacing={10}
                          date={
                            selectedServiceFromDate
                              ? moment(selectedServiceFromDate)
                              : null
                          }
                          isOutsideRange={(d) =>
                            isInclusivelyAfterDay(d, moment().add(1, 'days')) ||
                            d.isAfter(moment(selectedServiceToDate), 'day')
                          }
                          onDateChange={(date) => {
                            if (!date) {
                              props.change('serviceDateFrom', null);
                              return;
                            }
                            date = moment(date);
                            if (!date.isValid()) {
                              props.change('serviceDateFrom', null);
                              return;
                            }
                            props.change(
                              'serviceDateFrom',
                              date.format('YYYY-MM-DD')
                            );
                          }}
                          numberOfMonths={1}
                          orientation="horizontal"
                          block={true}
                          openDirection="up"
                        />
                      )}
                      name="serviceDateFrom"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <Field
                component={CustomDropdown}
                name="assignedTo"
                options={removeEmptyOrInactiveOptions(
                  dropdownOptions?.agents?.data,
                  false
                )}
                label="Assign To"
                isMulti
                placeholder="Select Agent(s)"
                datacy="agents-Field"
                showAllSelectedLabel={false}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="d-flex align-items-center justify-content-flex-end add-manual-claim-form-btn-wrapper">
        {!pristine && (
          <p
            className="add-manual-claim-popup__clear-btn"
            onClick={() => {
              change('patientFirstName', '');
              resetForm();
            }}
          >
            Clear All
          </p>
        )}
        <button
          type="submit"
          disabled={submitting}
          className="ap-button ap-button--secondary width-160 add-manual-claim-popup__submit-btn"
          datacy="add-manual-claim-form-submit-button"
        >
          Add Claim
        </button>
      </div>
    </form>
  );
};

AddSingleClaimForm.propTypes = {
  AddSingleClaimForm: PropTypes.func.isRequired,
};

AddSingleClaimForm = reduxForm({
  form: 'addManualClaimForm',
})(AddSingleClaimForm);

const mapStateToProps = (state) => {
  const userInfo = getUserInfo(state);
  const selector = formValueSelector('addManualClaimForm');
  const selectedPractice = selector(state, 'practiceIdentifier') || [];
  const selectedRenderingProvider = selector(state, 'providerLookupId') || [];
  const selectedServiceFromDate = selector(state, 'serviceDateFrom');
  const selectedServiceToDate = selector(state, 'serviceDateTo');

  return {
    userInfo,
    selectedPractice,
    selectedServiceFromDate,
    selectedServiceToDate,
    selectedRenderingProvider,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators({ change }, dispatch),
    resetForm: () => dispatch(reset('addManualClaimForm')),
  };
};

AddSingleClaimForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(AddSingleClaimForm);

export default AddSingleClaimForm;
