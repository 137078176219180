import {
  Field,
  change,
  reduxForm,
  FieldArray,
  initialize,
  SubmissionError,
  formValueSelector,
} from 'redux-form/immutable';
import { debounce } from 'lodash';
import { connect } from 'react-redux';
import { List, Map } from 'immutable';
import { bindActionCreators } from 'redux';
import React, { useState, useEffect, useCallback } from 'react';

import './style.css';

import Input from 'components/common/input';
import Button from 'components/common/button';
import Select from 'components/common/select/Select';
import { BUTTON_TYPE } from 'components/common/button/Button';
import LoadingIndicator from 'components/Shared/LoadingIndicator';

import { fetchProvidersFromRegistry } from 'API/CreateUserAPI';
import { fetchRenderingProviders } from 'API/AccountSettingsAPI';

import { validateNPI } from 'helpers/validators';
import { MASK_PROVIDER_NPI } from 'helpers/masks';
import { handleError } from 'helpers/errorHandler';

import {
  INDIVIDUAL_TYPE_PROVIDER,
  ORGANIZATION_TYPE_PROVIDER,
} from 'API/CreateAppealAPI';

const RENDERING_PROVIDER_TYPES = {
  INDIVIDUAL: INDIVIDUAL_TYPE_PROVIDER,
  ORGANIZATION: ORGANIZATION_TYPE_PROVIDER,
};

const validateMultiSelectRequired = (value) => {
  const message = 'Please select at least one option';
  if (!value) {
    return message;
  }
  if ((value.toJS && value.toJS().length === 0) || value.length === 0) {
    return message;
  }
  return undefined;
};

const AddRenderingProviderForm = (props) => {
  const {
    onSubmit,
    onCancel,
    submitting,
    handleSubmit,
    isUpdateForm = false,
    initialValues,
    isPopupForm = false,
    practiceDropdownOptions,
    practiceClinicsBillingProviders,
    billingProviderOptionsByPractice,
    clinicOptionsByPractice,
    change,
  } = props;
  const renderPracticesClinicsBillingProviders = useCallback(
    ({ fields }) => {
      return (
        <div>
          {fields.map((practicesClinicsBillingProviders, index) => {
            const { practice } = practiceClinicsBillingProviders.toJS()[index];

            return (
              <div className="clinic-billing-provider-container" key={index}>
                <Field
                  component={(props) => {
                    const value = props.input.value.toJS
                      ? props.input.value.toJS()
                      : props.input.value;

                    return (
                      <div className="clinic-billing-provider-practice-title">
                        Practice: {value.label}
                      </div>
                    );
                  }}
                  name={`${practicesClinicsBillingProviders}.practice`}
                  label="Practice"
                  options={practiceDropdownOptions}
                  isDisabled={true}
                />
                <div className="mb-8">
                  <Field
                    component={Select}
                    name={`${practicesClinicsBillingProviders}.clinics`}
                    label="Clinic(s)"
                    options={clinicOptionsByPractice[practice.value]}
                    validate={[validateMultiSelectRequired]}
                    reactSelectProps={{
                      isMulti: true,
                      maxMenuHeight: '100px',
                    }}
                  />
                </div>
                <Field
                  component={Select}
                  name={`${practicesClinicsBillingProviders}.billingProviders`}
                  label="Billing Provider(s)"
                  options={billingProviderOptionsByPractice[practice.value]}
                  reactSelectProps={{
                    isMulti: true,
                    menuPlacement: 'top',
                    maxMenuHeight: '130px',
                  }}
                  validate={[validateMultiSelectRequired]}
                />
              </div>
            );
          })}
        </div>
      );
    },
    [
      practiceDropdownOptions,
      practiceClinicsBillingProviders,
      billingProviderOptionsByPractice,
      clinicOptionsByPractice,
    ]
  );
  const [isFetchingProviderData, setIsFetchingProviderData] = useState(false);
  const [renderingProviderType, setRenderingProviderType] = useState(
    RENDERING_PROVIDER_TYPES.INDIVIDUAL
  );

  useEffect(() => {
    if (initialValues) {
      const values = initialValues.toJS();
      if (values.organizationName) {
        setRenderingProviderType(RENDERING_PROVIDER_TYPES.ORGANIZATION);
      }
    }
  }, [initialValues]);

  const fetchAndSetProvider = debounce(async (npi) => {
    try {
      setIsFetchingProviderData(true);
      const data = await fetchProvidersFromRegistry(npi);
      if (data.length === 0) {
        return setIsFetchingProviderData(false);
      }
      change('firstName', data[0].firstName);
      change('lastName', data[0].lastName);
      change('organizationName', data[0].organizationName);
      setIsFetchingProviderData(false);
      setRenderingProviderType(
        data[0].organizationName
          ? RENDERING_PROVIDER_TYPES.ORGANIZATION
          : RENDERING_PROVIDER_TYPES.INDIVIDUAL
      );
      setIsFetchingProviderData(false);
    } catch (error) {
      setIsFetchingProviderData(false);
      handleError(error);
    }
  }, 800);

  const onNpiChange = (e) => {
    const npi = e.target.value;
    if (!npi || npi.replaceAll('_', '').length !== 10) {
      return;
    }
    fetchAndSetProvider(npi);
  };

  const validate = (formValues) => {
    const errors = {
      firstName: 'First name is required',
      lastName: 'Last name is required',
      organizationName: 'Organization name is required',
      npi: 'NPI is required',
    };

    if (
      !formValues.npi &&
      !formValues.firstName &&
      !formValues.lastName &&
      !formValues.organizationName
    ) {
      throw new SubmissionError(errors);
    }

    if (renderingProviderType === RENDERING_PROVIDER_TYPES.INDIVIDUAL) {
      delete errors.organizationName;
      if (formValues.firstName) {
        delete errors.firstName;
      }
      if (formValues.lastName) {
        delete errors.lastName;
      }
    }

    if (renderingProviderType === RENDERING_PROVIDER_TYPES.ORGANIZATION) {
      delete errors.firstName;
      delete errors.lastName;
      if (formValues.organizationName) {
        delete errors.organizationName;
      }
    }

    if (formValues.npi) {
      delete errors.npi;
      const isValidNPI = validateNPI(formValues.npi);
      if (isValidNPI) errors.npi = validateNPI(formValues.npi);
    }

    if (Object.keys(errors).length > 0) {
      throw new SubmissionError(errors);
    }
  };

  const handleFormSubmit = async (formValues) => {
    const values = formValues.toJS();

    validate(values);

    values.providerType = renderingProviderType;

    if (renderingProviderType === RENDERING_PROVIDER_TYPES.ORGANIZATION) {
      values.firstName = '';
      values.lastName = '';
    } else {
      values.organizationName = '';
    }

    try {
      const { data } = await fetchRenderingProviders({
        page: 1,
        npi: values.npi,
      });

      if (data.length > 0) {
        const providerNpi = initialValues && initialValues.toJS().npi;
        const existingProvider = data.filter(
          (provider) => provider.npi !== providerNpi
        );
        if (existingProvider.length > 0) {
          throw new SubmissionError({
            npi: 'Rendering Provider with this NPI already exists.',
          });
        }
      }
      await onSubmit({
        ...values,
        taxId: '',
        clinicsUuids: (values.clinicsIds || []).map(({ value }) => value),
        billingProvidersUuids: (values.billingProvidersIds || []).map(
          ({ value }) => value
        ),
      });
    } catch (error) {
      if (error && error.name === 'SubmissionError') {
        throw error;
      }
      handleError(error);
    }
  };

  const onProviderTypeChange = (e) => {
    setRenderingProviderType(+e.target.value);
  };

  const handlePracticesChange = (values) => {
    const prevSelectedPracticeIds = props.selectedPracticeIds
      ? props.selectedPracticeIds.toJS
        ? props.selectedPracticeIds.toJS().map((option) => option.value)
        : props.selectedPracticeIds.map((option) => option.value)
      : [];

    const selectedPracticeIds = values.map((practice) => practice.value);

    const curPracticeClinicsBillingProviders =
      practiceClinicsBillingProviders && practiceClinicsBillingProviders.toJS
        ? Array.isArray(practiceClinicsBillingProviders.toJS())
          ? practiceClinicsBillingProviders.toJS()
          : []
        : [];
    const existingPracticeClinicBillingProviders =
      curPracticeClinicsBillingProviders.filter((practiceClinicBillingProv) =>
        selectedPracticeIds.includes(practiceClinicBillingProv.practice.value)
      );
    const newPracticeClinicBillingProviders = values
      .filter((practice) => !prevSelectedPracticeIds.includes(practice.value))
      .map((practiceOption) => {
        return {
          practice: practiceOption,
          billingProviders: null,
          clinics: null,
        };
      });

    change(
      'practiceClinicsBillingProviders',
      List([
        ...existingPracticeClinicBillingProviders.map(Map),
        ...newPracticeClinicBillingProviders.map(Map),
      ])
    );
  };

  return (
    <div>
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <div className="row mb-16">
          <div className="col-lg-4">
            <div className="d-flex">
              <Field
                component={Input}
                name="npi"
                label="Npi *"
                placeholder="Npi"
                maxLength={10}
                maskedInput={true}
                mask={MASK_PROVIDER_NPI}
                onChange={onNpiChange}
              />
              <div className="field-loading-indicator--right">
                <LoadingIndicator showing={isFetchingProviderData} />
              </div>
            </div>
          </div>
        </div>
        <div className="mb-16">
          <div>Rendering Provider Type</div>
          <div className="radio-wrapper">
            <div className="d-flex justify-content--space-between align-item-center">
              <div>
                <span className="mr-44">
                  <label>
                    <input
                      className="mr-8"
                      type="radio"
                      checked={
                        renderingProviderType ===
                        RENDERING_PROVIDER_TYPES.INDIVIDUAL
                      }
                      onChange={onProviderTypeChange}
                      name={RENDERING_PROVIDER_TYPES.INDIVIDUAL}
                      value={RENDERING_PROVIDER_TYPES.INDIVIDUAL}
                    />
                    Individual
                  </label>
                </span>
                <span>
                  <label>
                    <input
                      type="radio"
                      className="mr-8"
                      checked={
                        renderingProviderType ===
                        RENDERING_PROVIDER_TYPES.ORGANIZATION
                      }
                      onChange={onProviderTypeChange}
                      name={RENDERING_PROVIDER_TYPES.ORGANIZATION}
                      value={RENDERING_PROVIDER_TYPES.ORGANIZATION}
                    />
                    Organization
                  </label>
                </span>
              </div>
            </div>
          </div>
        </div>
        {renderingProviderType === RENDERING_PROVIDER_TYPES.INDIVIDUAL ? (
          <div className="row mb-16">
            <div className="col-lg-4">
              <Field
                component={Input}
                name="firstName"
                label="First Name *"
                placeholder="John"
                disabled={isFetchingProviderData}
                maxLength={100}
              />
            </div>

            <div className="col-lg-4">
              <Field
                component={Input}
                name="lastName"
                label="Last Name *"
                placeholder="Doe"
                disabled={isFetchingProviderData}
                maxLength={100}
              />
            </div>
          </div>
        ) : (
          <div className="row mb-16">
            <div className="col-lg-4">
              <Field
                component={Input}
                name="organizationName"
                label="Organization Name *"
                placeholder="Organization Name"
                disabled={isFetchingProviderData}
                maxLength={200}
              />
            </div>
          </div>
        )}

        <div className="row mb-16">
          <div className="col-lg-12">
            <Field
              component={Select}
              name="practiceIds"
              label="Practice(s)"
              options={practiceDropdownOptions}
              onChange={handlePracticesChange}
              validate={[validateMultiSelectRequired]}
              placeholder="Please select all practices for this rendering provider"
              reactSelectProps={{
                isMulti: true,
                maxMenuHeight: '100px',
              }}
            />
          </div>
        </div>
        {practiceClinicsBillingProviders &&
          practiceClinicsBillingProviders.toJS().length > 0 && (
            <div>
              <div className="clinic-billing-provider-title">
                Clinic(s) and Billing Provider(s)
              </div>
              <div className="row mb-16">
                <div className="col-lg-12">
                  <FieldArray
                    name="practiceClinicsBillingProviders"
                    component={renderPracticesClinicsBillingProviders}
                    label="Clinic(s) And Billing Provider(s)"
                  />
                </div>
              </div>
            </div>
          )}

        {isPopupForm ? (
          <div className="appealio-popup--v3__footer appealio-popup--v3__footer--inside">
            <button
              type="submit"
              disabled={props.submitting}
              className="ap-button ap-button--primary-dark ap-button--primary-md"
            >
              {isUpdateForm ? 'Update' : 'Add'}
            </button>
          </div>
        ) : (
          <div className="d-flex justify-content-flex-end pr-15 form-action-button-wrapper">
            <Button
              title={isUpdateForm ? 'Update' : 'Save'}
              className="ap-button--secondary justify-content-center mr-8"
              disabled={submitting}
            />
            <Button
              title="Cancel"
              type={BUTTON_TYPE.LIGHT}
              className=" justify-content-center fw-normal"
              disabled={submitting}
              onClick={onCancel}
            />
          </div>
        )}
      </form>
    </div>
  );
};

const FORM_NAME = 'add-rendering-provider-form';
const selector = formValueSelector(FORM_NAME);

export default connect(
  (state) => {
    const selectedPracticeIds = selector(state, 'practiceIds');
    return {
      npi: selector(state, 'npi'),
      practiceClinicsBillingProviders: selector(
        state,
        'practiceClinicsBillingProviders'
      ),
      selectedPracticeIds,
    };
  },
  () => ({
    initialize,
    actions: bindActionCreators({ change }),
  })
)(
  reduxForm({
    form: FORM_NAME,
  })(AddRenderingProviderForm)
);
