import { APPEALIO_SUPPORT_EMAIL } from '../../../constants/appConstants';

const SUBMISSION_FAILED_ERROR = {
  title: 'Form could not be submitted',
  message:
    'We couldn’t save your information. Make sure all fields are filled out correctly and try again.',
};

const SUBMISSION_FAILED_ERROR_BY_MAIL = {
  title: 'Form could not be submitted',
  message: `We couldn't submit your appeal by mail, please try submitting by fax. Please contact ${APPEALIO_SUPPORT_EMAIL} for support.`,
};

const INVALID_PAYER_FORM_PDF = {
  title: 'Payer Form PDF Error',
  message: `The selected payer form is invalid. Please contact ${APPEALIO_SUPPORT_EMAIL} for assistance.`,
};

const SUBMISSION_FAILED_BY_INTERNAL_SERVER_ERROR = {
  title: 'Form could not be submitted',
  message: `We couldn't submit your appeal. Please retry after a while. If the problem persists please contact ${APPEALIO_SUPPORT_EMAIL} for support.`,
};

const UPLOAD_FAILED_ERROR = {
  title: 'File could not be uploaded',
  message:
    'We couldn’t upload your file. Make sure it is an acceptable file format and try again.',
};

const NETWORK_ERROR = {
  title: 'Network error',
  message:
    'There was an issue with your network connection. Please try uploading your documents again in a few minutes.',
};

const INVALID_FILETYPE_ERROR = {
  title: 'File could not be added',
  message: 'Only PNG, JPG, and PDF files are accepted.',
};

const DELETE_LETTER_ERROR = {
  title: 'Letter could not be deleted',
  message:
    'We were not able to delete the letter for some reason. Close this message and try again.',
};

const MOVE_LETTER_ERROR = {
  title: 'Letter could not be moved',
  message:
    'We were not able to moved the letter for some reason. Close this message and try again.',
};

const DELETE_DOCUMENT_ERROR = {
  title: 'Document could not be deleted',
  message:
    'We were unable to delete the document for some reason. Close this message and try again.',
};

const REQUIRED_FIELD_ERROR = {
  title: 'Please review the form and fill all required fields.',
  message: 'This field is required.',
};

const INVALID_DATE_FORMAT_ERROR = {
  title: 'Invalid date format',
  message: 'Your date format isn’t correct. Try MM/DD/YYYY.',
};

const INVALID_DATE_ERROR = {
  title: 'Invalid date',
  message: 'Invalid date.',
};

const DATE_PAST_ERROR = {
  title: 'Invalid date',
  message: "This date should be before today's date.",
};

const CPT_ERROR_TITLE = 'CPT Error';

const CPT_ERROR_DEADLINE_IN_PAST = {
  title: 'Appeal Deadline Error',
  message: 'The deadline for the appeal should always be in the future.',
};

const INVALID_FAX_NUMBER_ENTRY_ERROR = {
  title: 'Invalid Fax Number Entry',
  message: 'The Fax Number is not valid.',
};

const INVALID_FAX_NUMBER_FORMAT_ERROR = {
  title: 'Invalid Fax Number Format',
  message:
    'Fax Number should be in (XXX) XXX-XXXX format and contain only digits.',
};

const INVALID_PHONE_NUMBER_ENTRY_ERROR = {
  title: 'Invalid Phone Number Entry',
  message: 'The Phone Number is not valid.',
};

const INVALID_PHONE_NUMBER_FORMAT_ERROR = {
  title: 'Invalid Phone Number Format',
  message:
    'Phone Number should be in (XXX) XXX-XXXX format and contain only digits.',
};

const ZIP_CODE_ERROR = {
  title: 'ZIP code error.',
  message:
    'Invalid ZIP code. Please update the ZIP code in the formats : 12345, 1234512345 12345-6789.',
};

const CPT_ERROR_DENIAL_DATE_IN_FUTURE = {
  title: 'EOB date Error',
  message: 'EOB date cannot be in the future.',
};

const CPT_ERROR_VALID_DENIAL_DATE = {
  title: 'EOB date Error',
  message: 'EOB Date: EOB date cannot be after :validEobDate.',
};

const ID_FORMAT_ERROR = {
  title: 'ID is not in correct format',
  message: 'Id should only contain letters or numbers',
};

const PAYER_CLAIM_ID_FORMAT_ERROR = {
  title: 'ID is not in correct format',
  message: "Id field should only have letters, numbers or '-'",
};

const INJURY_DATE_IN_FUTURE = {
  title: 'Injury date Error',
  message: 'Injury date cannot be in the future.',
};

const CPT_ERROR_BILLED_AMOUNT =
  'Billed Amount should always be greater or equal to the Allowed Amount.';

const CPT_ERROR_AMOUNT_ALLOWED =
  'The Allowed Amount is too low. It should be greater or equal to the Deductible, Co-Insurance and Payment Amount combined.';

const DATE_STRING_ERROR = 'The date must occur in the past.';

const INVALID_DATE_FORMAT = 'Your date format isn’t correct. Try MM/DD/YYYY.';

const INVALID_DATE = 'Invalid date.';

const NEGATIVE_VALUE_NOT_ALLOWED = 'Amount cannot be negative.';

const INVALID_AMOUNT_DECIMAL_VALUE =
  'The amount cannot have more than two decimal digits.';

const INVALID_AMOUNT_INTEGER_VALUE =
  'The amount cannot have more than eight integer digits.';

const DENIAL_DATE_IS_REQUIRED =
  'You must enter a EOB Date before adding Service Dates.';

const SERVICE_AFTER_DENIAL = 'Service date must be before EOB Date.';

const GENERIC_ERROR_TITLE = 'Please fix the following errors to proceed';

const FIELD_REQUIRED_STRING = 'This field is required';

const FIELD_NEGATIVE_VALUE_STRING = 'Negative amount';

const FIELD_INCORRECT_AMOUNT_FORMAT = 'Incorrect amount format.';

const CPT_CODE_ERROR =
  'Adjustment Code must be alphanumeric and between 3 to 6 characters.';

const CPT_ERROR_AMOUNT_ALLOWED_SUM =
  'The Allowed Amount should be equal to the Deductible, Co-Insurance and Payment Amount combined.';

const FIELD_INCORRECT_ID_FORMAT =
  'Incorrect ID format.This field should only have letters or numbers';
const PAYER_CLAIM_ID_FIELD_INCORRECT_FORMAT =
  'Payer Claim ID should only contain ‘-’ , letters or numbers.';
const SUBMITTER_CLAIM_ID_FIELD_INCORRECT_FORMAT =
  'Claim ID should only contain ‘-’, letters or numbers.';

const UPLOAD_PAYER_FORM = 'Please select a payer form to upload.';
const UPLOAD_LETTER_TEMPLATE = 'Please select a letter template to upload.';
const FILE_SIZE_EXCEEDS = 'File must not exceed 10 MB.';
const INVALID_TEMPLATE_TYPE =
  'Please upload a valid file. Only .pdf,.doc, and .docx files are supported.';

const UPLOAD_PM_NOTE_TEMPLATE = 'Please upload a template';
const INVALID_PM_NOTE_TEMPLATE_TYPE =
  'Please upload a valid file. Only .txt,.doc, and .docx files are supported.';

export {
  UPLOAD_PAYER_FORM,
  FILE_SIZE_EXCEEDS,
  INVALID_TEMPLATE_TYPE,
  UPLOAD_LETTER_TEMPLATE,
  SUBMISSION_FAILED_ERROR,
  UPLOAD_FAILED_ERROR,
  DELETE_LETTER_ERROR,
  MOVE_LETTER_ERROR,
  DELETE_DOCUMENT_ERROR,
  CPT_ERROR_TITLE,
  CPT_ERROR_BILLED_AMOUNT,
  CPT_ERROR_DEADLINE_IN_PAST,
  CPT_ERROR_AMOUNT_ALLOWED,
  FIELD_REQUIRED_STRING,
  INVALID_DATE_FORMAT,
  DATE_STRING_ERROR,
  GENERIC_ERROR_TITLE,
  INVALID_AMOUNT_DECIMAL_VALUE,
  INVALID_AMOUNT_INTEGER_VALUE,
  FIELD_NEGATIVE_VALUE_STRING,
  FIELD_INCORRECT_AMOUNT_FORMAT,
  NEGATIVE_VALUE_NOT_ALLOWED,
  DENIAL_DATE_IS_REQUIRED,
  SERVICE_AFTER_DENIAL,
  REQUIRED_FIELD_ERROR,
  CPT_CODE_ERROR,
  INVALID_FILETYPE_ERROR,
  INVALID_FAX_NUMBER_ENTRY_ERROR,
  INVALID_FAX_NUMBER_FORMAT_ERROR,
  INVALID_PHONE_NUMBER_ENTRY_ERROR,
  INVALID_PHONE_NUMBER_FORMAT_ERROR,
  ZIP_CODE_ERROR,
  CPT_ERROR_DENIAL_DATE_IN_FUTURE,
  INVALID_DATE_FORMAT_ERROR,
  INVALID_DATE_ERROR,
  INVALID_DATE,
  DATE_PAST_ERROR,
  INJURY_DATE_IN_FUTURE,
  CPT_ERROR_AMOUNT_ALLOWED_SUM,
  FIELD_INCORRECT_ID_FORMAT,
  ID_FORMAT_ERROR,
  SUBMISSION_FAILED_ERROR_BY_MAIL,
  PAYER_CLAIM_ID_FIELD_INCORRECT_FORMAT,
  PAYER_CLAIM_ID_FORMAT_ERROR,
  SUBMISSION_FAILED_BY_INTERNAL_SERVER_ERROR,
  SUBMITTER_CLAIM_ID_FIELD_INCORRECT_FORMAT,
  CPT_ERROR_VALID_DENIAL_DATE,
  INVALID_PAYER_FORM_PDF,
  NETWORK_ERROR,
  INVALID_PM_NOTE_TEMPLATE_TYPE,
  UPLOAD_PM_NOTE_TEMPLATE,
};
