import classnames from 'classnames';
import React, { useCallback } from 'react';
import ReactTooltip from 'react-tooltip';
import { IoIosArrowBack, IoIosArrowDown } from 'react-icons/io';

import ArrayCell from 'components/common/table/ArrayCell';

import './style.css';

const ClaimAccordion = ({
  isOpen,
  selectedClaims,
  isDisabled = false,
  onToggle,
  handleClaimSelect,
}) => {
  const handleAccordionToggle = useCallback(() => {
    if (isDisabled) return;
    if (selectedClaims.length === 0) return;
    onToggle(!isOpen);
  }, [isOpen, isDisabled, selectedClaims, onToggle]);

  return (
    <div
      className={classnames('claim-accordion', {
        'claim-accordion--open': isOpen,
      })}
      onClick={handleAccordionToggle}
    >
      <div className="claim-accordion__header">
        <div className="claim-accordion__info-text">
          {selectedClaims.length === 0
            ? 'Select up to 10 claims for duplicate action'
            : isDisabled
            ? `${selectedClaims.length} Claims selected for Batch Duplicate`
            : `${selectedClaims.length} of 10 Claims Selected for Duplicate`}
        </div>
        {selectedClaims.length && !isDisabled ? (
          isOpen ? (
            <IoIosArrowDown size="20" />
          ) : (
            <IoIosArrowBack size="20" />
          )
        ) : null}
      </div>
      {isOpen && (
        <div className="claim-accordion__content">
          <table className="claim-accordion__table">
            <thead>
              <tr>
                <th></th>
                <th>Patient</th>
                <th>Claim ID</th>
                <th>Payer Claim ID</th>
                <th>Service Date(s)</th>
                <th>
                  <div>Billed Amt</div>
                  <div>(Payment Amt)</div>
                </th>
                <th>
                  <div>Practice</div>
                  <div>(Provider)</div>
                </th>
                <th>Procedure(s)</th>
                <th>Modifier(s)</th>
                <th>Reason(s)</th>
                <th>Remark(s)</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {selectedClaims.map((claim, idx) => (
                <tr key={`${claim.claimNumber}-${idx}`}>
                  <td>{idx + 1}.</td>
                  <td data-tip={claim.patientName}>{claim.patientName}</td>
                  <td data-tip={claim.claimNumber}>{claim.claimNumber}</td>
                  <td data-tip={claim.claimControlNumber}>
                    {claim.claimControlNumber}
                  </td>
                  <td>
                    <ArrayCell value={claim.serviceDates} />
                  </td>
                  <td>
                    <div>${claim.billedAmount}</div>
                    <div>(${claim.paymentAmount})</div>
                  </td>
                  <td className="claim-accordion__table-td--practice-provider">
                    <div data-tip={claim.practiceName}>
                      {claim.practiceName}
                    </div>
                    {claim.providerName && (
                      <div data-tip={claim.providerName}>
                        ({claim.providerName})
                      </div>
                    )}
                  </td>
                  <td>
                    <ArrayCell value={claim.cptCodes} />
                  </td>
                  <td>
                    <ArrayCell value={claim.cptModifiersCodes} />
                  </td>
                  <td>
                    <ArrayCell value={claim.reasonCodes} />
                  </td>
                  <td>
                    <ArrayCell value={claim.remarkCodes} />
                  </td>
                  <td
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      handleClaimSelect(claim);
                    }}
                  >
                    <span className="text-decoration-underline cursor-pointer color-red">
                      Remove
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <ReactTooltip place="top" effect="solid" />
        </div>
      )}
    </div>
  );
};

export default ClaimAccordion;
