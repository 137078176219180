import React from 'react';
import { Map } from 'immutable';
import PropTypes from 'prop-types';
import { Field, FieldArray } from 'redux-form/immutable';

import './style.css';

import { USER_ROLE_OPTIONS } from 'constants/options';

import Button from 'components/common/button';
import TextArea from 'components/common/textarea';
import Select from 'components/common/select/Select';
import LoadingIndicator from 'components/Shared/LoadingIndicator';

import binImage from 'img/bin-grey.svg';

const SOP_INSTRUCTIONS_CHARACTERS_LIMIT = 255;

const validateSelectRequired = (value) => {
  const message = 'This field is required';
  if (!value) {
    return message;
  }
  if ((value.toJS && value.toJS().length === 0) || value.length === 0) {
    return message;
  }
  return undefined;
};

const RenderSteps = ({
  fields,
  meta: { touched, error, submitFailed },
  actionList,
  isActionListLoading,
  sopId,
  deleteSopStep,
  setSopDeleteAlert,
  deleteSopForRule,
  setShowProposedPopup,
}) => {
  const handleDeleteStep = (index, sopId) => {
    const stepId = fields.get(index)?.get('id');
    const deleteSopAndSteps = () => {
      deleteSopStep(stepId);
      deleteSopForRule();
    };

    if (sopId && stepId) {
      if (fields.length === 1) {
        setSopDeleteAlert({
          isOpen: true,
          message:
            'This is the only remaining step for your SOP. Deleting this will remove the entire SOP. Do you want to proceed?',
          deleteFunc: deleteSopAndSteps,
        });
      } else {
        deleteSopStep(stepId);
        fields.remove(index);
      }
    } else {
      fields.remove(index);
    }
  };

  if (fields.length < 1) {
    fields.push(Map({}));
  }

  return (
    <ul className="sop-steps-list">
      <>
        <div className="row  no-gutter mb-8">
          <div className="col-md-4 ml-32">
            <span className="ml-16 mr-8">Proposed Action</span>
            <span
              className="link-url text-decoration-underline fs-12 cursor-pointer"
              onClick={() => setShowProposedPopup(true)}
            >
              (+ Add New)
            </span>
          </div>
          <div className="col-md-3">
            <span className="ml-24">Role</span>
          </div>
          <div className="col-md-5">Instructions</div>
        </div>
        {fields.map((step, index) => (
          <li key={index}>
            <div className="row no-gutter d-flex align-item-flex-start">
              <div className="col-md-4">
                <div className="d-flex">
                  <span className="mr-16 mt-8 width-10">{index + 1}.</span>
                  <Field
                    component={Select}
                    width=""
                    options={actionList}
                    name={`${step}.action`}
                    placeholder="Select an Action"
                    required={true}
                    isLoading={isActionListLoading}
                    validate={validateSelectRequired}
                    reactSelectProps={{
                      maxMenuHeight: 140,
                    }}
                  />
                </div>
              </div>
              <div className="col-md-3">
                <div className="d-flex justify-content--space-between">
                  <span className="mr-8 ml-8 mt-8">by</span>
                  <Field
                    component={Select}
                    options={USER_ROLE_OPTIONS}
                    name={`${step}.role`}
                    defaultValue={null}
                    placeholder="Select Role"
                    validate={validateSelectRequired}
                  />
                </div>
              </div>
              <div className="col-md-5 ml-16">
                <div className="d-flex align-items-center justify-content--space-between">
                  <Field
                    component={TextArea}
                    name={`${step}.instruction`}
                    resize={false}
                    rows="1"
                    styles={{
                      minWidth: '308px',
                      resize: 'none',
                    }}
                    max={SOP_INSTRUCTIONS_CHARACTERS_LIMIT}
                    maxLength={SOP_INSTRUCTIONS_CHARACTERS_LIMIT}
                    placeholder="Enter instructions here"
                  />
                  <img
                    src={binImage}
                    alt="bin"
                    className="cursor-pointer ml-4 mr-8"
                    onClick={() => handleDeleteStep(index, sopId)}
                  />
                </div>
              </div>
            </div>
          </li>
        ))}
      </>
      <li className="sop-add-step-button-wrapper">
        <Button
          title="Add Step"
          className="width-120 ap-button--secondary justify-content-center"
          datacy="add-step-Button"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            fields.push(Map({}));
          }}
        />
        {(touched || submitFailed) && error && <span>{error}</span>}
      </li>
    </ul>
  );
};

const AddOrUpdateSopStepsForm = ({
  isFetchingSop = false,
  sopId,
  deleteSopForRule,
  deleteSopStep,
  setSopDeleteAlert,
  setShowProposedPopup,
  actionList,
  isActionListLoading,
}) => {
  if (isFetchingSop) {
    return <LoadingIndicator showing={true} />;
  }

  return (
    <>
      <FieldArray
        name="steps"
        component={RenderSteps}
        actionList={actionList}
        isActionListLoading={isActionListLoading}
        deleteSopStep={deleteSopStep}
        sopId={sopId}
        setSopDeleteAlert={setSopDeleteAlert}
        deleteSopForRule={deleteSopForRule}
        setShowProposedPopup={setShowProposedPopup}
      />
    </>
  );
};

AddOrUpdateSopStepsForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
};

export default AddOrUpdateSopStepsForm;
