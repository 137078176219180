/**
 * Created by goer on 11/10/16.
 */
import React from 'react';
import moment from 'moment';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { initialize } from 'redux-form';
import { bindActionCreators } from 'redux';
import ReactTooltip from 'react-tooltip';
import ReactDOMServer from 'react-dom/server';
import { MdInfoOutline } from 'react-icons/md';
import { replace, push } from 'connected-react-router';
import { get as _get, pickBy, identity, trim, isEqual } from 'lodash';
import {
  Field,
  reduxForm,
  SubmissionError,
  formValueSelector,
} from 'redux-form/immutable';

import Dropdown from '../../Shared/Dropdown';
import SectionHeader from '../SectionHeader';
import PmNoteInfo from './PmNotesInfo/PmNotesInfo';
import Select from 'components/common/select/Select';
import SubmitButton from '../../Shared/SubmitButton';
import PortalAssist from './PayerPortal/PortalAssist';
import DocumentViewer from '../Review/DocumentViewer';
import DvVerifiedIcon from 'components/common/dvVerified';
import LoadingBlockHelper from '../../Shared/LoadingBlockHelper';
import PayerPortalStandalone from './PayerPortal/PayerPortalStandalone';
import ActionPopup, { TYPE_ACCEPT } from 'components/Shared/ActionPopup';
import { renderInput } from 'components/Dashboard/RecordResponse/renderInput';
import SaveContinueFooterComponent from '../../Shared/SaveContinueFooterComponent';
import OutsideClickWrapper from 'components/common/outsideClickWrapper/OutsideClickWrapper';
import { renderCCEmails } from 'components/Submissions/DeliveryTracking/DuplicateClaim/ResendIdenticalAppeal/EmailFormFields/EmailFormFields';
import EmailFileDropzone from 'components/Submissions/DeliveryTracking/DuplicateClaim/ResendIdenticalAppeal/EmailFormFields/EmailFileDropzone';

import { states } from '../state-mock-data';

import { renderField } from '../../Shared/textfield';
import { renderReduxDropdownV2 } from '../reduxReactSelect';
import { renderReduxTextarea } from '../reduxTextarea';
import { renderReduxMaskedInput } from '../reduxMaskedInput';
import {
  validateExists,
  validateZipCode,
  validatePhoneNumber,
  fieldCharacterValidation,
  pageValidationForDocuments,
  validateFaxNumber,
} from '../../../helpers/validators';
import {
  getSubmissionTypeContacts,
  patientInfoValidatorForStandalone,
  serviceDateValidatorForStandalone,
  setValueIfExists,
} from './utils';

import { downloadFile, handleErrors } from '../../../API/Config';
import CreateAppealAPI from '../../../API/CreateAppealAPI';

import { getAppealFromState } from '../../../redux/reducers/AppealStore';
import {
  createSubmitPackage,
  setSubmitConfirmPrefillData,
} from '../../../redux/actions/createSubmitPackageActions';
import {
  setCurrentStep,
  getAppealById,
  fetchSubmissionPreview,
} from '../../../redux/actions/createAppealActions';
import {
  setShippingAddress,
  fetchShippingAddress,
} from 'redux/actions/appealFormPrefillActions';

import {
  getCurrentAppealData,
  getIsFetchingPreview,
  getPreviewError,
  getPreviewUrl,
  getSubmitConfirmPrefillData,
  isCurrentAppealMedicalRecord,
} from '../../../redux/reducers/createAppealStore';

import submitSuccessImage from '../../../img/appealsuccess.svg';

import * as AccountsAPI from 'API/AccountSettingsAPI';
import { AppealStatusCode } from 'API/AppealStatusCode';

import ErrorBannerSection from '../../Shared/Errors/ErrorBannerSection';
import {
  SUBMISSION_FAILED_ERROR,
  INVALID_FAX_NUMBER_ENTRY_ERROR,
  INVALID_FAX_NUMBER_FORMAT_ERROR,
  INVALID_PHONE_NUMBER_ENTRY_ERROR,
  INVALID_PHONE_NUMBER_FORMAT_ERROR,
  ZIP_CODE_ERROR,
  REQUIRED_FIELD_ERROR,
  FIELD_REQUIRED_STRING,
  SUBMISSION_FAILED_ERROR_BY_MAIL,
  SUBMISSION_FAILED_BY_INTERNAL_SERVER_ERROR,
} from '../../Shared/Errors/errorMessages';
import {
  APPEAL_STEPS,
  CONTACT_TYPE,
  DOCUMENT_CATEGORY,
} from '../../../constants/appConstants';

import { shippingAddressSerializer } from './mapper';

import { ErrorHandling } from '../../Shared/Errors/ErrorHandling';
import { addedRequiredFieldError } from '../addRequiredError';
import { MASK_PHONE } from '../../../helpers/masks';
import { mimeTypes } from '../../../helpers/mimeTypes';
import { handleError } from '../../../helpers/errorHandler';
import { getUserInfo } from '../../../redux/reducers/loginStore';
import { CREATE_APPEAL_PATIENT_INFO } from '../../../constants/routes';
import {
  PayerPortalSubmissionConfirmationPopUp,
  StandaloneRouterPrompt,
} from '../Popups/Popups';
import {
  extractErrorMessagesHTTPFromError,
  validateAndFormatZipCode,
} from '../../../helpers/utils';

import { validateAppealStep } from 'components/CreateAppeal/createAppealUtils';
import incompleteIcon from 'img/incomplete.svg';

import * as routes from 'constants/routes';
import AutoFillButton from './AutoFillButton/AutoFillButton';

import * as toast from 'components/Shared/toast';

import TagSubmissionForm, { TAGS_CHARACTER_LIMIT } from './TagSubmission';

import {
  fetchContacts,
  parseContactDataToDropdown,
} from 'API/AccountSettingsAPI';
import { createTagsWithPageCount } from 'API/SubmitPackageAPI';
import {
  isFaxCoverLetterDisabledForUser,
  isUserAuthorizedForOddPageSubmissionPadding,
} from 'Auth/FeatureFlags';
import {
  isAppealioExpressUser,
  isAuthorizedForEmailSubmission,
} from 'Auth/AuthUtils';

import { isEmpty, isEmptyObjectValues, withoutAttrs } from 'helpers/object';
import { normalizeMoney } from '../DenialInformation/CPTRowConfig';

import { preventBackNavigation } from 'helpers/navigationUtils';
import DocumentsUploadAPI from 'API/DocumentsUploadAPI';
import S3FileUploader from '../DocumentsUpload/ContentProcessing/S3FileUploader';

const SUBMISSION_TYPE = {
  PAYER_PORTAL: 'PAYER PORTAL',
  FAX: 'FAX',
  MAIL: 'MAIL',
  EMAIL: 'EMAIL',
  DIRECT_APPEAL: 'DIRECT_APPEAL',
  DIRECT_RECON: 'DIRECT_RECON',
};

const CHANGE_PAYER_PORTAL_OPTION = 'CHANGE_PAYER_PORTAL_OPTION';

const STANDALONE_SUBMIT_OPTIONS = [
  {
    label: SUBMISSION_TYPE.MAIL,
    value: SUBMISSION_TYPE.MAIL,
  },
  {
    label: SUBMISSION_TYPE.PAYER_PORTAL,
    value: SUBMISSION_TYPE.PAYER_PORTAL,
  },
  {
    label: SUBMISSION_TYPE.FAX,
    value: SUBMISSION_TYPE.FAX,
  },
  {
    label: SUBMISSION_TYPE.EMAIL,
    value: SUBMISSION_TYPE.EMAIL,
  },
];

const PAYER_CONTACT_SUBMIT_OPTIONS = [
  {
    label: SUBMISSION_TYPE.FAX,
    value: SUBMISSION_TYPE.FAX,
  },
  {
    label: SUBMISSION_TYPE.PAYER_PORTAL,
    value: SUBMISSION_TYPE.PAYER_PORTAL,
  },
  {
    label: SUBMISSION_TYPE.MAIL,
    value: SUBMISSION_TYPE.MAIL,
  },
  {
    label: SUBMISSION_TYPE.EMAIL,
    value: SUBMISSION_TYPE.EMAIL,
  },
  {
    label: 'Change Payer Contact selection',
    value: CHANGE_PAYER_PORTAL_OPTION,
  },
];

const DIRECT_APPEAL_SUBMIT_OPTION = {
  label: 'DIRECT APPEAL',
  value: SUBMISSION_TYPE.DIRECT_APPEAL,
};

const DIRECT_RECON_SUBMIT_OPTION = {
  label: 'DIRECT RECONSIDERATION',
  value: SUBMISSION_TYPE.DIRECT_RECON,
};

const COLOR_OPTIONS = {
  BLACK_AND_WHITE: '0',
  COLOR: '1',
};

const APPEAL_TYPE = {
  APPEAL: 1,
  RECON: 2,
};

const SIDE_OPTIONS = {
  DOUBLE: '0',
  SINGLE: '1',
};

const RETURN_ADDRESS_AUTOFILL_TYPE = {
  CLINIC: 'clinic',
  PRACTICE: 'practice',
  CONTACT: 'contact',
};

const RETURN_ADDRESS_TYPE = {
  ...RETURN_ADDRESS_AUTOFILL_TYPE,
  CLIENT: 'client',
};

const PAPER_CLAIM_CONFIG = {
  WITH_BACK_PAGE: 'WITH_BACK_PAGE',
  WITHOUT_BACK_PAGE: 'WITHOUT_BACK_PAGE',
};

const PAPER_CLAIM_CONFIG_OPTIONS = [
  {
    label: 'With Back Page (preferred)',
    value: PAPER_CLAIM_CONFIG.WITH_BACK_PAGE,
    tooltip:
      'Defaults to color, double-sided, and claim form(s) on its own page',
  },
  {
    label: 'Without Back Page',
    value: PAPER_CLAIM_CONFIG.WITHOUT_BACK_PAGE,
    tooltip: 'Defaults to color and single-sided',
  },
];

class SubmitConfirm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      submissionTypeIndexSelected: SUBMISSION_TYPE.FAX,
      isShowingSuccess: false,
      isLoading: false,
      documentViewerOpen: false,
      isDownloadingPDFPackage: false,
      isFetchingDeliveryFormats: false,
      isDirectUHCApiAvailable: false,
      deliveryFormats: [],
      isPayerPortalSubmissionConfirmationPopupOpen: false,
      submissionTypeFilterOptions: PAYER_CONTACT_SUBMIT_OPTIONS,
      showPreviewUnavailableDialog: false,
      showSubmissionErrorPopup: false,
      selectedColorType: COLOR_OPTIONS.BLACK_AND_WHITE,
      clinicOptions: [],
      payerContactFaxOptions: [],
      payerContactMailOptions: [],
      payerContactEmailOptions: [],
      isFetchingPayerContactOptions: false,
      isFetchingClinicsOptions: false,
      faxAutofillConfig: {
        isVisible: false,
      },
      emailAutofillConfig: {
        isVisible: false,
      },
      returnAddressAutofillConfig: {
        type: null,
        isVisible: false,
      },
      shippingAddressAutofillConfig: {
        isVisible: false,
      },
      enableReturnAddressInputs: false,
      appealPreviewData: {
        isLoading: false,
        url: null,
        error: null,
      },
      pageCountData: {
        isVisible: false,
        message: '',
        isDouble: false,
      },
      payersDropdownOptions: [],
      practicesDropdownOptions: [],
      providersDropdownOptions: [],
      isActiveReturnType: null,
      isActiveShippingType: false,
      tagSubmissionData: {
        tags: [],
        currentTag: '',
      },
      appealioExpressContactsOptions: {
        returnContactsOptions: [],
        destinationContactsOptions: [],
        emailContactsOptions: [],
      },
      standalonePayerPortalOptions: [],
      isPaperClaimSubmission: false,
      reconReasons: [],
      disablePayer: false,
      isEmailSubmissionAvailable: false,
      previewFileObjectUrl: null,
      emailFiles: [],
    };

    this.isEmailSubmissionAvailableChecked = false;
    this.isPrefillDataInitialized = false;
  }

  UNSAFE_componentWillMount() {
    if (this.props.isStandAlone) {
      return;
    }
    this.props.actions.setCurrentStep(5);
    this.props.actions.getAppealById(this.props.selectedAppeal);
  }

  async fetchAndSetReconReasons() {
    try {
      this.setState({ isFetchingReconReasons: true, reconReasons: [] });
      const reconReasons = await CreateAppealAPI.getReconsiderationReasons(
        this.props.selectedAppeal
      );
      this.setState({
        reconReasons,
      });
    } catch (error) {
      handleError(error);
    } finally {
      this.setState({ isFetchingReconReasons: false });
    }
  }

  // TODO:handle UHC for direct to payer if its is already in step1
  async fetchAndSetDeliveryFormats() {
    this.setState({ isFetchingDeliveryFormats: true });
    try {
      const deliveryFormats = await CreateAppealAPI.getDeliveryFormats(
        this.props.selectedAppeal
      );
      const includesDirectAppeal = deliveryFormats.includes('direct_appeal');
      const includesDirectRecon = deliveryFormats.includes('direct_recon');
      if (includesDirectRecon) {
        this.fetchAndSetReconReasons();
      }

      this.setState({
        isDirectUHCApiAvailable: includesDirectAppeal || includesDirectRecon,
        deliveryFormats,
      });

      if (!includesDirectAppeal && !includesDirectRecon) {
        return;
      }

      const submissionTypeFilterOptions =
        this.getDirectDropdownOptions(deliveryFormats);
      const submissionTypeIndexSelected = includesDirectAppeal
        ? SUBMISSION_TYPE.DIRECT_APPEAL
        : SUBMISSION_TYPE.DIRECT_RECON;
      this.setState({
        submissionTypeFilterOptions,
        submissionTypeIndexSelected,
      });
      this.props.initialize({
        submissionTypeIndexSelected,
      });
    } catch (error) {
      handleError(error);
    } finally {
      this.setState({ isFetchingDeliveryFormats: false });
    }
  }

  setEmailFiles = (files) => {
    this.setState({
      emailFiles: files,
    });
  };

  deleteEmailFile = (id) => {
    return CreateAppealAPI.deleteAppealDocument(id);
  };

  fetchAndSetEmailFiles = async () => {
    try {
      const emailFiles = await CreateAppealAPI.fetchEmailDocuments(
        this.props.submissionId
      );

      this.setState({
        emailFiles,
      });
    } catch (error) {
      handleError(error);
    }
  };

  fetchAndSetIsEmailSubmissionAvailable = async () => {
    if (!isAuthorizedForEmailSubmission(this.props.userInfo)) return;
    try {
      const isEmailSubmissionAvailable =
        await CreateAppealAPI.isEmailSubmissionAvailable(
          this.props.submissionId
        );
      this.isEmailSubmissionAvailableChecked = true;
      this.setState({ isEmailSubmissionAvailable });
      this.fetchAndSetEmailFiles();
      return isEmailSubmissionAvailable;
    } catch (error) {
      handleError(error);
    }
  };

  fetchSubmissionPreviewFile = () => {
    if (
      !this.props.submissionPreviewUrl &&
      !this.props.isSubmissionPreviewFetching
    ) {
      this.props.actions.fetchSubmissionPreview(this.props.submissionId);
    }
  };

  async componentDidMount() {
    this.getFormDropdownOptionsForTagSubmission();

    this.fetchAndSetIsEmailSubmissionAvailable();

    this.fetchSubmissionPreviewFile();

    if (this.props.isStandAlone) {
      this.fetchAndSetClinicOptions();
      this.fetchAndSetPayerContactOptions();
      this.props.initialize({
        submissionTypeIndexSelected: SUBMISSION_TYPE.MAIL,
        color: COLOR_OPTIONS.BLACK_AND_WHITE,
        side: SIDE_OPTIONS.DOUBLE,
        coverLetterPhone: this.props.userInfo?.phone,
        ...this.props.currentShippingAddress, // initialize shipping address if it exists
      });

      if (isUserAuthorizedForOddPageSubmissionPadding(this.props.userInfo)) {
        this.props.change('paperClaimConfig', PAPER_CLAIM_CONFIG_OPTIONS[0]);
      }

      if (isAppealioExpressUser(this.props.userInfo)) {
        this.fetchAppealioExpressContacts();
        this.setState({
          isActiveReturnType: RETURN_ADDRESS_TYPE.CLIENT,
        });

        this.changeReturnAddress(this.props.userInfo.client);
      }

      return this.setState(
        {
          submissionTypeIndexSelected: SUBMISSION_TYPE.MAIL,
          pageCountData: {
            isDouble: true,
          },
        },
        () => this.setPageValidationError()
      );
    }

    this.setPageValidationError();

    if (
      this.props.isImportedAppeal &&
      !this.props.isStandAlone &&
      !this.props.isMedicalRecordsSubmission
    ) {
      this.fetchAndSetDeliveryFormats();
    }
    if (this.props.selectedAppeal && !this.props.submissionId) {
      await this.props.actions.createSubmitPackage({
        appeal: this.props.selectedAppeal,
      });
    }
    this.props.actions.setCurrentStep(5);
    await this.props.actions.getAppealById(this.props.selectedAppeal);
    validateAppealStep(
      this.props.appealStep,
      APPEAL_STEPS.DENIAL_INFO,
      this.props.selectedAppeal,
      this.props.actions.replace
    );
  }

  /**
   * Fetch and set filtered payer contact options.
   */
  fetchAndSetPayerContactOptions = async () => {
    try {
      this.setState({
        isFetchingPayerContactOptions: true,
      });

      const payerContacts = await AccountsAPI.fetchPayerContacts({
        all: 1,
      });
      const { mailContacts, faxContacts, payerPortalContacts, emailContacts } =
        getSubmissionTypeContacts(payerContacts);
      this.setState({
        payerContactFaxOptions: faxContacts,
        payerContactMailOptions: mailContacts,
        payerContactEmailOptions: emailContacts,
        standalonePayerPortalOptions: payerPortalContacts,
        isFetchingPayerContactOptions: false,
      });
    } catch (e) {
      handleError(e);
      this.setState({
        isFetchingPayerContactOptions: false,
      });
    }
  };

  fetchAndSetClinicOptions = async () => {
    try {
      this.setState({ isFetchingClinicsOptions: true });
      const { data: clinics } = await CreateAppealAPI.getDropdownOptions(
        'clinics'
      );
      this.setState({
        clinicOptions: clinics
          .filter((clinic) => clinic.address1)
          .map((clinic) => ({
            label: clinic.name,
            value: clinic.id,
            address: {
              name: clinic.name,
              address1: clinic.address1,
              address2: clinic.address2,
              city: clinic.city,
              state: clinic.state,
              zipcode: clinic.zipcode,
            },
          })),
        isFetchingClinicsOptions: false,
      });
    } catch (error) {
      handleError(error);
    }
  };

  fetchAppealioExpressContacts = async () => {
    try {
      const [returnContacts, destinationContacts] = await Promise.all([
        fetchContacts({
          type: CONTACT_TYPE.RETURN_ADDRESS,
        }),
        fetchContacts({
          type: CONTACT_TYPE.DESTINATION_ADDRESS,
        }),
      ]);

      this.setState({
        appealioExpressContactsOptions: {
          returnContactsOptions: parseContactDataToDropdown(
            returnContacts.data
          ),
          destinationContactsOptions: parseContactDataToDropdown(
            destinationContacts.data
          ),
          emailContactsOptions: destinationContacts?.data.filter(
            (item) => item.email
          ),
        },
      });
    } catch (error) {
      handleError(error);
    }
  };

  getFormDropdownOptionsForTagSubmission = async () => {
    CreateAppealAPI.getDropdownOptions('payers').then((payers) => {
      const payersDropdownOptions = CreateAppealAPI.parsePayers(payers);
      this.setState({
        payersDropdownOptions,
      });

      if (this.props?.submitConfirmPrefillData?.payerName) {
        const payerId = payersDropdownOptions?.find(
          (option) =>
            option.value === this.props?.submitConfirmPrefillData?.payerName
        )?.key;
        this.props.change('payers', payerId);
      }
    });

    CreateAppealAPI.getDropdownOptions('providers').then((providers) => {
      this.setState({
        providersDropdownOptions: CreateAppealAPI.parsePayers(providers),
      });
    });

    const userPractices = this.props.userInfo.relatedPractices;
    this.setState(
      {
        practicesDropdownOptions:
          CreateAppealAPI.parsePracticeData(userPractices),
      },
      () => {
        if (
          userPractices.length === 1 &&
          !isAppealioExpressUser(this.props.userInfo) &&
          this.props.isStandAlone
        ) {
          const practiceId = userPractices[0].id;
          this.props.change('practice', practiceId);
          if (
            this.state.submissionTypeIndexSelected === SUBMISSION_TYPE.MAIL &&
            (!this.state.isActiveReturnType ||
              this.state.isActiveReturnType === RETURN_ADDRESS_TYPE.PRACTICE)
          ) {
            this.changeReturnAddressToPracticeAddress(practiceId);
          }
        }
        if (this.props?.submitConfirmPrefillData?.practiceName) {
          const practiceId = this.state.practicesDropdownOptions?.find(
            (option) =>
              option.value ===
              this.props?.submitConfirmPrefillData?.practiceName
          )?.key;
          this.props.change('practice', practiceId);
        }
      }
    );
  };

  setAppealPreviewData = (
    appealPreviewData = {
      isLoading: false,
      url: null,
      error: null,
    }
  ) =>
    this.setState({
      appealPreviewData,
    });

  componentWillUnmount() {
    this.props.actions.setSubmitConfirmPrefillData({});
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (
      newProps.appealDetailsWithMeta &&
      this.props.appealDetailsWithMeta &&
      newProps.appealDetailsWithMeta.isLoading !==
        this.props.appealDetailsWithMeta.isLoading &&
      newProps.appealDetailsWithMeta.isRejected !== true
    ) {
      const { isStandAlone, submitConfirmPrefillData } = this.props;
      if (
        !this.isPrefillDataInitialized &&
        !isEmpty(submitConfirmPrefillData)
      ) {
        this.isPrefillDataInitialized = true;
        this.initializePrefillData(
          newProps.appealDetailsWithMeta.appeal,
          submitConfirmPrefillData
        );
        return;
      }

      if (!isStandAlone) {
        this.setSubmissionTypeIndexSelectedByAppealData(
          newProps.appealDetailsWithMeta.appeal,
          () => {
            this.initializeCurrentFormValues(
              newProps.appealDetailsWithMeta.appeal
            );
          }
        );
      }
    }

    if (this.props?.isStandAlone && !this.isPrefillDataInitialized) {
      this.isPrefillDataInitialized = true;

      this.initializePrefillData(
        newProps?.appealDetailsWithMeta?.appeal,
        this.props.submitConfirmPrefillData
      );

      return;
    }

    if (
      newProps.faxParams !== this.props.faxParams ||
      newProps.previewIncludeCoverLetter !==
        this.props.previewIncludeCoverLetter
    ) {
      this.setState({
        finalPackage: [
          {
            link: CreateAppealAPI.extractPreviewURL(
              this.props.selectedAppeal,
              newProps.previewIncludeCoverLetter,
              newProps.faxParams
            ),
            name: 'Final package',
            docType: mimeTypes.APPLICATION_PDF,
          },
        ],
      });
    }

    if (
      newProps.payerPortalUrl !== this.props.payerPortalUrl &&
      newProps.payerPortalUrl
    ) {
      this.setState(
        {
          submissionTypeFilterOptions: PAYER_CONTACT_SUBMIT_OPTIONS,
        },
        () => {
          this.setPageValidationError();
        }
      );
    }
  }

  async determineEmailOrFaxSubmissionType() {
    let isEmailSubmissionAvailable = this.state.isEmailSubmissionAvailable;

    if (!this.isEmailSubmissionAvailableChecked) {
      isEmailSubmissionAvailable =
        await this.fetchAndSetIsEmailSubmissionAvailable();
    }

    return isEmailSubmissionAvailable
      ? SUBMISSION_TYPE.EMAIL
      : SUBMISSION_TYPE.FAX;
  }

  async initializePrefillData(appealData, submitConfirmPrefillData) {
    const { change } = this.props;

    if (
      !submitConfirmPrefillData?.fax &&
      !submitConfirmPrefillData?.addressLine1 &&
      !submitConfirmPrefillData?.recipientEmail
    )
      return;

    let submissionTypeIndexSelected = null;

    if (submitConfirmPrefillData?.fax) {
      submissionTypeIndexSelected = SUBMISSION_TYPE.FAX;
    } else if (submitConfirmPrefillData?.recipientEmail) {
      submissionTypeIndexSelected =
        await this.determineEmailOrFaxSubmissionType();
    } else if (submitConfirmPrefillData?.addressLine1) {
      submissionTypeIndexSelected = SUBMISSION_TYPE.MAIL;
    }

    this.setState({ submissionTypeIndexSelected }, async () => {
      if (appealData) this.initializeCurrentFormValues(appealData);
      change('submissionTypeIndexSelected', submissionTypeIndexSelected);
      if (submitConfirmPrefillData?.tags) {
        this.setState({
          tagSubmissionData: {
            tags: submitConfirmPrefillData?.tags.map((tag) => ({
              label: tag,
              value: tag,
            })),
            currentTag: '',
          },
        });
      }
      if (submissionTypeIndexSelected === SUBMISSION_TYPE.MAIL) {
        const { addressLine1, addressLine2, city, state, zipcode, certified } =
          submitConfirmPrefillData;
        this.changeShippingAddress({
          address1: addressLine1,
          address2: addressLine2,
          city,
          state,
          zipcode,
          name: submitConfirmPrefillData?.deliveryOptions?.shipping,
        });
        this.setState(
          {
            pageCountData: {
              isDouble:
                submitConfirmPrefillData?.deliveryOptions?.doubleSided === true,
            },
          },
          () => {
            this.setPageValidationError();
          }
        );
        change(
          'attention_line',
          submitConfirmPrefillData?.deliveryOptions?.attentionLine
        );
        change('certified', certified);

        change(
          'color',
          submitConfirmPrefillData?.deliveryOptions?.color === '1'
            ? COLOR_OPTIONS.COLOR
            : COLOR_OPTIONS.BLACK_AND_WHITE
        );

        change(
          'side',
          submitConfirmPrefillData?.deliveryOptions?.doubleSided === true
            ? SIDE_OPTIONS.DOUBLE
            : SIDE_OPTIONS.SINGLE
        );

        if (submitConfirmPrefillData?.deliveryOptions?.return) {
          this.changeReturnAddress({
            ...submitConfirmPrefillData?.deliveryOptions?.return,
            address1:
              submitConfirmPrefillData?.deliveryOptions?.return?.addressLine1,
            address2:
              submitConfirmPrefillData?.deliveryOptions?.return?.addressLine2,
          });
        }

        this.setState({
          isActiveReturnType: null,
          isActiveShippingType: false,
        });
      } else if (submissionTypeIndexSelected === SUBMISSION_TYPE.EMAIL) {
        change('recipientEmail', submitConfirmPrefillData?.recipientEmail);
        change('emailSubject', submitConfirmPrefillData?.subject || '');
        change('emailMessage', submitConfirmPrefillData?.message || '');
        change('ccEmails', submitConfirmPrefillData?.ccEmails || []);
      } else {
        change('fax', submitConfirmPrefillData.fax);
        if (submitConfirmPrefillData?.coverLetterPhone) {
          change('coverLetterPhone', submitConfirmPrefillData.coverLetterPhone);
        }
        if (submitConfirmPrefillData?.coverLetterRe) {
          change('coverLetterRe', submitConfirmPrefillData.coverLetterRe);
        }

        if (submitConfirmPrefillData?.coverLetterCc) {
          change('coverLetterCc', submitConfirmPrefillData.coverLetterCc);
        }
        if (submitConfirmPrefillData?.coverLetterText) {
          change('coverLetterText', submitConfirmPrefillData.coverLetterText);
        }
        if (submitConfirmPrefillData?.deliveryOptions?.faxTo) {
          change('faxTo', submitConfirmPrefillData?.deliveryOptions?.faxTo);
        }
        if (submitConfirmPrefillData?.deliveryOptions?.faxFrom) {
          change('faxFrom', submitConfirmPrefillData?.deliveryOptions?.faxFrom);
        }
      }
    });
  }

  renderPreviewUnavailableDialog() {
    if (!this.state.showPreviewUnavailableDialog) {
      return;
    }

    const onActionButtonPressed = () => {
      this.setState({
        showPreviewUnavailableDialog: false,
      });
    };

    let appealType = this.props.isMedicalRecordsSubmission
      ? `record`
      : `appeal`;

    if (this.props.isStandAlone) {
      appealType = `package`;
    }

    return (
      <ActionPopup
        title={`Preview is not available for this ${appealType}, but this does not impact submission. Please continue to submit the ${appealType}.`}
        actionButtons={[
          {
            title: 'Continue',
            type: TYPE_ACCEPT,
          },
        ]}
        onActionButtonPressed={onActionButtonPressed}
        icon={incompleteIcon}
        hideCloseButton={true}
        actionPopupClassName="submit-confirm-error-popup"
        handleOutsideClick={this.onActionButtonPressed}
      />
    );
  }

  renderSubmissionErrorPopup() {
    const onActionButtonPressed = () => {
      this.props.actions.replace(routes.SUBMISSIONS_IN_PROGRESS);
    };

    return (
      <ActionPopup
        title="Oops!"
        actionButtons={[
          {
            title: 'Return to Submissions',
            type: TYPE_ACCEPT,
          },
        ]}
        onActionButtonPressed={onActionButtonPressed}
        icon={incompleteIcon}
        hideCloseButton={true}
        actionPopupClassName="submit-confirm-error-popup"
        handleOutsideClick={onActionButtonPressed}
      >
        <div className="mb-8 text-center">
          We've run into an error processing this submission. Our Customer
          Support team will attempt to submit it for you and will be in touch
          within 1 business day.
        </div>
        <div className="text-center">
          This error is specific to these documents, please continue using
          Appealio for your other submissions.
        </div>
      </ActionPopup>
    );
  }

  setReturnAddressAutofillConfig = (type, isVisible = true) => {
    this.setState({
      returnAddressAutofillConfig: {
        type,
        isVisible,
      },
    });
  };

  setShippingAddressAutofillConfig = (isVisible = true) => {
    this.setState({
      shippingAddressAutofillConfig: {
        isVisible,
      },
    });
  };

  handleTagSubmission = (selectedTags) => {
    this.setState({
      tagSubmissionData: {
        tags: selectedTags,
      },
    });
  };

  render() {
    const showLoadingIndicator = this.props.isStandAlone
      ? false
      : !this.props.appealDetailsWithMeta ||
        this.props.appealDetailsWithMeta.isLoading ||
        this.props.appealDetailsWithMeta.isRejected ||
        this.state.isFetchingDeliveryFormats;
    if (showLoadingIndicator)
      return (
        <LoadingBlockHelper
          isLoading={true}
          className="submitconfirm__spinner"
        />
      );

    if (this.state.showSubmissionErrorPopup) {
      return this.renderSubmissionErrorPopup();
    }

    return (
      <div className="row appeal-box__row-no-padding">
        <ErrorBannerSection
          className="col-xs-offset-3 col-xs-6"
          errors={
            this.state.pageCountData.isVisible
              ? this.state.pageCountData.message
              : this.props.displayErrors
          }
          datacy="submit-confirm-ErrorBannerSection"
        />
        <div className="col-xs-offset-3 col-xs-6 appeal-box">
          {this.renderDeliveryForm()}
          {this.renderSuccess()}
        </div>
        {this.renderDocumentViewer()}
        {this.renderPreviewUnavailableDialog()}
        {!this.state.isShowingSuccess && this.props.isStandAlone && (
          <StandaloneRouterPrompt
            when={true}
            title=" "
            onOK={() => true}
            onCancel={() => false}
          />
        )}
        {this.state.submissionTypeIndexSelected ===
          SUBMISSION_TYPE.PAYER_PORTAL &&
          !this.props.isStandAlone && (
            <PortalAssist
              appealId={this.props.selectedAppeal}
              appealData={_get(this.props, ['appealDetailsWithMeta', 'appeal'])}
            />
          )}
      </div>
    );
  }

  getLoadingMessage = () => {
    return <div>Preparing your attachments for submission …</div>;
  };

  renderDeliveryForm() {
    if (this.state.isShowingSuccess === true) return;

    const { handleSubmit } = this.props;
    return (
      <LoadingBlockHelper
        isLoading={this.state.isLoading}
        loadingMessage={this.getLoadingMessage()}
        useProgressBar
      >
        <form onSubmit={handleSubmit(this.submit)}>
          <div className="col-xs-offset-1 col-xs-offset-right-1">
            {this.renderHeader()}
            {this.renderDeliveryOptions()}
          </div>
          {this.renderFooter()}
          {this.renderPayerPortalSubmissionConfirmationPopup()}
        </form>
      </LoadingBlockHelper>
    );
  }

  renderHeader() {
    return (
      <div className="row">
        <SectionHeader
          headerTitle="Review and Submit"
          className="appeal-box__section-header"
        />
      </div>
    );
  }

  onPreviewDocumentClick = async (e) => {
    if (!this.props.submissionPreviewUrl && this.props.submissionPreviewError) {
      toast.error({
        title: 'Error',
        message:
          'There was an issue preparing your package. Please contact CX team at cx@docvocate.com for further details.',
      });
      return;
    }
    if (!this.props.submissionPreviewUrl) {
      return;
    }

    e.preventDefault();
    e.stopPropagation();
    if (
      this.state.isPaperClaimSubmission &&
      this.state.submissionTypeIndexSelected === SUBMISSION_TYPE.MAIL &&
      isUserAuthorizedForOddPageSubmissionPadding(this.props.userInfo) &&
      this.props.selectedPaperClaimConfig?.value ===
        PAPER_CLAIM_CONFIG.WITH_BACK_PAGE
    ) {
      toast.info({
        title: 'Info',
        message:
          'Additional blank pages will be added to the final package to adjust double sided claim form.',
      });
    }
    this.setState({
      documentViewerOpen: true,
    });
    this.setAppealPreviewData({
      url: this.props.submissionPreviewUrl,
      isLoading: false,
      error: null,
    });
  };

  getPreviewSubmissionButtonTitle = () => {
    const {
      isStandAlone,
      isMedicalRecordsSubmission,
      isSubmissionPreviewFetching,
    } = this.props;

    if (isSubmissionPreviewFetching) {
      return 'Preparing Preview';
    }

    let previewBtnTitle = isMedicalRecordsSubmission
      ? 'Preview Full Record'
      : 'Preview Full Appeal';

    if (isStandAlone) {
      previewBtnTitle = 'Preview Full Package';
    }

    return previewBtnTitle;
  };

  renderFooter() {
    const { submitting, invalid, isMedicalRecordsSubmission } = this.props;
    let submitBtnTitle = isMedicalRecordsSubmission
      ? 'Submit Record'
      : 'Submit Appeal';
    if (this.props.isStandAlone) {
      submitBtnTitle = 'Submit Package';
    }

    const previewBtnTitle = this.getPreviewSubmissionButtonTitle();

    return (
      <div className="appeal-review__submitbtn">
        <SaveContinueFooterComponent
          shouldShowCancelButton={false}
          shouldShowPreviousButton={true}
          onPreviousPressed={this.onPreviousPressed}
          submitDisabled={
            this.state.pageCountData.isVisible || submitting || invalid
          }
          submitBtnTitle={submitBtnTitle}
          shouldShowCustomButton={true}
          shouldShowSubmitBtn={
            this.state.submissionTypeIndexSelected !==
            SUBMISSION_TYPE.PAYER_PORTAL
          }
          customButtonTitle={previewBtnTitle}
          onCustomPressed={this.onPreviewDocumentClick}
          disableCustomButton={!this.props.submissionPreviewUrl}
          datacy="submit-confirm-SaveContinueFooterComponent"
        />
      </div>
    );
  }

  saveShippingAddress() {
    const {
      dirty,
      shippingAddress,
      currentShippingAddress,
      actions: { setShippingAddress },
    } = this.props;
    if (
      !isEqual(shippingAddress, currentShippingAddress) &&
      dirty &&
      shippingAddress
    ) {
      setShippingAddress(shippingAddressSerializer(shippingAddress));
    }
  }

  onPreviousPressed = () => {
    this.saveShippingAddress();
    if (this.props.isStandAlone) {
      return this.props.actions.replace(routes.CREATE_APPEAL_REVIEW);
    }

    this.props.actions.replace(
      `${routes.CREATE_APPEAL_REVIEW}?appealId=${this.props.selectedAppeal}`
    );
  };

  getDirectDropdownOptions = (deliveryFormats) => {
    const includesDirectAppeal = deliveryFormats.includes('direct_appeal');
    const includesDirectRecon = deliveryFormats.includes('direct_recon');
    const options = [];

    if (includesDirectAppeal) {
      options.push(DIRECT_APPEAL_SUBMIT_OPTION);
    }

    if (includesDirectRecon) {
      options.push(DIRECT_RECON_SUBMIT_OPTION);
    }

    options.push(...PAYER_CONTACT_SUBMIT_OPTIONS);

    return options;
  };

  isPaperClaimVisible() {
    return (
      this.props.isStandAlone &&
      !isAppealioExpressUser(this.props.userInfo) &&
      this.state.submissionTypeIndexSelected === SUBMISSION_TYPE.MAIL
    );
  }

  handlePaperClaimChange() {
    const isPaperClaimSubmission = !this.state.isPaperClaimSubmission;
    this.setState({ isPaperClaimSubmission }, () => {
      const paperClaimConfigIndex = isUserAuthorizedForOddPageSubmissionPadding(
        this.props.userInfo
      )
        ? 0
        : 1;
      this.props.change(
        'paperClaimConfig',
        PAPER_CLAIM_CONFIG_OPTIONS[paperClaimConfigIndex]
      );
      if (
        isUserAuthorizedForOddPageSubmissionPadding(this.props.userInfo) &&
        isPaperClaimSubmission
      ) {
        this.props.change('side', SIDE_OPTIONS.DOUBLE);
      } else if (isPaperClaimSubmission) {
        this.props.change('side', SIDE_OPTIONS.SINGLE);
      }
      this.props.change(
        'color',
        isPaperClaimSubmission
          ? COLOR_OPTIONS.COLOR
          : COLOR_OPTIONS.BLACK_AND_WHITE
      );
    });
  }

  renderPaperClaimOptions() {
    if (!this.isPaperClaimVisible()) {
      return null;
    }

    return (
      <div className="mb-16">
        <div className="d-flex align-items-center">
          <label className="fw-normal mb-0">
            <Field
              name="paperClaim"
              component="input"
              type="checkbox"
              className="mr-4"
              onChange={this.handlePaperClaimChange.bind(this)}
            />
            {
              <span>
                {!isUserAuthorizedForOddPageSubmissionPadding(
                  this.props.userInfo
                )
                  ? `Includes Claim Form (defaults to color and single side)`
                  : 'Includes Claim Form'}
              </span>
            }
          </label>
          {isUserAuthorizedForOddPageSubmissionPadding(this.props.userInfo) && (
            <>
              <Field
                name="paperClaimConfig"
                isDisabled={!this.props.isPaperClaimSelected}
                className="width-250 ml-8"
                options={PAPER_CLAIM_CONFIG_OPTIONS}
                component={Select}
                onChange={(option) => {
                  this.props.change(
                    'side',
                    option.value === PAPER_CLAIM_CONFIG.WITH_BACK_PAGE
                      ? SIDE_OPTIONS.DOUBLE
                      : SIDE_OPTIONS.SINGLE
                  );
                  this.props.change('paperClaimConfig', option);
                }}
              />
            </>
          )}
        </div>
      </div>
    );
  }

  renderDeliveryOptions() {
    const payerPortalUrl = this.props.payerPortalUrl;

    let options = payerPortalUrl
      ? this.state.submissionTypeFilterOptions
      : this.state.submissionTypeFilterOptions.filter(
          ({ value }) => value !== SUBMISSION_TYPE.PAYER_PORTAL
        );

    if (this.props.isStandAlone) {
      const standalonePayerPortalOptions =
        this.state.standalonePayerPortalOptions;
      if (
        standalonePayerPortalOptions &&
        standalonePayerPortalOptions.length < 1
      ) {
        options = STANDALONE_SUBMIT_OPTIONS.filter(
          ({ value }) => value !== SUBMISSION_TYPE.PAYER_PORTAL
        );
      } else {
        options = STANDALONE_SUBMIT_OPTIONS;
      }
    }

    let dropdownOptions = this.state.isDirectUHCApiAvailable
      ? this.getDirectDropdownOptions(
          this.state.deliveryFormats,
          Boolean(payerPortalUrl)
        )
      : options;
    if (!this.state.isEmailSubmissionAvailable) {
      dropdownOptions = dropdownOptions.filter(
        (option) => option.value !== SUBMISSION_TYPE.EMAIL
      );
    }
    const selectedDropdownOption = dropdownOptions.find(
      ({ value }) => value === this.state.submissionTypeIndexSelected
    );

    const handleOnChange = (selectedOption) => {
      if (selectedOption.value === CHANGE_PAYER_PORTAL_OPTION) {
        return this.props.actions.replace(CREATE_APPEAL_PATIENT_INFO);
      }

      this.onSubmissionTypeChange(selectedOption.value);
    };

    const onTagInputChange = (tag) => {
      this.setState((prev) => ({
        ...prev,
        tagSubmissionData: {
          tags: prev.tagSubmissionData.tags,
          currentTag: tag,
        },
      }));
    };

    return (
      <div className="row d-flex justify-content-center">
        <div className="col-xs-12 d-flex flex-direction--column">
          <div className="row submission-type__dropdown">
            <Dropdown
              label="Submission Type:"
              options={dropdownOptions}
              value={selectedDropdownOption}
              onChange={handleOnChange}
            />
          </div>
          {this.renderPaperClaimOptions()}
          {this.props.isStandAlone &&
            this.state.submissionTypeIndexSelected === SUBMISSION_TYPE.FAX &&
            this.state.practicesDropdownOptions.length > 0 &&
            this.renderPracticeOptions()}
          {this.renderFaxOptions()}
          {this.renderEmailOptions()}
          {this.renderMailOptions()}
          {this.renderPayerPortalOptions()}
          {this.renderDirectAppealOptions()}
          {this.renderDirectReconsiderationOptions()}
          <TagSubmissionForm
            handleTagSubmission={this.handleTagSubmission}
            tagSubmissionData={this.state.tagSubmissionData}
            payersDropdownOptions={this.state.payersDropdownOptions}
            isStandAlone={this.props.isStandAlone}
            isAppealioExpressUser={isAppealioExpressUser(this.props.userInfo)}
            onTagInputChange={onTagInputChange}
            change={this.props.change}
            disablePayer={this.state.disablePayer}
            submissionTypeIndexSelected={this.state.submissionTypeIndexSelected}
            enablePayer={() => this.setState({ disablePayer: false })}
          />
        </div>
      </div>
    );
  }

  _onCoverLetterChange = (e) => {
    this.setState({
      coverLetterText: e.currentTarget.value,
    });
  };

  /**
   * Change return address field values.
   * @param {Object} returnAddress
   * @param {string} returnAddress.name
   * @param {string} returnAddress.addressLine1
   * @param {string} returnAddress.addressLine2
   * @param {string} returnAddress.city
   * @param {string} returnAddress.state
   * @param {string} returnAddress.zip
   */
  changeReturnAddress = (returnAddress = {}) => {
    this.props.change('return_address_name', _get(returnAddress, 'name', ''));
    this.props.change(
      'return_address_line1',
      _get(returnAddress, 'address1', '')
    );
    this.props.change(
      'return_address_line2',
      _get(returnAddress, 'address2', '')
    );
    this.props.change('return_city', _get(returnAddress, 'city', ''));
    this.props.change('return_state', _get(returnAddress, 'state', ''));
    this.props.change('return_zipcode', _get(returnAddress, 'zipcode', ''));

    this.setupReturnAddressInputs(returnAddress);
  };

  /**
   * Change shipping address field values.
   * @param {Object} shippingAddress
   * @param {string} shippingAddress.name
   * @param {string} shippingAddress.addressLine1
   * @param {string} shippingAddress.addressLine2
   * @param {string} shippingAddress.city
   * @param {string} shippingAddress.state
   * @param {string} shippingAddress.zip
   */
  changeShippingAddress = (option) => {
    const shippingAddress = option?.address;
    if (shippingAddress) {
      this.props.change('name', shippingAddress.name);
      this.props.change('address_line1', shippingAddress.address1);
      this.props.change('address_line2', shippingAddress.address2 || '');
      this.props.change('attention_line', shippingAddress.attentionLine || '');
      this.props.change('city', shippingAddress.city);
      this.props.change('state', shippingAddress.state);
      this.props.change('zipcode', shippingAddress.zipcode);
    }
    if (this.props.isStandAlone) {
      this.autoSelectPayerDropdown(option.payerIds);
    }
  };

  getClinicAddress = () => {
    return {
      name: _get(this.props, ['appealClinic', 'name'], '') || '',
      address1: _get(this.props, ['appealClinic', 'address1'], '') || '',
      address2: _get(this.props, ['appealClinic', 'address2'], '') || '',
      city: _get(this.props, ['appealClinic', 'city'], '') || '',
      state: _get(this.props, ['appealClinic', 'state'], '') || '',
      zipcode: _get(this.props, ['appealClinic', 'zipcode'], '') || '',
    };
  };

  changeReturnAddressToClinicAddress = () => {
    toast.success({
      message: 'Return address changed to clinic address.',
      title: '',
    });
    const clinicAddress = this.getClinicAddress();

    this.changeReturnAddress(clinicAddress);
    this.setState({
      isActiveReturnType: RETURN_ADDRESS_TYPE.CLINIC,
    });
  };

  getPracticeAddress = () => {
    return {
      name: _get(this.props, ['appealPractice', 'name'], '') || '',
      address1: _get(this.props, ['appealPractice', 'address1'], '') || '',
      address2: _get(this.props, ['appealPractice', 'address2'], '') || '',
      city: _get(this.props, ['appealPractice', 'city'], '') || '',
      state: _get(this.props, ['appealPractice', 'state'], '') || '',
      zipcode: _get(this.props, ['appealPractice', 'zipcode'], '') || '',
    };
  };

  getSelectedPracticeAddress = (practiceId) => {
    const practice = this.state.practicesDropdownOptions.find(
      (practice) => practice.key === practiceId
    );

    return {
      name: _get(practice, 'name', ''),
      address1: _get(practice, 'address1', ''),
      address2: _get(practice, 'address2', ''),
      city: _get(practice, 'city', ''),
      state: _get(practice, 'state', ''),
      zipcode: _get(practice, 'zipcode', ''),
    };
  };

  getClientAddress = () => {
    return {
      name: _get(this.props, ['userClient', 'name'], '') || '',
      address1: _get(this.props, ['userClient', 'address1'], '') || '',
      address2: _get(this.props, ['userClient', 'address2'], '') || '',
      city: _get(this.props, ['userClient', 'city'], '') || '',
      state: _get(this.props, ['userClient', 'state'], '') || '',
      zipcode: _get(this.props, ['userClient', 'zipcode'], '') || '',
    };
  };

  changeReturnAddressToPracticeAddress = (practiceId = null) => {
    toast.success({
      message: 'Return address changed to practice address.',
      title: '',
    });
    const practiceAddress = !practiceId
      ? this.getPracticeAddress()
      : this.getSelectedPracticeAddress(practiceId);
    this.changeReturnAddress(practiceAddress);
    this.setState({ isActiveReturnType: RETURN_ADDRESS_TYPE.PRACTICE });
  };

  changeReturnAddressToClientAddress = () => {
    toast.success({
      message: 'Return address changed to client address.',
      title: '',
    });

    const clientAddress = this.getClientAddress();
    this.changeReturnAddress(clientAddress);
    this.setState({ isActiveReturnType: RETURN_ADDRESS_TYPE.CLIENT });
  };

  autoSelectPayerDropdown = (payerIds) => {
    if (!payerIds) {
      return;
    }
    if (payerIds && payerIds.length === 1) {
      const selectedPayer = this.state.payersDropdownOptions.find((item) =>
        payerIds.includes(Number(item.key))
      );
      if (selectedPayer) {
        this.props.change('payers', selectedPayer.key);
        if (
          this.state.submissionTypeIndexSelected ===
          SUBMISSION_TYPE.PAYER_PORTAL
        ) {
          this.setState({
            disablePayer: true,
          });
        }
      }
    }
  };

  /**
   * Renders autofill shipping address button.
   * @returns {JSX}
   */
  renderAutofillShippingAddressButton = () => {
    let autoFillBtns = [];
    let selectConfig = {
      placeholder: 'Select Address',
      onChange: (option) => {},
      options: [],
      isLoading: true,
    };

    // this is only for appeals/records
    if (!this.props.isStandAlone) {
      return null;
    }

    if (!isAppealioExpressUser(this.props.userInfo)) {
      const isPayerContactSelected = this.getSelectedPayerContactViaMail();
      autoFillBtns = [
        {
          title: 'Use Payer Contact',
          onClick: () => {
            this.setShippingAddressAutofillConfig(
              !this.state.shippingAddressAutofillConfig.isVisible
            );
            this.setState({
              isActiveShippingType: true,
            });
          },
          className: classnames('mr-8', {
            'auto-fill-btn--active':
              isPayerContactSelected || this.state.isActiveShippingType,
          }),
          dataCy: 'payer-contact',
        },
      ];

      selectConfig = {
        placeholder: 'Select Payer Contact',
        onChange: (option) => {
          this.changeShippingAddress(option);
          this.setShippingAddressAutofillConfig(false);
          this.setState({
            isActiveShippingType: false,
          });
        },
        options: this.state.payerContactMailOptions,
        isLoading: this.state.isFetchingPayerContactOptions,
      };
    }

    if (isAppealioExpressUser(this.props.userInfo)) {
      autoFillBtns = [
        {
          title: 'Use Destination Contact',
          onClick: () => {
            this.setShippingAddressAutofillConfig(
              !this.state.shippingAddressAutofillConfig.isVisible
            );
            this.setState({
              isActiveShippingType: true,
            });
          },
          className: classnames('mr-8', {
            'auto-fill-btn--active': this.state.isActiveShippingType,
          }),
          dataCy: 'destination-contact',
        },
      ];

      selectConfig = {
        placeholder: 'Select Destination Contact',
        onChange: (option) => {
          this.changeShippingAddress(option);
          this.setShippingAddressAutofillConfig(false);
          this.setState({
            isActiveShippingType: false,
          });
        },
        options:
          this.state.appealioExpressContactsOptions.destinationContactsOptions,
        isLoading: false,
      };
    }

    return (
      <OutsideClickWrapper
        handleOutsideClick={() => {
          this.setShippingAddressAutofillConfig(false);
        }}
      >
        <div>
          {autoFillBtns.map((btn, idx) => (
            <AutoFillButton
              key={idx}
              className={btn.className}
              title={btn.title}
              onClick={btn.onClick}
              datacy={btn.dataCy}
            />
          ))}
        </div>
        {this.state.shippingAddressAutofillConfig.isVisible && (
          <div className="mb-20">
            <Select
              options={selectConfig.options}
              isLoading={selectConfig.isLoading}
              placeholder={selectConfig.placeholder}
              onChange={selectConfig.onChange}
              reactSelectProps={{
                formatOptionLabel: (option) => {
                  return this.renderSelectAddressOptionLabel(option);
                },
              }}
            />
          </div>
        )}
      </OutsideClickWrapper>
    );
  };

  setFaxAutoFillConfig = (isVisible) => {
    this.setState({ faxAutofillConfig: { isVisible } });
  };

  handleFaxAutofillSelect = (value) => {
    if (this.props.isStandAlone) {
      this.autoSelectPayerDropdown(value.payerIds);
    }
    this.props.change('fax', value.fax);
    toast.success({
      title: '',
      message: 'Fax number changed to payer contact fax number.',
    });
    this.setFaxAutoFillConfig(false);
  };

  formatFaxOptionLabel = (option) => {
    const isOptionVerified = option?.dvVerifiedFax;
    const dataTipContent = ReactDOMServer.renderToStaticMarkup(
      <div>
        {isOptionVerified ? (
          <p className="dv-verified-legend">
            DV
            <span className="ml-2">Verified</span>
          </p>
        ) : null}
        <div>Fax: {option.fax}</div>
      </div>
    );
    return (
      <div
        className={classnames({
          'position-relative d-flex align-items-center justify-content--space-between':
            isOptionVerified,
        })}
      >
        <span data-tip={dataTipContent} data-html={true}>
          {option.label}
        </span>
        {isOptionVerified ? (
          <DvVerifiedIcon className="dv-verified-field dv-verified-field--select z-index-9" />
        ) : null}
        <ReactTooltip effect="solid" place="top" />
      </div>
    );
  };

  renderFaxOptions() {
    const {
      submissionTypeIndexSelected,
      faxAutofillConfig,
      payerContactFaxOptions,
      isFetchingPayerContactOptions,
    } = this.state;

    const selectedPayerContact = this.getSelectedPayerContactViaFax();
    const isPayerContactSelected =
      this.props.faxParams.fax &&
      selectedPayerContact?.fax === this.props.faxParams.fax;
    const isVerifiedFax =
      isPayerContactSelected && selectedPayerContact?.dvVerifiedFax;

    if (submissionTypeIndexSelected === SUBMISSION_TYPE.FAX) {
      return (
        <div className="row">
          <div
            className={classnames('row', {
              'position-relative': isVerifiedFax,
            })}
          >
            {this.props.isStandAlone && (
              <OutsideClickWrapper
                handleOutsideClick={() =>
                  faxAutofillConfig.isVisible &&
                  this.setFaxAutoFillConfig(false)
                }
              >
                <div className="d-flex">
                  <AutoFillButton
                    className={classnames('mr-12', {
                      'auto-fill-btn--active':
                        isPayerContactSelected || faxAutofillConfig.isVisible,
                    })}
                    title="Select Payer Contact"
                    onClick={() =>
                      this.setFaxAutoFillConfig(!faxAutofillConfig.isVisible)
                    }
                  />
                </div>
                {faxAutofillConfig.isVisible && (
                  <div className="mb-20">
                    <Select
                      options={payerContactFaxOptions}
                      isLoading={isFetchingPayerContactOptions}
                      placeholder="Select Payer Contact"
                      onChange={this.handleFaxAutofillSelect}
                      reactSelectProps={{
                        formatOptionLabel: this.formatFaxOptionLabel,
                      }}
                    />
                  </div>
                )}
              </OutsideClickWrapper>
            )}
            {isVerifiedFax ? (
              <DvVerifiedIcon
                className="dv-verified-field"
                style={{
                  top: 'auto',
                  bottom: '10px',
                }}
              />
            ) : null}
            <Field
              label="FAX Number:"
              name="fax"
              placeholder="(DDD) DDD-DDDD"
              type="string"
              mask={MASK_PHONE}
              size="10"
              required={true}
              component={renderReduxMaskedInput}
              datacy="faxnumber-SubmitConfirm"
            />
          </div>
          <br />
          {this.props.previewIncludeCoverLetter && (
            <React.Fragment>
              <div className="create-appeal__separator"></div>
              <br />
              <h5>Cover Page details</h5>
              <br />
              <div className="row">
                <Field
                  label="From: *"
                  name="faxFrom"
                  placeholder=""
                  component={renderField}
                  datacy="from-SubmitConfirm"
                />
              </div>
              <div className="row">
                <Field
                  label="To: *"
                  name="faxTo"
                  placeholder=""
                  component={renderField}
                  datacy="to-SubmitConfirm"
                />
              </div>
              <div className="row">
                <Field
                  label="Phone:"
                  name="coverLetterPhone"
                  placeholder="(DDD) DDD-DDDD"
                  type="string"
                  mask={MASK_PHONE}
                  size="10"
                  required={true}
                  component={renderReduxMaskedInput}
                  datacy="phone-SubmitConfirm"
                />
              </div>
              <div className="row">
                <Field
                  label="Re:"
                  name="coverLetterRe"
                  placeholder=""
                  component={renderField}
                  datacy="cover-letter-re-SubmitConfirm"
                />
              </div>
              <div className="row">
                <Field
                  label="CC:"
                  name="coverLetterCc"
                  placeholder=""
                  component={renderField}
                  datacy="cover-letter-cc-SubmitConfirm"
                />
              </div>
              <div className="row">
                <Field
                  label="Additional Cover Page text:"
                  name="coverLetterText"
                  placeholder=""
                  component={renderReduxTextarea}
                  datcy="cover-letter-text-SubmitConfirm"
                />
              </div>
            </React.Fragment>
          )}
        </div>
      );
    }
  }

  handlePracticeChange = (practiceId) => {
    if (
      this.state.submissionTypeIndexSelected === SUBMISSION_TYPE.MAIL &&
      (!this.state.isActiveReturnType ||
        this.state.isActiveReturnType === RETURN_ADDRESS_TYPE.PRACTICE)
    ) {
      this.changeReturnAddressToPracticeAddress(practiceId);
    }
    this.props.change('practice', practiceId);
  };

  renderPracticeOptions() {
    if (!this.props.isStandAlone) {
      return null;
    }

    const filteredOptions = this.state.practicesDropdownOptions.filter(
      (option) => option.isActive
    );

    return (
      <div
        className={classnames('row submission-type__dropdown', {
          'mt-16':
            this.state.submissionTypeIndexSelected === SUBMISSION_TYPE.MAIL,
        })}
      >
        <label className="dropdown__label">Practice: *</label>
        <Field
          component={renderReduxDropdownV2}
          dropdownOptions={filteredOptions}
          name="practice"
          placeholder="Select Practice"
          isDisabled={this.state.practicesDropdownOptions.length === 1}
          onChange={this.handlePracticeChange}
        />
      </div>
    );
  }

  renderAddress = (address, dvVerifiedMail = false) => {
    const mappedAddressInfo = {
      Address: address.address1,
      'Address 2': address.address2,
      City: address.city,
      State: address.state,
      ZIP: address.zipcode,
    };
    const contactInfo = pickBy(mappedAddressInfo, identity);
    return (
      <div>
        {dvVerifiedMail ? (
          <p className="dv-verified-legend">
            DV
            <span className="ml-2">Verified</span>
          </p>
        ) : null}
        {Object.entries(contactInfo).map(([key, value], idx) => (
          <div key={idx}>
            <strong>{key}</strong>: {value || 'N/A'}
          </div>
        ))}
      </div>
    );
  };

  setPageValidationError = () => {
    const pageValidationValues = {
      isStandAlone: this.props.isStandAlone,
      isDoubleSide: this.state.pageCountData.isDouble,
      totalDocumentsPageNumbers: this.props.totalDocumentsPageNumbers,
      submissionType: this.state.submissionTypeIndexSelected,
    };
    const pageValidationError =
      pageValidationForDocuments(pageValidationValues);
    this.setState({
      pageCountData: {
        isVisible: pageValidationError.displayErrors.length > 0,
        message: pageValidationError.displayErrors,
      },
    });
  };

  clearPaperClaimSelection = () => {
    this.setState({
      isPaperClaimSubmission: false,
    });
    this.props.change('paperClaim', false);
  };

  handleOptionForColor = (e) => {
    this.clearPaperClaimSelection();
  };

  handleOptionsForSided = (e) => {
    this.clearPaperClaimSelection();
    if (!e.target.value) {
      return '';
    }
    const isDoubleSidedExist = e.target.value === '0';
    this.setState(
      {
        pageCountData: {
          isDouble: isDoubleSidedExist,
        },
      },
      () => {
        this.setPageValidationError();
      }
    );
  };

  /**
   * Renders react select option for address.
   * @param {Object} option
   * @returns {JSX}
   */
  renderSelectAddressOptionLabel = (option) => {
    const tooltipId = String(option.value);
    const isOptionVerified = option?.dvVerifiedMail || option?.dvVerifiedFax;
    const dataTipContent = ReactDOMServer.renderToStaticMarkup(
      this.renderAddress(option.address, option.dvVerifiedMail)
    );

    return (
      <div
        className={classnames({
          'position-relative d-flex align-items-center justify-content--space-between':
            isOptionVerified,
        })}
      >
        <span data-for={tooltipId} data-tip={dataTipContent} data-html={true}>
          {option.label}
        </span>
        {isOptionVerified ? (
          <DvVerifiedIcon className="dv-verified-field dv-verified-field--select z-index-9 " />
        ) : null}
        <ReactTooltip
          id={tooltipId}
          delayShow={300}
          effect="solid"
          place="top"
        />
      </div>
    );
  };

  renderReturnAddressInputs() {
    let autoFillBtns = [];
    let selectConfig = {
      placeholder: 'Select Address',
      onChange: (option) => {},
      options: [],
    };

    const practiceOptions = this.props.userInfo.relatedPractices
      .filter((practice) => practice.address1)
      .map((practice) => ({
        value: practice.id,
        label: practice.name,
        address: {
          name: practice.name,
          address1: practice.address1,
          address2: practice.address2,
          city: practice.city,
          state: practice.state,
          zipcode: practice.zipcode,
        },
      }));

    // this is only appeals/records
    if (!this.props.isStandAlone) {
      autoFillBtns = [
        {
          type: RETURN_ADDRESS_TYPE.CLIENT,
          title: 'Use Client Address',
          onClick: this.changeReturnAddressToClientAddress,
          dataCy: 'client-address',
        },
        {
          type: RETURN_ADDRESS_TYPE.PRACTICE,
          title: 'Use Practice Address',
          onClick: () => this.changeReturnAddressToPracticeAddress(),
          dataCy: 'practice-address',
        },
        {
          type: RETURN_ADDRESS_TYPE.CLINIC,
          title: 'Use Clinic Address',
          onClick: this.changeReturnAddressToClinicAddress,
          dataCy: 'clinic-address',
          reactSelectProps: {
            formatOptionLabel: (option) => {
              return this.renderSelectAddressOptionLabel(option);
            },
          },
        },
      ];
    }

    if (
      this.props.isStandAlone &&
      !isAppealioExpressUser(this.props.userInfo)
    ) {
      autoFillBtns = [
        {
          type: RETURN_ADDRESS_TYPE.CLIENT,
          title: 'Use Client Address',
          onClick: () => {
            this.changeReturnAddressToClientAddress();
            this.setReturnAddressAutofillConfig(null, false);
          },
          dataCy: 'client-address',
        },
        {
          type: RETURN_ADDRESS_TYPE.PRACTICE,
          title: 'Use Practice Address',
          onClick: () => {
            if (this.props.selectedPracticeFromDropdown) {
              this.changeReturnAddressToPracticeAddress(
                this.props.selectedPracticeFromDropdown
              );
              this.setReturnAddressAutofillConfig(null, false);
            } else {
              toast.info({
                message:
                  'Please select one of the practice for return address.',
                title: '',
              });
            }
          },
          dataCy: 'practice-address',
        },
        {
          type: RETURN_ADDRESS_TYPE.CLINIC,
          title: 'Use Clinic Address',
          onClick: () => {
            this.setReturnAddressAutofillConfig(
              RETURN_ADDRESS_AUTOFILL_TYPE.CLINIC
            );
            this.setState({
              isActiveReturnType: RETURN_ADDRESS_TYPE.CLINIC,
            });
          },
          dataCy: 'clinic-address',
          reactSelectProps: {
            formatOptionLabel: (option) => {
              return this.renderSelectAddressOptionLabel(option);
            },
          },
        },
      ];

      selectConfig = {
        placeholder:
          this.state.returnAddressAutofillConfig.type ===
          RETURN_ADDRESS_AUTOFILL_TYPE.CLINIC
            ? 'Select Clinic Address'
            : 'Select Practice Address',
        onChange: (option) => {
          this.changeReturnAddress(option.address);
        },
        options:
          this.state.returnAddressAutofillConfig.type ===
          RETURN_ADDRESS_AUTOFILL_TYPE.CLINIC
            ? this.state.clinicOptions
            : practiceOptions,
      };
    }

    if (isAppealioExpressUser(this.props.userInfo)) {
      autoFillBtns = [
        {
          type: RETURN_ADDRESS_TYPE.CLIENT,
          title: 'Use Client Address',
          onClick: () => {
            this.changeReturnAddressToClientAddress();
            this.setReturnAddressAutofillConfig(null, false);
          },
          dataCy: 'client-address',
        },
        {
          type: RETURN_ADDRESS_TYPE.CONTACT,
          title: 'Use Return Contact',
          onClick: () => {
            this.setReturnAddressAutofillConfig(
              RETURN_ADDRESS_AUTOFILL_TYPE.CONTACT
            );
            this.setState({
              isActiveReturnType: RETURN_ADDRESS_TYPE.CONTACT,
            });
          },
          dataCy: 'client-address',
        },
      ];

      selectConfig = {
        placeholder: 'Select Return Contact',
        onChange: (option) => {
          this.changeReturnAddress(option.address);
        },
        options:
          this.state.appealioExpressContactsOptions.returnContactsOptions,
      };
    }

    return (
      <div>
        <OutsideClickWrapper
          handleOutsideClick={() => {
            this.setReturnAddressAutofillConfig(null, false);
          }}
        >
          <div className="d-flex">
            {autoFillBtns.map((btn, idx) => (
              <AutoFillButton
                key={idx}
                className={classnames('mr-12', {
                  'auto-fill-btn--active':
                    this.state.isActiveReturnType === btn.type,
                })}
                title={btn.title}
                onClick={btn.onClick}
                datacy={btn.dataCy}
              />
            ))}
          </div>

          {this.state.returnAddressAutofillConfig.isVisible && (
            <div className="mb-20">
              <Select
                options={selectConfig.options}
                placeholder={selectConfig.placeholder}
                onChange={selectConfig.onChange}
                reactSelectProps={{
                  formatOptionLabel: (option) => {
                    const dataTipContent = ReactDOMServer.renderToStaticMarkup(
                      this.renderAddress(option.address)
                    );
                    return (
                      <div>
                        <span data-tip={dataTipContent} data-html={true}>
                          {option.label}
                        </span>
                        <ReactTooltip effect="solid" place="top" />
                      </div>
                    );
                  },
                }}
              />
            </div>
          )}
        </OutsideClickWrapper>
      </div>
    );
  }

  isSameMailingAddress = (contact1, contact2) => {
    if (!contact1 || !contact2) return false;

    return (
      trim(contact1.address1 || '') === trim(contact2.addressLine1 || '') &&
      trim(contact1.address2 || '') === trim(contact2.addressLine2 || '') &&
      trim(contact1.city || '') === trim(contact2.city || '') &&
      trim(contact1.state || '') === trim(contact2.state || '') &&
      trim(contact1.zipcode || '') === trim(contact2.zipcode || '')
    );
  };

  validateShippingAddress() {
    const selectedPayerContact = this.getSelectedPayerContactViaMail();

    if (this.props.isStandAlone) {
      return selectedPayerContact?.dvVerifiedMail;
    }
    return (
      selectedPayerContact?.dvVerifiedMail &&
      this.isSameMailingAddress(
        selectedPayerContact?.address,
        this.props.shippingAddress
      )
    );
  }

  getSelectedPayerContactViaMail = () => {
    if (this.props.isStandAlone) {
      return this.state.payerContactMailOptions.find((option) =>
        this.isSameMailingAddress(option.address, this.props.shippingAddress)
      );
    }
    return this.props.currentAppealPayerContact;
  };

  getSelectedPayerContactViaFax = () => {
    if (this.props.isStandAlone) {
      return this.state.payerContactFaxOptions.find(
        (option) => option.fax === this.props.faxParams.fax
      );
    }
    return this.props.currentAppealPayerContact;
  };

  renderMailOptions() {
    const isVerifiedMail = this.validateShippingAddress();
    if (this.state.submissionTypeIndexSelected === SUBMISSION_TYPE.MAIL) {
      // validation for page count after selecting mail
      const stateOptions = states.map((state) => ({
        ...state,
        label: state.value,
      }));

      const disableColorAndSideCheckbox =
        isUserAuthorizedForOddPageSubmissionPadding(this.props.userInfo) &&
        this.state.isPaperClaimSubmission;
      return (
        <div className="row">
          <div className="row">
            <div>
              <label className="fw-bold mb-8">Print/Mail Option</label>
              <div className="d-flex">
                <div className="mr-8">
                  <label className="fw-normal">
                    <Field
                      name="color"
                      component="input"
                      type="radio"
                      disabled={disableColorAndSideCheckbox}
                      value={COLOR_OPTIONS.BLACK_AND_WHITE}
                      datacy="print-bw-Radio"
                      onChange={(e) => this.handleOptionForColor(e)}
                    />{' '}
                    B&W
                  </label>
                </div>
                <div>
                  <label className="fw-normal">
                    <Field
                      name="color"
                      component="input"
                      type="radio"
                      disabled={disableColorAndSideCheckbox}
                      value={COLOR_OPTIONS.COLOR}
                      datacy="print-color-Radio"
                      onChange={(e) => this.handleOptionForColor(e)}
                    />{' '}
                    Color
                  </label>
                </div>
              </div>
              <div className="mt-8 mb-10 d-flex align-items-center">
                <label className="fw-normal mb-0">
                  <Field
                    name="certified"
                    component="input"
                    type="checkbox"
                    className="mr-4"
                  />

                  <span>Send as Certified Mail</span>
                </label>

                <MdInfoOutline
                  size="16"
                  className="ml-8"
                  data-tip={
                    '<span>USPS tracking URL available within Delivery Tracker</span><br/><span>Additional fees applied</span>'
                  }
                  data-html={true}
                />
                <ReactTooltip effect="solid" place="top" />
              </div>
            </div>
          </div>
          <div>
            <label className="fw-bold mb-8">Sides Option</label>
            <div className="d-flex">
              <div className="mr-8">
                <label className="fw-normal">
                  <Field
                    name="side"
                    component="input"
                    type="radio"
                    disabled={disableColorAndSideCheckbox}
                    value={SIDE_OPTIONS.SINGLE}
                    datacy="single-sided-radio"
                    onChange={(e) => this.handleOptionsForSided(e)}
                  />{' '}
                  Single-Sided
                </label>
              </div>
              <div>
                <label className="fw-normal">
                  <Field
                    name="side"
                    component="input"
                    type="radio"
                    disabled={disableColorAndSideCheckbox}
                    value={SIDE_OPTIONS.DOUBLE}
                    datacy="double-sided-radio"
                    onChange={(e) => this.handleOptionsForSided(e)}
                  />{' '}
                  Double-Sided
                </label>
              </div>
            </div>
          </div>

          {this.props.isStandAlone &&
            this.state.submissionTypeIndexSelected === SUBMISSION_TYPE.MAIL &&
            this.state.practicesDropdownOptions.length > 0 &&
            this.renderPracticeOptions()}

          <div className="row mt-20">
            <div className="fw-bold mb-8">Return Address</div>
            {this.renderReturnAddressInputs()}
            <div className="row">
              <div className="col-xs-6">
                <div className="col-xs-11">
                  <Field
                    label="Name:"
                    name="return_address_name"
                    autoFocus={true}
                    required={true}
                    component={renderField}
                    className="w-80"
                    datacy="return-address-name-Field"
                  />
                </div>
              </div>
            </div>
            <div className="row mt-20">
              <div className="col-xs-6">
                <div className="col-xs-11">
                  <Field
                    label="Address Line 1:"
                    name="return_address_line1"
                    required={true}
                    maxLength="50"
                    component={renderField}
                    className="w-80"
                    datacy="return-address-line1-Field"
                  />
                </div>
              </div>
              <div className="col-xs-6">
                <Field
                  label="Address Line 2:"
                  name="return_address_line2"
                  maxLength="50"
                  component={renderField}
                  datacy="return-address-line2-Field"
                />
              </div>
            </div>
            <div className="row mt-20">
              <div className="col-xs-4">
                <div className="col-xs-11">
                  <Field
                    label="City:"
                    name="return_city"
                    required={true}
                    maxLength="50"
                    component={renderField}
                    datacy="return-address-city-Field"
                  />
                </div>
              </div>
              <div className="col-xs-4">
                <div className="col-xs-11" datacy="return-address-state">
                  <label className="textfield__label textfield__label--required">
                    State: *
                  </label>
                  <Field
                    label="State"
                    name="return_state"
                    dropdownOptions={stateOptions}
                    maxStringLength={40}
                    shouldTrimOptionLength={true}
                    hideIcon={true}
                    component={renderReduxDropdownV2}
                    datacy="return-address-state-Field"
                  />
                </div>
              </div>
              <div className="col-xs-4">
                <Field
                  label="Zip: *"
                  name="return_zipcode"
                  maxLength="50"
                  component={renderField}
                  datacy="return-address-zip-Field"
                />
              </div>
            </div>
          </div>
          <div className="row mt-20">
            <div className="fw-bold mb-8">Shipping Address</div>
            {this.renderAutofillShippingAddressButton()}
            <div className="row">
              <div className="col-xs-6">
                <div
                  className={classnames('col-xs-12', {
                    'position-relative': isVerifiedMail,
                  })}
                >
                  {isVerifiedMail ? (
                    <DvVerifiedIcon className="dv-verified-field" />
                  ) : null}
                  <Field
                    label="Name:"
                    name="name"
                    required={true}
                    component={renderField}
                    className="w-80"
                    datacy="shipping-address-name-Field"
                  />
                </div>
              </div>
            </div>
            <div className="row mt-20">
              <div className="col-xs-12">
                <div className="col-xs-12">
                  <Field
                    component={renderField}
                    label="Attn:"
                    required={false}
                    name="attention_line"
                    placeholder=""
                    datacy="attention-line"
                  />
                </div>
              </div>
            </div>
            <div className="row mt-20">
              <div className="col-xs-6">
                <div className="col-xs-11">
                  <Field
                    label="Address Line 1:"
                    name="address_line1"
                    required={true}
                    maxLength="50"
                    component={renderField}
                    datacy="shipping-address-line1-Field"
                  />
                </div>
              </div>
              <div className="col-xs-6">
                <div>
                  <Field
                    label="Address Line 2:"
                    name="address_line2"
                    maxLength="50"
                    component={renderField}
                    datacy="shipping-address-line2-Field"
                  />
                </div>
              </div>
            </div>
            <div className="row mt-20">
              <div className="col-xs-4">
                <div className="col-xs-11">
                  <Field
                    label="City:"
                    name="city"
                    required={true}
                    maxLength="50"
                    component={renderField}
                    datacy="shipping-address-city-Field"
                  />
                </div>
              </div>
              <div className="col-xs-4">
                <div className="col-xs-11">
                  <label className="textfield__label textfield__label--required">
                    State: *
                  </label>
                  <Field
                    label="State"
                    name="state"
                    dropdownOptions={stateOptions}
                    maxStringLength={40}
                    shouldTrimOptionLength={true}
                    hideIcon={true}
                    component={renderReduxDropdownV2}
                    disabled={!this.props.isStandAlone}
                    datacy="shipping-address-state-Field"
                  />
                </div>
              </div>
              <div className="col-xs-4">
                <Field
                  label="Zip: *"
                  name="zipcode"
                  maxLength="50"
                  component={renderField}
                  datacy="shipping-address-zip-Field"
                />
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

  setEmailAutoFillConfig = (isVisible) => {
    this.setState({ emailAutofillConfig: { isVisible } });
  };

  handleEmailAutofillSelect = (value) => {
    if (this.props.isStandAlone) {
      this.autoSelectPayerDropdown(value.payerIds);
    }
    this.props.change('recipientEmail', value?.email);
    toast.success({
      title: '',
      message: 'Email changed to payer contact email.',
    });
    this.setEmailAutoFillConfig(false);
  };

  setEmailContacts = (emailContacts) => {
    if (isEmpty(emailContacts)) {
      return [];
    }
    return emailContacts.map((item) => ({
      label: item.name,
      value: item.id,
      email: item.email,
    }));
  };

  formatEmailOptionLabel = (option) => {
    const dataTipContent = ReactDOMServer.renderToStaticMarkup(
      <div>Email: {option.email}</div>
    );
    return (
      <div
        className={
          'position-relative d-flex align-items-center justify-content--space-between'
        }
      >
        <span data-tip={dataTipContent} data-html={true}>
          {option.label}
        </span>
        <ReactTooltip effect="solid" place="top" />
      </div>
    );
  };

  getSelectedPayerContactViaEmail = () => {
    if (this.props.isStandAlone || isAppealioExpressUser(this.props.userInfo)) {
      const emailOptions = isAppealioExpressUser(this.props.userInfo)
        ? this.state.appealioExpressContactsOptions.emailContactsOptions
        : this.state.payerContactEmailOptions;
      if (!isEmpty(emailOptions)) {
        return emailOptions.find(
          (option) => option.email === this.props.recipientEmail
        );
      }
    }
    return this.props.currentAppealPayerContact;
  };

  renderEmailOptions() {
    if (this.state.submissionTypeIndexSelected === SUBMISSION_TYPE.EMAIL) {
      const {
        emailAutofillConfig,
        isFetchingPayerContactOptions,
        payerContactEmailOptions,
      } = this.state;

      const isAppealioExpressOrStandAlone =
        this.props.isStandAlone || isAppealioExpressUser(this.props.userInfo);

      let contactsOptionsForEmail = [];

      if (isAppealioExpressOrStandAlone) {
        contactsOptionsForEmail = isAppealioExpressUser(this.props.userInfo)
          ? this.setEmailContacts(
              this.state.appealioExpressContactsOptions.emailContactsOptions
            )
          : payerContactEmailOptions;
      }

      const selectedPayerContact = this.getSelectedPayerContactViaEmail();
      const isPayerContactSelected =
        this.props.recipientEmail &&
        selectedPayerContact?.email === this.props.recipientEmail;

      return (
        <div className="row">
          {this.props.isStandAlone &&
            this.state.submissionTypeIndexSelected === SUBMISSION_TYPE.EMAIL &&
            this.state.practicesDropdownOptions.length > 0 &&
            this.renderPracticeOptions()}

          {isAppealioExpressOrStandAlone && (
            <OutsideClickWrapper
              handleOutsideClick={() =>
                emailAutofillConfig.isVisible &&
                this.setEmailAutoFillConfig(false)
              }
            >
              <div>
                <AutoFillButton
                  className={classnames('mr-12', {
                    'auto-fill-btn--active':
                      isPayerContactSelected || emailAutofillConfig.isVisible,
                  })}
                  title="Select Payer Contact"
                  onClick={() =>
                    this.setEmailAutoFillConfig(!emailAutofillConfig.isVisible)
                  }
                />
              </div>
              {emailAutofillConfig.isVisible && (
                <div className="mb-20">
                  <Select
                    options={contactsOptionsForEmail}
                    isLoading={isFetchingPayerContactOptions}
                    placeholder="Select Payer Contact"
                    onChange={this.handleEmailAutofillSelect}
                    reactSelectProps={{
                      formatOptionLabel: this.formatEmailOptionLabel,
                    }}
                  />
                </div>
              )}
            </OutsideClickWrapper>
          )}
          <div className="row mt-20">
            <div className="row">
              <Field
                label="Recipient Email:"
                name="recipientEmail"
                autoFocus={true}
                required={true}
                component={renderField}
                className="w-80"
              />
            </div>
            <div className="row">
              <Field
                label="Cc:"
                name="ccEmails"
                component={renderCCEmails}
                placeholder=""
                className="w-100"
              />
            </div>
            <div className="row">
              <Field
                label="Subject Line:"
                name="emailSubject"
                autoFocus={true}
                required={true}
                component={renderField}
                maxLength={255}
                className="w-80"
              />
            </div>
            <div className="row">
              <Field
                label="Message: *"
                name="emailMessage"
                required={true}
                placeholder=""
                component={renderReduxTextarea}
                className="email-message-container"
                maxLength={100000}
              />
            </div>
            <EmailFileDropzone
              files={this.state.emailFiles}
              onFilesChange={this.setEmailFiles}
              onFileDelete={this.deleteEmailFile}
              className="mt-8"
            />
          </div>
        </div>
      );
    }
  }

  renderDirectAppealOptions() {
    if (
      this.state.submissionTypeIndexSelected === SUBMISSION_TYPE.DIRECT_APPEAL
    ) {
      return (
        <div className="row">
          <div className="row">
            <Field
              label="Provider Comments: *"
              name="provComments"
              placeholder=""
              component={renderReduxTextarea}
              datacy="cover-letter-cc-SubmitConfirm"
              required={true}
            />
          </div>
        </div>
      );
    }
  }

  renderDirectReconsiderationOptions() {
    if (
      this.state.submissionTypeIndexSelected === SUBMISSION_TYPE.DIRECT_RECON
    ) {
      return (
        <div className="row">
          <div className="row mb-8">
            <label className="textfield__label textfield__label--required">
              Amount Requested: *
            </label>
            <br />
            <Field
              name="amtOwed"
              placeholder="$0"
              component={renderInput}
              format={(value) => {
                if (!value) return value;
                if (isNaN(value) || Number(value) <= 0) {
                  return '';
                }
                let formattedCurrency = String(value);
                if (!formattedCurrency.startsWith('$')) {
                  formattedCurrency = '$' + value;
                }
                return formattedCurrency;
              }}
              normalize={normalizeMoney}
              className="mb-4"
              disabled={this.props.disableAmountOwed}
            />
            <label className="fw-normal ml-8">
              <Field
                name="dontKnowDollarAmt"
                component="input"
                type="checkbox"
                className="mr-4"
                defaultChecked={false}
                onChange={(e) => {
                  if (e.target.checked) {
                    this.props.change('amtOwed', '$0');
                  }
                }}
              />
              I don't know
            </label>
          </div>
          <div className="row mb-8">
            <label className="textfield__label textfield__label--required">
              Request Reason: *
            </label>
            <Field
              name="reqReason"
              placeholder=""
              component={renderReduxDropdownV2}
              dropdownOptions={this.state?.reconReasons.map((reason) => ({
                value: reason,
                key: reason,
              }))}
              hideIcon
            />
          </div>
          <div className="row mb-8">
            <Field
              label="Provider Comments: *"
              name="provComments"
              placeholder=""
              component={renderReduxTextarea}
            />
          </div>
        </div>
      );
    }
  }

  renderPayerPortalOptions() {
    if (
      this.state.submissionTypeIndexSelected === SUBMISSION_TYPE.PAYER_PORTAL
    ) {
      if (
        this.props.isStandAlone &&
        !isAppealioExpressUser(this.props.userInfo)
      ) {
        return (
          <>
            {this.renderPracticeOptions()}
            <PayerPortalStandalone
              isDownloadingPDFPackage={this.props.isSubmissionPreviewFetching}
              standalonePayerPortalOptions={
                this.state.standalonePayerPortalOptions
              }
              openPayerPortalSubmissionConfirmationPopup={
                this.openPayerPortalSubmissionConfirmationPopup
              }
              clientPartitionId={this.props.clientPartitionId}
              submissionId={this.props.submissionId}
              onDownloadPDFPackage={this.onDownloadPDFPackage}
              change={this.props.change}
              selectedPayerContactForPayerPortal={
                this.props.selectedPayerContactForPayerPortal
              }
              autoSelectPayerDropdown={
                this.props.isStandAlone && this.autoSelectPayerDropdown
              }
            />
          </>
        );
      }
      const { patientName, claimNumber } =
        this.props.appealDetailsWithMeta.appeal;

      const downloadFileName = `${patientName}-${claimNumber}.pdf`;
      const { isMedicalRecordsSubmission } = this.props;

      return (
        <div className="row">
          <div className="appealio-card row mb-30">
            <div className="col-lg-8">
              <div className="appealio-card-title mb-8">
                1. Download Package
              </div>

              <div className="appealio-card-info">
                Click the following link to download {patientName}'s package.
              </div>
            </div>

            <div className="col-lg-4 d-flex flex-direction--column align-item-flex-end">
              <SubmitButton
                className="submit__button submit__button--fullwidth button--bg-primary mb-8"
                title="Download"
                type="button"
                disabled={this.props.isSubmissionPreviewFetching}
                onClick={() => this.onDownloadPDFPackage(downloadFileName)}
                datacy="submit-confirm-download-SubmitButton"
              />
              <span className="info-text pr-1">
                {`${downloadFileName} ${
                  this.props.isSubmissionPreviewFetching ||
                  this.state.isDownloadingPDFPackage
                    ? 'is preparing for download'
                    : 'is ready for download'
                }`}
              </span>
            </div>
          </div>

          <div className="appealio-card row mb-30">
            <div className="col-lg-8">
              <div className="appealio-card-title mt-12">
                2. Visit Payer Portal & Submit{' '}
                {isMedicalRecordsSubmission ? 'Medical Record' : 'Appeal'}
              </div>
            </div>

            <div className="col-lg-4 d-flex flex-direction--column align-item-flex-end mt-8">
              <a
                href={this.props.payerPortalUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                <SubmitButton
                  className="submit__button submit__button--fullwidth button--bg-primary"
                  title="Open Payer Portal"
                  type="button"
                />
              </a>
            </div>
          </div>

          <div className="appealio-card row mb-30">
            <div className="col-lg-8">
              <div className="appealio-card-title mb-8">
                3. Confirm{' '}
                {isMedicalRecordsSubmission ? 'Medical Record' : 'Appeal'}{' '}
                Submission
              </div>

              <div className="appealio-card-info">
                Once the{' '}
                {isMedicalRecordsSubmission ? 'medical record' : 'appeal'} is
                submitted through the{' '}
                {this.props.appealDetailsWithMeta.appeal.payer.name || ''} Payer
                Portal,
                <br /> please confirm by clicking "Confirm Submission".{' '}
              </div>
            </div>

            <div className="col-lg-4 d-flex flex-direction--column align-item-flex-end mt-8">
              <SubmitButton
                onClick={(e) => {
                  e.preventDefault();
                  this.openPayerPortalSubmissionConfirmationPopup();
                }}
                className="submit__button submit__button--fullwidth"
                title="Confirm Submission"
              />
            </div>
          </div>
        </div>
      );
    }
  }

  closePayerPortalSubmissionConfirmationPopup = () =>
    this.setState({
      isPayerPortalSubmissionConfirmationPopupOpen: false,
    });

  openPayerPortalSubmissionConfirmationPopup = () =>
    this.setState({
      isPayerPortalSubmissionConfirmationPopupOpen: true,
    });

  handleActionButtonPressed = (index) => {
    if (index === 0) return this.closePayerPortalSubmissionConfirmationPopup();
    this.props.handleSubmit(this.submit);
  };

  renderPayerPortalSubmissionConfirmationPopup = () => {
    if (!this.state.isPayerPortalSubmissionConfirmationPopupOpen) return;
    const isStandAlone = this.props.isStandAlone;
    const title = `Are you sure you submitted your ${
      isStandAlone ? 'package' : 'appeal'
    } to the ${
      (!isStandAlone && this.props.appealDetailsWithMeta.appeal.payer.name) ||
      ''
    } Payer Portal?`;
    return (
      <PayerPortalSubmissionConfirmationPopUp
        actionPopupClassName="payer-portal-submission-confirmation-popup"
        title={title}
        onClosePressed={this.closePayerPortalSubmissionConfirmationPopup}
        onActionButtonPressed={this.handleActionButtonPressed}
        isStandAlone={isStandAlone}
      />
    );
  };

  renderSuccessMessage() {
    const { isMedicalRecordsSubmission } = this.props;

    if (
      this.state.submissionTypeIndexSelected === SUBMISSION_TYPE.DIRECT_APPEAL
    ) {
      return (
        <div className="row submitconfirm__description--center">
          Appealio is submitting your{' '}
          {isMedicalRecordsSubmission ? 'medical record' : 'appeal'} for{' '}
          <strong>{this.props.appealDetailsWithMeta.appeal.patientName}</strong>{' '}
          directly to UHC within 3 hours. <br /> You can preview and make
          changes through Delivery Tracker.
        </div>
      );
    }

    return (
      <div className="row submitconfirm__description--center">
        The {isMedicalRecordsSubmission ? 'Medical Record' : 'Appeal'} Package
        for{' '}
        <strong>{this.props.appealDetailsWithMeta.appeal.patientName}</strong>{' '}
        is queued for submission and will be submitted within 3 hours.
        <br /> You can preview and make changes through Delivery Tracker.
      </div>
    );
  }

  renderSuccess() {
    if (this.state.isShowingSuccess !== true) return;
    const { submissionTypeIndexSelected } = this.state;
    const { isMedicalRecordsSubmission, isStandAlone, userInfo } = this.props;

    const isPayerPortalSubmission =
      submissionTypeIndexSelected === SUBMISSION_TYPE.PAYER_PORTAL;
    const isIntegratedClient = userInfo.ediIntegrationEnabled;

    const titleType = isStandAlone
      ? 'package'
      : isMedicalRecordsSubmission
      ? 'medical record'
      : 'appeal package';

    const navigateToDeliveryTrackerTitle = 'Go to Delivery Tracker';
    const navigateToDashboardTitle = isIntegratedClient
      ? 'Go to Denials Queue'
      : 'Go to Appeals';
    const title = isPayerPortalSubmission
      ? `You’ve submitted your ${titleType}`
      : `Appealio is ${
          isAppealioExpressUser(this.props.userInfo) ? 'sending' : 'submitting'
        } your ${titleType}`;

    return (
      <div className="row">
        <div className="row submitconfirm__title">{title}</div>
        {!isStandAlone &&
          !isPayerPortalSubmission &&
          this.renderSuccessMessage()}
        <div className="row">
          <img
            alt="Submit"
            className="center-block submitconfirm__image"
            src={submitSuccessImage}
          />
          {!isStandAlone && !isAppealioExpressUser(this.props.userInfo) && (
            <PmNoteInfo appealId={this.props.selectedAppeal} />
          )}
        </div>
        <div className="row">
          <div className="row appeal-box__divider" />
        </div>
        <div className="row">
          <div className="col-xs-12 d-flex justify-content-center submit-confirm-navigation-wrapper">
            <SubmitButton
              className="submit__button submit__button--fullwidth submit__button--delivery-tracker"
              onClick={this.navigateToDeliveryTracker}
              title={navigateToDeliveryTrackerTitle}
            />
            {!isAppealioExpressUser(this.props.userInfo) && (
              <SubmitButton
                className="submit__button submit__button--fullwidth ap-button--secondary-outline ml-8"
                onClick={this.navigateToDashboard}
                title={navigateToDashboardTitle}
              />
            )}
          </div>
        </div>
      </div>
    );
  }

  renderDocumentViewer() {
    if (this.state.documentViewerOpen !== true) return;

    if (this.state.appealPreviewData.error) return;
    const { isMedicalRecordsSubmission } = this.props;
    const name = isMedicalRecordsSubmission
      ? 'Medical Record Preview Package'
      : 'Appeal Preview Package';

    const finalPackage = [
      {
        link: this.state.appealPreviewData.url || '',
        name,
        docType: mimeTypes.APPLICATION_PDF,
      },
    ];

    return (
      <DocumentViewer
        documents={finalPackage}
        isLoadingDocuments={this.state.appealPreviewData.isLoading}
        defaultIndex={0}
        previewTitle="Cover letter"
        onClosePress={this.closeDocumentViewer}
        datacy="submit-confirm-cover-letter-DocumentViewer"
      />
    );
  }

  initializeWithPayerAddress(currentAppealData) {
    if (this.props.isStandAlone) {
      this.props.initialize({
        submissionTypeIndexSelected: this.state.submissionTypeIndexSelected,
        color: COLOR_OPTIONS.BLACK_AND_WHITE,
        side: SIDE_OPTIONS.DOUBLE,
      });

      this.setState({
        isActiveReturnType: RETURN_ADDRESS_TYPE.CLIENT,
      });
      return this.changeReturnAddress(this.props.userInfo.client);
    }

    const initData = {
      submissionTypeIndexSelected: this.state.submissionTypeIndexSelected,
      name: _get(currentAppealData, ['payerName'], ''),
      address_line1: _get(currentAppealData, ['payerContact', 'address1'], ''),
      address_line2:
        _get(currentAppealData, ['payerContact', 'address2'], '') || '',
      city: _get(currentAppealData, ['payerContact', 'city'], ''),
      state: _get(currentAppealData, ['payerContact', 'state'], ''),
      zipcode: _get(currentAppealData, ['payerContact', 'zipcode'], ''),
      country: 'US',
      return_address_name: _get(this.props, ['appealClinic', 'name'], '') || '',
      return_address_line1:
        _get(this.props, ['appealClinic', 'address1'], '') || '',
      return_address_line2:
        _get(this.props, ['appealClinic', 'address2'], '') || '',
      return_city: _get(this.props, ['appealClinic', 'city'], '') || '',
      return_state: _get(this.props, ['appealClinic', 'state'], '') || '',
      return_zipcode: _get(this.props, ['appealClinic', 'zipcode'], '') || '',
      color: COLOR_OPTIONS.BLACK_AND_WHITE,
      side: SIDE_OPTIONS.DOUBLE,
    };

    // initialize formValues and replace the shipping address if exists on the store;
    this.props.initialize({
      ...initData,
      ...this.props.currentShippingAddress,
    });

    const clientAddress = this.getClientAddress();
    const practiceAddress = this.getPracticeAddress();
    const clinicAddress = this.getClinicAddress();

    const isPracticeAddressEmpty = isEmptyObjectValues(
      withoutAttrs(practiceAddress, ['name'])
    );

    const isClinicAddressEmpty = isEmptyObjectValues(
      withoutAttrs(clinicAddress, ['name'])
    );

    const updateChangeReturnAddress = (returnAddress) => {
      this.changeReturnAddress(returnAddress.address);
      this.setState({
        isActiveReturnType: returnAddress.type,
      });
    };

    const extractDefaultReturnAddress = () => {
      if (!isPracticeAddressEmpty) {
        return {
          address: practiceAddress,
          type: RETURN_ADDRESS_TYPE.PRACTICE,
        };
      }

      if (!isClinicAddressEmpty) {
        return {
          address: clinicAddress,
          type: RETURN_ADDRESS_TYPE.CLINIC,
        };
      }

      return {
        address: clientAddress,
        type: RETURN_ADDRESS_TYPE.CLIENT,
      };
    };

    updateChangeReturnAddress(extractDefaultReturnAddress());
  }

  setupReturnAddressInputs(returnAddress) {
    const isAddressEmpty = Object.values(
      withoutAttrs(returnAddress, ['name', 'address2'])
    ).some((value) => !value);
    this.setState({
      enableReturnAddressInputs: isAddressEmpty,
    });
  }

  initializeWithPayerFax(currentAppealData) {
    if (this.props.isStandAlone) {
      return this.props.initialize({
        submissionTypeIndexSelected: this.state.submissionTypeIndexSelected,
        coverLetterPhone: isAppealioExpressUser(this.props.userInfo)
          ? this.props.userInfo.client.phone
          : '',
        faxFrom: this.props.userInfo.client.name || '',
        faxTo: '',
      });
    }

    const coverLetterRe = this.props.isMedicalRecordsSubmission
      ? `Medical Record for Claim ID: ${
          currentAppealData.submitterClaimId || '<Claim ID>'
        }`
      : `Appeal for Claim ID: ${currentAppealData.claimNumber || '<Claim ID>'}`;

    const initData = {
      submissionTypeIndexSelected: this.state.submissionTypeIndexSelected,
      fax: _get(currentAppealData, ['payerContact', 'fax'], ''),
      coverLetterPhone: this.props.userInfo.phone,
      coverLetterRe,
      coverLetterCc: '',
      coverLetterText: '',
      faxFrom: _get(currentAppealData, 'practiceName', ''),
      faxTo: _get(currentAppealData, 'payerName', ''),
    };

    this.props.initialize(initData);
  }

  async setSubmissionTypeIndexSelectedByAppealData(currentAppealData, cb) {
    let submissionTypeIndexSelected = this.state.submissionTypeIndexSelected;
    const payerContact = currentAppealData.payerContact;
    if (payerContact?.email) {
      submissionTypeIndexSelected =
        await this.determineEmailOrFaxSubmissionType();
    } else if (payerContact?.fax) {
      submissionTypeIndexSelected = SUBMISSION_TYPE.FAX;
    } else if (payerContact?.address1) {
      submissionTypeIndexSelected = SUBMISSION_TYPE.MAIL;
    }

    this.setState(
      {
        submissionTypeIndexSelected,
      },
      cb
    );
  }

  async initializeCurrentFormValues(currentAppealData) {
    if (this.state.submissionTypeIndexSelected === SUBMISSION_TYPE.FAX) {
      this.initializeWithPayerFax(currentAppealData);
    } else if (
      this.state.submissionTypeIndexSelected === SUBMISSION_TYPE.MAIL
    ) {
      this.initializeWithPayerAddress(currentAppealData);
    } else if (
      this.state.submissionTypeIndexSelected === SUBMISSION_TYPE.PAYER_PORTAL
    ) {
      this.props.initialize({
        submissionTypeIndexSelected: this.state.submissionTypeIndexSelected,
      });
    } else if (
      this.state.submissionTypeIndexSelected === SUBMISSION_TYPE.EMAIL
    ) {
      let isEmailSubmissionAvailable = this.state.isEmailSubmissionAvailable;
      if (!this.isEmailSubmissionAvailableChecked) {
        isEmailSubmissionAvailable =
          await this.fetchAndSetIsEmailSubmissionAvailable();
      }

      if (isEmailSubmissionAvailable) {
        this.props.initialize({
          recipientEmail: this.props?.recipientEmail || '',
          submissionTypeIndexSelected: this.state.submissionTypeIndexSelected,
        });
      }
    }
  }

  onDownloadPDFPackage = async (fileName) => {
    if (this.props.isSubmissionPreviewFetching) return;

    if (!this.props.submissionPreviewUrl && this.props.submissionPreviewError) {
      toast.error({
        title: 'Error',
        message:
          'There was an issue preparing your package. Please contact CX team at cx@docvocate.com for further details.',
      });
      return;
    }

    downloadFile(this.props.submissionPreviewUrl, fileName);
  };

  closeDocumentViewer = () => {
    this.setState({
      documentViewerOpen: false,
      defaultDocumentIndex: -1,
    });
  };

  syncClaimStatus = () => {
    const { claimNumber } = this.props.appealDetailsWithMeta.appeal;
    if (
      !this.props.isMedicalRecordsSubmission &&
      !this.props.isImportedAppeal &&
      claimNumber &&
      this.props.clientPartitionId
    ) {
      CreateAppealAPI.syncClaimStatus(
        this.props.clientPartitionId,
        claimNumber,
        2
      ).catch(handleError);
    }
  };

  setIsShowingSuccess = (isShowingSuccess = true) => {
    this.setState({
      isShowingSuccess,
    });

    if (isShowingSuccess) {
      const isUploadSupportsEBridge =
        this.props.userInfo?.clientExtras?.ebridge;

      preventBackNavigation();
      if (isUploadSupportsEBridge) {
        toast.success({
          title: 'Success',
          message: 'Package will be uploaded to EBridge after submission.',
        });
      }
    }
  };

  changeAppealStatusAndNavigate = (
    appealId,
    isAppealioSubmittingPackage = false
  ) => {
    const appealParams = {
      statusCode: AppealStatusCode.Submitted,
      submittedAt: moment().toISOString(),
    };

    /**
     * Info: Appeal status for FAX and Mail is updated by the API on the backend.
     */
    if (
      this.state.submissionTypeIndexSelected !== SUBMISSION_TYPE.PAYER_PORTAL
    ) {
      this.syncClaimStatus();
      this.setIsShowingSuccess(true);
      this.setState({
        isAppealioSubmittingPackage,
      });
      this.props.actions.setCurrentStep(6);
    } else {
      CreateAppealAPI.updateAppeal(appealId, null, appealParams)
        .then((res) => {
          this.syncClaimStatus();
          this.setIsShowingSuccess(true);
          this.setState({
            isAppealioSubmittingPackage,
          });
          this.props.actions.setCurrentStep(6);
        })
        .catch((error) => {
          handleErrors(error);
          this.setState({ isLoading: false });
        });
    }
  };

  isDirectSubmission = () => {
    return [
      SUBMISSION_TYPE.DIRECT_APPEAL,
      SUBMISSION_TYPE.DIRECT_RECON,
    ].includes(this.state.submissionTypeIndexSelected);
  };

  createTagsAndCountForStandalone = (requestData, submissionId) => {
    if (isEmpty(requestData)) {
      return;
    }
    return createTagsWithPageCount(requestData, submissionId);
  };

  /**
   * Create tags and Update counts for Appealio Express user
   * @param {values} values
   * @returns
   */

  createOptionalFields = async (values) => {
    const requestData = {};
    if (this.props.isStandAlone) {
      const valuesJS = values.toJS();
      if ('practice' in valuesJS && values.get('practice')) {
        requestData.practiceId = values.get('practice') || '';
      }
      if ('payers' in valuesJS && values.get('payers')) {
        requestData.payerId = values.get('payers') || '';
      }
      if (!isEmpty(this.state.tagSubmissionData.tags)) {
        requestData.tags = !isEmpty(this.state.tagSubmissionData.tags)
          ? this.state.tagSubmissionData.tags.map((tag) => tag.value)
          : [];
      }

      setValueIfExists('serviceStartDate', values, requestData);
      setValueIfExists('serviceEndDate', values, requestData);

      if (!isAppealioExpressUser(this.props.userInfo)) {
        const { firstName, lastName, dob } = valuesJS;
        const patientInfo = {
          ...(firstName && { firstName }),
          ...(lastName && { lastName }),
          ...(dob && { dob }),
        };

        if (!isEmpty(patientInfo)) {
          requestData.patientInfo = patientInfo;
        }
      }
    } else {
      if (!isEmpty(this.state.tagSubmissionData.tags)) {
        requestData.tags = !isEmpty(this.state.tagSubmissionData.tags)
          ? this.state.tagSubmissionData.tags.map((tag) => tag.value)
          : [];
      }
    }
    if (isAppealioExpressUser(this.props.userInfo)) {
      requestData.pageCount = this.props.totalDocumentsPageNumbers;
    }
    try {
      await this.createTagsAndCountForStandalone(
        requestData,
        this.props.submissionId
      );
    } catch (error) {
      return handleError(error);
    }
  };

  /**
   * Upload email files.
   * @param {Array<File>} files
   * @returns {Promise<Array<Document>>}
   */
  uploadEmailFiles = async (files) => {
    const filesToUpload = files.filter((file) => !file.id);
    const uploaders = filesToUpload.map((file) => this.createUploader(file));

    try {
      const uploadTasks = uploaders.map((uploader) => uploader.start());
      const uploadSuccess = await Promise.all(uploadTasks);
      const documents = uploadSuccess.map(({ signedUrl }, index) => {
        const uploader = uploaders[index];
        return {
          type: uploader.getFileType(),
          signedUrl,
          name: uploader.getFilename(),
          documentCategory: DOCUMENT_CATEGORY.EMAIL_ATTACHMENT,
        };
      });

      const documentUploadTasks = documents.map((doc) =>
        DocumentsUploadAPI.uploadDocument(this.props.submissionId, doc)
      );

      await Promise.all(documentUploadTasks);
      return documents;
    } catch (error) {
      handleError(error);
      throw error;
    }
  };

  /**
   * Create S3FileUploader instance for file.
   * @param {File} file
   * @returns
   */
  createUploader = (file) => {
    const uploader = new S3FileUploader(
      file,
      DocumentsUploadAPI.createOptions()
    );

    return uploader;
  };

  submit = async (values) => {
    if (this.isDirectSubmission()) {
      const errors = this.props.validateForm(values, (values) =>
        validate(values, false, this.state.tagSubmissionData, false)
      );
      if (errors.displayErrors.length > 0) {
        setTimeout(function () {
          window.scrollTo(0, 0);
        }, 2);
        throw new SubmissionError({ ...errors });
      }

      this.createOptionalFields(values);
      return new Promise((resolve, reject) =>
        this.setState(
          {
            isLoading: true,
          },
          () => {
            const deliveryOptions = {
              amt_owed: values.get('amtOwed'),
              req_reason: values.get('reqReason'),
              prov_comments: values.get('provComments'),
              dont_know_dollar_amt: values.get('dontKnowDollarAmt') || false,
            };
            const directSubmissionPromise = CreateAppealAPI.submitDirectUHCApi(
              this.props.selectedAppeal,
              this.props.submissionId,
              this.state.submissionTypeIndexSelected ===
                SUBMISSION_TYPE.DIRECT_RECON
                ? APPEAL_TYPE.RECON
                : APPEAL_TYPE.APPEAL,
              deliveryOptions
            );
            directSubmissionPromise
              .then(() => {
                this.changeAppealStatusAndNavigate(this.props.selectedAppeal);
              })
              .catch(async (e) => {
                const responseStatusCode = _get(e, 'response.status');
                if ([400, 500].includes(responseStatusCode)) {
                  const errorMessages = await extractErrorMessagesHTTPFromError(
                    e
                  );
                  this.props.updateErrors(errorMessages);

                  resolve(e);
                }
                resolve(e);
              });
          }
        )
      );
    }

    // Create tags and updates page counts

    const hasStandaloneFeature =
      !isAppealioExpressUser(this.props.userInfo) && this.props.isStandAlone;
    return new Promise((resolve, reject) => {
      const errors = this.props.validateForm(values, (values) =>
        validate(
          values,
          this.props.previewIncludeCoverLetter,
          this.state.tagSubmissionData,
          hasStandaloneFeature
        )
      );

      if (errors.displayErrors.length > 0) {
        setTimeout(function () {
          window.scrollTo(0, 0);
        }, 2);
        throw new SubmissionError({ ...errors });
      } else {
        // create tags
        this.createOptionalFields(values);
        this.setState({ isLoading: true }, async () => {
          let deliveryInfoPromise;
          const deliveryOptions = {
            color: values.get('color', 1),
            double_sided: values.get('side') === '0',
            shipping: values.get('name'),
            attention_line: values.get('attention_line'),
            is_paper_claim: values.get('paperClaim') === true,
            odd_page_submission_padding:
              values.get('paperClaimConfig')?.value ===
              PAPER_CLAIM_CONFIG.WITH_BACK_PAGE,
            return: {
              name: values.get('return_address_name'),
              address_line1: values.get('return_address_line1'),
              address_line2: values.get('return_address_line2'),
              city: values.get('return_city'),
              state: values.get('return_state'),
              zipcode: validateAndFormatZipCode(values.get('return_zipcode')),
              country: 'US',
            },
          };

          const requestBody = {
            ...values.toJS(),
            submissionId: this.props.submissionId,
            country: 'US',
            zipcode: validateAndFormatZipCode(values.get('zipcode')),
          };
          if (this.state.submissionTypeIndexSelected === SUBMISSION_TYPE.FAX) {
            deliveryInfoPromise = CreateAppealAPI.submitFax(
              this.props.selectedAppeal,
              {
                ...requestBody,
                deliveryOptions: {
                  cover_letter: this.props.previewIncludeCoverLetter ? 1 : 0,
                  fax_from: values.get('faxFrom'),
                  fax_to: values.get('faxTo'),
                },
              }
            );
          } else if (
            this.state.submissionTypeIndexSelected === SUBMISSION_TYPE.MAIL
          ) {
            deliveryInfoPromise = CreateAppealAPI.submitMail(
              this.props.selectedAppeal,
              {
                ...requestBody,
                deliveryOptions,
                certified: values.get('certified'),
              }
            );
          } else if (
            this.state.submissionTypeIndexSelected === SUBMISSION_TYPE.EMAIL
          ) {
            if (this.state.emailFiles.length > 0) {
              await this.uploadEmailFiles(this.state.emailFiles);
            }

            deliveryInfoPromise = CreateAppealAPI.submitEmail(
              this.props.selectedAppeal,
              {
                ...requestBody,
                subject: values.get('emailSubject'),
                message: values.get('emailMessage'),
                recipient_email: values.get('recipientEmail'),
                cc_emails: values.get('ccEmails'),
              }
            );
          } else if (
            this.state.submissionTypeIndexSelected ===
            SUBMISSION_TYPE.PAYER_PORTAL
          ) {
            if (
              this.props.isStandAlone &&
              !isAppealioExpressUser(this.props.userInfo)
            ) {
              const { payerPortalOptions } = requestBody;
              deliveryInfoPromise =
                CreateAppealAPI.submitPayerPortalForStandAlone(
                  this.props.submissionId,
                  payerPortalOptions?.payerPortalUrl
                );
            } else {
              // TODO: Add delivery info if needed
              deliveryInfoPromise = new Promise((resolve) => resolve());
            }
          }

          deliveryInfoPromise
            .then(() => {
              if (this.props.isStandAlone) {
                this.setIsShowingSuccess(true);
                this.props.actions.setCurrentStep(6);
              } else {
                this.changeAppealStatusAndNavigate(this.props.selectedAppeal);
              }
            })
            .catch(async (e) => {
              window.scrollTo(0, 0);
              this.setState({ isLoading: false });
              const isSubmittedByMail =
                this.state.submissionTypeIndexSelected === SUBMISSION_TYPE.MAIL;
              const responseStatusCode = _get(e, 'response.status');

              if (responseStatusCode >= 500) {
                CreateAppealAPI.logAppealSubmissionError(
                  this.props.selectedAppeal,
                  responseStatusCode
                ).catch(() => ({}));
                this.setState({
                  showSubmissionErrorPopup: true,
                });
                return resolve(e);
              }

              if ([400, 500].includes(responseStatusCode)) {
                const default400StatusMessages = isSubmittedByMail
                  ? [SUBMISSION_FAILED_ERROR_BY_MAIL]
                  : [SUBMISSION_FAILED_ERROR];
                const errorMessages = await extractErrorMessagesHTTPFromError(
                  e,
                  {
                    default400StatusMessages,
                    default500StatusMessages: [
                      SUBMISSION_FAILED_BY_INTERNAL_SERVER_ERROR,
                    ],
                  }
                );

                this.props.updateErrors(errorMessages);

                resolve(e);
              }

              resolve(e);
            });
        });
      }
    });
  };

  onSubmissionTypeChange = (selectedSubmissionType) => {
    if (this.state.submissionTypeIndexSelected === selectedSubmissionType)
      return;
    this.props.change('submissionTypeIndexSelected', selectedSubmissionType);
    this.setState(
      {
        submissionTypeIndexSelected: selectedSubmissionType,
        isPaperClaimSubmission: false,
      },
      () => {
        if (!this.props.isStandAlone) {
          this.initializeCurrentFormValues(
            _get(this.props, ['appealDetailsWithMeta', 'appeal'])
          );
        }
        this.setPageValidationError();
        if (this.state.practicesDropdownOptions.length === 1) {
          const practiceId = this.state.practicesDropdownOptions[0].key;
          this.props.change('practice', practiceId);
          if (
            this.state.submissionTypeIndexSelected === SUBMISSION_TYPE.MAIL &&
            (!this.state.isActiveReturnType ||
              this.state.isActiveReturnType === RETURN_ADDRESS_TYPE.PRACTICE)
          ) {
            this.changeReturnAddressToPracticeAddress(practiceId);
          }
        }
      }
    );
  };

  navigateToDashboard = () => {
    if (this.props.userInfo.ediIntegrationEnabled) {
      return this.props.actions.push({ pathname: routes.DENIED_QUEUE });
    }

    this.props.actions.push(routes.SUBMISSIONS_IN_PROGRESS);
  };

  navigateToDeliveryTracker = () => this.props.history.push(routes.SUBMISSIONS);

  navigateToDenialsQueue = () =>
    this.props.actions.push({ pathname: routes.DENIED_QUEUE });
}

function validate(
  values,
  includeCoverPageDetails,
  tagSubmissionData = null,
  hasStandaloneFeature
) {
  const errors = {};
  errors.displayErrors = [];
  if (values.get('submissionTypeIndexSelected') === SUBMISSION_TYPE.FAX) {
    const fieldListName = [
      {
        field: 'coverLetterRe',
        label: 'Cover Letter Re',
        message: 'Re',
        isRequiredField: false,
      },
      {
        field: 'coverLetterCc',
        label: 'Cover Letter CC',
        message: 'CC',
        isRequiredField: false,
      },
      {
        field: 'coverLetterText',
        label: 'Cover Letter Text',
        message: 'Text',
        isRequiredField: false,
      },
      {
        field: 'faxFrom',
        label: 'From',
        message: 'From',
        isRequiredField: true,
        maxLength: 40,
      },
      {
        field: 'faxTo',
        label: 'To',
        message: 'to',
        isRequiredField: true,
        maxLength: 40,
      },
    ];

    for (const item in fieldListName) {
      const maxLengthForToAndFromFields = fieldListName[item].maxLength;
      const maxLengthForCoverLetterFields = 50;
      const fieldName = fieldListName[item].field;
      const label = fieldListName[item].label;
      const message = fieldListName[item].message;
      const isRequiredField = fieldListName[item].isRequiredField;
      const maxLength =
        maxLengthForToAndFromFields || maxLengthForCoverLetterFields;
      const error = fieldCharacterValidation(
        message,
        values.get(fieldName),
        maxLength,
        isRequiredField
      );
      if (error) {
        errors[fieldName] = error;
        errors.displayErrors.push({
          title:
            error === `${FIELD_REQUIRED_STRING}.`
              ? REQUIRED_FIELD_ERROR.title
              : 'Invalid Length',
          message: `${label}: ${error}`,
        });
      }
    }
  }

  if (values.get('submissionTypeIndexSelected') === SUBMISSION_TYPE.FAX) {
    let existError = validateExists(values.get('fax'));
    if (existError === FIELD_REQUIRED_STRING) {
      errors.fax = existError;
      errors.displayErrors.push({
        title: REQUIRED_FIELD_ERROR.title,
        message: `Fax Number: ${REQUIRED_FIELD_ERROR.message}`,
      });
    } else {
      errors.fax = validateFaxNumber(values.get('fax'));
      if (errors.fax !== null) {
        if (errors.fax.startsWith('Invalid Fax format')) {
          errors.displayErrors.push({
            title: INVALID_FAX_NUMBER_FORMAT_ERROR.title,
            message: `Fax Number: ${INVALID_FAX_NUMBER_FORMAT_ERROR.message}`,
          });
        } else {
          errors.displayErrors.push({
            title: INVALID_FAX_NUMBER_ENTRY_ERROR.title,
            message: `Fax Number: ${INVALID_FAX_NUMBER_ENTRY_ERROR.message}`,
          });
        }
      }
    }
    if (includeCoverPageDetails) {
      existError = validateExists(values.get('coverLetterPhone'));
      if (existError === FIELD_REQUIRED_STRING) {
        errors.coverLetterPhone = existError;
        errors.displayErrors.push({
          title: REQUIRED_FIELD_ERROR.title,
          message: `Cover Letter Phone: ${REQUIRED_FIELD_ERROR.message}`,
        });
      } else {
        errors.coverLetterPhone = validatePhoneNumber(
          values.get('coverLetterPhone')
        );
        if (errors.coverLetterPhone !== null) {
          if (errors.coverLetterPhone.startsWith('Invalid phone format')) {
            errors.displayErrors.push({
              title: INVALID_FAX_NUMBER_FORMAT_ERROR.title,
              message: `Cover Letter Phone: ${INVALID_PHONE_NUMBER_FORMAT_ERROR.message}`,
            });
          } else {
            errors.displayErrors.push({
              title: INVALID_FAX_NUMBER_ENTRY_ERROR.title,
              message: `Cover Letter Phone: ${INVALID_PHONE_NUMBER_ENTRY_ERROR.message}`,
            });
          }
        }
      }
    }
  } else if (
    values.get('submissionTypeIndexSelected') === SUBMISSION_TYPE.MAIL
  ) {
    const notEmptyFields = [
      {
        field: 'name',
        label: 'Shipping Address name',
      },
      {
        field: 'address_line1',
        label: 'Shipping Address Line 1',
      },
      {
        field: 'city',
        label: 'Shipping City',
      },
      {
        field: 'state',
        label: 'Shipping State',
      },
      {
        field: 'zipcode',
        label: 'Shipping Zip',
      },
      {
        field: 'return_address_name',
        label: 'Return Address Name',
      },
      {
        field: 'return_address_line1',
        label: 'Return Address Line 1',
      },
      {
        field: 'return_city',
        label: 'Return City',
      },
      {
        field: 'return_state',
        label: 'Return State',
      },
      {
        field: 'return_zipcode',
        label: 'Return Zip',
      },
    ];
    for (const f in notEmptyFields) {
      const fieldName = notEmptyFields[f].field;
      const label = notEmptyFields[f].label;
      errors[fieldName] = validateExists(values.get(fieldName));
      addedRequiredFieldError(errors.displayErrors, errors[fieldName], label);
    }
    errors.zipcode = validateZipCode(values.get('zipcode'));
    if (errors.zipcode !== null) {
      errors.displayErrors.push({
        title: ZIP_CODE_ERROR.title,
        message: `Shipping ZIP: ${ZIP_CODE_ERROR.message}`,
      });
    }

    errors.return_zipcode = validateZipCode(values.get('return_zipcode'));
    if (errors.return_zipcode !== null) {
      errors.displayErrors.push({
        title: ZIP_CODE_ERROR.title,
        message: `Return ZIP: ${ZIP_CODE_ERROR.message}`,
      });
    }

    errors.return_address_name = fieldCharacterValidation(
      '',
      values.get('return_address_name'),
      40
    );

    errors.attention_line = fieldCharacterValidation(
      '',
      values.get('attention_line'),
      40,
      false
    );
    if (errors.return_address_name !== null) {
      errors.displayErrors.push({
        message: `Return Address: Name should be less than 40 characters.`,
      });
    }

    if (errors.attention_line !== null) {
      errors.displayErrors.push({
        message: `Attention Line: Attention Line should be less than 40 characters.`,
      });
    }

    errors.name = fieldCharacterValidation('', values.get('name'), 40);
    if (errors.name !== null) {
      errors.displayErrors.push({
        message: `Shipping Address: Name should be less than 40 characters.`,
      });
    }
  } else if (
    values.get('submissionTypeIndexSelected') === SUBMISSION_TYPE.EMAIL
  ) {
    const notEmptyFields = [
      {
        field: 'emailSubject',
        label: 'Email Subject',
      },
      {
        field: 'emailMessage',
        label: 'Message',
      },
      {
        field: 'recipientEmail',
        label: 'Recipient Email',
      },
    ];
    for (const f in notEmptyFields) {
      const fieldName = notEmptyFields[f].field;
      const label = notEmptyFields[f].label;
      errors[fieldName] = validateExists(values.get(fieldName));
      addedRequiredFieldError(errors.displayErrors, errors[fieldName], label);
    }
  }

  const submissionTypeIndexSelected = values.get('submissionTypeIndexSelected');
  if (
    [SUBMISSION_TYPE.DIRECT_APPEAL, SUBMISSION_TYPE.DIRECT_RECON].includes(
      submissionTypeIndexSelected
    )
  ) {
    let notEmptyFields = [
      {
        field: 'provComments',
        label: 'Provider Comments',
      },
    ];
    if (submissionTypeIndexSelected === SUBMISSION_TYPE.DIRECT_RECON) {
      notEmptyFields = [
        ...notEmptyFields,
        {
          field: 'amtOwed',
          label: 'Amount Requested',
        },
        {
          field: 'reqReason',
          label: 'Request Reason',
        },
        {
          field: 'provComments',
          label: 'Provider Comments',
        },
      ];
    }
    for (const f in notEmptyFields) {
      const fieldName = notEmptyFields[f].field;
      const label = notEmptyFields[f].label;
      errors[fieldName] = validateExists(values.get(fieldName));
      addedRequiredFieldError(errors.displayErrors, errors[fieldName], label);
    }
  }

  if (
    tagSubmissionData &&
    tagSubmissionData.currentTag.length > TAGS_CHARACTER_LIMIT
  ) {
    errors.displayErrors.push({
      title: REQUIRED_FIELD_ERROR.title,
      message: `Tags: The tag must not exceed 50 characters.`,
    });
  }
  if (hasStandaloneFeature) {
    errors.practice = validateExists(values.get('practice'));
    if (errors.practice !== null) {
      errors.displayErrors.push({
        title: REQUIRED_FIELD_ERROR.title,
        message: `Practice: ${REQUIRED_FIELD_ERROR.message}`,
      });
    }

    // validate the Patient Infos and service date
    patientInfoValidatorForStandalone(values, errors, errors.displayErrors);
    serviceDateValidatorForStandalone(values, errors, errors.displayErrors);
  }

  return errors;
}

const SubmissionInfoFrom = reduxForm({
  form: 'submissionInfo',
})(SubmitConfirm);

function mapStateToProps(state) {
  const currentAppeal = getCurrentAppealData(state);
  const selectedAppealID = currentAppeal.get('id');
  const appealDetailsWithMeta = getAppealFromState(state, selectedAppealID);
  const selector = formValueSelector('submissionInfo');
  const userInfo = getUserInfo(state);

  const isPaperClaimSelected = selector(state, 'paperClaim') === true;
  const disableCoverLetter = isFaxCoverLetterDisabledForUser(userInfo);
  const previewIncludeCoverLetter = disableCoverLetter
    ? false
    : selector(state, 'submissionTypeIndexSelected') === SUBMISSION_TYPE.FAX;
  const letters = [];
  const payerPortalUrl = _get(
    appealDetailsWithMeta,
    'appeal.payerContact.payerPortalUrl',
    null
  );
  const isImportedAppeal = currentAppeal && currentAppeal.get('imported');
  const { practice: clientPartitionId } = userInfo;
  const appealClinic = _get(appealDetailsWithMeta, 'appeal.clinic', null);
  const isStandAlone =
    currentAppeal && currentAppeal.get('isStandAlone', false);
  const submissionId = currentAppeal && currentAppeal.get('submissionId');

  const recipientEmail = isStandAlone
    ? selector(state, 'recipientEmail')
    : _get(appealDetailsWithMeta, 'appeal.payerContact.email', null);

  const userClient = userInfo.client;
  const appealPracticeId = _get(
    appealDetailsWithMeta,
    'appeal.practiceId',
    null
  );
  const appealPractice = userInfo.relatedPractices.find(
    (practice) => practice.id === appealPracticeId
  );
  const pageCount = state
    .get('createAppealStore')
    .get('totalDocumentsPageNumbers');
  const totalDocumentsPageNumbers = pageCount || currentAppeal.get('pageCount');
  const selectedPracticeFromDropdown = selector(state, 'practice');
  const submitConfirmPrefillData =
    getSubmitConfirmPrefillData(state)?.toJS() || {};
  const currentAppealPayerContact = _get(
    appealDetailsWithMeta,
    'appeal.payerContact',
    null
  );
  const selectedPayerContactForPayerPortal =
    isStandAlone && selector(state, 'payerPortalOptions');
  return {
    isImportedAppeal,
    clientPartitionId,
    selectedAppeal: selectedAppealID,
    appealDetailsWithMeta,
    previewIncludeCoverLetter,
    userInfo,
    faxParams: {
      fax: selector(state, 'fax'),
      coverLetterPhone: selector(state, 'coverLetterPhone'),
      coverLetterRe: selector(state, 'coverLetterRe'),
      coverLetterCc: selector(state, 'coverLetterCc'),
      coverLetterText: selector(state, 'coverLetterText'),
      faxFrom: selector(state, 'faxFrom'),
      faxTo: selector(state, 'faxTo'),
    },
    shippingAddress: {
      name: selector(state, 'name'),
      addressLine1: selector(state, 'address_line1'),
      addressLine2: selector(state, 'address_line2'),
      attentionLine: selector(state, 'attention_line'),
      city: selector(state, 'city'),
      state: selector(state, 'state'),
      zipcode: selector(state, 'zipcode'),
    },
    recipientEmail,
    disableAmountOwed: selector(state, 'dontKnowDollarAmt') === true,
    formattedLetters: letters,
    payerPortalUrl,
    isMedicalRecordsSubmission: isCurrentAppealMedicalRecord(state),
    appealStep: _get(appealDetailsWithMeta, 'step'),
    isPaperClaimSelected,
    appealClinic,
    appealPractice,
    isStandAlone,
    submissionId,
    userClient,
    submitConfirmPrefillData,
    totalDocumentsPageNumbers,
    selectedPracticeFromDropdown,
    selectedPaperClaimConfig: selector(state, 'paperClaimConfig'),
    currentAppealPayerContact,
    selectedPayerContactForPayerPortal,
    isSubmissionPreviewFetching: getIsFetchingPreview(state),
    submissionPreviewUrl: getPreviewUrl(state),
    submissionPreviewError: getPreviewError(state),
    currentShippingAddress: fetchShippingAddress(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        setCurrentStep,
        getAppealById,
        createSubmitPackage,
        initialize,
        push,
        replace,
        setSubmitConfirmPrefillData,
        fetchSubmissionPreview,
        setShippingAddress,
      },
      dispatch
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ErrorHandling(SubmissionInfoFrom));
