import { withAttrs } from 'helpers/object';
import { validateDateString } from 'helpers/validators';
import { addedDateFieldError } from 'components/CreateAppeal/addDateError';
import { REQUIRED_FIELD_ERROR } from 'components/Shared/Errors/errorMessages';

const requiredFieldError = (label) => ({
  title: REQUIRED_FIELD_ERROR.title,
  message: `${label}: ${REQUIRED_FIELD_ERROR.message}`,
});

/**
 * Validate the Patient info for standalone
 * @param {object}
 * @param {object}
 * @param {object}
 * @returns {object}
 */
export function patientInfoValidatorForStandalone(
  values,
  errors,
  displayErrors
) {
  const patientFirstName = values.get('firstName') || '';
  const patientLastName = values.get('lastName') || '';
  const patientDob = values.get('dob') || '';

  // validate empty patient last name
  if (patientFirstName && patientLastName === '') {
    if (patientDob === '') {
      displayErrors.push(requiredFieldError('Date of Birth'));
    }
    displayErrors.push(requiredFieldError('Last Name'));
  }

  // validate empty patient first name
  if (patientLastName && patientFirstName === '') {
    if (patientDob === '') {
      displayErrors.push(requiredFieldError('Date of Birth'));
    }
    displayErrors.push(requiredFieldError('First Name'));
  }

  // validate the empty DOB if patient last name and patient first name are not empty
  if (patientLastName && patientFirstName && patientDob === '') {
    displayErrors.push(requiredFieldError('Date of Birth'));
  }

  if (patientDob) {
    // validate empty patient first name and patient last name
    if (patientLastName === '' && patientFirstName === '') {
      for (const item of ['First Name', 'Last Name']) {
        displayErrors.push({
          title: REQUIRED_FIELD_ERROR.title,
          message: `${item}: ${REQUIRED_FIELD_ERROR.message}`,
        });
      }
    }

    const patientDobError = validateDateString(patientDob, true);
    addedDateFieldError(displayErrors, patientDobError, 'Date of Birth');
  }

  return errors;
}

/**
 * Validate service date for standalone
 * @param {object}
 * @param {object}
 * @param {object}
 * @returns {object}
 */
export function serviceDateValidatorForStandalone(
  values,
  errors,
  displayErrors
) {
  const serviceStartDate = values.get('serviceStartDate') || '';
  const serviceEndDate = values.get('serviceEndDate') || '';

  if (serviceStartDate && serviceEndDate && serviceStartDate > serviceEndDate) {
    const errorMessage = 'Service Start Date must be before Service End Date.';
    errors.serviceStartDate = errorMessage;
    errors.displayErrors.push({
      title: REQUIRED_FIELD_ERROR.title,
      message: `Service Start Date : ${errorMessage}`,
    });
  }

  if (!serviceStartDate && serviceEndDate) {
    const errorMessage = 'Service Start Date is required.';
    errors.serviceStartDate = errorMessage;
    errors.displayErrors.push({
      title: REQUIRED_FIELD_ERROR.title,
      message: `Service Start Date : ${errorMessage}`,
    });
  }

  validateDate(displayErrors, serviceStartDate, 'Service Start Date');
  validateDate(displayErrors, serviceEndDate, 'Service End Date');

  return errors;
}

/**
 * Creates a mail contact object.
 * @param {Object} payerContact - The contact details of the payer.
 * @param {Array} payerNames - The names of the payers.
 * @param {Array} payerIds - The IDs of the payers.
 * @returns {Object} A mail contact object.
 */
const createMailContact = (payerContact, payerNames, payerIds) => ({
  label: `${payerContact.name} (${payerNames})`,
  value: payerContact.id,
  address: {
    name: payerContact.name,
    address1: payerContact.address1,
    address2: payerContact.address2,
    city: payerContact.city,
    state: payerContact.state,
    zipcode: payerContact.zipcode,
  },
  dvVerifiedMail: payerContact.dvVerifiedMail,
  payerNames,
  payerIds,
});

/**
 * Creates a fax contact object.
 * @param {Object} payerContact - The contact details of the payer.
 * @param {Array} payerNames - The names of the payers.
 * @param {Array} payerIds - The IDs of the payers.
 * @returns {Object} A fax contact object.
 */
const createFaxContact = (payerContact, payerNames, payerIds) => ({
  label: `${payerContact.name} (${payerNames})`,
  value: payerContact.id,
  fax: payerContact.fax,
  dvVerifiedFax: payerContact.dvVerifiedFax,
  payerNames,
  payerIds,
});

/**
 * Creates a payer portal contact object.
 * @param {Object} payerContact - The contact details of the payer.
 * @param {Array} payerNames - The names of the payers.
 * @param {Array} payerIds - The IDs of the payers.
 * @returns {Object} A payer portal contact object.
 */
const createPayerPortalContact = (payerContact, payerNames, payerIds) => ({
  label: `${payerContact.name} (${payerNames})`,
  value: payerContact.id,
  payerPortalUrl: payerContact.payerPortalUrl,
  payerNames,
  payerIds,
});

/**
 * Creates an email contact object.
 * @param {Object} payerContact - The contact details of the payer.
 * @param {Array} payerNames - The names of the payers.
 * @param {Array} payerIds - The IDs of the payers.
 * @returns {Object} An email contact object.
 */
const createEmailContact = (payerContact, payerNames, payerIds) => ({
  label: `${payerContact.name} (${payerNames})`,
  value: payerContact.id,
  email: payerContact.email,
  payerNames,
  payerIds,
});

/**
 * Get all the Mail,Fax,Payer Portal and Email contacts.
 *
 * @param {Array} payerContacts - Array of payerContact.
 * @returns {object}
 */
export const getSubmissionTypeContacts = (payerContacts) => {
  const { mailContacts, faxContacts, payerPortalContacts, emailContacts } =
    payerContacts.reduce(
      (acc, payerContact) => {
        const payers = payerContact.payersDetails;
        const { payerNames, payerIds } = payers
          ? payers.reduce(
              (result, payer) => {
                if (payer.name !== null) {
                  result.payerNames.push(payer.name);
                }
                if (payer.id !== null) {
                  result.payerIds.push(payer.id);
                }
                return result;
              },
              { payerNames: [], payerIds: [] }
            )
          : { payerNames: [], payerIds: [] };
        const hasValidShippingAddress = Object.values(
          withAttrs(payerContact, ['address1', 'city', 'state', 'zipcode'])
        ).some((value) => value);

        const hasFax = payerContact?.fax;
        if (hasValidShippingAddress) {
          acc.mailContacts.push(
            createMailContact(payerContact, payerNames, payerIds)
          );
        }

        if (hasFax) {
          acc.faxContacts.push(
            createFaxContact(payerContact, payerNames, payerIds)
          );
        }
        if (payerContact?.payerPortalUrl) {
          acc.payerPortalContacts.push(
            createPayerPortalContact(payerContact, payerNames, payerIds)
          );
        }

        if (payerContact?.email) {
          acc.emailContacts.push(
            createEmailContact(payerContact, payerNames, payerIds)
          );
        }
        return acc;
      },

      {
        mailContacts: [],
        faxContacts: [],
        payerPortalContacts: [],
        emailContacts: [],
      }
    );
  return { mailContacts, faxContacts, payerPortalContacts, emailContacts };
};

/**
 * Sets data in requestData if the key exists and has a truthy value.
 * @param {Array} displayErrors - List of errors messages.
 * @param {string} date - date to validate.
 * @param {string} values - Date values.
 */
const validateDate = (displayErrors, date, label) => {
  if (!date) {
    return;
  }
  const error = validateDateString(date, false);
  addedDateFieldError(displayErrors, error, label);
};

/**
 * Sets data in requestData if the key exists and has a truthy value.
 * @param {string} dataKey - The key under which to look for data.
 * @param {Map} values - A Map containing key-value pairs.
 * @param {Object} requestData - An object to which data will be added if present.
 * @returns {Object} The updated requestData object.
 */
export const setValueIfExists = (key, values, requestData) => {
  const value = values.get(key) || '';
  if (value) {
    requestData[key] = value;
  }
  return requestData;
};
