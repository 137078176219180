import PropTypes from 'prop-types';
import React, { Component } from 'react';

import ViewAppealDetailsButton from '../Shared/ViewAppealDetailsButton';
import DropdownListFilter from '../Shared/DropdownListFilterForKeyValue';

export default class CreateAppealSubHeader extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    agentsDropdownOptions: PropTypes.array,
    displayStatus: PropTypes.bool,
    displayAgentDropdown: PropTypes.bool,
    onAppealDetailsClicked: PropTypes.func,
    onBillingAgentChange: PropTypes.func,
  };

  static defaultProps = {
    data: {
      patientName: '--',
      patient: {
        providerName: '--',
      },
      payerName: '--',
      status: '--',
      agent: null,
    },
    agentsDropdownOptions: [],
    displayStatus: true,
    displayAgentDropdown: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      selectedAgentID: null,
    };
  }

  getHeaderCell(title, value) {
    return (
      <div className="createappeal-subheader__cell">
        <div className="createappeal-subheader__cell-title">{title}:</div>
        <div className="createappeal-subheader__cell-value" datacy={title}>
          {value || 'N/A'}
        </div>
      </div>
    );
  }

  render() {
    const {
      practiceName = '',
      providerName = '',
      claimNumber: payerClaimId = '',
      submitterClaimId = '',
      payer: { name: payerName = '' },
    } = this.props.data;

    return (
      <div className="createappeal-subheader" datacy="createappealSubheader">
        <div className="createappeal-subheader__titlecell">
          <div
            className="createappeal-subheader__titlecell-text"
            datacy="subheaderPatientName"
          >
            {this.props.data.patientName}
          </div>
        </div>
        {practiceName && this.getHeaderCell('Practice', practiceName)}
        {providerName && this.getHeaderCell('Provider', providerName)}
        {payerName && this.getHeaderCell('Payer', payerName)}
        {this.getHeaderCell(' Claim ID', submitterClaimId)}
        {this.getHeaderCell('Payer Claim ID', payerClaimId)}
        {this.props.displayStatus &&
          this.getHeaderCell('Status', this.props.data.status)}
        {this.renderAppealDetailsButton()}
      </div>
    );
  }

  renderAppealDetailsButton() {
    if (this.props.data.appealRound > 1) {
      return <ViewAppealDetailsButton onClick={this.onDetailsClicked} />;
    }
  }

  renderAgentDropdown() {
    if (!this.props.agentsDropdownOptions) {
      return;
    }

    if (this.props.agentsDropdownOptions.length === 1) {
      return this.props.agentsDropdownOptions[0].value;
    }

    return (
      <DropdownListFilter
        placeholder="Select Agent"
        dropdownOptions={this.props.agentsDropdownOptions}
        maxStringLength={40}
        shouldTrimOptionLength={true}
        onDropdownFilterChange={this.onAgentDropdownAgentsChanged}
        selectedKey={this.state.selectedAgentID || this.props.data.agent.id}
        dropdownIndexSelected={this.props.agentsDropdownOptions.findIndex(
          (a) =>
            a.key === this.props.data.agent.id ||
            a.key === this.state.selectedAgentID
        )}
        input={this}
        error={this}
        allowNoneItem={false}
      />
    );
  }

  onDetailsClicked = () => {
    this.props.onAppealDetailsClicked();
  };

  onAgentDropdownAgentsChanged = (agentID) => {
    if (this.props.onBillingAgentChange && agentID) {
      this.setState({ selectedAgentID: agentID });
      this.props.onBillingAgentChange(agentID);
    }
  };
}
