import moment from 'moment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { isInclusivelyAfterDay } from 'react-dates';
import React, { useCallback, useEffect } from 'react';

import Dropdown from 'components/Shared/Dropdown';
import { DatePicker } from 'components/common/datepicker';

import calendar from 'img/calendar-icon.svg';

import {
  fetchTeamOptions,
  fetchAgentOptions,
  fetchActionLogActions,
  fetchDenialsRuleOptions,
  fetchDenialsPayerOptions,
  fetchDenialsPracticeOptions,
} from 'redux/actions/dropdownOptionsActions';

import {
  DROPDOWN_OPTIONS_STATE_KEYS,
  getDropdownOptions,
} from 'redux/reducers/dropdownOptionsStore';

import { objectsAreEqual } from 'helpers/object';

import {
  DATE_FORMAT_YYYY_MM_DD,
  getFormattedDate,
  getDateMonthsAgo,
} from 'helpers/dateFormats';

const FILTERS_KEYS = {
  FROM_DATE: 'date_from',
  TO_DATE: 'date_to',
  PRACTICE: 'practice_identifier',
  PAYER: 'payer',
  USER: 'user_involved_in_action',
  RULE: 'applied_rule',
  DATE_RANGE_TYPE: 'date_range_type',
  TEAM: 'team_id',
};

const DATE_RANGE_TYPE_OPTION = {
  data: [
    {
      label: 'Completed Actions',
      value: 'action_completed_by',
    },
    {
      label: 'Denials/Actions Added',
      value: 'denial_actions_added',
    },
  ],
};

export const DEFAULT_FILTERS = Object.values(FILTERS_KEYS).reduce(
  (defaultFilters, key) => {
    if (key === FILTERS_KEYS.FROM_DATE) {
      return {
        ...defaultFilters,
        [key]: getDateMonthsAgo(3, DATE_FORMAT_YYYY_MM_DD),
      };
    } else if (key === FILTERS_KEYS.TO_DATE) {
      return {
        ...defaultFilters,
        [key]: getFormattedDate(new Date(), DATE_FORMAT_YYYY_MM_DD),
      };
    }

    if (key === FILTERS_KEYS.DATE_RANGE_TYPE) {
      return {
        ...defaultFilters,
        [key]: 'action_completed_by',
      };
    }

    return { ...defaultFilters, [key]: null };
  },
  {}
);

const Filter = ({
  dropdownOptions,
  filters,
  setFilters,
  isAuthorizedForTeamsOptions,
  actions: {
    fetchAgentOptions,
    fetchTeamOptions,
    // fetchActionLogActions,
    fetchDenialsRuleOptions,
    fetchDenialsPayerOptions,
    fetchDenialsPracticeOptions,
  },
}) => {
  useEffect(() => {
    if (isAuthorizedForTeamsOptions) {
      fetchTeamOptions();
    }
    fetchAgentOptions();
    // fetchActionLogActions();
    fetchDenialsRuleOptions();
    fetchDenialsPayerOptions();
    fetchDenialsPracticeOptions();
  }, [
    fetchTeamOptions,
    fetchAgentOptions,
    fetchDenialsRuleOptions,
    fetchDenialsPayerOptions,
    fetchDenialsPracticeOptions,
    isAuthorizedForTeamsOptions,
  ]);

  const handleDropdownChange = useCallback(
    (key, value) => {
      setFilters((prevFilters) => ({ ...prevFilters, [key]: value }));
    },
    [setFilters]
  );

  const generateDropdown = useCallback(
    (options, placeholder, label, filterKey, className = '') => {
      const value = filters[filterKey];
      const selectedValue =
        options?.data?.find((option) => option.value === value) || null;
      return (
        <div className={`width-180 ${className}`}>
          <Dropdown
            options={options?.data}
            isLoading={options?.isFetching}
            placeholder={placeholder}
            className="mr-16"
            onChange={(option) => handleDropdownChange(filterKey, option.value)}
            value={selectedValue}
            label={label}
          />
        </div>
      );
    },
    [filters, handleDropdownChange]
  );

  const showResetButton = !objectsAreEqual(DEFAULT_FILTERS, filters);

  const clearFilters = () => {
    setFilters(DEFAULT_FILTERS);
  };

  // const userOptions = dropdownOptions?.agents?.data?.map((agent) => ({
  //   label: agent.label,
  //   value: agent.username,
  // }));

  return (
    <div className="d-flex align-item-flex-end">
      <div>
        {generateDropdown(
          DATE_RANGE_TYPE_OPTION,
          '',
          'Date to filter by',
          FILTERS_KEYS.DATE_RANGE_TYPE,
          'width-190'
        )}
      </div>
      <div>
        <label className="dropdown__label">Date Range</label>
        <div className="d-flex align-items-center">
          <div className="mr-16 denial-analytics__date-item">
            <DatePicker
              displayFormat="LL"
              customInputIcon={
                <img className="form-icon" src={calendar} alt="Calendar" />
              }
              placeholder="From"
              verticalSpacing={10}
              date={
                filters[FILTERS_KEYS.FROM_DATE]
                  ? moment(filters[FILTERS_KEYS.FROM_DATE])
                  : null
              }
              isOutsideRange={(d) =>
                isInclusivelyAfterDay(d, moment().add(1, 'days')) ||
                d.isAfter(moment(filters[FILTERS_KEYS.TO_DATE]), 'day')
              }
              onDateChange={(date) => {
                date = moment(date);
                if (!date.isValid()) {
                  setFilters({ ...filters, [FILTERS_KEYS.FROM_DATE]: null });
                  return;
                }
                setFilters({
                  ...filters,
                  [FILTERS_KEYS.FROM_DATE]: date.format('YYYY-MM-DD'),
                });
              }}
              numberOfMonths={1}
              orientation="horizontal"
              block
            />
          </div>
          <div className="mr-16 denial-analytics__date-item">
            <DatePicker
              displayFormat="LL"
              customInputIcon={
                <img className="form-icon" src={calendar} alt="Calendar" />
              }
              placeholder="To"
              verticalSpacing={10}
              date={
                filters[FILTERS_KEYS.TO_DATE]
                  ? moment(filters[FILTERS_KEYS.TO_DATE])
                  : null
              }
              isOutsideRange={(d) =>
                isInclusivelyAfterDay(d, moment().add(1, 'days')) ||
                d.isBefore(moment(filters[FILTERS_KEYS.FROM_DATE]), 'day')
              }
              onDateChange={(date) => {
                date = moment(date);
                if (!date.isValid()) {
                  setFilters({ ...filters, [FILTERS_KEYS.TO_DATE]: null });
                  return;
                }
                setFilters({
                  ...filters,
                  [FILTERS_KEYS.TO_DATE]: date.format('YYYY-MM-DD'),
                });
              }}
              numberOfMonths={1}
              orientation="horizontal"
              block
            />
          </div>
        </div>
      </div>
      {generateDropdown(
        dropdownOptions?.rules,
        'All Rules',
        'Rule',
        FILTERS_KEYS.RULE
      )}
      {generateDropdown(
        dropdownOptions?.practices,
        'All Practices',
        'Practice',
        FILTERS_KEYS.PRACTICE
      )}
      {generateDropdown(
        dropdownOptions?.payers,
        'All Payers',
        'Payer',
        FILTERS_KEYS.PAYER
      )}
      {/* {generateDropdown(
        { data: userOptions },
        'All Users',
        'User',
        FILTERS_KEYS.USER
      )} */}
      {isAuthorizedForTeamsOptions &&
        generateDropdown(
          dropdownOptions?.teams,
          'All Teams',
          'Team',
          FILTERS_KEYS.TEAM
        )}
      {showResetButton && (
        <div className="table__filter-clear" onClick={clearFilters}>
          Reset
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  dropdownOptions: {
    rules: getDropdownOptions(
      state,
      DROPDOWN_OPTIONS_STATE_KEYS.DENIALS_RULE_OPTIONS
    ),
    agents: getDropdownOptions(
      state,
      DROPDOWN_OPTIONS_STATE_KEYS.AGENT_OPTIONS
    ),
    payers: getDropdownOptions(
      state,
      DROPDOWN_OPTIONS_STATE_KEYS.DENIALS_PAYER_OPTIONS
    ),
    practices: getDropdownOptions(
      state,
      DROPDOWN_OPTIONS_STATE_KEYS.DENIALS_PRACTICE_OPTIONS
    ),
    teams: getDropdownOptions(state, DROPDOWN_OPTIONS_STATE_KEYS.TEAM_OPTIONS),
  },
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      fetchTeamOptions,
      fetchAgentOptions,
      fetchActionLogActions,
      fetchDenialsRuleOptions,
      fetchDenialsPayerOptions,
      fetchDenialsPracticeOptions,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Filter);
