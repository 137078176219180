import _ from 'lodash';
import classnames from 'classnames';
import { BiX } from 'react-icons/bi';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { BsPencil } from 'react-icons/bs';
import React, { useState, useEffect, useMemo } from 'react';

import emptyIcon from 'img/empty.svg';

import * as toast from 'components/Shared/toast';
import Pagination from 'components/common/pagination';
import TextHighlighter from 'components/common/textHighlighter';
import { AppealioPopupWithFooter } from 'components/common/popup';
import LoadingBlockHelper from 'components/Shared/LoadingBlockHelper';
import ActionButton from 'components/common/actionButton/ActionButton';
import SearchWithOption from 'components/common/search/SearchWithOption/SearchWithOption';
import ChangeRenderingProviderStatusPopup from './ChangeRenderingProviderStatusPopup/ChangeRenderingProviderStatusPopup';
import AddRenderingProviderForm from 'components/CreateAccount/CreateAppealioAccountDetails/AddRenderingProviderContainer/AddRenderingProviderForm';

import { handleError } from 'helpers/errorHandler';
import { attachCountsToArrayOfObjects } from '../utils';
import { SEARCH_QUERY_CHARACTERS_OFFSET } from '../constants';

import {
  useFetchAllClinics,
  useFetchRenderingProviders,
  useFetchAllBillingProviders,
  useFilterPractices,
  useFetchRenderingProviderCount,
} from '../hook';

import * as AccountsAPI from 'API/AccountSettingsAPI';

import { getUserInfo } from 'redux/reducers/loginStore';
import { SETTINGS_PAGE_LIMIT } from 'constants/appConstants';
import { isClientAdmin } from 'Auth/AuthUtils';

const PRACTICE_OPTION = {
  label: 'Practice',
  value: 'PRACTICE',
  isDefault: true,
};

const RENDERING_PROVIDER_OPTION = {
  label: 'Rendering Provider',
  value: 'RENDERING_PROVIDER',
};

const SEARCH_TYPES = [PRACTICE_OPTION, RENDERING_PROVIDER_OPTION];

const RenderingProvider = (props) => {
  const [page, setPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState({
    query: '',
    type: PRACTICE_OPTION.value,
  });
  const [selectedPractice, setSelectedPractice] = useState();
  const [isRenderingProviderPopupOpen, setIsRenderingProviderPopupOpen] =
    useState(false);
  const [renderingProviderToUpdate, setRenderingProviderToUpdate] =
    useState(null);
  const [
    changeRenderingProviderStatusPopup,
    setChangeRenderingProviderStatusPopup,
  ] = useState({
    isOpen: false,
    renderingProvider: null,
    toDeactivate: true,
  });

  const resetChangeRenderingProviderStatusPopup = () => {
    setChangeRenderingProviderStatusPopup({
      isOpen: false,
      renderingProvider: null,
      toDeactivate: true,
    });
  };

  const isPracticeSearch = searchQuery.type === PRACTICE_OPTION.value;
  const practiceSearchQuery =
    searchQuery.type === PRACTICE_OPTION.value ? searchQuery.query : '';
  const renderingProviderSearchQuery =
    searchQuery.type === RENDERING_PROVIDER_OPTION.value
      ? searchQuery.query
      : '';

  const { practices } = useFilterPractices(
    props.userPractices,
    practiceSearchQuery
  );
  const selectedPracticeId = selectedPractice ? selectedPractice.id : null;
  const { billingProviders } = useFetchAllBillingProviders();

  const [listedPractices, setListedPractices] = useState(practices);

  const { loading, renderingProviders, totalRenderingProviders, fetch } =
    useFetchRenderingProviders(
      page,
      selectedPracticeId,
      renderingProviderSearchQuery
    );

  const { clinics } = useFetchAllClinics();

  const clinicDropdownOptions = clinics.map(({ id, name, practiceId }) => ({
    label: name,
    value: id,
    practiceId,
  }));

  const practiceDropdownOptions = props.userPractices.map(({ id, name }) => ({
    label: name,
    value: id,
  }));

  const billingProviderDropdownOptions = billingProviders.map(
    ({ id, firstName, lastName, organizationName, practice }) => {
      const name = organizationName || `${firstName} ${lastName}`;

      return { label: name, value: id, practiceId: practice };
    }
  );

  const onPageChange = (page) => setPage(page);
  const pageCount = Math.ceil(totalRenderingProviders / SETTINGS_PAGE_LIMIT);

  const handleProviderCreateUpdateError = async (error) => {
    const errorResponse = error.response;
    if (errorResponse.status === 400) {
      const response = await error.response.json();
      if (response.name) {
        return toast.error({
          message: response.name[0],
          title: 'Error',
        });
      }
    }
    handleError(error);
  };

  const handleSubmit = async (values) => {
    const practiceClinicsBillingProviders =
      values.practiceClinicsBillingProviders || [];

    const { billingProviders, clinics } =
      practiceClinicsBillingProviders.reduce(
        (acc, cur) => {
          return {
            billingProviders: [
              ...acc.billingProviders,
              ...cur.billingProviders.map(({ value }) => value),
            ],
            clinics: [...acc.clinics, ...cur.clinics.map(({ value }) => value)],
          };
        },
        {
          billingProviders: [],
          clinics: [],
        }
      );
    const practices = values.practiceIds.map(({ value }) => value);
    const formData = {
      ...values,
      practices,
      clinics,
      billingProviders,
    };

    try {
      const requestPayload = !renderingProviderToUpdate
        ? formData
        : {
            ...renderingProviderToUpdate,
            ...formData,
            clinics,
            billingProviders,
          };
      if (!renderingProviderToUpdate) {
        await AccountsAPI.createRenderingProvider(requestPayload);
      } else {
        await AccountsAPI.updateRenderingProvider(requestPayload);
      }
      toast.success({
        title: 'Success',
        message: !renderingProviderToUpdate
          ? 'Rendering Provider added successfully.'
          : 'Rendering Provider updated successfully',
      });
      setIsRenderingProviderPopupOpen(false);
    } catch (error) {
      handleProviderCreateUpdateError(error);
    }

    fetch();
  };

  const renderingProviderToRender = renderingProviders;

  const handlePracticeClick = (practice) => {
    setPage(1);
    setSelectedPractice(practice);
  };

  const handleSearch = ({ query, type }) => {
    setSearchQuery({
      query,
      type,
    });
  };

  const { renderingProviderCount } = useFetchRenderingProviderCount(
    renderingProviderSearchQuery
  );

  const filteredPractices = useMemo(() => {
    if (isPracticeSearch) {
      if (!searchQuery.query) {
        if (!selectedPractice?.id) {
          setSelectedPractice(practices[0]);
        }
        return practices;
      }

      const search = searchQuery.query.toUpperCase();
      return practices.filter((payer) =>
        payer.name.toUpperCase().includes(search)
      );
    }

    if (
      renderingProviderCount?.length > 0 &&
      searchQuery.query.length >= SEARCH_QUERY_CHARACTERS_OFFSET
    ) {
      const practiceWithProviderCount = attachCountsToArrayOfObjects(
        practices,
        renderingProviderCount,
        'practiceId',
        'providerCount'
      ).filter((provider) => provider.providerCount);

      const selectedHasProvider = practiceWithProviderCount.some(
        (payer) => payer.id === selectedPractice.id
      );

      if (
        (renderingProviders.length < 1 &&
          practiceWithProviderCount.length > 0 &&
          !selectedHasProvider) ||
        practiceWithProviderCount.length === 1
      ) {
        setSelectedPractice(practiceWithProviderCount[0]);
      }

      return practiceWithProviderCount;
    }

    return practices;
  }, [
    practices,
    isPracticeSearch,
    renderingProviderCount,
    renderingProviders.length,
    selectedPractice?.id,
    searchQuery,
  ]);

  useEffect(() => {
    setListedPractices(filteredPractices);
  }, [filteredPractices, searchQuery]);

  const initialClinicIds =
    renderingProviderToUpdate && renderingProviderToUpdate.clinics
      ? renderingProviderToUpdate.clinics.map(({ id }) => id)
      : [];
  const selectedClinicIds = clinicDropdownOptions.filter((option) =>
    initialClinicIds.includes(option.value)
  );

  const initialBillingProviderIds =
    renderingProviderToUpdate && renderingProviderToUpdate.billingProviders
      ? renderingProviderToUpdate.billingProviders.map(({ id }) => id)
      : [];
  const selectedBillingProviderIds = billingProviderDropdownOptions.filter(
    (option) => initialBillingProviderIds.includes(option.value)
  );

  const initialPracticeIds =
    renderingProviderToUpdate && renderingProviderToUpdate.practices
      ? renderingProviderToUpdate.practices.map(({ id }) => id)
      : [];

  const extractSelectedPracticeDropdown = () => {
    const selectedPracticeOptions = practiceDropdownOptions.filter((option) =>
      initialPracticeIds.includes(option.value)
    );
    if (selectedPracticeOptions.length > 0) return selectedPracticeOptions;

    return practiceDropdownOptions.filter(
      (option) => option.value === selectedPracticeId
    );
  };

  const selectedPracticeOptions = extractSelectedPracticeDropdown();
  const billingProviderOptionsByPractice = practiceDropdownOptions
    .map((option) => option.value)
    .reduce((acc, cur) => {
      return {
        ...acc,
        [cur]: billingProviderDropdownOptions.filter(
          ({ practiceId }) => practiceId === cur
        ),
      };
    }, {});

  const clinicOptionsByPractice = practiceDropdownOptions
    .map((option) => option.value)
    .reduce((acc, cur) => {
      return {
        ...acc,
        [cur]: clinicDropdownOptions.filter(
          ({ practiceId }) => practiceId === cur
        ),
      };
    }, {});

  const practiceClinicsBillingProviders = selectedPracticeOptions.map(
    (selectedPractice) => {
      return {
        practice: selectedPractice,
        billingProviders: billingProviderOptionsByPractice[
          selectedPractice.value
        ].filter(({ value }) => initialBillingProviderIds.includes(value)),
        clinics: clinicOptionsByPractice[selectedPractice.value].filter(
          ({ value }) => initialClinicIds.includes(value)
        ),
      };
    }
  );

  const extractPracticeNames = (renderingProvider) => {
    return renderingProvider.practices
      .map((practice) => {
        return practice.name;
      })
      .join(', ');
  };

  const searchBoxPlaceholder = isPracticeSearch
    ? 'Search by Practice'
    : 'Search by Rendering Provider';

  return (
    <div className="settings-content-wrapper" datacy="rendering-provider">
      <p className="setting-content__description">
        All Rendering Providers are organized by their respective Practice. To
        add a Practice, please go to the
        <span className="fw-bold"> Practices </span> tab.
      </p>
      <div
        className={classnames(
          'd-flex row align-items-center settings-content__top-action',
          {
            'justify-content--space-between': isClientAdmin(props.userInfo),
          }
        )}
      >
        <div className="col-lg-6">
          <SearchWithOption
            onSearch={handleSearch}
            options={SEARCH_TYPES}
            placeholder={searchBoxPlaceholder}
            datacy="billing-provider-SearchInput"
            handleReset={() => setSelectedPractice(practices[0])}
          />
        </div>
        {isClientAdmin(props.userInfo) && (
          <button
            onClick={() => {
              setRenderingProviderToUpdate(null);
              setIsRenderingProviderPopupOpen(true);
            }}
            className="ap-button ap-button--secondary ml-auto settings-add-button"
            datacy="add-rendering-provider-button"
          >
            <span className="mr-4">+</span> Add Rendering Provider
          </button>
        )}
      </div>
      <div className="row mt-12">
        <div className="col-lg-3 border-right-grey settings-content__left-sidebar">
          {listedPractices?.length > 0 ? (
            listedPractices.map((practice, idx) => (
              <div
                key={idx}
                onClick={() => handlePracticeClick(practice)}
                className={classnames('create-account-list-item mb-8', {
                  'create-account-list-item--selected':
                    selectedPractice && selectedPractice.id === practice.id,
                  'd-flex justify-content--space-between align-items-center':
                    renderingProviderCount?.length > 0,
                })}
              >
                <span> {practice.name}</span>
                {practice?.providerCount && (
                  <span>({practice.providerCount})</span>
                )}
              </div>
            ))
          ) : (
            <p className="fw-bold"> No Practice found </p>
          )}
        </div>
        <div className="col-lg-9">
          {loading && loading ? (
            <LoadingBlockHelper
              className="dashboard-bottom-loader"
              isLoading={loading}
            />
          ) : renderingProviderToRender.length > 0 ? (
            <React.Fragment>
              <div className="row mb-16 create-acount-list-item-header">
                <div className="col-lg-2">Name</div>
                <div className="col-lg-2">Type</div>
                <div className="col-lg-2">NPI</div>
                <div className="col-lg-2">Billing Provider(s)</div>
                <div className="col-lg-2">Clinic(s)</div>
                <div className="col-lg-2">&nbsp;</div>
              </div>
              {renderingProviderToRender.map((renderingProvider, idx) => {
                const organizationOrProviderName =
                  renderingProvider.organizationName ||
                  `${renderingProvider.firstName} ${renderingProvider.lastName}`;

                const providerType = renderingProvider.organizationName
                  ? 'Organization'
                  : 'Individual';

                const npi = renderingProvider.npi;
                const billingProvidersList = renderingProvider.billingProviders
                  .map(
                    ({ organizationName, firstName, lastName }) =>
                      `${organizationName}` || `${firstName} ${lastName}`
                  )
                  .join(', ');

                const clinicsList = renderingProvider.clinics
                  .map(({ name }) => name)
                  .join(', ');
                return (
                  <div
                    className={classnames(
                      'create-account-list-item row mb-16',
                      {
                        'create-account-list-item--inactive':
                          !renderingProvider.isActive,
                      }
                    )}
                    key={idx}
                  >
                    <div className="col-lg-2">
                      <span data-tip={organizationOrProviderName}>
                        {organizationOrProviderName &&
                        searchQuery.query?.length >=
                          SEARCH_QUERY_CHARACTERS_OFFSET ? (
                          <TextHighlighter
                            text={organizationOrProviderName}
                            query={searchQuery.query}
                          />
                        ) : (
                          organizationOrProviderName || '--'
                        )}
                      </span>
                    </div>
                    <div className="col-lg-2">
                      <span data-tip={providerType}>
                        {providerType || '--'}
                      </span>
                    </div>
                    <div className="col-lg-2">
                      <span data-tip={npi}>{npi || '--'}</span>
                    </div>
                    <div className="col-lg-2">
                      <span data-tip={billingProvidersList}>
                        {billingProvidersList || '--'}
                      </span>
                    </div>
                    <div className="col-lg-2">
                      <span data-tip={clinicsList}>{clinicsList || '--'}</span>
                    </div>
                    {isClientAdmin(props.userInfo) && (
                      <div className="col-lg-2 d-flex justify-content-flex-end pr-0">
                        {renderingProvider.isActive ? (
                          <div className="d-flex">
                            <ActionButton
                              Icon={BsPencil}
                              className="mr-16 ml-auto"
                              dataTip="Edit"
                              onClick={(e) => {
                                setRenderingProviderToUpdate(renderingProvider);
                                setIsRenderingProviderPopupOpen(true);
                              }}
                              iconClassName="appeal__action--appeal"
                              datacy="rendering-provider-edit-ActionButton"
                            />
                            <ActionButton
                              Icon={BiX}
                              className="mr-16 ap-button--action-delete"
                              dataTip="Deactivate"
                              onClick={(e) => {
                                setChangeRenderingProviderStatusPopup({
                                  isOpen: true,
                                  renderingProvider,
                                  toDeactivate: true,
                                });
                              }}
                              iconClassName="appeal__action--appeal"
                              datacy="rendering-provider-deactivate-ActionButton"
                            />
                          </div>
                        ) : (
                          <div>
                            <button
                              onClick={(e) => {
                                setChangeRenderingProviderStatusPopup({
                                  isOpen: true,
                                  renderingProvider,
                                  toDeactivate: false,
                                });
                              }}
                              className="ap-button ap-button--secondary ml-auto settings-reactivate-button"
                              datacy="rendering-provider-reactivate-button"
                            >
                              Reactivate
                            </button>
                          </div>
                        )}
                      </div>
                    )}
                    <ReactTooltip effect="solid" place="top" multiline={true} />
                  </div>
                );
              })}
            </React.Fragment>
          ) : (
            <div className="no-data-container">
              <img src={emptyIcon} alt="No Data Found" />
              <h4>No Data Found</h4>
            </div>
          )}

          {isRenderingProviderPopupOpen && (
            <AppealioPopupWithFooter
              className="appealio-popup--v3 appealio-popup--v3--overflow-hidden"
              onClosePressed={() => setIsRenderingProviderPopupOpen(false)}
              title={`${
                renderingProviderToUpdate ? 'Edit' : 'Add'
              } Rendering Provider`}
              isFooterOutside={false}
              datacy="rendering-provider-AppealioPopupWithFooter"
            >
              <AddRenderingProviderForm
                onSubmit={handleSubmit}
                initialValues={{
                  ...renderingProviderToUpdate,
                  clinicsIds: selectedClinicIds,
                  billingProvidersIds: selectedBillingProviderIds,
                  practiceIds: selectedPracticeOptions,
                  practiceClinicsBillingProviders,
                }}
                isUpdateForm={!!renderingProviderToUpdate}
                clinics={clinics}
                billingProviders={billingProviders}
                isPopupForm={true}
                clinicDropdownOptions={clinicDropdownOptions}
                billingProviderDropdownOptions={billingProviderDropdownOptions}
                practiceDropdownOptions={practiceDropdownOptions}
                billingProviderOptionsByPractice={
                  billingProviderOptionsByPractice
                }
                clinicOptionsByPractice={clinicOptionsByPractice}
                datacy="rendering-provider-AddRenderingProviderForm"
              />
            </AppealioPopupWithFooter>
          )}

          {changeRenderingProviderStatusPopup.isOpen && (
            <ChangeRenderingProviderStatusPopup
              onClosePressed={resetChangeRenderingProviderStatusPopup}
              renderingProvider={
                changeRenderingProviderStatusPopup.renderingProvider
              }
              practiceName={extractPracticeNames(
                changeRenderingProviderStatusPopup.renderingProvider
              )}
              title={`Are you sure want to ${
                changeRenderingProviderStatusPopup.toDeactivate
                  ? 'deactivate'
                  : 'reactivate'
              } this rendering provider?`}
              onSubmitSuccess={() => {
                resetChangeRenderingProviderStatusPopup();
                fetch();
              }}
              toDeactivate={changeRenderingProviderStatusPopup.toDeactivate}
              datacy="rendering-provider-ChangeRenderingProviderStatusPopup"
            />
          )}

          {!loading && totalRenderingProviders && pageCount > 1 ? (
            <div className="d-flex justify-content-center">
              <Pagination
                pageCount={pageCount}
                activePage={page}
                onPageChange={onPageChange}
                datacy="rendering-provider-Pagination"
              />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  const userInfo = getUserInfo(state);
  const userPractices = _.get(userInfo, 'relatedPractices', []);

  return {
    userInfo,
    userPractices,
  };
}

export default connect(mapStateToProps, {})(RenderingProvider);
