export const MANUAL_ERA_UPLOAD = 'manual-era-upload';
export const DISABLE_FAX_COVER_LETTER_FLAG = 'PD-2993-disable-fax-cover-letter';
export const UHC_CLAIM_STATUS_ENABLED_FLAG = 'PD-2888-uhc-claim-status-api';
export const AVAILITY_CLAIM_STATUS_ENABLED_FLAG =
  'PD-2962-availity-claim-status-api';
export const UHC_API_DIRECT_SUBMISSION = 'PD-2888-uhc-api-direct-submission';
export const AUTO_ARCHIVE_DQ = 'PD-3944-AUTO_ARCHIVE_DQ';
export const EBRIDGE_SEARCH = 'PD-4126-ebridge-search';
export const ODD_PAGE_SUBMISSION_PADDING = 'odd-page-submission-padding';
export const BATCH_UPLOAD_MANUAL_CLAIM = 'batch-upload-manual-claim';
export const ENABLE_CLAIM_LINE_FLAG = 'enable-claim-line-flag';
export const ENABLE_BATCH_SUBMISSION = 'enable-batch-submission';
