import {
  generateDropdownActionTypes,
  generateDropdownInitialState,
  generateKeyFromDropdownActionType,
} from '../utils/dropdownOptionsCommon';

/**
 * Keys for dropdown option state.
 * @enum {string}
 */
export const DROPDOWN_OPTIONS_STATE_KEYS = {
  DENIALS_PAYER_OPTIONS: 'denialsPayerOptions',
  DENIALS_PROVIDER_OPTIONS: 'denialsProviderOptions',
  DENIALS_REASON_OPTIONS: 'denialsReasonOptions',
  DENIALS_REMARK_OPTIONS: 'denialsRemarkOptions',
  DENIALS_CPT_CODE_OPTIONS: 'denialsCptCodeOptions',
  DENIALS_PROCEDURE_MODIFIER_OPTIONS: 'denialsProcedureModifierOptions',
  DENIALS_RULE_OPTIONS: 'denialsRuleOptions',
  AGENT_OPTIONS: 'agentOptions',
  DQ_ACTION_LOG_OPTIONS: 'dqActionLogOptions',
  PRACTICE_GROUP_OPTIONS: 'practiceGroupOptions',
  DENIALS_PRACTICE_OPTIONS: 'denialsPracticeOptions',
  TEAM_OPTIONS: 'teamOptions',
  PAYER_OPTIONS: 'payerOptions',
};

/**
 * Generates action types for dropdown options.
 * @type {object}
 */
const generatedDropdownActionTypes = generateDropdownActionTypes(
  DROPDOWN_OPTIONS_STATE_KEYS
);

/**
 * Maps action types to option keys.
 * @type {object}
 */
const ACTION_OPTION_MAP = Object.keys(generatedDropdownActionTypes).reduce(
  (map, actionType) => {
    map[actionType] =
      DROPDOWN_OPTIONS_STATE_KEYS[
        generateKeyFromDropdownActionType(actionType)
      ];
    return map;
  },
  {}
);

/**
 * Initial state for dropdown options.
 * @type {object}
 */
const initialState = generateDropdownInitialState(DROPDOWN_OPTIONS_STATE_KEYS);

/**
 * Creates dynamic switch cases for action types.
 * @param {Array} actionTypes - List of action types.
 * @returns {object} - Object with dynamic switch cases.
 */
const createDynamicSwitchCases = (actionTypes) =>
  actionTypes.reduce((cases, actionType) => {
    const actionPending = `${actionType}_PENDING`;
    const actionFulfilled = `${actionType}_FULFILLED`;
    const actionRejected = `${actionType}_REJECTED`;
    const optionKey = ACTION_OPTION_MAP[actionType];
    cases[actionPending] = (state) =>
      state.setIn([optionKey, 'isFetching'], true);

    cases[actionFulfilled] = (state, action) => {
      return state
        .setIn([optionKey, 'data'], action.payload)
        .setIn([optionKey, 'isFetching'], false);
    };

    cases[actionRejected] = (state) =>
      state.setIn([optionKey, 'isFetching'], false);

    return cases;
  }, {});
/**
 * Reducer for dropdown options.
 * @param {object} state - Current state.
 * @param {object} action - Action object.
 * @returns {object} - New state after applying the action.
 */
function dropdownOptionsReducer(state = initialState, action = {}) {
  const dynamicCases = createDynamicSwitchCases(
    Object.values(generatedDropdownActionTypes)
  );

  if (dynamicCases[action.type]) {
    return dynamicCases[action.type](state, action);
  }

  return state;
}

export function getDropdownOptions(state, optionKey) {
  return state.get('dropdownOptionsStore').get(optionKey).toJS();
}

export default dropdownOptionsReducer;
