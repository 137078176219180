import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';

import Userpilot from 'components/Userpilot';

import App from './components/App';
import Help from './components/Help/HelpComponent';
import NotFound from './components/NotFound/NotFound';
import Submissions from 'components/Submissions/Submissions';
// import Analytics from "./components/Analytics/Analytics";

import ManualERAUpload from 'components/ManualERA';

import AnalyticsDashboard from 'components/Analytics/v3';
import MergeTool from 'components/MergeTool';

import ResetPassword from './components/Login/ResetPassword';
import LoginComponent from './components/Login/LoginComponent';
import ForgotPassword from './components/Login/ForgotPassword';
import PatientCostEstimator from './components/PatientCostEstimator';
import ManagerSettings from 'components/ApDashboard/Manager/Settings';
import DenialsAnalytics from 'components/Analytics/DenialsQueueAnalytics';
import SubmitConfirm from './components/CreateAppeal/Submit/SubmitConfirm';
import ManagerTeam from './components/ApDashboard/Manager/Team/ManagerTeam';
// import ManagerOverview from "./components/ApDashboard/Manager/Overview";
// import ReAppealComponent from "./components/CreateAppeal/ReAppealComponent";
import StatusTracker from './components/DenialQueue/StatusTracker';
import AccountDetails from 'components/ApDashboard/Manager/Settings/Account';
import Users from 'components/ApDashboard/Manager/Settings/Organization/Users';
import Teams from 'components/ApDashboard/Manager/Settings/Organization/Teams';
import PayersPortal from 'components/ApDashboard/Manager/Settings/PayersPortal';
import Organization from 'components/ApDashboard/Manager/Settings/Organization';
import Payers from 'components/ApDashboard/Manager/Settings/Organization/Payer';
import Practice from 'components/ApDashboard/Manager/Settings/Organization/Practice';
import Clinic from 'components/ApDashboard/Manager/Settings/Organization/Clinic/Clinic';
import PayerContacts from 'components/ApDashboard/Manager/Settings/Organization/PayerContacts';
import PracticeGroup from 'components/ApDashboard/Manager/Settings/Organization/PracticeGroup';
import BillingProvider from 'components/ApDashboard/Manager/Settings/Organization/BillingProvider';
import RenderingProvider from 'components/ApDashboard/Manager/Settings/Organization/RenderingProvider';

import DenialQueueContainer from './components/DenialQueue/DenialQueueContainer';
import ForgotPasswordEmailSent from './components/Login/ForgotPasswordEmailSent';
import CreateAppealComponent from './components/CreateAppeal/CreateAppealComponent';
import AppealLetter from './components/CreateAppeal/AppealLetter/AppealLetterContainer';
import PatientInfo from './components/CreateAppeal/PatientInformation/PatientInfoContainer';
import HistoryAppealContainer from './components/CreateAppeal/DenialInformation/HistoryAppealContainer';
import DenialInformationContainer from './components/CreateAppeal/DenialInformation/DenialInformationContainer';
// import {renderPrivacyPolicy, renderTermsOfService} from './components/Shared/DocPage';
import CreateAppealioAccountIntro from './components/CreateAccount/CreateAppealioAccountIntro';
import CreateAppealioAccountDetails from './components/CreateAccount/CreateAppealioAccountDetails';
import CreateEstimate from './components/PatientCostEstimator/CreateEstimate/CreateEstimate';

import AppealioExpressUsers from 'components/ApDashboard/Manager/Settings/AppealioExpress/Users';
import AppealioExpressReturnAddress from 'components/ApDashboard/Manager/Settings/AppealioExpress/Contacts/ReturnContacts';
import AppealioExpressDestinationAddress from 'components/ApDashboard/Manager/Settings/AppealioExpress/Contacts/DestinationContact';

import {
  IsAuthorizedForPCE,
  IsDashboardEnabled,
  IsUserAuthenticated,
  IsUserCreatingAppeal,
  IsUserNotAuthenticated,
  IsAuthorizedForTeamsPage,
  IsUserAuthorizedForAnalytics,
  IsUserAuthorizedForSubmission,
  IsAuthorizedForDeliveryTracker,
  IsAppealioExpressRoutesEnabled,
  IsUserAuthorizedForDenialsPage,
  IsAuthorizedForUB04MergeUtility,
  IsAuthorizedForPracticeGroupPage,
  IsAuthorizedForCMS1500MergeUtility,
  isAuthorizedForDenialAnalyticsPage,
  IsUserAuthorizedForManagerSettings,
  IsAuthorizedForCreateAccountInfoForm,
  IsUserAuthorizedForStatusTrackerPage,
} from './Auth/AuthWrappers';

import withDashboardNavbar from './components/ApDashboard/withDashboardNavbar';

import * as routes from './constants/routes';

import LoadingIndicator from 'components/Shared/LoadingIndicator';
import Template from 'components/ApDashboard/Manager/Settings/Organization/PayerTemplates';
import DenialAction from 'components/ApDashboard/Manager/Settings/Organization/DenialAction';
import AppealioExpressSettings from 'components/ApDashboard/Manager/Settings/AppealioExpress';

import { getUserInfo } from 'redux/reducers/loginStore';
import { getHomePageRouteForUser } from 'helpers/route';
import { MERGE_TOOL_TYPE } from 'constants/appConstants';

/**
 * Default fallback component displaying a loading indicator.
 */
const DefaultLoadingFallback = () => (
  <div>
    <LoadingIndicator showing />
  </div>
);

/**
 * A Higher-Order Component that wraps a lazy-loaded component with React Suspense.
 *
 * @param {React.ComponentType} WrappedComponent - The lazy-loaded component to be wrapped.
 * @param {React.ComponentType} [FallbackComponent=DefaultLoadingFallback] - A fallback component to display while the lazy-loaded component is being loaded.
 * @returns {React.ComponentType} - A component that combines Suspense and the specified lazy-loaded component.
 */
function withSuspense(
  WrappedComponent,
  FallbackComponent = DefaultLoadingFallback
) {
  return function SuspenseComponent(props) {
    return (
      <React.Suspense fallback={<FallbackComponent />}>
        <WrappedComponent {...props} />
      </React.Suspense>
    );
  };
}
const ReviewAppealLazy = React.lazy(() =>
  import('components/CreateAppeal/Review/Review')
);
const DocumentsUploadContainerLazy = React.lazy(() =>
  import('components/CreateAppeal/DocumentsUpload/DocumentsUploadContainer')
);
const PaymentDetailsLazy = React.lazy(() =>
  import('components/Signup/payment/PaymentDetails')
);
const SignupFormLazy = React.lazy(() => import('components/Signup/SignupForm'));
const SignupMailLazy = React.lazy(() => import('components/Signup/SignupMail'));
const SignupSuccessLazy = React.lazy(() =>
  import('components/Signup/SignupSuccess')
);

/**
 * Create appeal(`/createappeal`) routes.
 */
const CreateAppealsRoutes = () => (
  <CreateAppealComponent>
    <Switch>
      <Route path={routes.CREATE_APPEAL_PATIENT_INFO} component={PatientInfo} />
      <Route
        path={routes.CREATE_APPEAL_DENIAL_INFO}
        component={IsUserCreatingAppeal(DenialInformationContainer)}
      />
      <Route
        path={routes.CREATE_APPEAL_APPEAL_LETTER}
        component={IsUserCreatingAppeal(AppealLetter)}
      />
      <Route
        path={routes.CREATE_APPEAL_DOCUMENTS}
        component={IsUserCreatingAppeal(
          withSuspense(DocumentsUploadContainerLazy)
        )}
      />
      <Route
        path={routes.CREATE_APPEAL_REVIEW}
        component={IsUserCreatingAppeal(withSuspense(ReviewAppealLazy))}
      />
      <Route
        path={routes.CREATE_APPEAL_SUBMIT_CONFIRM}
        component={IsUserCreatingAppeal(SubmitConfirm)}
      />
    </Switch>
  </CreateAppealComponent>
);

const ManagerDashboardRoutes = () => {
  return (
    <Switch>
      <Route
        path={routes.MANAGER_DASHBOARD_TEAM}
        component={IsUserAuthenticated(
          IsDashboardEnabled(
            IsUserAuthorizedForSubmission(withDashboardNavbar(ManagerTeam))
          )
        )}
      />

      {/* <Route
        path={routes.MANAGER_DASHBOARD_OVERVIEW}
        component={IsUserAuthenticated(
          IsDashboardEnabled(
            IsUserAuthorizedForDenialsPage(withDashboardNavbar(ManagerOverview))
          )
        )}
      /> */}

      <Redirect to={routes.MANAGER_DASHBOARD_TEAM} />
    </Switch>
  );
};

const ManagerOrganizationRoutes = () => {
  return (
    <Organization>
      <Switch>
        <Route
          exact
          path={routes.MANAGER_SETTINGS_ORGANIZATION_USERS}
          component={Users}
        />
        <Route
          exact
          path={routes.MANAGER_SETTINGS_ORGANIZATION_PAYERS}
          component={Payers}
        />
        <Route
          exact
          path={routes.MANAGER_SETTINGS_ORGANIZATION_DENIAL_ACTIONS}
          component={DenialAction}
        />
        <Route
          exact
          path={routes.MANAGER_SETTINGS_ORGANIZATION_PAYER_CONTACTS}
          component={PayerContacts}
        />
        <Route
          exact
          path={routes.MANAGER_SETTINGS_ORGANIZATION_PRACTICES}
          component={Practice}
        />
        <Route
          exact
          path={routes.MANAGER_SETTINGS_ORGANIZATION_PRACTICE_GROUP}
          component={IsAuthorizedForPracticeGroupPage(PracticeGroup)}
        />
        <Route
          exact
          path={routes.MANAGER_SETTINGS_ORGANIZATION_TEAMS}
          component={IsAuthorizedForTeamsPage(Teams)}
        />
        <Route
          exact
          path={routes.MANAGER_SETTINGS_ORGANIZATION_CLINICS}
          component={Clinic}
        />
        <Route
          exact
          path={routes.MANAGER_SETTINGS_ORGANIZATION_BILLING_PROVIDERS}
          component={BillingProvider}
        />
        <Route
          exact
          path={routes.MANAGER_SETTINGS_ORGANIZATION_RENDERING_PROVIDERS}
          component={RenderingProvider}
        />
        <Route
          exact
          path={routes.MANAGER_SETTINGS_ORGANIZATION_TEMPLATES}
          component={Template}
        />
        <Redirect to={routes.MANAGER_SETTINGS_ORGANIZATION_USERS} />
      </Switch>
    </Organization>
  );
};

const ManagerSettingsRoutes = () => {
  return (
    <ManagerSettings>
      <Switch>
        <Route
          path={routes.MANAGER_SETTINGS_ACCOUNT}
          component={AccountDetails}
        />

        <Route
          path={routes.MANAGER_SETTINGS_ORGANIZATION}
          component={ManagerOrganizationRoutes}
        />
        <Route
          path={routes.MANAGER_SETTINGS_PAYER_PORTALS}
          component={PayersPortal}
        />
        <Redirect to={routes.MANAGER_SETTINGS_ORGANIZATION} />
      </Switch>
    </ManagerSettings>
  );
};

const ExpressSettingsRoutes = () => {
  return (
    <ManagerSettings>
      <AppealioExpressSettings>
        <Switch>
          <Route
            exact
            path={routes.EXPRESS_SETTINGS_USERS}
            component={AppealioExpressUsers}
          />
          <Route
            exact
            path={routes.EXPRESS_SETTINGS_RETURN_CONTACTS}
            component={AppealioExpressReturnAddress}
          />
          <Route
            exact
            path={routes.EXPRESS_SETTINGS_DESTINATION_CONTACTS}
            component={AppealioExpressDestinationAddress}
          />
          <Redirect to={routes.EXPRESS_SETTINGS_USERS} />
        </Switch>
      </AppealioExpressSettings>
    </ManagerSettings>
  );
};
const UB04MergeTool = () => <MergeTool mergeToolType={MERGE_TOOL_TYPE.UB_04} />;
const Router = (props) => (
  <React.Fragment>
    <App>
      <Userpilot />
      <Switch>
        <Route
          exact
          path={routes.LOGIN}
          component={IsUserNotAuthenticated(LoginComponent)}
        />
        <Route
          exact
          path={routes.CREATE_APPEALIO_ACCOUNT_DETAIL}
          component={IsAuthorizedForCreateAccountInfoForm(
            CreateAppealioAccountDetails
          )}
        />
        <Route
          exact
          path={routes.CREATE_APPEALIO_ACCOUNT_INTRO}
          component={IsUserNotAuthenticated(CreateAppealioAccountIntro)}
        />

        <Route
          path={routes.DENIED_QUEUE}
          component={IsUserAuthenticated(
            IsUserAuthorizedForDenialsPage(DenialQueueContainer)
          )}
        />
        {/* <Route
          exact
          path={routes.DENIED_QUEUE_LOGS}
          component={IsUserAuthenticated(
            IsUserAuthorizedForDenialsPage(ActionLogs)
          )}
        /> */}
        <Route
          path={routes.CLAIM_STATUS_TRACKER}
          component={IsUserAuthenticated(
            IsUserAuthorizedForDenialsPage(
              IsUserAuthorizedForStatusTrackerPage(StatusTracker)
            )
          )}
        />
        <Route
          path={routes.APPEAL_HISTORY}
          component={IsUserAuthenticated(HistoryAppealContainer)}
        />
        <Route
          exact
          path={routes.PATIENT_COST_ESTIMATOR}
          component={IsUserAuthenticated(
            IsAuthorizedForPCE(PatientCostEstimator)
          )}
        />
        <Route
          exact
          path={routes.PATIENT_COST_ESTIMATOR_CREATE_ESTIMATE}
          component={IsUserAuthenticated(IsAuthorizedForPCE(CreateEstimate))}
        />
        <Route
          path={routes.CREATE_APPEAL}
          component={IsUserAuthenticated(CreateAppealsRoutes)}
        />
        {/* <Route path={routes.RE_APPEAL} component={IsUserAuthenticated(ReAppealComponent)} /> */}
        {/* <Route path={routes.ANALYTICS} component={IsUserAuthenticated(Analytics)} /> */}
        <Route path={routes.HELP} component={IsUserAuthenticated(Help)} />
        <Route
          exact
          path={routes.ANALYTICS}
          component={IsUserAuthenticated(
            IsUserAuthorizedForAnalytics(AnalyticsDashboard)
          )}
        />

        <Route
          exact
          path={routes.DENIALS_QUEUE_ANALYTICS}
          component={IsUserAuthenticated(
            isAuthorizedForDenialAnalyticsPage(DenialsAnalytics)
          )}
        />

        <Route
          path={routes.CMS_1500_MERGE_TOOL}
          component={IsUserAuthenticated(
            IsAuthorizedForCMS1500MergeUtility(MergeTool)
          )}
        />

        <Route
          path={routes.UB_04_MERGE_TOOL}
          component={IsUserAuthenticated(
            IsAuthorizedForUB04MergeUtility(UB04MergeTool)
          )}
        />

        <Route
          exact
          path={routes.SUBMISSIONS}
          component={IsUserAuthenticated(
            IsAuthorizedForDeliveryTracker(Submissions)
          )}
        />
        <Route
          exact
          path={routes.SUBMISSIONS_IN_PROGRESS}
          component={IsUserAuthenticated(
            IsUserAuthorizedForSubmission(Submissions)
          )}
        />

        <Route
          path="/dashboard"
          render={() => (
            <Redirect to={getHomePageRouteForUser(props?.userInfo)} />
          )}
        />

        <Route
          exact
          path="/"
          render={() => (
            <Redirect to={getHomePageRouteForUser(props?.userInfo)} />
          )}
        />

        <Route path={routes.REQUEST_NEW_PASSWORD} component={ForgotPassword} />
        <Route path={routes.FORGOT_PASSWORD} component={ForgotPassword} />
        <Route
          path={routes.PASSWORD_SENT}
          component={ForgotPasswordEmailSent}
        />
        <Route path={routes.RESET_PASSWORD} component={ResetPassword} />
        <Route path={routes.CREATE_PASSWORD} component={ResetPassword} />
        <Route
          path={routes.MANAGER_DASHBOARD}
          component={ManagerDashboardRoutes}
        />
        <Route
          path={routes.MANAGER_SETTINGS}
          component={IsUserAuthenticated(
            IsUserAuthorizedForManagerSettings(
              IsDashboardEnabled(ManagerSettingsRoutes)
            )
          )}
        />
        <Route
          exact
          path={routes.SIGNUP}
          component={IsAppealioExpressRoutesEnabled(
            IsUserNotAuthenticated(withSuspense(SignupFormLazy))
          )}
        />
        <Route
          exact
          path={routes.USER_CONFIRMATION}
          component={IsAppealioExpressRoutesEnabled(
            IsUserNotAuthenticated(withSuspense(SignupMailLazy))
          )}
        />
        <Route
          exact
          path={routes.ACTIVATE_USER}
          component={IsAppealioExpressRoutesEnabled(
            IsUserNotAuthenticated(withSuspense(SignupSuccessLazy))
          )}
        />
        <Route
          exact
          path={routes.PAYMENT_DETAILS}
          component={IsAppealioExpressRoutesEnabled(
            IsUserAuthenticated(withSuspense(PaymentDetailsLazy))
          )}
        />
        <Route
          path={routes.MANUAL_ERA_UPLOAD}
          component={IsUserAuthenticated(ManualERAUpload)}
        />
        {/* <Route path="/terms_of_service" component={Authenticated(renderTermsOfService)} /> */}
        {/* <Route path="/privacy_policy" component={Authenticated(renderPrivacyPolicy)} /> */}

        <Route
          path={routes.EXPRESS_SETTINGS}
          component={IsUserAuthenticated(
            IsUserAuthorizedForManagerSettings(ExpressSettingsRoutes)
          )}
        />
        <Route path={routes.NOT_FOUND} component={NotFound} />
        <Route path="*" component={NotFound} />
      </Switch>
    </App>
  </React.Fragment>
);

function mapStateToProps(state) {
  return {
    userInfo: getUserInfo(state),
  };
}

export default connect(mapStateToProps)(Router);
