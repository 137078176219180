import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { bindActionCreators } from 'redux';
import ReactDOMServer from 'react-dom/server';
import React, { useState, useCallback, useEffect, useMemo } from 'react';

import { findMaxOccurrenceValue } from 'helpers/object';

import './style.css';

import incompleteIcon from 'img/incomplete.svg';

import {
  getIsOpen,
  getIsMinimized,
  getAppealIdToDuplicate,
} from 'redux/reducers/batchUploadWidgetStore';
import {
  closeBatchUploadWidget,
  minimizeBatchUploadWidget,
} from 'redux/actions/batchUploadWidgetActions';
import {
  fetchPayerOptions,
  fetchDenialsReasonOptions,
  fetchDenialsRemarkOptions,
  fetchDenialsCptCodeOptions,
  fetchDenialsPracticeOptions,
  fetchDenialsProviderOptions,
  fetchDenialsProcedureModifierOptions,
} from 'redux/actions/dropdownOptionsActions';
import {
  getDropdownOptions,
  DROPDOWN_OPTIONS_STATE_KEYS,
} from 'redux/reducers/dropdownOptionsStore';

import ClaimTable from './ClaimTable';
import FilterSection from './FilterSection';
import ClaimAccordion from './ClaimAccordion';
import Button from 'components/common/button';
import * as toast from 'components/Shared/toast';
import AppealioPopup from 'components/Shared/AppealioPopup';
import BatchSubmission from './BatchSubmission/BatchSubmission';
import LoadingIndicator from 'components/Shared/LoadingIndicator';
import ActionPopup, { TYPE_ACCEPT } from 'components/Shared/ActionPopup';
import { DataTipContent } from 'components/CreateAppeal/PatientInformation/PayerContactDropdown';

import { useAppealData, useClaimsData, useTemplateOptions } from './hooks';

import { STATUS_LOADING } from './constant';

const BatchSubmissionWidget = ({
  actions,
  isWidgetOpen,
  dropdownOptions,
  appealIdToDuplicate,
}) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [selectedClaims, setSelectedClaims] = useState([]);
  const [showCloseConfirmation, setShowCloseConfirmation] = useState(false);
  const [filters, setFilters] = useState({
    payer: '',
    practice: '',
    providers: '',
    procedures: '',
    modifiers: '',
    reasons: '',
    remarks: '',
  });
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  const [isDefaultPayerSet, setIsDefaultPayerSet] = useState(false);

  const [submittedClaims, setSubmittedClaims] = useState({});
  const [submissionStatus, setSubmissionStatus] = useState({});
  const [bulkSubmissionStatus, setBulkSubmissionStatus] = useState(null);

  const { isFetchingAppeal, selectedAppeal } = useAppealData(
    isWidgetOpen,
    appealIdToDuplicate
  );

  const { appealLetterTemplateOptions, appealFormTemplateOptions } =
    useTemplateOptions(selectedAppeal?.appealId, selectedAppeal?.payerId);

  const priorSubmissionLetterTemplateOptions =
    appealLetterTemplateOptions.filter((option) =>
      selectedAppeal?.appealLetterTemplateIds.includes(option.value)
    ) || [];
  const priorSubmissionFormTemplateOptions =
    appealFormTemplateOptions.filter((option) =>
      selectedAppeal?.appealFormTemplateIds.includes(option.value)
    ) || [];

  useEffect(() => {
    if (!isWidgetOpen) {
      setCurrentStep(1);
      setSelectedClaims([]);
      setFilters({
        payer: '',
        practice: '',
        providers: '',
        procedures: '',
        modifiers: '',
        reasons: '',
        remarks: '',
      });
      setSearchTerm('');
      setCurrentPage(1);
      setIsAccordionOpen(false);
      setIsDefaultPayerSet(false);
      setSubmittedClaims({});
      setSubmissionStatus({});
      setBulkSubmissionStatus(null);
      setShowCloseConfirmation(false);
    }
  }, [isWidgetOpen]);

  useEffect(() => {
    if (isWidgetOpen) {
      actions.fetchPayerOptions();
      actions.fetchDenialsPracticeOptions();
      actions.fetchDenialsProviderOptions();
      actions.fetchDenialsCptCodeOptions();
      actions.fetchDenialsProcedureModifierOptions();
      actions.fetchDenialsReasonOptions();
      actions.fetchDenialsRemarkOptions();
    }
  }, [actions, isWidgetOpen]);

  useEffect(() => {
    if (
      !dropdownOptions?.payers?.isFetching &&
      selectedAppeal?.payerId &&
      dropdownOptions?.payers?.data?.length > 0 &&
      dropdownOptions?.reasonCodes?.data?.length > 0 &&
      !isDefaultPayerSet
    ) {
      const selectedPayer = dropdownOptions.payers.data.find(
        (option) => +option.value === +selectedAppeal.payerId
      );

      const reasonCodes = dropdownOptions.reasonCodes.data.find(
        (option) =>
          option.value === findMaxOccurrenceValue(selectedAppeal?.reasonCodes)
      );

      if (selectedPayer) {
        setFilters((prevFilters) => ({
          ...prevFilters,
          payer: selectedPayer,
          reasons: reasonCodes,
        }));
        setIsDefaultPayerSet(true);
      }
    }
  }, [
    dropdownOptions.payers,
    selectedAppeal,
    isDefaultPayerSet,
    dropdownOptions.reasonCodes.data,
  ]);

  const isPayerOptionsLoaded = useMemo(
    () =>
      dropdownOptions?.payers?.data.length > 0 &&
      !dropdownOptions?.payers.isFetching,
    [dropdownOptions?.payers]
  );

  const shouldFetchData = useMemo(
    () =>
      !isFetchingAppeal &&
      selectedAppeal &&
      isPayerOptionsLoaded &&
      filters.payer &&
      isDefaultPayerSet,
    [
      isFetchingAppeal,
      selectedAppeal,
      isPayerOptionsLoaded,
      filters.payer,
      isDefaultPayerSet,
    ]
  );

  const {
    claimsData,
    isLoading,
    error,
    refetch: refetchClaims,
  } = useClaimsData(filters, searchTerm, currentPage, shouldFetchData);

  /**
   * Handles closing the widget
   */
  const handleCloseClick = useCallback(() => {
    const ongoingSubmissions = Object.values(submissionStatus).some(
      (status) => status === STATUS_LOADING
    );
    if (ongoingSubmissions) {
      setShowCloseConfirmation(true);
    } else {
      actions.closeBatchUploadWidget();
    }
  }, [submissionStatus, actions]);

  /**
   * Toggles the accordion open/closed state
   */
  const handleAccordionToggle = useCallback(() => {
    setIsAccordionOpen((prev) => !prev);
  }, []);

  /**
   * Handles changes to the filters
   * @param {string} field - The filter field to update
   * @param {*} value - The new value for the filter
   */
  const handleFilterChange = useCallback((field, value) => {
    setCurrentPage(1);
    setFilters((prevFilters) => ({ ...prevFilters, [field]: value }));
  }, []);

  /**
   * Handles changes to the search term
   * @param {string} value - The new search term
   */
  const handleSearchChange = useCallback((value) => {
    setCurrentPage(1);
    setSearchTerm(value);
  }, []);

  /**
   * Updates a selected claim's data
   * @param {string} claimNumber - The claim number
   * @param {string} claimControlNumber - The claim control number
   * @param {Object} updateData - The data to update for the claim
   */
  const handleClaimUpdate = useCallback(
    (claimNumber, claimControlNumber, updateData) => {
      setSelectedClaims((prevClaims) =>
        prevClaims.map((claim) =>
          claim.claimNumber === claimNumber &&
          claim.claimControlNumber === claimControlNumber
            ? { ...claim, ...updateData }
            : claim
        )
      );
    },
    []
  );

  /**
   * Handles selecting or deselecting a claim
   * @param {Object} claim - The claim to select or deselect
   */
  const handleClaimSelect = useCallback((claim) => {
    setSelectedClaims((prevSelected) => {
      const isAlreadySelected = prevSelected.some(
        (sc) =>
          sc.claimNumber === claim.claimNumber &&
          sc.claimControlNumber === claim.claimControlNumber
      );

      if (prevSelected.length >= 10 && !isAlreadySelected) {
        toast.error({
          title: 'Error',
          message: 'You can only select up to 10 claims.',
        });
        return prevSelected;
      }

      const updatedSelectedClaims = isAlreadySelected
        ? prevSelected.filter(
            (sc) =>
              !(
                sc.claimNumber === claim.claimNumber &&
                sc.claimControlNumber === claim.claimControlNumber
              )
          )
        : [...prevSelected, claim];

      if (updatedSelectedClaims.length === 0) {
        setIsAccordionOpen(false);
      }

      return updatedSelectedClaims;
    });
  }, []);

  /**
   * Renders the popup title
   * @returns {JSX.Element} The rendered popup title
   */
  const renderPopupTitle = useCallback(() => {
    const dataTip = ReactDOMServer.renderToStaticMarkup(
      DataTipContent(selectedAppeal?.payerContact || {})
    );
    return (
      <div className="batch-submission-popup-title">
        <div>
          Batch Duplicate for{' '}
          {selectedAppeal?.isMedicalRecord ? 'Claim ID' : 'Payer Claim ID'}{' '}
          {selectedAppeal?.isMedicalRecord
            ? selectedAppeal?.claimId
            : selectedAppeal?.claimControlNumber}
        </div>
        <div className="batch-submission-popup-title__details">
          <div>Delivery Method: {selectedAppeal?.deliveryMethod}</div>
          <div
            className="batch-submission-popup-title__details__payer"
            data-tip={dataTip}
            data-for="payer-details-tooltip"
            data-html={true}
          >
            Payer Details: {selectedAppeal?.payerName}
          </div>
        </div>
        <ReactTooltip effect="solid" place="left" id="payer-details-tooltip" />
      </div>
    );
  }, [selectedAppeal]);

  /**
   * Handles changing the current page
   * @param {number} newPage - The new page number
   */
  const handlePageChange = useCallback((newPage) => {
    setCurrentPage(newPage);
  }, []);

  /**
   * Handles clicking the back button
   */
  const onBackButtonClick = useCallback(() => {
    setCurrentStep(1);
    refetchClaims();
  }, [refetchClaims]);

  if (!isWidgetOpen) return null;

  if (isFetchingAppeal) {
    return (
      <AppealioPopup
        className="appealio-popup--v3 batch-submission-widget-popup"
        titleClassName="appealio-popup--v3__title-wrapper"
        title="Batch Duplicate"
      >
        <LoadingIndicator showing />
      </AppealioPopup>
    );
  }

  const showCloseConfirmationPopup = () => {
    if (!showCloseConfirmation) return null;
    return (
      <ActionPopup
        title="In Progress Submissions"
        subtitle="The are still In progress claims being created. Are you sure you want to cancel?"
        actionButtons={[
          {
            title: 'Cancel',
          },
          {
            title: 'Close Anyway',
            type: TYPE_ACCEPT,
          },
        ]}
        onActionButtonPressed={(idx) => {
          if (idx === 1) {
            actions.closeBatchUploadWidget();
            setShowCloseConfirmation(false);
          } else {
            setShowCloseConfirmation(false);
          }
        }}
        icon={incompleteIcon}
      />
    );
  };
  return (
    <AppealioPopup
      className="appealio-popup--v3 batch-submission-widget-popup"
      titleClassName="appealio-popup--v3__title-wrapper"
      title={renderPopupTitle()}
      onClosePressed={handleCloseClick}
    >
      {currentStep === 1 && (
        <div className="batch-submission-step1">
          <ClaimAccordion
            isOpen={isAccordionOpen}
            onToggle={handleAccordionToggle}
            selectedClaims={selectedClaims}
            handleClaimSelect={handleClaimSelect}
          />
          <FilterSection
            filters={filters}
            searchTerm={searchTerm}
            onFilterChange={handleFilterChange}
            onSearchChange={handleSearchChange}
            dropdownOptions={dropdownOptions}
          />
          {isLoading ? (
            <LoadingIndicator showing />
          ) : error ? (
            <div>Error: {error}</div>
          ) : (
            <ClaimTable
              claims={claimsData.rows}
              selectedClaims={selectedClaims.map((sc) => ({
                claimNumber: sc.claimNumber,
                claimControlNumber: sc.claimControlNumber,
              }))}
              onClaimSelect={handleClaimSelect}
              currentPage={claimsData.page}
              totalPages={claimsData.pages}
              totalData={claimsData.total}
              onPageChange={handlePageChange}
            />
          )}
          <div className="batch-submission__footer justify-content-flex-end">
            <Button
              title="Continue"
              type="secondary"
              disabled={selectedClaims.length === 0}
              onClick={() => {
                setCurrentStep(2);
                setIsAccordionOpen(false);
              }}
            />
          </div>
        </div>
      )}

      {currentStep === 2 && (
        <div className="batch-submission-step2">
          <ClaimAccordion
            isOpen={isAccordionOpen}
            onToggle={handleAccordionToggle}
            selectedClaims={selectedClaims}
            isDisabled
          />
          <BatchSubmission
            appealIdToDuplicate={appealIdToDuplicate}
            selectedClaims={selectedClaims}
            selectedAppeal={selectedAppeal}
            onClaimUpdate={handleClaimUpdate}
            appealLetterTemplateOptions={priorSubmissionLetterTemplateOptions}
            appealFormTemplateOptions={priorSubmissionFormTemplateOptions}
            onBackButtonClick={onBackButtonClick}
            // Added props to maintain state of submitted claims
            submittedClaims={submittedClaims}
            setSubmittedClaims={setSubmittedClaims}
            submissionStatus={submissionStatus}
            setSubmissionStatus={setSubmissionStatus}
            bulkSubmissionStatus={bulkSubmissionStatus}
            setBulkSubmissionStatus={setBulkSubmissionStatus}
          />
        </div>
      )}

      {showCloseConfirmationPopup()}
    </AppealioPopup>
  );
};

const mapStateToProps = (state) => ({
  isWidgetOpen: getIsOpen(state),
  isWidgetMinimized: getIsMinimized(state),
  appealIdToDuplicate: getAppealIdToDuplicate(state),
  dropdownOptions: {
    payers: getDropdownOptions(
      state,
      DROPDOWN_OPTIONS_STATE_KEYS.PAYER_OPTIONS
    ),
    reasonCodes: getDropdownOptions(
      state,
      DROPDOWN_OPTIONS_STATE_KEYS.DENIALS_REASON_OPTIONS
    ),
    remarkCodes: getDropdownOptions(
      state,
      DROPDOWN_OPTIONS_STATE_KEYS.DENIALS_REMARK_OPTIONS
    ),
    cptCodes: getDropdownOptions(
      state,
      DROPDOWN_OPTIONS_STATE_KEYS.DENIALS_CPT_CODE_OPTIONS
    ),
    providers: getDropdownOptions(
      state,
      DROPDOWN_OPTIONS_STATE_KEYS.DENIALS_PROVIDER_OPTIONS
    ),
    procedureModifiers: getDropdownOptions(
      state,
      DROPDOWN_OPTIONS_STATE_KEYS.DENIALS_PROCEDURE_MODIFIER_OPTIONS
    ),
    practices: getDropdownOptions(
      state,
      DROPDOWN_OPTIONS_STATE_KEYS.DENIALS_PRACTICE_OPTIONS
    ),
  },
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      minimizeBatchUploadWidget,
      closeBatchUploadWidget,
      fetchPayerOptions,
      fetchDenialsPracticeOptions,
      fetchDenialsProviderOptions,
      fetchDenialsCptCodeOptions,
      fetchDenialsProcedureModifierOptions,
      fetchDenialsReasonOptions,
      fetchDenialsRemarkOptions,
    },
    dispatch
  ),
});

const MemoizedBatchSubmissionWidget = React.memo(
  BatchSubmissionWidget,
  (prevProps, nextProps) => {
    return (
      prevProps.isWidgetOpen === nextProps.isWidgetOpen &&
      prevProps.appealIdToDuplicate === nextProps.appealIdToDuplicate &&
      prevProps.dropdownOptions === nextProps.dropdownOptions
    );
  }
);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MemoizedBatchSubmissionWidget);
