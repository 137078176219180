import PropTypes from 'prop-types';
import { isEmpty, orderBy } from 'lodash';
import React, { useState, useRef, useMemo } from 'react';

import './style.css';

import Button from 'components/common/button';
import ImportRule from './ImportRule/ImportRule';
import { AlertDialog } from 'components/common/popup';
import AppealioPopup from 'components/Shared/AppealioPopup';
import LoadingIndicator from 'components/Shared/LoadingIndicator';

import appealCheck from 'img/appeal_check.svg';

import {
  STATUS,
  USER_ROLES,
  RULE_STATUSES_LABEL,
  RULE_STATUSES,
  DENIALS_FLYOVER_TITLE,
} from 'constants/appConstants';

import { createObjectMap, updateFilterOptions } from 'helpers/utils';

import { useFetchCodeOptionsCount, useImportRules } from './hooks';

const ImportRulePopup = (props) => {
  const {
    onClosePressed,
    clientPartitionId,
    openAddDenialContainer,
    dropdownOptions,
    userRole,
    userInfo,
  } = props;

  const rulesListEndRef = useRef(null);

  const [ruleNotificationDialog, setRuleNotificationDialog] = useState({
    isOpen: true,
    status: null,
    importRule: {},
    isCreate: true,
  });
  const [isImportRuleFormOpen, setIsImportRuleFormOpen] = useState(false);
  const { loading, importRules, setImportRules } = useImportRules();

  const {
    loading: isFetchingCodeOptionsCount,
    data: { reasonCodeCounts, remarkCodeCounts },
  } = useFetchCodeOptionsCount();

  const updateOptionsWithCount = useMemo(() => {
    if (isFetchingCodeOptionsCount) {
      return dropdownOptions;
    }

    const updatedReasonCodeCounts = updateFilterOptions(
      dropdownOptions.reasonCodes?.data,
      createObjectMap(reasonCodeCounts, 'reasonCode', 'count'),
      'value',
      'label'
    );
    const updatedRemarkCodesCounts = updateFilterOptions(
      dropdownOptions.remarkCodes?.data,
      createObjectMap(remarkCodeCounts, 'remarkCode', 'count'),
      'value',
      'label'
    );

    return {
      mappedReasonCodeCounts: updatedReasonCodeCounts,
      mappedRemarkCodeCounts: updatedRemarkCodesCounts,
    };
  }, [
    dropdownOptions,
    isFetchingCodeOptionsCount,
    reasonCodeCounts,
    remarkCodeCounts,
  ]);

  const dropdownOptionsWithCounts = {
    ...dropdownOptions,
    reasonCodes: {
      data:
        updateOptionsWithCount?.reasonCodes?.data ||
        updateOptionsWithCount?.mappedReasonCodeCounts,
    },
    remarkCodes: {
      data:
        updateOptionsWithCount?.remarkCodes?.data ||
        updateOptionsWithCount?.mappedRemarkCodeCounts,
    },
  };

  const onCancelClick = () => {
    const importRulesWithoutNewRule = importRules.filter((rule) => rule.id);
    setIsImportRuleFormOpen(false);
    return setImportRules([...importRulesWithoutNewRule]);
  };

  const onEditClick = () => {
    setIsImportRuleFormOpen(true);
  };

  const onFormSubmit = (data) => {
    let isNewImportRule = true;
    let isStatusChanged = false;
    setIsImportRuleFormOpen(false);

    const newImportRules = importRules.map((importRule) => {
      if (importRule.id === data.id) {
        if (importRule.status !== data.status) {
          isStatusChanged = true;
        }
        isNewImportRule = false;
        return data;
      }
      return importRule;
    });

    setImportRules(newImportRules);

    if (isNewImportRule) {
      const importRulesWithoutNewRule = importRules.filter((rule) => rule.id);

      setRuleNotificationDialog({
        isOpen: true,
        status: STATUS.SUCCESS,
        importRule: data,
        isCreate: true,
      });

      return setImportRules([data, ...importRulesWithoutNewRule]);
    }
    if (!isNewImportRule && !isStatusChanged) {
      const importRulesWithoutNewRule = importRules.map((rule) => {
        if (rule.id === data.id) {
          return data;
        }
        return rule;
      });

      setRuleNotificationDialog({
        isOpen: true,
        status: STATUS.SUCCESS,
        importRule: data,
        isCreate: false,
      });
      const resData = [
        ...new Map(
          [data, ...importRulesWithoutNewRule].map((item) => [item.id, item])
        ).values(),
      ];

      return setImportRules(resData);
    }

    setRuleNotificationDialog({
      isOpen: true,
      status: STATUS.UPDATE,
      importRule: data,
    });
  };

  const addNewImportRule = () => {
    const newImportRuleExists = importRules.find((rule) => !rule.id);
    if (newImportRuleExists) return;

    setImportRules([...importRules, {}]);
    setIsImportRuleFormOpen(true);

    if (rulesListEndRef.current) {
      setTimeout(
        () => rulesListEndRef.current.scrollIntoView({ behavior: 'smooth' }),
        200
      );
    }
  };

  const handleCloseDialog = () =>
    setRuleNotificationDialog({
      isOpen: false,
      status: null,
    });

  const redirectToDenialsClaimsQueue = () => {
    handleCloseDialog();
    onClosePressed();
    openAddDenialContainer();
  };

  const onRulesDelete = (id) => {
    setImportRules((prevImportRules) => {
      return prevImportRules.filter((importRule) => {
        return importRule.id !== id;
      });
    });
    setRuleNotificationDialog({
      isOpen: true,
      status: STATUS.DELETE,
      isCreate: false,
    });
  };

  const renderRuleNotificationDialog = () => {
    const status = ruleNotificationDialog.status;

    const notificationContent = {
      [STATUS.SUCCESS]: {
        getContent: () => (
          <React.Fragment>
            <p>
              Your rule{' '}
              <span className="fw-bold">
                {' '}
                {ruleNotificationDialog.importRule.name}{' '}
              </span>{' '}
              has been {ruleNotificationDialog.isCreate ? 'created' : 'updated'}{' '}
              and will be applied automatically to {DENIALS_FLYOVER_TITLE}{' '}
              beginning tonight at midnight PST.
            </p>
            <div>
              <button
                onClick={redirectToDenialsClaimsQueue}
                className="ap-button--primary-md"
              >
                {' '}
                Search {DENIALS_FLYOVER_TITLE}
              </button>
            </div>
          </React.Fragment>
        ),
      },
      [STATUS.UPDATE]: {
        getContent: () => (
          <React.Fragment>
            Rule{' '}
            <span className="fw-bold">
              {' '}
              {ruleNotificationDialog.importRule.name}
            </span>
            's status updated to{' '}
            <span className="fw-bold">
              {RULE_STATUSES_LABEL[ruleNotificationDialog.importRule.status]}
            </span>
            .
            {ruleNotificationDialog.importRule.status ===
              RULE_STATUSES.PAUSED && (
              <div>
                Within 24 hours,{' '}
                {ruleNotificationDialog.importRule.denialsCount} claims
                associated with a rule in the denied queue will be returned to
                the {DENIALS_FLYOVER_TITLE}.
              </div>
            )}
          </React.Fragment>
        ),
      },
      [STATUS.DELETE]: {
        getContent: () => (
          <React.Fragment>Rule has been deleted successfully.</React.Fragment>
        ),
      },
    };

    const { getContent, statusIcon = appealCheck } =
      notificationContent[status];

    return (
      <AlertDialog onClosePressed={handleCloseDialog} statusIcon={statusIcon}>
        {getContent()}
      </AlertDialog>
    );
  };

  /**
   * Sorts the importRules array based on specified criteria(status,isMarkedFavourite,updatedAt if available else createdAt ).
   *
   * @param {Array} importRules - The array to be sorted.
   * @returns {Array} - The sorted array.
   */
  const sortedImportRules = orderBy(
    importRules,
    [
      'status',
      'priority',
      (item) => new Date(item.updatedAt || item.createdAt),
    ],
    ['asc', 'asc', 'desc']
  );

  const handleSopChange = (sopId, ruleId) => {
    setImportRules((prevImportRules) => {
      return prevImportRules.map((importRule) => {
        if (importRule.id === ruleId) {
          return {
            ...importRule,
            sopId,
            sopUpdatedAt: sopId ? new Date().toISOString() : null,
          };
        }
        return importRule;
      });
    });
  };

  return (
    <AppealioPopup
      title="Automated Rules"
      className="import-rule-popup"
      onClosePressed={onClosePressed}
      datacy="import-rules-AppealioPopup"
    >
      {!(
        loading ||
        Object.values(dropdownOptionsWithCounts).some((x) => x?.isFetching)
      ) &&
        userRole === USER_ROLES.CLIENT_ADMIN && (
          <Button
            className="add-new-rule-button"
            onClick={addNewImportRule}
            datacy="add-new-rule-Button"
            disabled={isImportRuleFormOpen}
          >
            + Add New Rule
          </Button>
        )}
      <div className="table-header-container">
        <div className="row table-header">
          <div className="col-lg-1"></div>
          <div className="col-lg-1 col-width-15">Rule Name</div>
          <div className="col-lg-1 col-width-7">Practice</div>
          <div className="col-lg-1 col-width-7">Procedure</div>
          <div className="col-lg-1 col-width-7">Modifier</div>
          <div className="col-lg-1 col-width-7">Reason</div>
          <div className="col-lg-1 col-width-7">Remark</div>
          <div className="col-lg-1 col-width-7">Payer</div>
          <div className="col-lg-1">Agents</div>
          <div className="col-lg-1">Date Created</div>
          <div className="col-lg-1">Effective From</div>
        </div>
      </div>
      {loading || isEmpty(dropdownOptionsWithCounts.agents?.data) ? (
        <LoadingIndicator showing={true} />
      ) : (
        sortedImportRules.map((importRule, idx) => (
          <ImportRule
            key={`import-rule-${importRule.id || idx}`}
            sNo={idx + 1}
            importRule={importRule}
            dropdownOptions={dropdownOptionsWithCounts}
            onFormSubmit={onFormSubmit}
            onCancel={onCancelClick}
            onEditClick={onEditClick}
            existingRules={importRules}
            clientPartitionId={clientPartitionId}
            closeImportRulePopup={onClosePressed}
            openAddDenialContainer={openAddDenialContainer}
            canUpdateRule={userRole === USER_ROLES.CLIENT_ADMIN}
            datacy="import-rule-popup-ImportRule"
            onRulesDelete={onRulesDelete}
            isImportRuleFormOpen={isImportRuleFormOpen}
            userRole={userRole}
            userInfo={userInfo}
            handleSopChange={handleSopChange}
          />
        ))
      )}

      <div ref={rulesListEndRef}></div>

      {ruleNotificationDialog.isOpen &&
        ruleNotificationDialog.status &&
        renderRuleNotificationDialog()}
    </AppealioPopup>
  );
};

ImportRulePopup.propTypes = {
  onClosePressed: PropTypes.func.isRequired,
  filterOptions: PropTypes.shape({}),
};

export default ImportRulePopup;
