import moment from 'moment';
import { omit } from 'lodash';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

import Button from 'components/common/button';
import Avatar from 'components/Shared/Avatar';
import Dropdown from 'components/Shared/Dropdown';
import SearchBox from 'components/common/search/SearchBox';
import { BUTTON_TYPE } from 'components/common/button/Button';
import DownloadCSV from 'components/common/button/DownloadCSV';
import LoadingBlockHelper from 'components/Shared/LoadingBlockHelper';
import DeliveryTrackerPopupFilter from './DeliveryTrackerPopupFilter';
import TablePagination from 'components/common/pagination/TablePagination';

import settingsIcon from 'img/settings.svg';
import filterIconRed from 'img/filter-icon-red.svg';
import searchIcon from 'img/search-icon-white.svg';
import searchIconRed from 'img/search-icon-red.svg';

import { downloadDeliveryReport } from 'API/DeliveryLogsAPI';

import { isAppealioExpressUser } from 'Auth/AuthUtils';
import { getUserInfo } from 'redux/reducers/loginStore';

import { snakeCaseKeys } from 'helpers/object';
import { handleError } from 'helpers/errorHandler';
import { getPageCountMessage } from 'helpers/utils';
import { VALIDATION_DATE_FORMAT } from 'helpers/dateFormats';

import { useFetchDeliveryLogsOptions } from '../hooks';

import { METHOD_OPTIONS, STATUS_OPTIONS, TYPE_OPTIONS } from './constant';

import './style.css';

const DeliveryTrackerActionBar = (props) => {
  const {
    filters,
    onFilterChange,
    isResetActive,
    onResetClick,
    deliveryLogs,
    page,
    totalDeliveryLogs,
    userInfo,
    pageCount,
    onPageChange,
    defaultFilters,
    updateAgentsList,
  } = props;

  const [showSearchBox, setShowSearchBox] = useState(false);
  const [isExportingCSV, setIsExportingCSV] = useState(false);
  const isAuthorizedForStandAloneOnly = isAppealioExpressUser(userInfo);

  const { loading, options } = useFetchDeliveryLogsOptions(
    isAuthorizedForStandAloneOnly,
    userInfo
  );

  const [showPopupFilter, setShowPopupFilter] = useState(false);

  const userPractices = userInfo.relatedPractices || [];

  const handleFilterChange = (key, value) =>
    onFilterChange({
      ...props.filters,
      [key]: value,
    });

  const getSelectedValue = (options, value) =>
    options.find((option) => option.value === value) || null;

  const deliveryLogsCountOnPage = deliveryLogs.length;
  const deliveryLogsText =
    deliveryLogsCountOnPage === 0 || deliveryLogsCountOnPage > 1
      ? 'Submissions'
      : 'Submission';
  const deliveryLogsCountText =
    deliveryLogsCountOnPage > 0
      ? `${getPageCountMessage(
          page,
          deliveryLogsCountOnPage,
          totalDeliveryLogs
        )} ${deliveryLogsText}`
      : `${totalDeliveryLogs} ${deliveryLogsText}`;
  const submissionMethods = !isAuthorizedForStandAloneOnly
    ? METHOD_OPTIONS
    : METHOD_OPTIONS.filter(
        (method) => method.value !== 'direct' && method.value !== 'portal'
      );

  const onExportCSVPressed = async () => {
    setIsExportingCSV(true);
    const fileName = `${
      userInfo.clientName
    } Delivery Report - ${moment().format(VALIDATION_DATE_FORMAT)}.csv`;

    const queryParams = {
      ...snakeCaseKeys(filters),
    };

    try {
      await downloadDeliveryReport(queryParams, fileName, 'text/csv');
    } catch (err) {
      handleError(err);
    }
    setIsExportingCSV(false);
  };
  const appliedFiltersCount = Object.values(
    omit(filters, 'search', 'from_date')
  ).filter((item) => item).length;

  const isFiltersApplied = !Object.values(omit(filters, 'search')).every(
    (x) => x === null || x === '' || x === undefined || x === false
  );

  useEffect(() => {
    updateAgentsList(options?.agents);
  }, [updateAgentsList, options?.agents]);

  return (
    <>
      <div
        className={classnames(
          'appealio-table__action-bar appealio-table__action-bar--delivery-tracker row no-gutter'
        )}
      >
        <div className="d-flex align-items-center col-md-5">
          <Dropdown
            hideLabel
            options={STATUS_OPTIONS}
            className="width-190"
            placeholder="Delivery Status"
            value={getSelectedValue(STATUS_OPTIONS, filters.status)}
            onChange={(option) => handleFilterChange('status', option.value)}
          />
          <Dropdown
            hideLabel
            options={
              isAuthorizedForStandAloneOnly ? submissionMethods : METHOD_OPTIONS
            }
            placeholder="All Method"
            value={getSelectedValue(METHOD_OPTIONS, filters.method)}
            onChange={(option) => handleFilterChange('method', option.value)}
          />

          {!isAuthorizedForStandAloneOnly && (
            <Dropdown
              hideLabel
              options={TYPE_OPTIONS}
              placeholder="All Type"
              value={getSelectedValue(TYPE_OPTIONS, filters.types)}
              onChange={(option) => handleFilterChange('types', option.value)}
            />
          )}

          {isAuthorizedForStandAloneOnly && (
            <Dropdown
              hideLabel
              options={options.agents}
              placeholder="Users"
              className="mr-16"
              onChange={(option) =>
                handleFilterChange('agent_id', option.value)
              }
              isLoading={loading}
              value={getSelectedValue(options.agents, filters.agent_id)}
            />
          )}
        </div>
        <div className="d-flex align-items-center justify-content-flex-end col-md-2">
          <Button
            title=""
            icon={filters.search ? searchIconRed : searchIcon}
            className="mr-16"
            type={BUTTON_TYPE.SECONDARY}
            onClick={() => setShowSearchBox(!showSearchBox)}
            datacy="more-filters-search-Button"
          />

          <Button
            title=""
            icon={isFiltersApplied ? filterIconRed : settingsIcon}
            className="appealio-filter-button"
            type={BUTTON_TYPE.SECONDARY}
            onClick={() => setShowPopupFilter(true)}
            datacy="more-filters-search-Button"
          >
            {appliedFiltersCount ? (
              <Avatar
                withBorder={false}
                name={appliedFiltersCount.toString()}
                size={16}
                shouldRenderFullName={true}
                datacy="filters-count-avatar"
              />
            ) : null}
          </Button>
          {isResetActive && (
            <div onClick={onResetClick} className="denial-table__filter-clear">
              Reset
            </div>
          )}
        </div>
        {showSearchBox && (
          <SearchBox
            placeholder={
              isAuthorizedForStandAloneOnly
                ? 'Search by Tags'
                : 'Search by Name, ID or Tags'
            }
            value={filters.search}
            onSearch={(value) =>
              onFilterChange({
                ...props.filters,
                search: value,
              })
            }
          />
        )}
        <div className="appealio-table__action-bar-pagination col-md-5">
          {!showSearchBox && (
            <div className="delivery-tracking-page-actions-wrapper">
              <LoadingBlockHelper isLoading={loading}>
                <DownloadCSV
                  onClick={onExportCSVPressed}
                  disabled={totalDeliveryLogs.length < 1}
                  isLoading={isExportingCSV}
                />
              </LoadingBlockHelper>
            </div>
          )}
          {!showSearchBox && (
            <span className="denial-table-count">
              <span className="searchbar__counter">
                {deliveryLogsCountText}
              </span>
            </span>
          )}
          {totalDeliveryLogs && pageCount > 1 ? (
            <div className="d-flex justify-content-center">
              <TablePagination
                pageCount={pageCount}
                activePage={page}
                onPageChange={onPageChange}
                datacy="delivery-tracker-Pagination"
              />
            </div>
          ) : null}
        </div>
      </div>
      {showPopupFilter && (
        <DeliveryTrackerPopupFilter
          setShowPopupFilter={setShowPopupFilter}
          filters={filters}
          onFilterChange={onFilterChange}
          options={options}
          defaultFilters={defaultFilters}
          getSelectedValue={getSelectedValue}
          userPractices={userPractices}
          loading={loading}
          isAppealioExpressUser={isAuthorizedForStandAloneOnly}
        />
      )}
    </>
  );
};

DeliveryTrackerActionBar.propTypes = {
  filters: PropTypes.object,
  onFilterChange: PropTypes.func.isRequired,
  isResetActive: PropTypes.bool,
  onResetClick: PropTypes.func,
  deliveryLogs: PropTypes.array,
  page: PropTypes.number,
  totalDeliveryLogs: PropTypes.number,
  userInfo: PropTypes.object,
  pageCount: PropTypes.number,
  onPageChange: PropTypes.func,
  defaultFilters: PropTypes.object,
};

function mapStateToProps(state) {
  const userInfo = getUserInfo(state);
  return {
    userInfo,
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(DeliveryTrackerActionBar));
