import _ from 'lodash';
import classnames from 'classnames';
import { BiX } from 'react-icons/bi';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { BsPencil } from 'react-icons/bs';
import React, { useState, useEffect, useMemo } from 'react';

import * as toast from 'components/Shared/toast';
import Pagination from 'components/common/pagination';
import TextHighlighter from 'components/common/textHighlighter';
import { AppealioPopupWithFooter } from 'components/common/popup';
import LoadingBlockHelper from 'components/Shared/LoadingBlockHelper';
import ActionButton from 'components/common/actionButton/ActionButton';
import SearchWithOption from 'components/common/search/SearchWithOption/SearchWithOption';
import AddClinicForm from 'components/CreateAccount/CreateAppealioAccountDetails/AddClinic/AddClinicForm';

import emptyIcon from 'img/empty.svg';

import { handleError } from 'helpers/errorHandler';
import { attachCountsToArrayOfObjects } from '../utils';
import { SETTINGS_PAGE_LIMIT } from 'constants/appConstants';
import { SEARCH_QUERY_CHARACTERS_OFFSET } from '../constants';

import * as AccountsAPI from 'API/AccountSettingsAPI';
import {
  useFetchClinics,
  useFilterPractices,
  useFetchClinicCounts,
} from '../hook';

import { getUserInfo } from 'redux/reducers/loginStore';
import ChangeClinicStatusPopup from './changeClinicStatusPopup';
import { isClientAdmin } from 'Auth/AuthUtils';

const PRACTICE_OPTION = {
  label: 'Practice',
  value: 'PRACTICE',
  isDefault: true,
};

const CLINIC_OPTION = {
  label: 'Clinic',
  value: 'CLINIC',
};

const SEARCH_TYPES = [PRACTICE_OPTION, CLINIC_OPTION];

const Clinic = (props) => {
  const [page, setPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState({
    query: '',
    type: PRACTICE_OPTION.value,
  });
  const [clinicToUpdate, setClinicToUpdate] = useState(null);
  const [isClinicPopupOpen, setIsClinicPopupOpen] = useState(false);
  const [changeClinicStatusPopup, setChangeClinicStatusPopup] = useState({
    isOpen: false,
    clinic: null,
    toDeactivate: true,
  });
  const [selectedPractice, setSelectedPractice] = useState(
    props.userPractices[0]
  );

  const isPracticeSearch = searchQuery.type === PRACTICE_OPTION.value;
  const practiceSearchQuery =
    searchQuery.type === PRACTICE_OPTION.value ? searchQuery.query : '';
  const clinicSearchQuery =
    searchQuery.type === CLINIC_OPTION.value ? searchQuery.query : '';

  const { practices } = useFilterPractices(
    props.userPractices,
    practiceSearchQuery
  );
  const [listedPractices, setListedPractices] = useState(practices);

  const {
    loading,
    clinics = [],
    totalClinics,
    fetch,
  } = useFetchClinics(
    page,
    selectedPractice ? selectedPractice.id : null,
    clinicSearchQuery
  );

  const clinicsToRender =
    selectedPractice &&
    clinics.filter(({ practiceId }) => practiceId === selectedPractice.id);

  const handlePracticeClick = (practice) => setSelectedPractice(practice);

  const handleSubmit = async (values) => {
    const practiceId = selectedPractice.id;

    try {
      const requestPayload = !clinicToUpdate
        ? { ...values, practice_id: practiceId }
        : {
            ...clinicToUpdate,
            ...values,
            practice_id: practiceId,
          };

      if (!clinicToUpdate) {
        await AccountsAPI.createClinic(requestPayload);
      } else {
        await AccountsAPI.updateClinic(requestPayload);
      }
      toast.success({
        title: 'Success',
        message: !clinicToUpdate
          ? 'Clinic added successfully.'
          : 'Clinic updated successfully.',
      });
      setIsClinicPopupOpen(false);
    } catch (error) {
      handleError(error);
    }
    fetch();
  };

  const handleSearch = ({ query, type }) => {
    setSearchQuery({
      query,
      type,
    });
  };

  const { clinicCount } = useFetchClinicCounts(clinicSearchQuery);

  const filteredPractices = useMemo(() => {
    if (isPracticeSearch) {
      if (!searchQuery.query) {
        if (!selectedPractice?.id) {
          setSelectedPractice(practices[0]);
        }
        return practices;
      }

      const search = searchQuery.query.toUpperCase();
      return practices.filter((payer) =>
        payer.name.toUpperCase().includes(search)
      );
    }

    if (
      clinicCount?.length > 0 &&
      searchQuery.query.length >= SEARCH_QUERY_CHARACTERS_OFFSET
    ) {
      const practiceWithClinicCount = attachCountsToArrayOfObjects(
        practices,
        clinicCount,
        'practiceId',
        'clinicCount'
      ).filter((clinic) => clinic.clinicCount);

      const selectedHasClinics = practiceWithClinicCount.some(
        (payer) => payer.id === selectedPractice.id
      );

      if (
        (clinics.length < 1 &&
          practiceWithClinicCount.length > 0 &&
          !selectedHasClinics) ||
        practiceWithClinicCount === 1
      ) {
        setSelectedPractice(practiceWithClinicCount[0]);
      }

      return practiceWithClinicCount;
    }

    return practices;
  }, [
    practices,
    isPracticeSearch,
    clinics.length,
    clinicCount,
    searchQuery,
    selectedPractice,
  ]);

  useEffect(() => {
    setListedPractices(filteredPractices);
  }, [filteredPractices, searchQuery]);

  const onPageChange = (page) => setPage(page);
  const pageCount = Math.ceil(totalClinics / SETTINGS_PAGE_LIMIT);

  const resetChangeClinicStatusPopup = () => {
    setChangeClinicStatusPopup({
      isOpen: false,
      clinic: null,
      toDeactivate: false,
    });
  };

  const searchBoxPlaceholder = isPracticeSearch
    ? 'Search by Practice'
    : 'Search by Clinic';

  return (
    <div className="settings-content-wrapper" datacy="clinic">
      <p className="setting-content__description">
        All Clinics are organized by their respective Practice. To add a
        Practice, please go to the
        <span className="fw-bold"> Practices </span> tab.
      </p>
      <div
        className={classnames(
          'd-flex row align-items-center settings-content__top-action',
          {
            'justify-content--space-between': isClientAdmin(props.userInfo),
          }
        )}
      >
        <div className="col-lg-6">
          <SearchWithOption
            onSearch={handleSearch}
            options={SEARCH_TYPES}
            placeholder={searchBoxPlaceholder}
            handleReset={() => setSelectedPractice(practices[0])}
          />
        </div>
        {isClientAdmin(props.userInfo) && (
          <button
            onClick={() => {
              setClinicToUpdate(null);
              setIsClinicPopupOpen(true);
            }}
            className="ap-button ap-button--secondary ml-auto settings-add-button"
            datacy="clinic-settings-add-button"
          >
            <span className="mr-4">+</span> Add Clinic
          </button>
        )}
      </div>
      <div className="row d-flex mt-12">
        <div className="col-lg-3 border-right-grey settings-content__left-sidebar">
          {listedPractices?.length > 0 ? (
            listedPractices.map((practice, idx) => (
              <div
                key={idx}
                onClick={() => handlePracticeClick(practice)}
                className={classnames('create-account-list-item mb-8', {
                  'create-account-list-item--selected':
                    selectedPractice && selectedPractice.id === practice.id,
                  'd-flex justify-content--space-between align-items-center':
                    clinicCount?.length > 0,
                })}
              >
                <span> {practice.name}</span>
                {practice?.clinicCount && <span>({practice.clinicCount})</span>}
              </div>
            ))
          ) : (
            <p className="fw-bold"> No Practice found </p>
          )}
        </div>
        <div className="col-lg-9">
          {loading && loading ? (
            <LoadingBlockHelper
              className="dashboard-bottom-loader"
              isLoading={loading}
              datacy="clinic-LoadingBlockHelper"
            />
          ) : selectedPractice && clinicsToRender.length > 0 ? (
            <React.Fragment>
              <div className="row mb-16 create-acount-list-item-header">
                <div className="col-lg-2">Clinic Name</div>
                <div className="col-lg-2">Address Line 1</div>
                <div className="col-lg-2">Address Line 2</div>
                <div className="col-lg-2">City</div>
                <div className="col-lg-1">State</div>
                <div className="col-lg-1">Zip Code</div>
                <div className="col-lg-2">&nbsp;</div>
              </div>
              {clinicsToRender.map((clinic, idx) => (
                <div className="row mt-12 ap-card-list-wraper" key={idx}>
                  <div className="col-lg-12">
                    <div
                      className={classnames(
                        'create-account-list-item row mb-5',
                        {
                          'create-account-list-item--inactive':
                            !clinic.isActive,
                        }
                      )}
                    >
                      <div className="col-lg-2">
                        <span data-tip={clinic.name}>
                          {clinic.name &&
                          searchQuery.query?.length >=
                            SEARCH_QUERY_CHARACTERS_OFFSET ? (
                            <TextHighlighter
                              text={clinic.name}
                              query={searchQuery.query}
                            />
                          ) : (
                            <span>{clinic.name || '--'}</span>
                          )}
                        </span>
                      </div>
                      <div className="col-lg-2">
                        <span data-tip={clinic.address1}>
                          {clinic.address1 || '--'}
                        </span>
                      </div>
                      <div className="col-lg-2">
                        <span data-tip={clinic.address2}>
                          {clinic.address2 || '--'}
                        </span>
                      </div>
                      <div className="col-lg-2">
                        <span data-tip={clinic.city}>
                          {clinic.city || '--'}
                        </span>
                      </div>
                      <div className="col-lg-1">
                        <span data-tip={clinic.state}>
                          {clinic.state || '--'}
                        </span>
                      </div>
                      <div className="col-lg-1">
                        <span data-tip={clinic.zipcode}>
                          {clinic.zipcode || '--'}
                        </span>
                      </div>
                      {isClientAdmin(props.userInfo) && (
                        <div className="col-lg-2 d-flex justify-content-flex-end">
                          {clinic.isActive ? (
                            <div className="d-flex">
                              <ActionButton
                                Icon={BsPencil}
                                className="mr-16 ml-auto"
                                dataTip="Edit"
                                onClick={(e) => {
                                  setClinicToUpdate(clinic);
                                  setIsClinicPopupOpen(true);
                                }}
                                iconClassName="appeal__action--appeal"
                              />
                              <ActionButton
                                Icon={BiX}
                                className="mr-16 ap-button--action-delete"
                                dataTip="Deactivate"
                                onClick={(e) => {
                                  setChangeClinicStatusPopup({
                                    isOpen: true,
                                    clinic,
                                    toDeactivate: true,
                                  });
                                }}
                                iconClassName="appeal__action--appeal"
                              />
                            </div>
                          ) : (
                            <div>
                              <button
                                onClick={(e) => {
                                  setChangeClinicStatusPopup({
                                    isOpen: true,
                                    clinic,
                                    toDeactivate: false,
                                  });
                                }}
                                className="ap-button ap-button--secondary ml-auto settings-reactivate-button"
                              >
                                Reactivate
                              </button>
                            </div>
                          )}
                        </div>
                      )}
                      <ReactTooltip
                        effect="solid"
                        place="top"
                        multiline={true}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </React.Fragment>
          ) : (
            <div className="no-data-container">
              <img src={emptyIcon} alt="No Data Found" />
              <h4>No Data Found</h4>
            </div>
          )}
          {changeClinicStatusPopup.isOpen && (
            <ChangeClinicStatusPopup
              onClosePressed={resetChangeClinicStatusPopup}
              clinic={changeClinicStatusPopup.clinic}
              title={`Are you sure want to ${
                changeClinicStatusPopup.toDeactivate
                  ? 'deactivate'
                  : 'reactivate'
              } this clinic?`}
              onSubmitSuccess={() => {
                resetChangeClinicStatusPopup();
                fetch();
              }}
              toDeactivate={changeClinicStatusPopup.toDeactivate}
            />
          )}

          {isClinicPopupOpen && (
            <AppealioPopupWithFooter
              className="appealio-popup--v3"
              onClosePressed={() => setIsClinicPopupOpen(false)}
              title={`${clinicToUpdate ? 'Edit' : 'Add'} Clinic`}
              isFooterOutside={false}
              datacy="clinic-AppealioPopupWithFooter"
            >
              <AddClinicForm
                onSubmit={handleSubmit}
                practice={selectedPractice}
                initialValues={clinicToUpdate}
                isPopupForm={true}
                datacy="clinic-AddClinicForm"
                isAccountSettings={true}
              />
            </AppealioPopupWithFooter>
          )}

          {!loading && totalClinics && pageCount > 1 ? (
            <div className="d-flex justify-content-center">
              <Pagination
                pageCount={pageCount}
                activePage={page}
                onPageChange={onPageChange}
                datacy="clinic-Pagination"
              />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  const userInfo = getUserInfo(state);
  const userPractices = _.get(userInfo, 'relatedPractices', []);

  return {
    userInfo,
    userPractices,
  };
}

export default connect(mapStateToProps, {})(Clinic);
