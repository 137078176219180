import { parse } from 'qs';
import classnames from 'classnames';
import {
  isEqual,
  isInteger,
  isEmpty,
  get,
  xorWith,
  uniqWith,
  omit,
  orderBy,
  sortBy as _sortBy,
  filter,
  snakeCase,
} from 'lodash';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';
import { BiStopCircle } from 'react-icons/bi';
import { GrUserManager } from 'react-icons/gr';
import { MdOutlineEventNote } from 'react-icons/md';
import { useLocation, useHistory } from 'react-router-dom';
import React, { useState, useMemo, memo, useEffect, useCallback } from 'react';

import { useFetchActionList } from '../hooks/useFetchActionList';

import { generatePmNote } from './utils';
import { moneyFormatter } from 'helpers/money';
import { extractObjectMap } from 'helpers/object';
import {
  getPageCountMessage,
  isDateWithinPastDays,
  copyToClipboard,
} from 'helpers/utils';
import { calculateDateDifference, getFormattedDate } from 'helpers/dateFormats';

import { getUserInfo } from 'redux/reducers/loginStore';
import { setAppealData } from 'redux/actions/createAppealActions';

import NotesPopup from '../Notes';
import Avatar from 'components/Shared/Avatar';
import Button from 'components/common/button';
import * as toast from 'components/Shared/toast';
import Dropdown from 'components/Shared/Dropdown';
import AppealioTable from 'components/common/table';
import ReminderPopup from 'components/Shared/Reminder';
import SopInfoIcon from 'components/Shared/SopInfoIcon';
import ReminderIcon from 'components/Shared/ReminderIcon';
import ActionLogPopupFilter from './ActionLogPopupFilter';
import SearchBox from 'components/common/search/SearchBox';
import { BUTTON_TYPE } from 'components/common/button/Button';
import LockFilterButton from 'components/Shared/LockFilterButton';
import LoadingBlockHelper from 'components/Shared/LoadingBlockHelper';
import DownloadClaimsCsv from 'components/common/button/DownloadClaimsCSV';
import TablePagination from 'components/common/pagination/TablePagination';

import binIcon from 'img/bin-white.svg';
import chargerIcon from 'img/charger-1.svg';
import settingsIcon from 'img/settings.svg';
import completeIcon from 'img/complete.svg';
import noteIconBlack from 'img/note--black.svg';
import noteIconGreen from 'img/note--green.svg';
import searchIcon from 'img/search-icon-white.svg';
import searchIconRed from 'img/search-icon-red.svg';
import filterIconRed from 'img/filter-icon-red.svg';
import markAsCompletedIcon from 'img/arrow-right-x.svg';

import {
  ACTION_LOG_LOCK_FILTER_KEY_PENDING,
  ACTION_LOG_FILTER_VALUE_KEY_PENDING,
} from 'constants/localStorage';
import {
  TABLE_PAGE_LIMIT,
  ALL_AGENTS_OPTION_VALUE,
  EXPORT_CSV_MESSAGE,
} from 'constants/appConstants';
import { CREATE_APPEAL } from 'constants/routes';

import DenialsAPI from 'API/DenialsAPI';
import CreateAppealAPI from 'API/CreateAppealAPI';

import {
  useFetchDenialClaimsLogs,
  useFetchAndSetActionLogsCounts,
  applyGlobalFilterForActionLogs,
  FILTER_KEYS,
} from './hooks';

import { handleError } from 'helpers/errorHandler';
import { isUserAuthorizedForAutoArchiveDQ } from 'Auth/FeatureFlags';
import {
  DROPDOWN_OPTIONS_STATE_KEYS,
  getDropdownOptions,
} from 'redux/reducers/dropdownOptionsStore';

import { isSuperUserOrStaffUser, isClientAdmin } from 'Auth/AuthUtils';

import {
  getSopIds,
  hasSopIds,
  useFetchNotesCounts,
  useFetchSopAndRuleInfo,
} from 'components/Submissions/DeliveryTracking/hooks';
import { useRemindersData } from 'hooks/components/useRemindersData';
import { interpolate } from 'helpers/string';

export const DEFAULT_FILTERS = {
  payers: null,
  providers: null,
  agents: null,
  practices: null,
  search: '',
  removalReason: null,
  appliedRule: null,
  actionCompletedBy: null,
  actionAssignedBy: null,
  reminderStatus: null,
};

const DEFAULT_SORTBY = (isArchivedPage, logType) => ({
  id: !isArchivedPage
    ? logType.completed === COMPLETE_LOG
      ? 'actionCompletedAt'
      : 'actionCreatedAt'
    : 'updatedAt',
  desc: true,
});

export const FILTERS_LABEL = {
  payers: 'Payer',
  practices: 'Practice',
  agents: 'Assigned To',
  actionCompletedBy: 'Completed By',
  actionAssignedBy: 'Assigned By',
};

const COMPLETE_LOG = 1;
const INCOMPLETE_LOG = 0;

const DEFAULT_LOG_TYPE = {
  completed: INCOMPLETE_LOG,
};

export const appendAllOption = (options) => [
  { value: null, label: 'All' },
  ...options,
];

const parseQs = () => {
  return parse(window.location.search, {
    ignoreQueryPrefix: true,
  });
};

const generateActionLogStorageKey = (baseKey, userId, logType) => {
  const key = logType.completed
    ? baseKey.replace('_PENDING', '_COMPLETED')
    : baseKey;
  return interpolate(key, { userId });
};

const getLockKeyForActionLogFilters = (userId, logType) =>
  generateActionLogStorageKey(
    ACTION_LOG_LOCK_FILTER_KEY_PENDING,
    userId,
    logType
  );

const getValueKeyForActionLogFilters = (userId, logType) =>
  generateActionLogStorageKey(
    ACTION_LOG_FILTER_VALUE_KEY_PENDING,
    userId,
    logType
  );

const parseFilters = (userId, logType) => {
  const qs = parseQs();
  const storedFilters =
    JSON.parse(
      localStorage.getItem(getValueKeyForActionLogFilters(userId, logType))
    ) || {};
  const isFiltersLocked =
    localStorage.getItem(getLockKeyForActionLogFilters(userId, logType)) ===
    'true';
  if (isFiltersLocked) {
    return {
      ...DEFAULT_FILTERS,
      ...storedFilters,
      search: qs?.search || storedFilters.search || DEFAULT_FILTERS.search,
      reminderStatus:
        qs?.reminderStatus ||
        storedFilters.reminderStatus ||
        DEFAULT_FILTERS.reminderStatus,
      actionCompletedBy:
        qs?.actionCompletedBy ||
        storedFilters.actionCompletedBy ||
        DEFAULT_FILTERS.actionCompletedBy,
    };
  }

  return {
    ...DEFAULT_FILTERS,
    search: qs?.search || DEFAULT_FILTERS.search,
    reminderStatus: qs?.reminderStatus || DEFAULT_FILTERS.reminderStatus,
    actionCompletedBy:
      qs?.actionCompletedBy || DEFAULT_FILTERS.actionCompletedBy,
  };
};

const ACTION_LOG_COMMON_FILTERS = ['actionAssignedBy'];

const ActionLogs = memo((props) => {
  const location = useLocation();
  const history = useHistory();
  const [page, setPage] = useState(1);
  const [sortBy, setSortBy] = useState(
    DEFAULT_SORTBY(props.isArchivedPage, props?.logType || DEFAULT_LOG_TYPE)
  );
  const [filters, setFilters] = useState(
    parseFilters(props.userId, props.logType)
  );
  const [showFilters, setShowFilters] = useState(false);
  const [selectedClaims, setSelectedClaims] = useState([]);
  const [isExportingCSV, setIsExportingCSV] = useState(false);
  const [isFiltersLocked, setIsFiltersLocked] = useState(
    localStorage.getItem(
      getLockKeyForActionLogFilters(props.userId, props.logType)
    ) === 'true'
  );

  const [logType, setLogType] = useState(props?.logType || DEFAULT_LOG_TYPE);

  const toggleLock = useCallback(() => {
    const newLockState = !isFiltersLocked;
    setIsFiltersLocked(newLockState);
    localStorage.setItem(
      getLockKeyForActionLogFilters(props.userId, props.logType),
      newLockState.toString()
    );
    if (newLockState) {
      localStorage.setItem(
        getValueKeyForActionLogFilters(props.userId, props.logType),
        JSON.stringify(filters)
      );
    } else {
      localStorage.removeItem(
        getValueKeyForActionLogFilters(props.userId, props.logType)
      );
    }
  }, [isFiltersLocked, props.userId, props.logType, filters]);

  const {
    users,
    userInfo: { practice, secretKey, firstName, lastName },
    userPractices,
    globalFilters,
    updateActionLogPageCounts,
    updateDenialsTotalCount,
    isArchivedPage,
    userInfo,
    sourceFilters,
  } = props;

  const handleFilterChange = useCallback(
    (newFilters) => {
      setFilters(newFilters);
      setPage(1);
      if (isFiltersLocked) {
        localStorage.setItem(
          getValueKeyForActionLogFilters(props.userId, props.logType),
          JSON.stringify(newFilters)
        );
      }
    },
    [isFiltersLocked, props.userId, props.logType]
  );

  useEffect(() => {
    if (props?.logType) {
      if (!isEqual(props.logType, logType)) {
        setLogType(props.logType);
        handleFilterChange(parseFilters(props.userId, props.logType));

        setIsFiltersLocked(
          localStorage.getItem(
            getLockKeyForActionLogFilters(props.userId, props.logType)
          ) === 'true'
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.logType]);

  const [showSearchBox, setShowSearchBox] = useState(false);
  const [notesPopup, setNotesPopup] = useState({
    isOpen: false,
    claimId: null,
    denialId: null,
  });

  const [reminderPopup, setReminderPopup] = useState({
    isOpen: false,
    claimId: null,
    denialsQueueId: null,
    reminderId: null,
  });

  const { actionList } = useFetchActionList(practice, secretKey);

  const { loading, actionLogs, totalActionLogs, fetch, updatePmNoteStatus } =
    useFetchDenialClaimsLogs(
      sortBy,
      filters,
      page,
      practice,
      secretKey,
      userPractices,
      logType,
      globalFilters,
      props?.userInfo?.username,
      isArchivedPage,
      sourceFilters
    );

  const actionLogsIds = useMemo(
    () => actionLogs.map(({ id }) => Number(id)),
    [actionLogs]
  );

  const {
    notesCount,
    notesLatestCreatedTime,
    fetch: fetchNotesCount,
  } = useFetchNotesCounts(actionLogsIds, false);

  const { sopAndRuleInfo } = useFetchSopAndRuleInfo(actionLogsIds, false);
  const {
    remindersData,
    fetchReminders,
    loading: isFetchingReminder,
  } = useRemindersData(actionLogsIds, false);

  const shouldHideArchiveButton = useMemo(() => {
    if (isFetchingReminder) return true;

    return selectedClaims.some(({ id }) => {
      const hasReminder = get(remindersData, `${id}.reminderId`);
      return hasReminder;
    });
  }, [isFetchingReminder, remindersData, selectedClaims]);

  const { filterOptions, optionsLoading = false } = props;

  useEffect(() => {
    const { completed } = parseQs();
    const newLogType = { ...logType };

    if (completed !== undefined) {
      newLogType.completed =
        Number(completed) === 1 ? COMPLETE_LOG : INCOMPLETE_LOG;
    }

    if (logType.completed !== newLogType.completed) {
      setLogType({ completed: newLogType.completed });
    }

    const filters = parseFilters(props.userId, newLogType);
    setFilters(filters);

    const shouldUpdateCompletedCount = newLogType.completed === COMPLETE_LOG;
    const shouldUpdateTodoCount = newLogType.completed === INCOMPLETE_LOG;

    // Update count for current page
    updateActionLogPageCounts(
      filters,
      newLogType,
      shouldUpdateCompletedCount,
      shouldUpdateTodoCount
    );
    // Update count for other tab
    updateActionLogPageCounts(
      DEFAULT_FILTERS,
      { completed: Number(!newLogType.completed) },
      !shouldUpdateCompletedCount,
      !shouldUpdateTodoCount
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search, isArchivedPage, logType]);

  const { filterWithCountOptions } = useFetchAndSetActionLogsCounts(
    filters,
    logType,
    globalFilters,
    props?.userInfo?.username,
    isArchivedPage,
    sourceFilters
  );

  useEffect(() => {
    updateDenialsTotalCount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCloseNotesPopup = () => {
    setNotesPopup({
      isOpen: false,
      denialId: null,
      claimId: null,
    });
    fetchNotesCount();
  };

  const attachAgentsNameToActionLog = useCallback(
    (actionLog, appealioUsers) => {
      const agents = actionLog.agents || [];
      const userMapByUsername = extractObjectMap(
        appealioUsers,
        'username',
        'name'
      );

      if (!agents || !agents.length) {
        return {
          ...actionLog,
          agents: ['Unassigned'],
        };
      }

      let agentNames = [];
      if (agents.includes(ALL_AGENTS_OPTION_VALUE)) {
        const priorityAgent = filters.agents
          ? userMapByUsername[filters.agents[0]]
          : `${firstName} ${lastName}`;
        agentNames = [priorityAgent, 'All Other agents'];
      } else {
        const sortedAgentByFilterApplied = filters.agents
          ? _sortBy(agents, (username) =>
              username === filters.agents[0] ? 1 : 2
            )
          : agents;

        agentNames = sortedAgentByFilterApplied
          .map((agentUsername) => userMapByUsername[agentUsername])
          .filter((agentName) => agentName);
      }

      return {
        ...actionLog,
        agents: agentNames,
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const copyNoteToClipboard = useCallback((note) => {
    copyToClipboard(note);
    toast.success({
      title: 'Success',
      message: 'Note copied to clipboard.',
    });
  }, []);

  const handleNoteCopy = useCallback(
    async (note, denialId) => {
      copyNoteToClipboard(note);
      updatePmNoteStatus(denialId);
      try {
        await DenialsAPI.copyPmNote(denialId);
      } catch (error) {
        updatePmNoteStatus(denialId, true);
        handleError(error);
      }
    },
    [copyNoteToClipboard, updatePmNoteStatus]
  );

  const onPageChange = (page) => setPage(page);
  const totalCount =
    totalActionLogs[logType.completed === COMPLETE_LOG ? 'completed' : 'todo'];

  const pageCount = Math.ceil(totalCount / TABLE_PAGE_LIMIT);

  const handleMarkAsDone = useCallback(
    async (claimId) => {
      const filteredSelectedClaims = selectedClaims.filter(
        (item) => item.appealId === null
      );
      try {
        const ids = claimId
          ? [claimId]
          : filteredSelectedClaims &&
            filteredSelectedClaims.map(({ id }) => id);

        await DenialsAPI.completeActionLogs(ids);
        setSelectedClaims([]);
        if (page === 1) {
          fetch();
        } else {
          setPage(1);
        }
        updateActionLogPageCounts(filters, logType, true);
        toast.success({
          title: 'Success',
          message: 'Action completed successfully.',
        });
      } catch (error) {
        handleError(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedClaims, page, fetch]
  );

  const onPendingActionRowClick = useCallback(
    async (appealId) => {
      if (!appealId) {
        return;
      }
      try {
        const appeal = await CreateAppealAPI.getAppealById(appealId);
        props.actions.setAppealData({
          appeals: [
            {
              ...appeal,
              step: appeal.step || 0,
            },
          ],
          name: appeal.patientName,
        });

        setTimeout(() => props.actions.push(CREATE_APPEAL), 100);
      } catch (error) {
        handleError(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleArchive = useCallback(async () => {
    const ids = selectedClaims && selectedClaims.map(({ id }) => id);
    try {
      await DenialsAPI.archiveDeniedClaim(ids);
      await fetch();
      updateDenialsTotalCount();
      setSelectedClaims([]);
      updateActionLogPageCounts(filters, logType, true);

      toast.success({
        title: 'Success',
        message: 'Claim(s) archived successfully.',
      });
    } catch (error) {
      handleError(error);
    }
  }, [
    fetch,
    filters,
    logType,
    selectedClaims,
    updateActionLogPageCounts,
    updateDenialsTotalCount,
  ]);

  const handleUnArchive = useCallback(async () => {
    const ids = selectedClaims && selectedClaims.map(({ id }) => id);
    try {
      await DenialsAPI.unArchiveDeniedClaim(ids);
      await fetch();
      updateDenialsTotalCount();
      setSelectedClaims([]);
      updateActionLogPageCounts(filters, logType, true);

      const message =
        logType.completed === INCOMPLETE_LOG
          ? `Claim(s) moved to Active Pending Actions`
          : `Claim(s) moved to Active Completed Actions`;
      toast.success({
        title: 'Success',
        message,
      });
    } catch (error) {
      handleError(error);
    }
  }, [
    fetch,
    filters,
    logType,
    selectedClaims,
    updateActionLogPageCounts,
    updateDenialsTotalCount,
  ]);

  const notesQueryParams = {
    ...logType,
    action: 1,
    archive: isArchivedPage ? 1 : 0,
  };

  const BuildArrayCell = useCallback(({ value: array, isLink = false }) => {
    if (isEmpty(array)) {
      const handleClick = (e) => {
        if (!isLink) return;

        e.preventDefault();
        e.stopPropagation();
      };

      return (
        <div onClick={handleClick} className="appealio-table__custom-cell">
          <span data-tip="N/A">N/A</span>
        </div>
      );
    }

    const firstArrayItem = array[0];
    const arrayLength = array.length;

    const dataToRender =
      arrayLength === 1
        ? firstArrayItem
        : `${firstArrayItem} +${arrayLength - 1}`;

    const dataTip = array.length === 1 ? array : array.join(', ');

    return (
      <div
        className={classnames('appealio-table__custom-cell', {
          'appealio-table__custom-cell--is-link': isLink,
        })}
      >
        <span data-for="array-tooltip" data-tip={dataTip}>
          {dataToRender}
        </span>
        <ReactTooltip id="array-tooltip" effect="solid" place="top" />
      </div>
    );
  }, []);

  const BuildDateArrayCell = useCallback(({ value: array }) => {
    const formattedDate = array?.map((date) => getFormattedDate(date));
    return <BuildArrayCell value={formattedDate} />;
  }, []);

  const BuildProviderCell = useCallback((props) => {
    const data = props.row.original;
    const providerName = data.providerName;
    const practiceName = data.practiceName || '';

    return (
      <div className={'appealio-table__custom-cell'}>
        <span data-tip={providerName} data-for="practice-provider-tooltip">
          {providerName || 'N/A'}
        </span>
        <br />
        {practiceName && (
          <span data-tip={practiceName} data-for="practice-provider-tooltip">
            ({practiceName || 'N/A'})
          </span>
        )}
        <ReactTooltip
          id="practice-provider-tooltip"
          className="interactive-tooltip"
          delayHide={600}
          effect="solid"
          place="left"
        />
      </div>
    );
  }, []);

  const BuildPatientCell = useCallback(
    (props) => {
      const data = props.row.original;
      const sopAndRuleInfo = props.sopAndRuleInfo;
      const sopIds = getSopIds(sopAndRuleInfo, data.id);
      const includesSops = hasSopIds(sopAndRuleInfo, data.id);
      const { patientName, patientNumber, appliedRule, manual } = data;

      return (
        <div
          className={classnames(
            'appealio-table__custom-cell d-flex align-items-center justify-content--space-between',
            {
              'blur-on-td-hover': !isArchivedPage && !includesSops,
            }
          )}
        >
          <div className="appealio-table__custom-cell">
            <span data-tip={patientName} className="table-span--fixed-width">
              {patientName}
            </span>
            <br />
            {patientNumber && (
              <span data-tip={patientNumber}>({patientNumber})</span>
            )}
          </div>
          {appliedRule && (
            <React.Fragment>
              {includesSops ? (
                <SopInfoIcon appliedRule={appliedRule} sopIds={sopIds} />
              ) : (
                <>
                  <img
                    src={chargerIcon}
                    data-for="rule-name-tooltip"
                    alt="applied rule"
                    data-tip={`Applied Rule: ${appliedRule}`}
                    className="table-rule-icon table-rule-icon--custom-height"
                  />
                  <ReactTooltip
                    effect="solid"
                    place="top"
                    id="rule-name-tooltip"
                    className="sop-info-tooltip-background sop-info-tooltip-background--width-auto"
                  />
                </>
              )}
            </React.Fragment>
          )}
          {manual && (
            <GrUserManager
              data-tip="Manual Appeal"
              className="ingested-appeal-indicator"
            />
          )}
        </div>
      );
    },
    [isArchivedPage]
  );

  const BuildCreatedByCell = useCallback(({ value }) => {
    if (!value) {
      return <span>N/A</span>;
    }

    const { firstName, lastName } = value;
    const fullName = `${firstName} ${lastName}`;

    return (
      <div className={'appealio-table__custom-cell'}>
        <span data-tip={fullName}>{fullName}</span>
        <ReactTooltip effect="solid" className="interactive-tooltip" />
      </div>
    );
  }, []);

  const BuildAmountCell = useCallback(({ value: amount }) => {
    if (amount === null || undefined) {
      return (
        <div className="appealio-table__custom-cell">
          <span data-tip="N/A">N/A</span>
        </div>
      );
    }
    const formattedAmount = isInteger(amount)
      ? amount
      : parseFloat(amount).toFixed(2);
    const formattedText = moneyFormatter().format(formattedAmount);

    return (
      <div className="appealio-table__custom-cell" data-tip={formattedText}>
        {formattedText}
      </div>
    );
  }, []);

  const DeadlineEobDateCellHeader = () => {
    return (
      <div>
        Until Deadline
        <br />
        (EOB Date)
      </div>
    );
  };

  const BuildDeadlineEobDateCell = ({ row }) => {
    const data = row.original;
    const { ediPaymentDate, appealDeadline } = data;

    const eobDate = ediPaymentDate ? getFormattedDate(ediPaymentDate) : null;
    const deadline = appealDeadline ? getFormattedDate(appealDeadline) : null;
    const daysUntilDeadline =
      eobDate && deadline
        ? calculateDateDifference(getFormattedDate(new Date()), deadline)
        : null;
    const isDeadlinePassed = daysUntilDeadline <= 0;

    return (
      <div className={'appealio-table__custom-cell'}>
        <span
          {...(!deadline ? {} : { 'data-tip': deadline })}
          data-for="eob-date-tooltip"
        >
          {daysUntilDeadline
            ? `${isDeadlinePassed ? 0 : daysUntilDeadline} Day(s)`
            : 'N/A'}
        </span>
        <br />
        {eobDate && (
          <span
            {...(!eobDate ? {} : { 'data-tip': eobDate })}
            data-for="eob-date-tooltip"
          >
            ({eobDate || 'N/A'})
          </span>
        )}
        <ReactTooltip
          id="eob-date-tooltip"
          className="interactive-tooltip"
          delayHide={600}
          effect="solid"
          place="left"
        />
      </div>
    );
  };

  const CustomCell = useCallback(({ value, delayHide = null }) => {
    const isEmptyString = !!(value === '--' || isEmpty(value));
    const cellValue = isEmptyString ? 'N/A' : value;
    const toolTip = isEmptyString ? '' : value;

    return (
      <div className="appealio-table__custom-cell">
        <span data-tip={toolTip} data-delay-hide={delayHide}>
          {cellValue}
        </span>
        <ReactTooltip effect="solid" className="interactive-tooltip" />
      </div>
    );
  }, []);

  const claimCell = useCallback((props) => {
    const { value } = props;
    const isEmptyString = value === '--' || isEmpty(value);
    const cellValue = isEmptyString ? 'N/A' : value;
    const toolTip = isEmptyString ? '' : value;
    const randomId = Math.random().toString(36).slice(2, 9);

    return (
      <div
        className="appealio-table__custom-cell"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div data-tip={toolTip} data-for={randomId} data-delay-hide={400}>
          {cellValue}
        </div>
        <ReactTooltip
          effect="solid"
          className="interactive-tooltip"
          id={randomId}
        />
      </div>
    );
  }, []);

  const NoteCell = useCallback(
    (props) => {
      const claimId = props?.row?.original?.claim;
      const denialId = props?.row?.original?.id;
      const count = get(notesCount, denialId);
      const latestCreatedTime = get(notesLatestCreatedTime, denialId);
      const isNotesRecent = isDateWithinPastDays(latestCreatedTime, 7);

      return (
        <div className="appealio-table__custom-cell">
          <div
            className={classnames('custom-badge', {
              'custom-badge--border-0': !count,
              'custom-badge--primary': isNotesRecent,
            })}
            onClick={() => {
              setNotesPopup({
                isOpen: true,
                claimId,
                denialId,
              });
            }}
          >
            <MdOutlineEventNote
              size="18"
              className={classnames('cursor-pointer custom-badge__icon')}
            />
            <span> {count > 0 && count} </span>
          </div>
        </div>
      );
    },
    [notesCount, notesLatestCreatedTime]
  );

  const ReminderCell = useCallback(
    (props) => {
      const remindersData = props.remindersData;
      const isMedicalRecord = props?.row?.original?.isMedicalRecord;
      const claimId = isMedicalRecord
        ? props?.row?.original?.claim
        : props?.row?.original?.claimControlNumber;
      const id = props?.row?.original?.id;
      const reminderId = remindersData?.[id]?.reminderId;
      const denialsQueueId = remindersData?.[id]?.denialsQueueId;

      const handleClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setReminderPopup({
          isOpen: true,
          denialsQueueId,
          claimId,
          reminderId,
        });
      };
      return (
        <ReminderIcon
          remindersData={remindersData}
          id={denialsQueueId}
          claimId={claimId}
          handleClick={handleClick}
          isClientAdmin={isClientAdmin(userInfo)}
          userInfo={userInfo}
        />
      );
    },
    [userInfo]
  );

  const PayerCell = useCallback((props) => {
    const { appealsPayerName, payerName } = props.row.original;
    const value = appealsPayerName || payerName;

    return <CustomCell value={value} />;
  }, []);

  const BuildDateCell = useCallback(({ value: date }) => {
    let formattedDate;
    if (date === '--' || !date) {
      formattedDate = 'N/A';
    } else {
      formattedDate = getFormattedDate(date, 'll');
    }

    const createdDate = formattedDate.split(',')[0];

    return (
      <div className="appealio-table__custom-cell">
        <span data-tip={formattedDate}>{createdDate}</span>
      </div>
    );
  }, []);

  const SelectCell = useCallback(
    (props) => {
      const rowData = props.row.original;
      const { description, mappedAppealId } = rowData;
      const handleToggleClaimSelection = (rowData) => {
        const newSelectedClaimInfos = xorWith(
          selectedClaims,
          [
            {
              id: rowData.id,
              claim: rowData.claim,
              claimControlNumber: rowData.claimControlNumber,
              appealId: rowData.mappedAppealId,
            },
          ],
          isEqual
        );

        setSelectedClaims(newSelectedClaimInfos);
      };

      const isChecked = selectedClaims.find(
        ({ claim, claimControlNumber }) =>
          rowData.claim === claim &&
          rowData.claimControlNumber === claimControlNumber
      );

      return (
        <div className="appealio-table-checkbox-action">
          {(isUserAuthorizedForAutoArchiveDQ(userInfo) ||
            logType.completed === INCOMPLETE_LOG) && (
            <input
              onClick={(e) => e.stopPropagation()}
              onChange={() => {
                handleToggleClaimSelection(rowData);
              }}
              checked={!mappedAppealId && isChecked}
              disabled={mappedAppealId}
              type="checkbox"
              className="denials-queue-td-checkbox"
            />
          )}
          {logType.completed === INCOMPLETE_LOG &&
            !isArchivedPage &&
            !mappedAppealId && (
              <button
                className="mark-complete-btn"
                onClick={() => handleMarkAsDone(rowData.id)}
              >
                <img src={markAsCompletedIcon} alt="Mark as Completed" />
                <span>Mark as Completed</span>
              </button>
            )}
          {logType.completed === INCOMPLETE_LOG && mappedAppealId && (
            <button
              className="mark-complete-btn"
              onClick={() => onPendingActionRowClick(rowData.mappedAppealId)}
            >
              <BiStopCircle size="18" />
              <span>Complete {description}</span>
            </button>
          )}
        </div>
      );
    },
    [
      handleMarkAsDone,
      isArchivedPage,
      logType.completed,
      selectedClaims,
      userInfo,
      onPendingActionRowClick,
    ]
  );

  const ActionWithNoteCell = useCallback(
    (props) => {
      const rowData = props.row.original;
      const pmNoteData = rowData?.pmNote;
      if (!pmNoteData)
        return (
          <div className="d-flex align-items-center">
            <span className="pm-note-wrapper"></span>
            <CustomCell {...props} />
          </div>
        );
      const { pmNoteCopiedAt } = pmNoteData;

      const pmNote = generatePmNote(pmNoteData);

      const handleNoteClick = () => {
        pmNoteCopiedAt
          ? copyNoteToClipboard(pmNote)
          : handleNoteCopy(pmNote, rowData.id);
      };

      return (
        <div className="d-flex align-items-center">
          <div className="pm-note-wrapper">
            {pmNote && (
              <img
                onClick={handleNoteClick}
                className="cursor-pointer"
                src={!pmNoteCopiedAt ? noteIconGreen : noteIconBlack}
                alt="PM note"
                data-tip={pmNote}
                data-for={`pm-note-tooltip-${rowData.id}`}
              />
            )}
            <ReactTooltip
              effect="solid"
              id={`pm-note-tooltip-${rowData.id}`}
              className="ap-info-tooltip-background ap-info-tooltip-background--width-auto ap-info-tooltip-background--mw-800 ap-info-tooltip"
              html={true}
            />
          </div>
          <CustomCell {...props} />
        </div>
      );
    },
    [handleNoteCopy, copyNoteToClipboard]
  );

  const isCompletedLogTab = logType.completed === COMPLETE_LOG;

  const columns = useMemo(
    () =>
      [
        {
          Header: '',
          accessor: 'select',
          Cell: SelectCell,
          width: 35,
        },
        {
          Header: () => (
            <div
              className={classnames({
                'ml-30': isCompletedLogTab,
              })}
            >
              Action
            </div>
          ),
          accessor: 'description',
          className: '',
          width: isCompletedLogTab ? 120 : 110,
          Cell: isCompletedLogTab ? ActionWithNoteCell : CustomCell,
          disableSortBy: true,
        },
        {
          Header: 'Patient',
          accessor: 'patientName',
          className: '',
          width: 145,
          sortKey: 'patientName',
          Cell: (props) => (
            <BuildPatientCell {...props} sopAndRuleInfo={sopAndRuleInfo} />
          ),
        },
        {
          Header: '',
          accessor: 'isNotesRecent',
          width: 55,
          sortKey: '',
          disableSortBy: true,
          Cell: NoteCell,
          className: 'pr-0',
        },
        {
          Header: '',
          accessor: 'setReminder',
          width: 45,
          sortKey: '',
          disableSortBy: true,
          Cell: (props) => (
            <ReminderCell {...props} remindersData={remindersData} />
          ),
          className: 'pr-0',
        },
        {
          Header: 'Claim ID',
          accessor: 'claim',
          width: 70,
          sortKey: 'claim',
          Cell: claimCell,
          className: 'pl-0',
        },
        {
          Header: 'Payer Claim ID',
          accessor: 'claimControlNumber',
          width: isCompletedLogTab ? 120 : 104,
          sortKey: 'claimControlNumber',
          Cell: claimCell,
          className: 'pl-0',
        },
        {
          Header: 'Service Date',
          accessor: 'serviceDate',
          width: 105,
          sortKey: 'serviceDate',
          disableSortBy: true,
          Cell: BuildDateArrayCell,
        },
        {
          Header: 'Billed Amt',
          accessor: 'billedAmount',
          width: 85,
          sortKey: 'billedAmount',
          Cell: BuildAmountCell,
        },
        {
          Header: 'Payer',
          accessor: 'payerName',
          width: 80,
          sortKey: 'payerName',
          Cell: PayerCell,
        },
        {
          Header: () => (
            <div>
              Provider
              <br />
              (Practice)
            </div>
          ),
          accessor: 'providerName',
          width: isCompletedLogTab ? 130 : 90,
          sortKey: 'providerName',
          Cell: BuildProviderCell,
        },
        {
          Header: 'Assigned By',
          accessor: 'createdBy',
          className: '',
          width: isCompletedLogTab ? 90 : 98,
          sortKey: 'actionCreatedBy',
          Cell: BuildCreatedByCell,
        },
        {
          Header:
            logType.completed === INCOMPLETE_LOG
              ? 'Assigned To'
              : 'Completed By',
          accessor:
            logType.completed === INCOMPLETE_LOG
              ? 'agents'
              : 'actionCompletedBy',
          className: '',
          width: logType.completed === INCOMPLETE_LOG ? 98 : 115,
          sortKey:
            logType.completed === INCOMPLETE_LOG
              ? 'agents'
              : 'actionCompletedBy',
          disableSortBy: logType.completed === INCOMPLETE_LOG,
          Cell:
            logType.completed === INCOMPLETE_LOG ? BuildArrayCell : CustomCell,
        },
        {
          Header: DeadlineEobDateCellHeader,
          accessor: 'deadline',
          width: 110,
          sortKey: '',
          disableSortBy: true,
          Cell: BuildDeadlineEobDateCell,
        },
        {
          Header: !isArchivedPage
            ? isCompletedLogTab
              ? 'Date Completed'
              : 'Date Assigned'
            : 'Date Archived',
          accessor: !isArchivedPage
            ? isCompletedLogTab
              ? 'actionCompletedAt'
              : 'createdAt'
            : 'updatedAt',
          className: 'appealio-table-cell__border--left',
          width: isCompletedLogTab ? 110 : 90,
          sortKey: !isArchivedPage
            ? isCompletedLogTab
              ? 'actionCompletedAt'
              : 'actionCreatedAt'
            : 'updatedAt',
          Cell: BuildDateCell,
        },
      ].filter((column) => {
        if (isArchivedPage && column.accessor === 'setReminder') {
          return false;
        }
        if (column.accessor === 'deadline' && isCompletedLogTab) {
          return false;
        }

        if (column.accessor === 'setReminder' && !isCompletedLogTab) {
          return false;
        }

        return true;
      }),
    [
      SelectCell,
      CustomCell,
      NoteCell,
      claimCell,
      BuildArrayCell,
      BuildAmountCell,
      PayerCell,
      BuildProviderCell,
      BuildCreatedByCell,
      logType.completed,
      isArchivedPage,
      BuildDateCell,
      BuildDateArrayCell,
      sopAndRuleInfo,
      isCompletedLogTab,
      ActionWithNoteCell,
      remindersData,
    ]
  );

  const onTableSort = (sortBy) => setSortBy(sortBy);

  const handleSearchSubmit = (search) => {
    setFilters({
      ...DEFAULT_FILTERS,
      search,
    });
  };

  const isResetButtonVisible = !Object.values(filters).every(
    (x) => x === null || x === '' || x === undefined
  );

  const claimsCountOnPage = actionLogs ? actionLogs.length : 0;

  const claimsText =
    claimsCountOnPage === 0 || claimsCountOnPage > 1 ? 'Claims' : 'Claim';
  const claimsCountText =
    claimsCountOnPage > 0
      ? `${getPageCountMessage(
          page,
          claimsCountOnPage,
          totalCount
        )} ${claimsText}`
      : `${totalCount} ${claimsText}`;

  const removalReasonSelectionOption =
    actionList.find((option) => option.value === filters.removalReason) || null;

  const dropDownFilters = [
    ...Object.keys(filterOptions).filter((filter) =>
      Object.keys(FILTERS_LABEL).includes(filter)
    ),
    ...ACTION_LOG_COMMON_FILTERS,
  ];

  const isSomeClaimsSelected = selectedClaims.length > 0;

  const mapToSelectedClaimInfo = (claim) => ({
    id: claim.id,
    claim: claim.claim,
    claimControlNumber: claim.claimControlNumber || null,
    appealId: claim.mappedAppealId,
  });

  const extractUniqueClaimInfos = (denials) =>
    uniqWith(denials.map(mapToSelectedClaimInfo), isEqual);

  const isAllClaimsSelected = () => {
    const uniqueClaimInfos = extractUniqueClaimInfos(actionLogs);
    return (
      selectedClaims.length > 0 &&
      uniqueClaimInfos.length === selectedClaims.length
    );
  };

  const isAllSelected = isAllClaimsSelected();
  const checkboxTitleBulkAction =
    !isAllSelected && !isSomeClaimsSelected ? 'Select All' : 'Clear All';

  const onSelectAllLogsClicked = () => {
    const isSomeClaimsSelected = selectedClaims.length > 0;
    const uniqueClaimInfos = extractUniqueClaimInfos(actionLogs);
    const selectAllClaims = !isAllClaimsSelected() && !isSomeClaimsSelected;
    const newSelectedClaimInfos = selectAllClaims ? uniqueClaimInfos : [];
    setSelectedClaims(newSelectedClaimInfos);
  };

  const appliedFiltersCount = Object.values(omit(filters, 'search')).filter(
    (item) => item
  ).length;
  const isFiltersApplied = !Object.values(omit(filters, 'search')).every(
    (x) => x === null || x === '' || x === undefined
  );

  /**
   * Add counts in filters for action logs
   * @param {*} filterOptions
   * @param {*} meta
   * @returns object
   */
  const addCountToActionLogsFilterOptions = (filterOptions, meta) => {
    if (optionsLoading) {
      return filterOptions;
    }

    const {
      payerCounts = [],
      practicesCounts = [],
      agentsCounts = [],
      ruleCounts = [],
      actionCompletedByCounts = [],
      actionAssignedByCounts = [],
    } = meta;

    const extractLabelSuffix = (countValue) => {
      if (!countValue || countValue === 'undefined') return '(0)';

      return `(${countValue})`;
    };

    const extractOptionWithCount = (option, countMap) => {
      if (!option.value) return option;
      return {
        ...option,
        label: `${option.label} ${extractLabelSuffix(countMap[option.value])}`,
        count: countMap[option.value],
      };
    };

    const extractObjectMap = (array, objectKey) =>
      array.reduce((acc, cur) => {
        const key = cur[objectKey];

        return {
          ...acc,
          [key]: cur.count,
        };
      }, {});

    const extractLabelSuffixForAgent = (countValue, allCountData) => {
      return `(${countValue + allCountData})`;
    };

    const extractOptionWithCountFromAgents = (option, countMap) => {
      if (option.label.toLowerCase() === 'all') {
        return option;
      }
      const allCountData = countMap?._all_ || 0;
      const count = countMap[option.value];
      return {
        ...option,
        label: `${option.label} ${extractLabelSuffixForAgent(
          countMap[option.value] || 0,
          allCountData
        )}`,
        count: count + allCountData,
      };
    };

    // payers
    const payersCountMap = extractObjectMap(payerCounts, 'payerLookupId');
    const payersWithCount = filterOptions.payers?.data.map((option) =>
      extractOptionWithCount(option, payersCountMap)
    );

    // practices
    const practiceIdentifiersCountMap = extractObjectMap(
      practicesCounts,
      'practiceIdentifier'
    );
    const practiceIdentifierCountsWithCount =
      filterOptions.practices?.data?.map((option) =>
        extractOptionWithCount(option, practiceIdentifiersCountMap)
      );

    // agents
    const agentsCountMap = extractObjectMap(agentsCounts, 'agent');
    const agentsCountsWithCount = filterOptions.agents?.data?.map((option) =>
      extractOptionWithCountFromAgents(option, agentsCountMap)
    );

    // rules
    const rulesCountMap = extractObjectMap(ruleCounts, 'appliedRule');
    const rulesWithCount = filterOptions.rules?.data?.map((option) =>
      extractOptionWithCount(option, rulesCountMap)
    );

    // action completed by
    const actionCompletedByMap = extractObjectMap(
      actionCompletedByCounts,
      'username'
    );
    const actionCompletedByWithCount = filterOptions.agents?.data?.map(
      (option) => extractOptionWithCountFromAgents(option, actionCompletedByMap)
    );

    // action assigned by
    const actionAssignedByMap = extractObjectMap(
      actionAssignedByCounts,
      'username'
    );
    const actionAssignedByWithCount = filterOptions.agents?.data?.map(
      (option) => extractOptionWithCountFromAgents(option, actionAssignedByMap)
    );

    const sortOptions = (options) =>
      orderBy(
        options,
        [
          (option) => {
            if (option.label.toLowerCase().trim() === 'all') {
              return undefined;
            }

            return option.count || 0;
          },
        ],
        ['desc']
      );

    return {
      ...filterOptions,
      payers: {
        data: sortOptions(payersWithCount),
        isFetching: filterOptions.payers?.isFetching,
      },
      practices: {
        data: sortOptions(practiceIdentifierCountsWithCount),
        isFetching: filterOptions.practices?.isFetching,
      },
      agents: {
        data: sortOptions(agentsCountsWithCount),
        isFetching: filterOptions.agents?.isFetching,
      },
      rules: {
        data: sortOptions(rulesWithCount),
        isFetching: filterOptions.rules?.isFetching,
      },
      actionCompletedBy: {
        data: sortOptions(actionCompletedByWithCount),
        isFetching: filterOptions.agents?.isFetching,
      },
      actionAssignedBy: {
        data: sortOptions(actionAssignedByWithCount),
        isFetching: filterOptions.agents?.isFetching,
      },
    };
  };
  const filterWithCount = addCountToActionLogsFilterOptions(
    filterOptions,
    filterWithCountOptions
  );

  const ruleSelectedOption =
    (filterWithCount.rules?.data &&
      filterWithCount.rules?.data.find(
        (option) => option.value === filters.appliedRule
      )) ||
    null;

  const getFileName = (isCompleted, isArchived) => {
    const currentDate = getFormattedDate(new Date());
    if (isArchived) {
      return isCompleted
        ? `archived-completed-actions-${currentDate}.csv`
        : `archived-pending-actions-${currentDate}.csv`;
    } else {
      return isCompleted
        ? `completed-actions-${currentDate}.csv`
        : `pending-actions-${currentDate}.csv`;
    }
  };

  const getCurrentTableName = (isCompleted, isArchived) => {
    let tableName = '';
    if (isArchived) {
      tableName = isCompleted
        ? `Archived completed Actions`
        : `Archived Pending Actions`;
    } else {
      tableName = isCompleted ? `Completed Actions` : `Pending Actions`;
    }

    return `Current Tab (${tableName} - ${totalCount})`;
  };

  const resetFilters = useCallback(() => {
    setIsFiltersLocked(false);
    localStorage.removeItem(
      getLockKeyForActionLogFilters(props.userId, props.logType)
    );
    localStorage.removeItem(
      getValueKeyForActionLogFilters(props.userId, props.logType)
    );
    handleFilterChange(DEFAULT_FILTERS);
    history.push(location.pathname);
  }, [
    handleFilterChange,
    history,
    location.pathname,
    props.userId,
    props.logType,
  ]);

  const onExportCSVPressed = async () => {
    const completedValue = logType.completed || 0;
    setIsExportingCSV(true);
    const fileName = getFileName(completedValue, isArchivedPage);
    const currentUsername = props?.userInfo?.username;
    const actionByFilterLogs = Object.entries(
      applyGlobalFilterForActionLogs(
        filters,
        globalFilters,
        currentUsername,
        sourceFilters
      )
    );
    const searchRequestBodyParams = actionByFilterLogs.map(([key, value]) => {
      if (key === 'agents' && value && !Array.isArray(value)) {
        return { key, value: [value] };
      }
      return { key, value };
    });
    try {
      const sort = !isEmpty(sortBy)
        ? sortBy.desc
          ? `-${snakeCase(sortBy.id)}`
          : snakeCase(sortBy.id)
        : '';
      const search = filter(
        searchRequestBodyParams,
        (o) => o.value || o.value === 0
      ).map(({ key, value }) => ({ key: FILTER_KEYS[key] || key, value }));
      const res = await DenialsAPI.downloadReportForDenialsQueue({
        filters: search,
        sort,
        search,
        practiceId: practice,
        secretKey,
        completed: completedValue,
        action: 1,
        fetchArchivedItems: isArchivedPage,
        onlyExport: 1,
        fileName,
        mimeType: 'text/csv',
      });
      setIsExportingCSV(false);
      toast.success({
        title: 'Success',
        message: EXPORT_CSV_MESSAGE,
      });
      return res;
    } catch (error) {
      setIsExportingCSV(false);
    }
  };

  const logsWithAgentsName = useMemo(
    () => actionLogs.map((log) => attachAgentsNameToActionLog(log, users)),
    [actionLogs, attachAgentsNameToActionLog, users]
  );

  return (
    <div
      className="denials-queue-container denial-table-container removal-log-container"
      datacy="ActionLogs"
    >
      <div className="appealio-table-container">
        <div
          className={classnames('appealio-table__action-bar row no-gutter', {
            'background-grey-4': isArchivedPage,
          })}
        >
          <div className="appealio-table__checkbox-action col-md-3">
            {actionLogs.length ? (
              <React.Fragment>
                {(isUserAuthorizedForAutoArchiveDQ(userInfo) ||
                  logType.completed === INCOMPLETE_LOG) && (
                  <input
                    className="select-all-checkbox ml-4"
                    data-tip={checkboxTitleBulkAction}
                    onChange={onSelectAllLogsClicked}
                    type="checkbox"
                    checked={isAllSelected}
                    ref={(el) =>
                      el &&
                      (el.indeterminate =
                        !isAllSelected && isSomeClaimsSelected)
                    }
                    datacy="select-all-checkbox-input"
                  />
                )}
                <ReactTooltip effect="solid" />
                {selectedClaims &&
                selectedClaims.length &&
                logType.completed === INCOMPLETE_LOG &&
                !isArchivedPage ? (
                  <Button
                    title="Completed"
                    type={BUTTON_TYPE.SECONDARY}
                    iconClassName="mr-4 py-5"
                    icon={completeIcon}
                    className="ml-20"
                    onClick={() => handleMarkAsDone()}
                    datacy="denial-table-remove-Button"
                  />
                ) : null}
                {isUserAuthorizedForAutoArchiveDQ(userInfo) &&
                !shouldHideArchiveButton &&
                selectedClaims &&
                selectedClaims.length ? (
                  <Button
                    iconClassName="mr-4"
                    icon={binIcon}
                    title={isArchivedPage ? 'Unarchive' : 'Archive'}
                    type={BUTTON_TYPE.SECONDARY}
                    className="ml-20"
                    onClick={() =>
                      isArchivedPage ? handleUnArchive() : handleArchive()
                    }
                  />
                ) : null}
              </React.Fragment>
            ) : null}
          </div>

          {showSearchBox && (
            <SearchBox value={filters.search} onSearch={handleSearchSubmit} />
          )}

          <div
            className={classnames('appealio-table__filter col-md-6', {
              'mr-80': isResetButtonVisible && showSearchBox,
            })}
          >
            <div className={'d-flex align-items-center'}>
              <Dropdown
                className="width-160 mr-16"
                name="rules"
                placeholder="Filter by Rule"
                onChange={(selectedOption) => {
                  handleFilterChange({
                    ...filters,
                    appliedRule: selectedOption.value,
                  });
                }}
                isClearable={false}
                options={filterWithCount?.rules?.data || []}
                isLoading={false}
                value={ruleSelectedOption}
                hideLabel
                datacy="rules-Dropdown"
                toolTip={ruleSelectedOption ? ruleSelectedOption.label : ''}
              />
              <Dropdown
                className="width-160 mr-16"
                name="removalReason"
                placeholder="Filter by Action"
                onChange={(selectedOption) => {
                  handleFilterChange({
                    ...filters,
                    removalReason: selectedOption.value,
                  });
                }}
                isClearable={false}
                options={[{ value: '', label: 'All' }, ...actionList]}
                isLoading={false}
                value={removalReasonSelectionOption}
                hideLabel
                datacy="removal-reason-Dropdown"
                toolTip={
                  removalReasonSelectionOption
                    ? removalReasonSelectionOption.label
                    : ''
                }
              />
              <Button
                title=""
                icon={filters.search ? searchIconRed : searchIcon}
                className="mr-16"
                type={BUTTON_TYPE.SECONDARY}
                onClick={() => setShowSearchBox(!showSearchBox)}
                datacy="more-filters-search-Button"
              />

              <Button
                title=""
                icon={isFiltersApplied ? filterIconRed : settingsIcon}
                className="appealio-filter-button"
                type={BUTTON_TYPE.SECONDARY}
                onClick={() => setShowFilters(!showFilters)}
                datacy="more-filters-search-Button"
              >
                {appliedFiltersCount ? (
                  <Avatar
                    withBorder={false}
                    name={appliedFiltersCount.toString()}
                    size={16}
                    shouldRenderFullName={true}
                    className="ml-4"
                    datacy="denial-queue-container-Avatar"
                  />
                ) : null}
              </Button>
              <LockFilterButton
                className="ml-12"
                isLocked={isFiltersLocked}
                onClick={toggleLock}
              />
              {isResetButtonVisible && (
                <button
                  className="ap-button--link ap-button--link-danger"
                  onClick={resetFilters}
                  datacy="filter-reset-button"
                >
                  Reset
                </button>
              )}
            </div>
          </div>

          <div className="appealio-table__action-bar-pagination col-md-5">
            {/* Download csv  */}
            {!showSearchBox && (
              <div className="dq-download_report-container">
                <LoadingBlockHelper isLoading={loading}>
                  <DownloadClaimsCsv
                    tableName={getCurrentTableName(
                      Number(logType.completed),
                      isArchivedPage
                    )}
                    onClick={onExportCSVPressed}
                    isLoading={isExportingCSV}
                    userInfo={props.userInfo}
                  />
                </LoadingBlockHelper>
              </div>
            )}
            <span className="denial-table-count">
              {selectedClaims.length
                ? `${selectedClaims.length} Selected`
                : claimsCountText}{' '}
            </span>
            {!loading && pageCount && pageCount > 1 ? (
              <div className="d-flex justify-content-center">
                <TablePagination
                  pageCount={pageCount}
                  activePage={page}
                  onPageChange={onPageChange}
                  datacy="denial-queue-container-Pagination"
                />
              </div>
            ) : null}
          </div>
        </div>

        <AppealioTable
          columns={columns}
          onSort={onTableSort}
          data={logsWithAgentsName}
          isLoading={loading}
          className={classnames('denials-queue-action-logs', {
            'denials-queue-removal-logs--todo':
              logType.completed === INCOMPLETE_LOG,
            'appealio-table-body--grey-1-bg': isArchivedPage,
          })}
          defaultSortBy={sortBy}
          datacy="denials-queue-action-logs-AppealioTable"
        />
      </div>
      {showFilters && (
        <ActionLogPopupFilter
          dropDownFilters={dropDownFilters}
          filters={filters}
          logType={logType}
          setShowFilters={setShowFilters}
          filterOptions={filterWithCount}
          onFilterChange={handleFilterChange}
          isAdminOrStaffUser={isSuperUserOrStaffUser(userInfo)}
        />
      )}
      {notesPopup.isOpen && (
        <NotesPopup
          dqId={notesPopup.denialId}
          claimId={notesPopup.claimId}
          claimNumber={notesPopup.claimNumber}
          userInfo={userInfo}
          agents={filterOptions.agents?.data}
          setShowNotesPopup={onCloseNotesPopup}
          isAppeal={false}
          queryParams={notesQueryParams}
        />
      )}
      {reminderPopup.isOpen && (
        <ReminderPopup
          onClosePressed={() => {
            setReminderPopup({
              isOpen: false,
              denialsQueueId: null,
              claimId: null,
            });
            fetchReminders();
          }}
          denialsQueueId={reminderPopup.denialsQueueId}
          claimId={reminderPopup.claimId}
          reminderId={reminderPopup.reminderId}
        />
      )}
    </div>
  );
}, isEqual);

function mapStateToProps(state) {
  const userInfo = getUserInfo(state);
  const users =
    getDropdownOptions(state, DROPDOWN_OPTIONS_STATE_KEYS.AGENT_OPTIONS)
      ?.data || [];
  const userPractices = get(userInfo, 'relatedPractices', []);
  return {
    users,
    userInfo,
    userPractices,
    userId: userInfo.id,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ push, setAppealData }, dispatch),
  };
}

ActionLogs.propTypes = {};

export default connect(mapStateToProps, mapDispatchToProps)(ActionLogs);
