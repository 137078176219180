import classnames from 'classnames';
import React, { useEffect, useState } from 'react';
import './style.css';

import * as toast from 'components/Shared/toast';
import AddOrUpdateSopForm from './AddOrUpdateSopForm';

import { USER_ROLES_ENUM } from 'constants/appConstants';

import { handleError } from 'helpers/errorHandler';
import { withoutAttrs, filterByPropertyExclusion } from 'helpers/object';

import {
  createSopForRule,
  updateSopForRule,
  deleteSop,
  deleteSopStep,
} from 'API/SopAPI';

import { mapToRequestData } from '../ImportForm/utils';
import { INITIAL_FORM_VALUES } from '../ImportForm/ImportForm';

import { useFetchDetailsForSop, useFetchSopList } from './hooks';

import SopList from './SopList';
import LoadingIndicator from 'components/Shared/LoadingIndicator';

export const SOP_FORM_STEPS = {
  STEP1: 1,
  STEP2: 2,
};

const SopContainer = (props) => {
  const {
    ruleId,
    ruleName,
    fetchSopInfo,
    handleSopChange,
    selectedPayers,
    excludedPayers,
    selectedRemarks,
    excludedRemarks,
    selectedProcedureCodes,
    excludedProcedureCodes,
    selectedReasonCodes,
    excludedReasonCodes,
    selectedProcedureModifierCodes,
    excludedProcedureModifierCodes,
    isZeroPayAnyClaimLineDisabled,
    isSelectedZeoClaimLineOptionWithoutAnyClaimLine,
    dropdownOptions,
    onClosePressed,
  } = props;

  const [sopId, setSopId] = useState(null);
  const [sopStep, setSopStep] = useState(SOP_FORM_STEPS.STEP1);
  const { initialFormValues, loading: isFetchingSopDetails } =
    useFetchDetailsForSop(sopId, dropdownOptions);

  const {
    loading: isFetchingSopList,
    sopList,
    fetch: fetchSopList,
  } = useFetchSopList(ruleId);

  useEffect(() => {
    if (!isFetchingSopList && !sopId && sopList.length) {
      setSopId(sopList[0].id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetchingSopList]);

  const handleSOPSubmissionError = async (error) => {
    const errorResponse = await error?.response?.json();

    if (
      error?.response?.status === 400 &&
      errorResponse?.mutually_exclusive_error
    ) {
      return toast.error({
        title: 'Validation Error',
        message: errorResponse.mutually_exclusive_error[0],
      });
    }

    if (error?.response?.status === 400 && errorResponse?.__all__) {
      toast.error({
        title: 'Error',
        message: errorResponse?.__all__[0],
      });
      setSopStep(SOP_FORM_STEPS.STEP1);
    }

    if (error?.response?.status === 400 && errorResponse?.non_field_errors) {
      toast.error({
        title: 'Error',
        message: errorResponse?.non_field_errors[0],
      });
    }

    handleError(error);
  };

  const handleStepsFormSubmit = async (formValues) => {
    const formData = formValues.toJS();
    const mapStepFormData = (data) => {
      const transformedSteps = data.steps.map((step) => ({
        ...(step.id && { id: step.id }),
        action_id: step.action.value,
        role: USER_ROLES_ENUM[step.role.value] || step.role.value,
        instruction: step.instruction,
      }));

      return {
        ruleId,
        steps: transformedSteps,
      };
    };
    const stepData = mapStepFormData(formData);
    const sopData = mapToRequestData({
      ...INITIAL_FORM_VALUES,
      ...withoutAttrs(formData, ['name', '']),
    });
    const payload = {
      steps: stepData.steps,
      ...sopData,
      name: formData.name,
      ruleId,
    };

    try {
      if (sopId) {
        await updateSopForRule(sopId, payload);
        toast.success({
          title: 'Success',
          message: 'Standard Operating Procedure updated successfully',
        });
        fetchSopInfo(sopId, true);
        onClosePressed();
        handleSopChange(sopId, ruleId);
      } else {
        const { data } = await createSopForRule(payload);
        toast.success({
          title: 'Success',
          message: 'Standard Operating Procedure created successfully',
        });
        fetchSopInfo(data?.id, true);
        onClosePressed();
        handleSopChange(data?.id, ruleId);
      }
    } catch (error) {
      handleSOPSubmissionError(error);
    }
  };

  const deleteSopForRule = async () => {
    try {
      await deleteSop(sopId);
      toast.success({
        title: 'Success',
        message: 'Standard Operating Procedure deleted successfully',
      });
      await fetchSopList();
      handleSopChange(sopList[0]?.id, ruleId);
      setSopId(null);
      setSopStep(SOP_FORM_STEPS.STEP1);
    } catch (error) {
      handleError(error);
    }
  };

  const deleteSopStepById = async (stepId) => {
    try {
      await deleteSopStep(stepId);
      toast.success({
        title: 'Success',
        message: 'Step deleted successfully',
      });
      handleSopChange(sopId, ruleId);
    } catch (error) {
      handleError(error);
    }
  };

  // Reomve count from the label in option
  const addLabelFromValue = (arr) =>
    arr.map((option) => ({
      ...option,
      label: option.value,
    }));

  const sopFormDropdownOptions = {
    payerOptions: filterByPropertyExclusion(selectedPayers, excludedPayers),
    remarkOptions: addLabelFromValue(
      filterByPropertyExclusion(selectedRemarks, excludedRemarks)
    ),
    procedureCodeOptions: addLabelFromValue(
      filterByPropertyExclusion(selectedProcedureCodes, excludedProcedureCodes)
    ),
    reasonCodeOptions: addLabelFromValue(
      filterByPropertyExclusion(selectedReasonCodes, excludedReasonCodes)
    ),
    procedureModifierCodeOptions: addLabelFromValue(
      filterByPropertyExclusion(
        selectedProcedureModifierCodes,
        excludedProcedureModifierCodes
      )
    ),
  };

  if (isFetchingSopList) {
    return <LoadingIndicator showing={isFetchingSopList} />;
  }

  const isEditForm = !!sopId;

  return (
    <div className="sop-container">
      <div className="sop-container__sidebar">
        <div className="sop-container__sidebar-header">
          <p className="sop-container__sidebar-title"> Your SOP(s) </p>
          <div
            className={classnames('card-list__item card-list__item--action', {
              'card-list__item card-list__item--action-active': !sopId,
            })}
            onClick={() => {
              setSopId(null);
              setSopStep(SOP_FORM_STEPS.STEP1);
            }}
          >
            <span>+</span> Add New SOP
          </div>
        </div>
        <SopList
          ruleId={ruleId}
          selectedSopId={sopId}
          isFetchingSopList={isFetchingSopList}
          sopList={sopList}
          onSopClick={(sopId) => {
            setSopId(sopId);
            setSopStep(SOP_FORM_STEPS.STEP1);
          }}
        />
      </div>
      <div className="sop-container__main">
        <LoadingIndicator showing={isFetchingSopDetails} />
        {isEditForm && !isFetchingSopDetails && (
          <AddOrUpdateSopForm
            sopFormDropdownOptions={sopFormDropdownOptions}
            sopId={sopId}
            form={`add-update-sop-form-${sopId}`}
            isZeroPayAnyClaimLineDisabled={isZeroPayAnyClaimLineDisabled}
            isSelectedZeoClaimLineOptionWithoutAnyClaimLine={
              isSelectedZeoClaimLineOptionWithoutAnyClaimLine
            }
            handleFormSubmit={handleStepsFormSubmit}
            initialValues={initialFormValues}
            isFetchingSopDetails={isFetchingSopDetails}
            deleteSopForRule={deleteSopForRule}
            deleteSopStep={deleteSopStepById}
            ruleName={ruleName}
            sopListLength={initialFormValues?.steps?.length}
            sopStep={sopStep}
            setSopStep={setSopStep}
          />
        )}
        {!isEditForm && !isFetchingSopDetails && (
          <AddOrUpdateSopForm
            sopFormDropdownOptions={sopFormDropdownOptions}
            sopId={sopId}
            form={`add-update-sop-form`}
            isZeroPayAnyClaimLineDisabled={isZeroPayAnyClaimLineDisabled}
            isSelectedZeoClaimLineOptionWithoutAnyClaimLine={
              isSelectedZeoClaimLineOptionWithoutAnyClaimLine
            }
            handleFormSubmit={handleStepsFormSubmit}
            initialValues={{}}
            isFetchingSopDetails={false}
            deleteSopForRule={deleteSopForRule}
            deleteSopStep={deleteSopStepById}
            ruleName={ruleName}
            sopListLength={initialFormValues?.steps?.length}
            sopStep={sopStep}
            setSopStep={setSopStep}
          />
        )}
      </div>
    </div>
  );
};

export default SopContainer;
