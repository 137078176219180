import Immutable from 'immutable';
import querystring from 'query-string';
import { get as _get } from 'lodash';
import { routerActions } from 'connected-react-router';
import { connectedReduxRedirect } from 'redux-auth-wrapper/history4/redirect';

import {
  getUserInfo,
  isAuthenticated,
  isAuthenticating,
} from '../redux/reducers/loginStore';
import {
  isUserAuthorizedForPCE,
  isUserAuthorizedUB04Merge,
  isClientAdminOrSupervisor,
  isUserAuthorizedCms1500Merge,
  isUserAuthorizedForDashboard,
  isUserAuthorizedForDenialsPage,
  isUserAuthorizedForSubmission,
  isUserAuthorizedForAnalytics,
  isAuthorizedForDenialAnalytics,
  isAuthorizedForPracticeGroup,
  isAuthorizedForDeliveryTracker,
  isAuthorizedForTeams,
} from './AuthUtils';
import {
  isUHCClaimStatusCheckEnabledForUser,
  isUserAuthorizedForAutoArchiveDQ,
} from 'Auth/FeatureFlags';
import { getCurrentAppealData } from '../redux/reducers/createAppealStore';

import * as routes from '../constants/routes';
import { ONBOARDING } from '../constants/appConstants';

import { isProd2Instance } from 'helpers/utils';

export const IsUserCreatingAppeal = connectedReduxRedirect({
  wrapperDisplayName: 'IsUserCreatingAppeal',
  authenticatedSelector: (state) => {
    const isCreatingAppeal = !!getCurrentAppealData(state);

    return isCreatingAppeal;
  },
  redirectAction: routerActions.replace,
  redirectPath: routes.CREATE_APPEAL_PATIENT_INFO,
  allowRedirectBack: false,
});

export const IsUserAuthorizedForManagerSettings = connectedReduxRedirect({
  wrapperDisplayName: 'IsUserAuthorizedForManagerSettings',
  authenticatedSelector: (state) => {
    const userInfo = getUserInfo(state);

    return userInfo && isClientAdminOrSupervisor(userInfo.role);
  },
  redirectAction: routerActions.replace,
  redirectPath: routes.SUBMISSIONS_IN_PROGRESS,
  allowRedirectBack: false,
});

export const IsAuthorizedForCreateAccountInfoForm = connectedReduxRedirect({
  wrapperDisplayName: 'IsAuthorizedForCreateAccountInfoForm',
  redirectPath: routes.SUBMISSIONS_IN_PROGRESS,
  authenticatedSelector: (state) => {
    const loginStore = state.get('loginStore');
    const isAuthenticatedUser = isAuthenticated(loginStore);

    if (!isAuthenticatedUser) {
      return false;
    }

    const userInfo = _get(loginStore.toJS(), 'authState.userInfo', null);

    if (
      userInfo.clientStatus === ONBOARDING ||
      userInfo.status === ONBOARDING
    ) {
      return true;
    }

    return false;
  },
  redirectAction: routerActions.replace,
});

export const IsUserAuthenticated = connectedReduxRedirect({
  wrapperDisplayName: 'IsUserAuthenticated',
  redirectPath: routes.LOGIN,
  authenticatedSelector: (state) => isAuthenticated(state.get('loginStore')),
  authenticatingSelector: (state) => isAuthenticating(state),
  redirectAction: routerActions.replace,
});

export const IsUserAuthorizedForSubmission = connectedReduxRedirect({
  wrapperDisplayName: 'IsUserAuthorizedForSubmission',
  redirectPath: (state, ownProps) => {
    const userInfo = getUserInfo(state);
    const redirectMaps = {
      [routes.DENIED_QUEUE]: isUserAuthorizedForDenialsPage,
      [routes.ANALYTICS]: isUserAuthorizedForAnalytics,
      [routes.SUBMISSIONS]: isUserAuthorizedForSubmission,
      [routes.PATIENT_COST_ESTIMATOR_CREATE_ESTIMATE]: isUserAuthorizedForPCE,
    };

    return (
      Object.keys(redirectMaps).find((route) => {
        const isAuthorized = redirectMaps[route](userInfo);
        return isAuthorized;
      }) || routes.HELP
    );
  },
  authenticatedSelector: (state) => {
    const userInfo = getUserInfo(state);

    return isUserAuthorizedForSubmission(userInfo);
  },
  authenticatingSelector: (state) => isAuthenticating(state),
  redirectAction: routerActions.replace,
});

export const IsUserAuthorizedForAnalytics = connectedReduxRedirect({
  wrapperDisplayName: 'IsUserAuthorizedForAnalytics',
  redirectPath: '/',
  authenticatedSelector: (state) => {
    const userInfo = getUserInfo(state);

    return isUserAuthorizedForAnalytics(userInfo);
  },
  redirectAction: routerActions.replace,
});
export const IsDashboardEnabled = connectedReduxRedirect({
  wrapperDisplayName: 'IsDashboardEnabled',
  redirectPath: '/',
  authenticatedSelector: (state) => {
    const userInfo = getUserInfo(state);

    return isUserAuthorizedForDashboard(userInfo);
  },
  redirectAction: routerActions.replace,
});

export const IsAppealioExpressRoutesEnabled = connectedReduxRedirect({
  wrapperDisplayName: 'IsAppealioExpressRoutesEnabled',
  redirectPath: routes.NOT_FOUND,
  authenticatedSelector: () => {
    return !isProd2Instance();
  },
  redirectAction: routerActions.replace,
});

export const IsAuthorizedForDeliveryTracker = connectedReduxRedirect({
  wrapperDisplayName: 'IsAuthorizedForDeliveryTracker',
  redirectPath: '/',
  authenticatedSelector: (state) => {
    const userInfo = getUserInfo(state);

    return isAuthorizedForDeliveryTracker(userInfo);
  },
  redirectAction: routerActions.replace,
});

export const IsAuthorizedForPCE = connectedReduxRedirect({
  wrapperDisplayName: 'IsAuthorizedForPCE',
  redirectPath: '/',
  authenticatedSelector: (state) => {
    const userInfo = getUserInfo(state);

    return isUserAuthorizedForPCE(userInfo);
  },
  redirectAction: routerActions.replace,
});

export const IsAuthorizedForCMS1500MergeUtility = connectedReduxRedirect({
  wrapperDisplayName: 'IsAuthorizedForCMS1500MergeUtility',
  redirectPath: '/',
  authenticatedSelector: (state) => {
    const userInfo = getUserInfo(state);

    return isUserAuthorizedCms1500Merge(userInfo);
  },
  redirectAction: routerActions.replace,
});

export const IsAuthorizedForUB04MergeUtility = connectedReduxRedirect({
  wrapperDisplayName: 'IsAuthorizedForUB04MergeUtility',
  redirectPath: '/',
  authenticatedSelector: (state) => {
    const userInfo = getUserInfo(state);

    return isUserAuthorizedUB04Merge(userInfo);
  },
  redirectAction: routerActions.replace,
});

export const IsUserAuthorizedForDenialsPage = connectedReduxRedirect({
  wrapperDisplayName: 'IsUserAuthorizedForDenialsPage',
  authenticatedSelector: (state) => {
    const userInfo = getUserInfo(state);

    return isUserAuthorizedForDenialsPage(userInfo);
  },
  redirectPath: routes.SUBMISSIONS,
  redirectAction: routerActions.replace,
  allowRedirectBack: false,
});

export const IsUserAuthorizedForArchivedPage = connectedReduxRedirect({
  wrapperDisplayName: 'IsUserAuthorizedForArchivedPage',
  authenticatedSelector: (state) => {
    const userInfo = getUserInfo(state);

    return isUserAuthorizedForAutoArchiveDQ(userInfo);
  },
  redirectPath: routes.DENIED_QUEUE,
  redirectAction: routerActions.replace,
  allowRedirectBack: false,
});

export const IsUserAuthorizedForStatusTrackerPage = connectedReduxRedirect({
  wrapperDisplayName: 'IsUserAuthorizedForStatusTrackerPage',
  authenticatedSelector: (state) => {
    const userInfo = getUserInfo(state);

    return isUHCClaimStatusCheckEnabledForUser(userInfo);
  },
  redirectPath: routes.SUBMISSIONS_IN_PROGRESS,
  redirectAction: routerActions.replace,
  allowRedirectBack: false,
});

export const IsUserNotAuthenticated = connectedReduxRedirect({
  authenticatedSelector: (state, ownProps) => {
    // we allow user reach login page if there's expired=true in query
    // meaning that one of requests return 401 or 403 and we have to
    // delete auth token from redux
    const query = querystring.parse(ownProps.location.search);

    const isTokenExpired = query.expired === 'true';

    const tokenExpired = isTokenExpired
      ? Immutable.fromJS({ tokenExpired: true })
      : state.get('loginStore');

    return tokenExpired.has('tokenExpired')
      ? true
      : !isAuthenticated(tokenExpired);
  },
  wrapperDisplayName: 'IsUserNotAuthenticated',
  redirectPath: (_, ownProps) => {
    const query = querystring.parse(ownProps.location.search);

    return query.redirect || routes.DENIED_QUEUE;
  },
  redirectAction: routerActions.replace,
  allowRedirectBack: false,
});

export const isAuthorizedForDenialAnalyticsPage = connectedReduxRedirect({
  wrapperDisplayName: 'isAuthorizedForDenialAnalyticsPage',
  authenticatedSelector: (state) => {
    const userInfo = getUserInfo(state);

    return isAuthorizedForDenialAnalytics(userInfo);
  },
  redirectPath: routes.SUBMISSIONS_IN_PROGRESS,
  redirectAction: routerActions.replace,
  allowRedirectBack: false,
});

export const IsAuthorizedForPracticeGroupPage = connectedReduxRedirect({
  wrapperDisplayName: 'isAuthorizedForPracticeGroupPage',
  authenticatedSelector: (state) => {
    const userInfo = getUserInfo(state);

    return isAuthorizedForPracticeGroup(userInfo);
  },
  redirectPath: routes.SUBMISSIONS_IN_PROGRESS,
  redirectAction: routerActions.replace,
  allowRedirectBack: false,
});

export const IsAuthorizedForTeamsPage = connectedReduxRedirect({
  wrapperDisplayName: 'IsAuthorizedForTeamPage',
  authenticatedSelector: (state) => {
    const userInfo = getUserInfo(state);

    return isAuthorizedForTeams(userInfo);
  },
  redirectPath: routes.SUBMISSIONS_IN_PROGRESS,
  redirectAction: routerActions.replace,
  allowRedirectBack: false,
});
