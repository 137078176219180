import {
  SET_CURRENT_STEP,
  GET_DEADLINE_DATE,
  SAVE_DEADLINE_DATE,
  CREATE_APPEAL_SEND_PATIENT_INFO,
  ADD_APPEAL_LETTER,
  GET_APPEAL_BY_ID,
  CREATE_DENIAL_INFORMATION,
  SET_APPEAL_DATA,
  CLEAR_CURRENT_APPEAL_DATA,
  CREATE_REAPPEAL,
  SET_BILLING_AGENT,
  CREATE_FINAL_APPEAL,
  SET_APPEAL_PREFILL_DATA,
  SET_INCLUDE_EOB_DOCUMENT,
  SET_INCLUDE_CMS_1500_DOCUMENT,
  FETCH_SUBMISSION_PREVIEW_START,
  FETCH_SUBMISSION_PREVIEW_SUCCESS,
  FETCH_SUBMISSION_PREVIEW_FAILURE,
  RESET_SUBMISSION_PREVIEW,
} from './actionTypes';

import CreateAppealAPI from '../../API/CreateAppealAPI';
import { AppealStatusCode } from '../../API/AppealStatusCode';

export function setCurrentStep(currentStep) {
  return {
    type: SET_CURRENT_STEP,
    value: currentStep,
  };
}

export function createAppealAndSendPatientInfo(values) {
  return {
    type: CREATE_APPEAL_SEND_PATIENT_INFO,
    payload: CreateAppealAPI.createAppealAndSendPatientInfo(values.toJS()),
  };
}

export function setBillingAgent(appealID, agentID) {
  return {
    type: SET_BILLING_AGENT,
    payload: CreateAppealAPI.updateBillingAgent(appealID, agentID),
  };
}

export function getDeadlineForAppeal(denialDate, appealID) {
  return {
    type: GET_DEADLINE_DATE,
    payload: CreateAppealAPI.calculateDeadline(appealID, denialDate),
    meta: {
      appealID,
      denialDate,
    },
  };
}

export function saveDeadlineForAppeal(appealID, denialDate, deadline) {
  return {
    type: SAVE_DEADLINE_DATE,
    deadline,
    appealID,
    denialDate,
  };
}

export function getAppealById(id, includeOptions = null) {
  return {
    type: GET_APPEAL_BY_ID,
    payload: includeOptions
      ? CreateAppealAPI.getAppealById(id, includeOptions)
      : CreateAppealAPI.getAppealById(id),
    meta: {
      appealID: id,
      includeOptions,
    },
  };
}

export function createDenialInformation(
  appealParams,
  proceduresParams,
  procedureIdsToDelete
) {
  return {
    type: CREATE_DENIAL_INFORMATION,
    payload: CreateAppealAPI.updateAppealAndCreateProcedures(
      appealParams,
      proceduresParams,
      procedureIdsToDelete
    ),
  };
}

export function addAppealLetter(appealLetter, isExisting) {
  return {
    type: ADD_APPEAL_LETTER,
    payload:
      isExisting === true
        ? CreateAppealAPI.updateAppealLetter(appealLetter)
        : CreateAppealAPI.createAppealLetter(appealLetter),
  };
}

export function setAppealData(data) {
  return {
    type: SET_APPEAL_DATA,
    data,
  };
}

export function setAppealPreFillData(data) {
  return {
    type: SET_APPEAL_PREFILL_DATA,
    data,
  };
}

export function clearCurrentAppealData(initialAppealData = null) {
  return {
    type: CLEAR_CURRENT_APPEAL_DATA,
    ...(initialAppealData && { initialAppealData }),
  };
}

export function createReAppeal(appealID, appealAttributes, procedures) {
  return {
    type: CREATE_REAPPEAL,
    payload: CreateAppealAPI.createReAppeal(
      appealID,
      appealAttributes,
      procedures
    ),
    meta: {
      appealID,
    },
  };
}

export function createFinalAppeal(
  appealID,
  status = AppealStatusCode.Closed,
  appealAttributes,
  procedures
) {
  return {
    type: CREATE_FINAL_APPEAL,
    payload: CreateAppealAPI.createFinalAppeal(
      appealID,
      status,
      appealAttributes,
      procedures
    ),
    meta: {
      appealID,
    },
  };
}

export function setIncludeEobDocument(includeEobDocument) {
  return {
    type: SET_INCLUDE_EOB_DOCUMENT,
    value: includeEobDocument,
  };
}

export function setIncludeCms1500Document(includeCms1500Document) {
  return {
    type: SET_INCLUDE_CMS_1500_DOCUMENT,
    value: includeCms1500Document,
  };
}

export function resetSubmissionPreview() {
  return {
    type: RESET_SUBMISSION_PREVIEW,
  };
}

export function fetchSubmissionPreview(submissionId, bypassCache = false) {
  return async (dispatch, getState) => {
    const state = getState();
    const submissionPreview = state.getIn([
      'createAppealStore',
      'submissionPreview',
      submissionId,
    ]);

    if (
      !bypassCache &&
      submissionPreview &&
      submissionPreview.get('previewUrl')
    ) {
      return;
    }

    dispatch({
      type: FETCH_SUBMISSION_PREVIEW_START,
      payload: { submissionId },
    });

    try {
      const previewUrl = await CreateAppealAPI.createSubmissionPreviewURL(
        submissionId
      );
      dispatch({
        type: FETCH_SUBMISSION_PREVIEW_SUCCESS,
        payload: { submissionId, previewUrl },
      });
    } catch (error) {
      dispatch({
        type: FETCH_SUBMISSION_PREVIEW_FAILURE,
        payload: { submissionId, error: error.message },
      });
    }
  };
}
