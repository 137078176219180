import { addTypeToOptions } from 'components/ApDashboard/Manager/Settings/Organization/Users/PracticeSelect/utils';

import { INITIAL_FORM_VALUES } from '../ImportForm/ImportForm';
import { ZERO_PAY_RULE_OPTIONS } from 'constants/appConstants';
import { OPTION_TYPES } from 'components/ApDashboard/Manager/Settings/Organization/Users/PracticeSelect/constant';

const filterSelectOptions = (selectOptions, values) =>
  selectOptions.filter(({ value }) => values.includes(value));

export const extractInitialFormValues = (importRule, dropdownOptions) => {
  const payers = dropdownOptions?.payers?.data || [];
  const practices = dropdownOptions?.practices?.data || [];
  const practiceGroups = dropdownOptions?.practiceGroups?.data || [];
  const reasonCodes = dropdownOptions?.reasonCodes?.data || [];
  const cptCodes = dropdownOptions?.cptCodes?.data || [];
  const remarkCodes = dropdownOptions?.remarkCodes?.data || [];
  const procedureModifiers = dropdownOptions?.procedureModifiers?.data || [];
  const agents = dropdownOptions?.agents?.data || [];
  const providers = dropdownOptions?.providers?.data || [];

  return {
    ...INITIAL_FORM_VALUES,
    ruleId: importRule.id || '',
    name: importRule.name || '',
    effectiveDate:
      importRule.effectiveDate || INITIAL_FORM_VALUES.effectiveDate,
    practices: [
      ...addTypeToOptions(
        filterSelectOptions(practiceGroups, importRule?.forGroup?.pgroup || []),
        OPTION_TYPES.PRACTICE_GROUP
      ),
      ...addTypeToOptions(
        filterSelectOptions(practices, importRule.practiceIds || []),
        OPTION_TYPES.PRACTICE
      ),
    ],
    excludedPractices: [
      ...addTypeToOptions(
        filterSelectOptions(practiceGroups, importRule?.exclude?.pgroup || []),
        OPTION_TYPES.PRACTICE_GROUP
      ),
      ...addTypeToOptions(
        filterSelectOptions(practices, importRule?.exclude?.practiceIds || []),
        OPTION_TYPES.PRACTICE
      ),
    ],
    payers: filterSelectOptions(payers, importRule.payerIds || []),
    excludedPayers: filterSelectOptions(
      payers,
      importRule?.exclude?.payerIds || []
    ),
    minimumDeniedAmount: importRule.minimumDeniedAmount || '',
    procedureCodes: filterSelectOptions(
      cptCodes,
      importRule.procedureCodes || []
    ),
    excludedProcedureCodes: filterSelectOptions(
      cptCodes,
      importRule?.exclude?.procedureCodes || []
    ),
    reasonCodes: filterSelectOptions(reasonCodes, importRule.reasonCodes || []),
    excludedReasonCodes: filterSelectOptions(
      reasonCodes,
      importRule?.exclude?.reasonCodes || []
    ),
    remarkCodes: filterSelectOptions(remarkCodes, importRule.remarkCodes || []),
    excludedRemarkCodes: filterSelectOptions(
      remarkCodes,
      importRule?.exclude?.remarkCodes || []
    ),
    procedureModifierCodes: filterSelectOptions(
      procedureModifiers,
      importRule.procedureCodeModifiers || []
    ),
    excludedProcedureModifierCodes: filterSelectOptions(
      procedureModifiers,
      importRule?.exclude?.procedureCodeModifiers || []
    ),
    agents: filterSelectOptions(agents, importRule.agents || []),
    providers: filterSelectOptions(providers, importRule.providerNpis || []),
    onlyZeroPay:
      importRule?.paymentAmount === 0 ||
      importRule?.checkServiceLineZeroPay === true,
    onlyZeroPayRule:
      ZERO_PAY_RULE_OPTIONS[
        importRule?.checkServiceLineZeroPay === true ? 1 : 0
      ],
    excludeOnlyZeroPay:
      importRule?.exclude?.paymentAmount === 0 ||
      importRule?.exclude?.checkServiceLineZeroPay === true,
    excludeOnlyZeroPayRule:
      ZERO_PAY_RULE_OPTIONS[
        importRule?.exclude?.checkServiceLineZeroPay === true ? 1 : 0
      ],
  };
};
