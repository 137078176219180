import { useCallback, useEffect, useState, useRef } from 'react';

import AppealsAPI from 'API/AppealsAPI';
import CreateAppealAPI from 'API/CreateAppealAPI';
import * as AccountsAPI from 'API/AccountSettingsAPI';

import { handleError } from 'helpers/errorHandler';
import { camelizeKeys, snakeCaseKeys } from 'helpers/object';
import { SEARCH_QUERY_CHARACTERS_OFFSET } from './constants';

export const useFetchPractices = (page, search) => {
  const [error, setError] = useState();
  const [loading, setLoading] = useState(true);
  const [totalPractices, setTotalPractices] = useState(0);
  const [practices, setPractices] = useState([]);

  const fetch = useCallback(async () => {
    setLoading(true);
    setPractices([]);
    setTotalPractices(0);

    try {
      const queryParam = {
        ...(!search && { page }),
        ...(search && { search }),
      };
      const {
        data,
        meta: { total },
      } = await AccountsAPI.fetchPractices(queryParam);
      setPractices(data);
      setTotalPractices(total);
    } catch (error) {
      setError(error);
    }
    setLoading(false);
  }, [page, search]);

  useEffect(() => {
    fetch();
  }, [page, search, fetch]);

  return { loading, error, practices, totalPractices, fetch };
};

export const useFetchClinics = (page, practice, search) => {
  const [error, setError] = useState();
  const [loading, setLoading] = useState(true);
  const [totalClinics, setTotalClinics] = useState(0);
  const [clinics, setClinics] = useState([]);
  const fetchAbortControllerRef = useRef(null);

  const fetch = useCallback(async () => {
    setLoading(true);
    setClinics([]);
    setTotalClinics(0);

    if (fetchAbortControllerRef.current) {
      fetchAbortControllerRef.current.abort();
    }
    fetchAbortControllerRef.current = new AbortController();

    if (!practice) {
      return setLoading(false);
    }

    try {
      const queryParam = {
        page,
        practice,
        ...(search && { search }),
      };
      const {
        data,
        meta: { total },
      } = await AccountsAPI.fetchClinics(
        queryParam,
        fetchAbortControllerRef.current.signal
      );
      setClinics(data);
      setTotalClinics(total);
    } catch (error) {
      setError(error);
    }
    setLoading(false);
  }, [page, search, practice]);

  useEffect(() => {
    fetch();
  }, [page, practice, fetch]);

  return { loading, error, clinics, totalClinics, fetch };
};

export const useFetchBillingProviders = (page, practice, search) => {
  const [error, setError] = useState();
  const [loading, setLoading] = useState(true);
  const [totalBillingProviders, setTotalBillingProviders] = useState(0);
  const [billingProviders, setBillingProviders] = useState([]);
  const fetchAbortControllerRef = useRef(null);

  const fetch = useCallback(async () => {
    setLoading(true);
    setBillingProviders([]);
    setTotalBillingProviders(0);

    if (!practice) {
      return setLoading(false);
    }

    if (fetchAbortControllerRef.current) {
      fetchAbortControllerRef.current.abort();
    }
    fetchAbortControllerRef.current = new AbortController();

    try {
      const queryParam = {
        page,
        practice_id: practice,
        ...(search && { search }),
      };
      const {
        data,
        meta: { total },
      } = await AccountsAPI.fetchBillingProviders(
        queryParam,
        fetchAbortControllerRef.current.signal
      );
      setBillingProviders(data);
      setTotalBillingProviders(total);
    } catch (error) {
      setError(error);
    }
    setLoading(false);
  }, [page, search, practice]);

  useEffect(() => {
    fetch();
  }, [page, practice, fetch]);

  return { loading, error, billingProviders, totalBillingProviders, fetch };
};

export const useFetchBillingProviderCount = (query) => {
  const [error, setError] = useState();
  const [loading, setLoading] = useState(true);
  const [billingProviderCount, setBillingProviderCount] = useState();

  const fetchAbortControllerRef = useRef(null);

  const fetch = useCallback(async () => {
    setLoading(true);
    setBillingProviderCount(null);
    const queryParam = {
      ...(query && { search: query }),
    };

    if (fetchAbortControllerRef.current) {
      fetchAbortControllerRef.current.abort();
    }

    fetchAbortControllerRef.current = new AbortController();
    try {
      const { data } = await AccountsAPI.fetchPracticeBillingProviderCount(
        queryParam,
        fetchAbortControllerRef.current.signal
      );
      setBillingProviderCount(data);
    } catch (error) {
      setError(error);
    }
    setLoading(false);
  }, [query]);

  useEffect(() => {
    if (!query || query.length < SEARCH_QUERY_CHARACTERS_OFFSET) {
      setBillingProviderCount(null);
      return;
    }
    fetch();
  }, [fetch, query]);

  return { loading, error, billingProviderCount };
};

export const useFetchAllClinics = () => {
  const [error, setError] = useState();
  const [loading, setLoading] = useState(true);
  const [totalClinics, setTotalClinics] = useState(0);
  const [clinics, setClinics] = useState([]);

  const fetch = useCallback(async () => {
    setLoading(true);
    setClinics([]);
    setTotalClinics(0);

    try {
      const {
        data,
        meta: { total },
      } = await AccountsAPI.fetchClinics({
        disable_pagination: true,
      });
      setClinics(data);
      setTotalClinics(total);
    } catch (error) {
      setError(error);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return { loading, error, clinics, totalClinics, fetch };
};

export const useFetchAllBillingProviders = () => {
  const [error, setError] = useState();
  const [loading, setLoading] = useState(true);
  const [totalBillingProviders, setTotalBillingProviders] = useState(0);
  const [billingProviders, setBillingProviders] = useState([]);

  const fetch = useCallback(async () => {
    setLoading(true);
    setBillingProviders([]);
    setTotalBillingProviders(0);

    try {
      const queryParam = {
        disable_pagination: true,
      };
      const {
        data,
        meta: { total },
      } = await AccountsAPI.fetchBillingProviders(queryParam);
      setBillingProviders(data);
      setTotalBillingProviders(total);
    } catch (error) {
      setError(error);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return { loading, error, billingProviders, totalBillingProviders, fetch };
};

export const useFetchRenderingProviders = (page, practiceId, search) => {
  const [error, setError] = useState();
  const [loading, setLoading] = useState(true);
  const [totalRenderingProviders, setTotalRenderingProviders] = useState(0);
  const [renderingProviders, setRenderingProviders] = useState([]);

  const fetch = useCallback(async () => {
    setLoading(true);
    setRenderingProviders([]);
    setTotalRenderingProviders(0);

    if (!practiceId) {
      return setLoading(false);
    }

    try {
      const queryParam = {
        page,
        practice_id: practiceId,
        ...(search && {
          search,
        }),
      };
      const {
        data,
        meta: { total },
      } = await AccountsAPI.fetchRenderingProviders(queryParam);
      setRenderingProviders(data);
      setTotalRenderingProviders(total);
    } catch (error) {
      setError(error);
    }
    setLoading(false);
  }, [practiceId, search, page]);

  useEffect(() => {
    fetch();
  }, [page, practiceId, fetch]);

  return { loading, error, renderingProviders, totalRenderingProviders, fetch };
};

export const useFetchRenderingProviderCount = (query) => {
  const [error, setError] = useState();
  const [loading, setLoading] = useState(true);
  const [renderingProviderCount, setRenderingProviderCount] = useState();

  const fetchAbortControllerRef = useRef(null);

  const fetch = useCallback(async () => {
    setLoading(true);
    setRenderingProviderCount(null);
    const queryParam = {
      ...(query && { search: query }),
    };

    if (fetchAbortControllerRef.current) {
      fetchAbortControllerRef.current.abort();
    }

    fetchAbortControllerRef.current = new AbortController();
    try {
      const { data } = await AccountsAPI.fetchPracticeRenderingProviderCount(
        queryParam,
        fetchAbortControllerRef.current.signal
      );
      setRenderingProviderCount(data);
    } catch (error) {
      setError(error);
    }
    setLoading(false);
  }, [query]);

  useEffect(() => {
    if (!query || query.length < SEARCH_QUERY_CHARACTERS_OFFSET) {
      setRenderingProviderCount(null);
      return;
    }
    fetch();
  }, [fetch, query]);

  return { loading, error, renderingProviderCount };
};

export const useFetchPayers = (page, search) => {
  const [error, setError] = useState();
  const [loading, setLoading] = useState(true);
  const [totalPayers, setTotalPayers] = useState(0);
  const [payers, setPayers] = useState([]);

  const fetch = useCallback(async () => {
    setLoading(true);
    setPayers([]);
    setTotalPayers(0);

    try {
      const queryParam = {
        page,
        ...(search && { search }),
      };
      const {
        data,
        meta: { total },
      } = await AccountsAPI.fetchPayers(queryParam);
      setPayers(data);
      setTotalPayers(total);
    } catch (error) {
      setError(error);
    }
    setLoading(false);
  }, [page, search]);

  useEffect(() => {
    fetch();
  }, [page, search, fetch]);

  return { loading, error, payers, totalPayers, fetch };
};

export const useFetchPayersList = () => {
  const [error, setError] = useState();
  const [payers, setPayers] = useState([]);
  const [loading, setLoading] = useState(true);
  const fetch = useCallback(async () => {
    setLoading(true);
    setPayers([]);
    try {
      const data = await AppealsAPI.getDropdownOptions('payer-list');
      setPayers(data);
    } catch (error) {
      setError(error);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return { loading, error, payers, fetch };
};

export const useFetchPayerContacts = (page, payer, search) => {
  const [error, setError] = useState();
  const [loading, setLoading] = useState(true);
  const [totalContacts, setTotalContacts] = useState(0);
  const [payerContacts, setPayerContacts] = useState([]);

  const fetchAbortControllerRef = useRef(null);

  const fetch = useCallback(async () => {
    setLoading(true);
    setPayerContacts([]);
    setTotalContacts(0);
    if (fetchAbortControllerRef.current) {
      fetchAbortControllerRef.current.abort('Aborting previous request');
    }
    fetchAbortControllerRef.current = new AbortController();

    try {
      if (!payer) {
        setPayerContacts([]);
        setTotalContacts(0);
        setLoading(false);
        return;
      }
      const queryParam = {
        page,
        ...(payer && { payer }),
        ...(search && { search }),
      };
      const {
        data,
        meta: { total },
      } = await AccountsAPI.fetchPayerContacts(
        queryParam,
        fetchAbortControllerRef.current.signal
      );
      setPayerContacts(data);
      setTotalContacts(total);
    } catch (error) {
      setError(error);
    }
    setLoading(false);
  }, [page, search, payer]);

  useEffect(() => {
    fetch();
  }, [page, payer, search, fetch]);

  return { loading, error, payerContacts, totalContacts, fetch };
};

export const useFetchPayerContactCounts = (query) => {
  const [error, setError] = useState();
  const [loading, setLoading] = useState(true);
  const [payerContactCounts, setPayerContactCounts] = useState();

  const fetchAbortControllerRef = useRef(null);

  const fetch = useCallback(async () => {
    setLoading(true);
    setPayerContactCounts(null);
    const queryParam = {
      ...(query && { search: query }),
    };

    if (fetchAbortControllerRef.current) {
      fetchAbortControllerRef.current.abort();
    }

    fetchAbortControllerRef.current = new AbortController();
    try {
      const { data } = await AccountsAPI.fetchPayerContactCounts(
        queryParam,
        fetchAbortControllerRef.current.signal
      );
      setPayerContactCounts(data);
    } catch (error) {
      setError(error);
    }
    setLoading(false);
  }, [query]);

  useEffect(() => {
    if (!query || query.length < SEARCH_QUERY_CHARACTERS_OFFSET) {
      setPayerContactCounts(null);
      return;
    }
    fetch();
  }, [fetch, query]);

  return { loading, error, payerContactCounts };
};

/**
 * Use fetch payers hook.
 * @returns {Promise}
 */
export const useFetchPublicPayers = () => {
  const [error, setError] = useState();
  const [loading, setLoading] = useState(true);
  const [payers, setPayers] = useState([]);

  const fetch = useCallback(async () => {
    try {
      const data = await CreateAppealAPI.getDropdownOptions('payers', {
        is_public: 1,
      });

      setPayers(CreateAppealAPI.parsePayerData(data));
    } catch (error) {
      setError(error);
    }

    setLoading(false);
  }, []);
  useEffect(() => {
    setLoading(true);
    fetch();
  }, [fetch]);

  return { loading, error, payers };
};

export const useFetchTemplates = (page, search, type) => {
  const [error, setError] = useState();
  const [loading, setLoading] = useState(true);
  const [templates, setTemplates] = useState([]);
  const [totalTemplates, setTotalTemplates] = useState(null);

  useEffect(() => {
    setLoading(true);
    const fetch = async () => {
      const params = {
        paginate: true,
        page,
        ...(search && { search }),
        type,
      };
      try {
        const data = await CreateAppealAPI.getDropdownOptions(
          'appeal_templates',
          params
        );
        setTemplates(CreateAppealAPI.parseTemplates(data));
        setTotalTemplates(data.meta.pagination.count);
      } catch (error) {
        setError(error);
      }

      setLoading(false);
    };

    fetch();
  }, [page, type, search]);

  return { loading, error, templates, totalTemplates, fetch };
};

export const useFetchPayerTemplateCount = (query, templateType) => {
  const [error, setError] = useState();
  const [loading, setLoading] = useState(true);
  const [appealTemplateCount, setAppealTemplateCount] = useState();

  const fetchAbortControllerRef = useRef(null);

  const fetch = useCallback(async () => {
    setLoading(true);
    setAppealTemplateCount(null);
    const queryParam = {
      ...(query && { search: query, template_type: templateType }),
    };

    if (fetchAbortControllerRef.current) {
      fetchAbortControllerRef.current.abort();
    }

    fetchAbortControllerRef.current = new AbortController();
    try {
      const { data } = await AccountsAPI.fetchPayerAppealTemplateCount(
        queryParam,
        fetchAbortControllerRef.current.signal
      );
      setAppealTemplateCount(data);
    } catch (error) {
      setError(error);
    }
    setLoading(false);
  }, [query, templateType]);

  useEffect(() => {
    if (!query || query.length < SEARCH_QUERY_CHARACTERS_OFFSET) {
      setAppealTemplateCount(null);
      return;
    }
    fetch();
  }, [fetch, query]);

  return { loading, error, appealTemplateCount };
};

export const useFetchAppealTemplates = (page, type, payerId, search) => {
  const [error, setError] = useState();
  const [loading, setLoading] = useState(true);
  const [templates, setTemplates] = useState([]);
  const [totalTemplates, setTotalTemplates] = useState(null);

  useEffect(() => {
    setLoading(true);
    const fetch = async () => {
      const params = {
        paginate: true,
        page,
        ...(!payerId ? { unassignedPayer: '1' } : payerId && { payerId }),
        type,
        ...(search && { search }),
      };
      try {
        const { data, meta } = await CreateAppealAPI.getDropdownOptions(
          'appeal-templates',
          snakeCaseKeys(params)
        );
        setTemplates(camelizeKeys(data));
        setTotalTemplates(meta.total);
      } catch (error) {
        setError(error);
      }

      setLoading(false);
    };

    fetch();
  }, [page, search, type, payerId]);

  return { loading, error, templates, totalTemplates, fetch };
};

export const useFilterPractices = (userPractices, search) => {
  const [practices, setPractices] = useState([]);
  useEffect(() => {
    const filterPractices = () => {
      let filteredList = userPractices;
      if (search) {
        filteredList = userPractices.filter((practice) => {
          if (practice.name.toLowerCase().startsWith(search.toLowerCase())) {
            return practice;
          }

          return null;
        });
      }
      setPractices(filteredList);
    };
    filterPractices();
  }, [search, userPractices]);

  return { practices };
};

export const useFetchClinicCounts = (query) => {
  const [error, setError] = useState();
  const [loading, setLoading] = useState(true);
  const [clinicCount, setClinicCount] = useState();

  const fetchAbortControllerRef = useRef(null);

  const fetch = useCallback(async () => {
    setLoading(true);
    setClinicCount(null);
    const queryParam = {
      ...(query && { search: query }),
    };

    if (fetchAbortControllerRef.current) {
      fetchAbortControllerRef.current.abort();
    }

    fetchAbortControllerRef.current = new AbortController();
    try {
      const { data } = await AccountsAPI.fetchPracticeClinicCount(
        queryParam,
        fetchAbortControllerRef.current.signal
      );
      setClinicCount(data);
    } catch (error) {
      setError(error);
    }
    setLoading(false);
  }, [query]);

  useEffect(() => {
    if (!query || query.length < SEARCH_QUERY_CHARACTERS_OFFSET) {
      setClinicCount(null);
      return;
    }
    fetch();
  }, [fetch, query]);

  return { loading, error, clinicCount };
};

/**
 * Fetches payer identifiers from the API.
 *
 * Returns an object with:
 * - loading: boolean for whether it is loading
 * - error: any error that occurred
 * - payerIdentifiers: array of fetched payer identifiers
 */
export const useFetchPayerIdentifiers = () => {
  const [error, setError] = useState();
  const [loading, setLoading] = useState(true);
  const [payerIdentifiers, setPayerIdentifiers] = useState([]);
  const [payerIdentifiersOptions, setPayerIdentifiersOptions] = useState([]);

  const addNewPayerIdentifierOption = (option) => {
    setPayerIdentifiersOptions([...payerIdentifiersOptions, option]);
  };

  const fetch = useCallback(async () => {
    try {
      const data = await AccountsAPI.fetchPayerIdentifiers();
      setPayerIdentifiers(data);
      setPayerIdentifiersOptions(
        data.map((payerIdentifier) => ({
          id: payerIdentifier.id,
          value: payerIdentifier.id,
          label: `${payerIdentifier.identificationNumber} (${
            payerIdentifier.partnerName || 'N/A'
          })`,
        }))
      );
    } catch (error) {
      setError(error);
    }
    setLoading(false);
  }, []);
  useEffect(() => {
    setLoading(true);
    fetch();
  }, [fetch]);
  return {
    loading,
    error,
    payerIdentifiers,
    payerIdentifiersOptions,
    addNewPayerIdentifierOption,
  };
};

export const useFetchDenialActions = (page, search) => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [totalDenialActions, setTotalDenialActions] = useState(0);
  const [denialActions, setDenialActions] = useState([]);

  const fetch = useCallback(async () => {
    setLoading(true);
    setDenialActions([]);
    setTotalDenialActions(0);

    try {
      const queryParam = { page, ...(search && { search }) };
      const {
        data,
        meta: { total },
      } = await AccountsAPI.fetchDenialActions(queryParam);
      setDenialActions(data);
      setTotalDenialActions(total);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }, [page, search]);

  const toggleDenialActionConfig = useCallback(
    async (action) => {
      const updateAction = (denialAction) => {
        if (denialAction.id === action.id && denialAction.autoReminderConfig) {
          return {
            ...denialAction,
            autoReminderConfig: {
              ...denialAction.autoReminderConfig,
              isEnabled: !denialAction.autoReminderConfig.isEnabled,
            },
          };
        }
        return denialAction;
      };

      const updatedDenialActions = denialActions.map(updateAction);
      setDenialActions(updatedDenialActions);

      try {
        await AccountsAPI.updateDenialActionConfig({
          actionType: action.id,
          ...action.autoReminderConfig,
          isEnabled: !action.autoReminderConfig.isEnabled,
        });
      } catch (error) {
        handleError(error);
        const revertedDenialActions = denialActions.map(updateAction);
        setDenialActions(revertedDenialActions);
      }
    },
    [denialActions]
  );

  useEffect(() => {
    fetch();
  }, [fetch]);

  return {
    loading,
    error,
    denialActions,
    totalDenialActions,
    fetch,
    toggleDenialActionConfig,
  };
};
